import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {VenueWithCoursesFragment} from 'gql/generated';
import {md5} from 'utils/object';

export type VenueFAQProps = {
    venue: VenueWithCoursesFragment;
};

const VenueFAQ: FC<VenueFAQProps> = ({venue}) => {
    const {t} = useTranslation();

    if (!venue.frequentlyAskedQuestions?.length) return null;

    return (
        <div className="mt-8 pb-8">
            <hr />
            <h3 className="mt-8 text-lg">{t('venue.faq')}</h3>
            <ul className="mt-5 space-y-4 text-xs sm:text-sm">
                {venue.frequentlyAskedQuestions.map((faq) => (
                    <li key={md5(faq)}>
                        <div className="text-grey-600 dark:text-grey-400">
                            {faq.question}
                        </div>
                        <div>{faq.answer}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default VenueFAQ;
