import {FC, ReactNode} from 'react';
import {FieldError, useFormContext} from 'react-hook-form';
import clsx from 'clsx';
import CheckboxRadioGroup from 'components/Form/CheckboxRadioGroup';
import {InputSizeProps} from 'components/Form/types';
import {getRegisterRules} from 'components/Form/utils';
import {Option, Size} from 'types';

export type BaseRadioButtonsProps<T = HTMLInputElement> = InputSizeProps<T> & {
    children?: ReactNode;
    classnameLabel?: string;
    error?: FieldError;
    isFullWidth?: boolean;
    isHorizontal?: boolean;
    isItemsCenter?: boolean;
    name: string;
    options: Option[];
    size?: Size;
    type?: never;
};

export const SIZE: Record<Size, string> = {
    base: 'w-6 h-6',
    lg: 'w-7 h-7',
    sm: 'w-5 h-5',
    xl: 'w-8 h-8',
    xs: 'w-4 h-4',
};

export const TEXT: Record<Size, string> = {
    base: 'text-base',
    lg: 'text-lg',
    sm: 'text-sm',
    xl: 'text-xl',
    xs: 'text-xs',
};

const BaseRadioButtons: FC<BaseRadioButtonsProps> = ({
    children,
    className,
    classnameLabel,
    disabled,
    error,
    id,
    isFullWidth,
    isHorizontal,
    isItemsCenter,
    name,
    options,
    required,
    rules,
    size = 'base',
    ...rest
}) => {
    const {register} = useFormContext();

    const registerRules = getRegisterRules({disabled, required, rules});

    return (
        <CheckboxRadioGroup
            className={className}
            isHorizontal={isHorizontal}
            size={size}
        >
            {options.map((option) => (
                <label
                    key={option.value}
                    className={clsx(
                        'flex select-none',
                        isFullWidth ? 'w-full' : 'w-fit',
                        isItemsCenter ? 'items-center' : 'items-start',
                        disabled || option.disabled || registerRules?.disabled
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    )}
                    htmlFor={`${id || name}-${option.value}`}
                >
                    <input
                        className={clsx(SIZE[size], classnameLabel)}
                        disabled={
                            disabled ||
                            option.disabled ||
                            registerRules?.disabled
                        }
                        {...register(name, registerRules)}
                        id={`${id || name}-${option.value}`}
                        required={!!(registerRules?.required && error)}
                        type="radio"
                        value={option.value}
                        {...rest}
                    />
                    <div
                        className={clsx(
                            'ml-2',
                            TEXT[size],
                            isFullWidth && 'flex-1',
                            size === 'xl' && 'mt-[3px]',
                            (disabled ||
                                option.disabled ||
                                registerRules?.disabled) &&
                                'text-disabled'
                        )}
                    >
                        {option.label}
                    </div>
                </label>
            ))}
            {children}
        </CheckboxRadioGroup>
    );
};

export default BaseRadioButtons;
