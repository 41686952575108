import {FC, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import ButtonAnchor from 'components/ButtonAnchor';
import {QuestionnaireSatisfactionRating} from 'gql/generated';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';

type UserReservationQuestionnaireStatusProps = {
    className?: string;
    feedback: string;
    satisfactionRating: QuestionnaireSatisfactionRating;
};

export const QuestionnaireStatus: FC<
    UserReservationQuestionnaireStatusProps
> = ({className, feedback, satisfactionRating}) => {
    const {t} = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const textRef = useRef<HTMLDivElement>(null);
    const breakpoint = useCurrentBreakpoint();

    const showExpand = useMemo(() => {
        if (!textRef.current || !breakpoint) return false;

        return textRef.current.scrollHeight > textRef.current.clientHeight;
    }, [breakpoint]);

    const onToggle = () => setIsCollapsed((p) => !p);

    return (
        <div
            className={clsx(
                'bg-step flex items-center rounded-md py-4 pr-4',
                className
            )}
        >
            <div className="w-14 flex-none text-center text-2xl">
                <FontAwesomeIcon
                    className="text-gold-200"
                    icon={['fas', 'check-circle']}
                    size="sm"
                />
            </div>
            <div className="border-l-[3px] border-gold-200 pl-4">
                <div className="font-semibold">
                    {t(
                        `user.reservations.questionnaire.satisfactionRating.${satisfactionRating}`
                    )}
                </div>
                <div
                    ref={textRef}
                    className={clsx('text-sm', isCollapsed && 'line-clamp-2')}
                >
                    {feedback}
                </div>
                {showExpand && (
                    <ButtonAnchor className="text-xs" onClick={onToggle}>
                        <span>{t(isCollapsed ? 'readMore' : 'readLess')}</span>
                        <FontAwesomeIcon
                            className="ml-1.5 text-gold-200"
                            icon={[
                                'fas',
                                isCollapsed ? 'chevron-down' : 'chevron-up',
                            ]}
                            size="sm"
                        />
                    </ButtonAnchor>
                )}
            </div>
        </div>
    );
};
