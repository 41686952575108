import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'components/Loaders/Skeleton';
import SkeletonButton from 'components/Loaders/Skeleton/SkeletonButton';
import SkeletonSelect from 'components/Loaders/Skeleton/SkeletonSelect';
import {ServiceType} from 'gql/generated';

type ReservationWidgetSkeletonProps = {
    className?: string;
    isWaitlist: boolean;
};

const ReservationWidgetSkeleton: FC<ReservationWidgetSkeletonProps> = ({
    className,
    isWaitlist = false,
}) => {
    const {t} = useTranslation();

    return (
        <Skeleton className={`flex flex-col gap-2 ${className}`}>
            <div className="mt-2 text-sm font-semibold">
                {t('search.selectGuests')}
            </div>
            <SkeletonSelect className="w-full" />
            <div className="mt-2 text-sm font-semibold">
                {t('search.selectServiceType')}
            </div>
            <div className="mt-2 grid grid-cols-2 gap-2 sm:grid-cols-2">
                <SkeletonButton className="w-full">
                    {t(ServiceType.Lunch)}
                </SkeletonButton>
                <SkeletonButton className="w-full">
                    {t(ServiceType.Dinner)}
                </SkeletonButton>
            </div>
            {isWaitlist ? (
                <>
                    <div>
                        <div className="text-sm font-semibold">
                            {t('reservation.timeOfArrival')}
                        </div>
                        <div className="mt-2 grid grid-cols-2 gap-2 sm:grid-cols-2">
                            <SkeletonSelect
                                className="w-full"
                                label={t('reservation.timeOfArrival')}
                            />
                            <SkeletonSelect
                                className="w-full"
                                label={t('reservation.timeOfArrival')}
                            />
                        </div>
                    </div>
                    <SkeletonSelect
                        className="w-full"
                        label={t('reserve.confirmation.waitlist.deadline')}
                    />
                </>
            ) : (
                <>
                    <div className="grid h-32 grid-cols-3 gap-1 sm:grid-cols-4">
                        {Array(9)
                            .fill(null)
                            .map((_, index) => (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className="skeleton h-full w-full rounded-md"
                                />
                            ))}
                    </div>
                    <div className="mt-2 text-sm font-semibold">
                        {t('search.selectSeating')}
                    </div>
                    <div className="mt-2 grid grid-cols-3 gap-2">
                        <SkeletonButton className="w-full">
                            {t('seatingType.TABLE')}
                        </SkeletonButton>
                        <SkeletonButton className="w-full">
                            {t('seatingType.COUNTER')}
                        </SkeletonButton>
                        <SkeletonButton className="w-full">
                            {t('seatingType.PRIVATE_ROOM')}
                        </SkeletonButton>
                    </div>
                </>
            )}
            <div className="mt-2 text-sm font-semibold">
                {t('venue.tabs.courses.label')}
            </div>
            <div className="mt-2 grid grid-cols-1 justify-between gap-2">
                <div className="skeleton mt-2 h-6 gap-x-2 rounded-md p-3" />
                <div className="skeleton mt-2 h-6 gap-x-2 rounded-md p-3" />
                <div className="skeleton mt-2 h-6 gap-x-2 rounded-md p-3" />
            </div>
        </Skeleton>
    );
};

export default ReservationWidgetSkeleton;
