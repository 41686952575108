import {
    faArrowDown,
    faArrowUp,
    faBell,
    faBookmark,
    faChair,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClock,
    faCoins,
    faCompass,
    faExclamationCircle,
    faGlasses,
    faGlobe,
    faHammer,
    faListUl,
    faLocationArrow,
    faMapMarkerAlt,
    faMoon,
    faQuestion,
    faSearch,
    faSlidersH,
    faStar,
    faStoreAlt,
    faStoreAltSlash,
    faSun,
    faTimes,
    faUser,
    faUserFriends,
    faUsers,
    faUtensils,
} from '@fortawesome/free-solid-svg-icons';

export default [
    faArrowDown,
    faArrowUp,
    faBell,
    faBookmark,
    faChair,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClock,
    faCoins,
    faCompass,
    faExclamationCircle,
    faGlasses,
    faGlobe,
    faHammer,
    faListUl,
    faLocationArrow,
    faMapMarkerAlt,
    faMoon,
    faQuestion,
    faSearch,
    faSlidersH,
    faStar,
    faStoreAlt,
    faStoreAltSlash,
    faSun,
    faTimes,
    faUser,
    faUserFriends,
    faUsers,
    faUtensils,
];
