import {FC, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import Form from 'components/Form';
import InputEmail from 'components/Form/InputEmail';
import SubmitButton from 'components/Form/SubmitButton';
import {UpdateEmailDocument, UserDocument} from 'gql/generated';
import {useToastNotification} from 'state/toastNotification';
import {useUser} from 'state/user';
import {FormData} from 'types';

type ChangeEmailFormData = FormData<{
    email: string;
}>;

export const ChangeEmailContent: FC = () => {
    const {t} = useTranslation();
    const {toastWrapper} = useToastNotification();

    const user = useUser();

    const methods = useForm<ChangeEmailFormData>();
    const {handleSubmit, reset} = methods;
    const [pendingEmail, setPendingEmail] = useState('');

    const [updateEmail] = useMutation(UpdateEmailDocument, {
        refetchQueries: [UserDocument],
    });

    const onSubmit = async (formData: ChangeEmailFormData) => {
        await toastWrapper({
            onFulfilled: () => {
                setPendingEmail(formData.email);
                reset();
            },
            promise: updateEmail({variables: {...formData}}),
            successMessage: t('user.changeEmail.toastMessage'),
        });
    };

    return (
        <FormProvider {...methods}>
            <Form className="gap-y-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-step flex flex-col gap-y-4 rounded-md p-4">
                    {pendingEmail ? (
                        <div>
                            <div className="mb-1 ml-px text-sm">
                                {t('user.changeEmail.success')}
                            </div>
                            <div className="rounded-md border border-grey-300 bg-white px-3 py-2 text-grey-800 dark:border-grey-700 dark:bg-grey-900 dark:text-white">
                                {pendingEmail}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div>
                                <div className="mb-1 ml-px text-sm">
                                    {t('user.changeEmail.currentEmail')}
                                </div>
                                <div
                                    className="rounded-md border border-grey-300 bg-white px-3 py-2 text-gold-400 dark:border-grey-700 dark:bg-grey-900 dark:text-gold-200"
                                    data-dd-privacy="mask"
                                >
                                    {user?.email}
                                </div>
                            </div>
                            <InputEmail
                                label={t('user.changeEmail.newEmail')}
                                name="email"
                                placeholder={t('user.changeEmail.newEmail')}
                                rules={{
                                    required: true,
                                    validate: {
                                        isChanged: (value: string) =>
                                            value !== user?.email ||
                                            t('user.changeEmail.warning'),
                                    },
                                }}
                            />
                        </>
                    )}
                </div>
                {!pendingEmail && (
                    <div className="flex justify-center pb-2 sm:pb-0 md:justify-end">
                        <SubmitButton
                            className="w-11/12 md:w-auto"
                            loading={t('user.changeEmail.title')}
                        >
                            {t('form.save')}
                        </SubmitButton>
                    </div>
                )}
            </Form>
        </FormProvider>
    );
};
