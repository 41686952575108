import {FC, ReactNode, SelectHTMLAttributes} from 'react';
import {useFormContext} from 'react-hook-form';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import BaseSelect from 'components/Form/Base/BaseSelect';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import Field from 'components/Form/Field';
import {FormContextString, RequiredRules} from 'components/Form/types';
import {getRegisterRules} from 'components/Form/utils';

export type SelectProps<T = HTMLSelectElement> = RequiredRules<
    SelectHTMLAttributes<T>
> & {
    classNameIcon?: string;
    classNameSelect?: string;
    helpText?: ReactNode;
    label?: ReactNode;
    name: string;
    options: SelectOption[];
    type?: never;
    /**
     * string to display if you want an unselected choice
     */
    unselected?: string;
    unselectedIcon?: IconProp;
};

const Select: FC<SelectProps> = ({
    className,
    classNameIcon,
    classNameSelect,
    disabled,
    helpText,
    label,
    name,
    options,
    required,
    rules,
    unselected,
    unselectedIcon,
    ...rest
}) => {
    const {
        formState: {errors},
        getValues,
        register,
        watch,
    } = useFormContext<FormContextString>();

    const registerRules = getRegisterRules({disabled, required, rules});

    if (unselectedIcon || options.some((option) => option.icon)) {
        // watch for icon changes
        watch(name);
    }

    return (
        <Field
            className={className}
            disabled={registerRules?.disabled}
            error={errors[name]}
            helpText={helpText}
            label={label}
            name={name}
            required={!!registerRules?.required}
            type="select"
        >
            <BaseSelect
                classNameIcon={classNameIcon}
                classNameSelect={classNameSelect}
                error={errors[name]}
                options={options}
                required={!!registerRules?.required}
                unselected={unselected}
                unselectedIcon={unselectedIcon}
                {...rest}
                {...register(name, registerRules)}
                value={getValues(name)}
            />
        </Field>
    );
};

export default Select;
