import useBreakpoint from 'hooks/useBreakpoint';

export const PrivacyPolicyPage = () => {
    const isDesktop = useBreakpoint('sm');

    if (isDesktop) {
        return (
            <div className="prose prose-sm mb-6 flex min-h-[600px] max-w-none flex-col pt-6 dark:prose-dark md:prose sm:min-h-[900px] md:max-w-none md:px-0">
                <h1 className="shrink place-self-center sm:place-self-start">
                    Privacy Policy
                </h1>
                <object
                    className="grow"
                    data="https://pocket-docs.s3.ap-southeast-2.amazonaws.com/Pocket+Concierge+Privacy+Policy.pdf#toolbar=1&navpanes=0&scrollbar=1"
                    height="100%"
                    type="application/pdf"
                    width="100%"
                >
                    <p>
                        This browser does not support inline PDFs. Please
                        download the PDF to view it:{' '}
                        <a href="https://pocket-docs.s3.ap-southeast-2.amazonaws.com/Pocket+Concierge+Privacy+Policy.pdf">
                            Download PDF
                        </a>
                    </p>
                </object>
            </div>
        );
    }

    return (
        <div className="mx-4 flex flex-col items-center">
            Please download the PDF to view it:
            <a
                className="pt-4 text-xl"
                href="https://pocket-docs.s3.ap-southeast-2.amazonaws.com/Pocket+Concierge+Privacy+Policy.pdf"
            >
                Download PDF
            </a>
        </div>
    );
};
