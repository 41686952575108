import {FC, ReactNode} from 'react';
import {FieldError} from 'react-hook-form';
import HelpError from 'components/Form/HelpError';
import MaxLength from '../MaxLength';
import FieldLabel from './FieldLabel';

type WithMaxLength = {
    maxLength?: number;
    name: string;
    type: 'input' | 'password' | 'textarea';
};

type WithLabel = {
    maxLength?: never;
    name: string;
    type: 'select';
};

type WithoutLabel = {
    maxLength?: never;
    name?: never;
    type: 'button' | 'checkbox' | 'radio';
};

export type FieldProps = {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    error?: FieldError;
    helpText?: ReactNode;
    isLegend?: boolean;
    label?: ReactNode;
    required?: boolean;
} & (WithMaxLength | WithLabel | WithoutLabel);

const Field: FC<FieldProps> = ({
    children,
    className,
    disabled,
    error,
    helpText,
    isLegend,
    label,
    maxLength,
    name,
    required,
}) => {
    const fieldLabel = label ? (
        <FieldLabel
            disabled={disabled}
            error={error}
            htmlFor={name}
            isLegend={isLegend}
            required={required}
        >
            {label}
        </FieldLabel>
    ) : null;

    const helpError =
        helpText || error || maxLength ? (
            <HelpError
                disabled={disabled}
                error={error}
                helpText={helpText}
                label={label}
            >
                {maxLength && <MaxLength maxLength={maxLength} name={name} />}
            </HelpError>
        ) : null;

    return (
        <div className={className} role="presentation">
            {fieldLabel}
            {children}
            {helpError}
        </div>
    );
};

export default Field;
