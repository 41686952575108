import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseSelectProps} from 'components/Form/Base/BaseSelect';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import Select from 'components/Form/Select';
import {getRange} from 'utils/array';

export type PartySizeSelectProps = Omit<BaseSelectProps, 'name' | 'options'>;

const options: SelectOption[] = getRange(1, 8).map((value) => ({
    icon: ['fas', `user${value > 2 ? 's' : value === 2 ? '-friends' : ''}`],
    label: String(value),
    value: String(value),
}));

const PartySizeSelect: FC<PartySizeSelectProps> = (props) => {
    const {t} = useTranslation();

    return (
        <Select
            aria-label={t('search.selectGuests')}
            data-dd-privacy="allow"
            unselected={t('search.selectGuests')}
            unselectedIcon={['fas', 'user']}
            {...props}
            name="partySize"
            options={options}
        />
    );
};

export default PartySizeSelect;
