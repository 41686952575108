import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseSelectProps} from 'components/Form/Base/BaseSelect';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import Select from 'components/Form/Select';
import {ServiceType} from 'gql/generated';

export type ServiceSelectProps = Omit<BaseSelectProps, 'name' | 'options'> & {
    onlyShow?: ServiceType;
};

const OPTIONS: SelectOption[] = [
    {
        icon: ['fas', 'sun'],
        label: ServiceType.Lunch,
        value: ServiceType.Lunch,
    },
    {
        icon: ['fas', 'moon'],
        label: ServiceType.Dinner,
        value: ServiceType.Dinner,
    },
];

const ServiceSelect: FC<ServiceSelectProps> = ({
    disabled,
    onlyShow,
    ...rest
}) => {
    const {t} = useTranslation();

    const options = OPTIONS.map((item) => ({
        ...item,
        label: t(`serviceTypes.${item.label}`),
    })).filter((item) => !onlyShow || item.value === onlyShow);

    return (
        <Select
            aria-label={t('search.selectServiceType')}
            data-dd-privacy="allow"
            {...rest}
            disabled={disabled}
            name="serviceType"
            options={options}
            unselected={onlyShow ? undefined : t('search.selectServiceType')}
            unselectedIcon={onlyShow ? undefined : ['far', 'clock']}
        />
    );
};

export default ServiceSelect;
