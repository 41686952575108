import {FC} from 'react';
import clsx from 'clsx';
import PocketConciergeLogo from 'components/PocketConciergeLogo';
import {useLocale} from 'state/locale';

export type FooterLogoProps = {
    version?: string;
};

const FooterLogo: FC<FooterLogoProps> = ({version}) => {
    const locale = useLocale();

    return (
        <div
            className={clsx(
                'relative w-40 select-none sm:ml-2.5',
                locale === 'en' ? '-ml-px' : '-ml-5'
            )}
        >
            <PocketConciergeLogo className="dark:brightness-[100]" />
            {version && (
                <div className="absolute -bottom-1 left-[1.9rem] text-[0.6rem] font-light leading-none opacity-50">
                    {version}
                </div>
            )}
        </div>
    );
};

export default FooterLogo;
