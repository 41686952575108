import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {useLocale} from 'state/locale';
import InputText from '../InputText';

export type InputKanaNameProps = {
    className?: string;
};

const InputKanaName: FC<InputKanaNameProps> = ({className}) => {
    const locale = useLocale();
    const {t} = useTranslation();

    if (locale !== 'ja') return null;

    return (
        <div className={clsx('flex gap-2 sm:gap-4', className)}>
            <InputText
                className="flex-1"
                hideMaxLength={true}
                label={t('user.basicInformation.name.last.kana')}
                maxLength={40}
                name="lastNameKana"
                placeholder={t('user.basicInformation.name.furigana')}
                required={true}
            />
            <InputText
                className="flex-1"
                hideMaxLength={true}
                label={t('user.basicInformation.name.first.kana')}
                maxLength={40}
                name="firstNameKana"
                placeholder={t('user.basicInformation.name.furigana')}
                required={true}
            />
        </div>
    );
};

export default InputKanaName;
