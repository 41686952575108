import {RefObject, useEffect, useRef, useState} from 'react';
import useBreakpoint from './useBreakpoint';
import usePanelToggle from './usePanelToggle';

export type Panel = {
    containerRef: RefObject<HTMLDivElement>;
    isDesktop: boolean;
    isFocused: boolean;
    setIsFocused: (value: boolean) => void;
    showPanel: boolean;
    togglePanel: () => void;
};

const usePanel = (): Panel => {
    const isDesktop = useBreakpoint('sm');

    const [showPanel, togglePanel] = usePanelToggle(!!isDesktop);
    const [isFocused, setIsFocused] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isDesktop) {
            const onClickOutside = (event: MouseEvent) => {
                if (
                    showPanel &&
                    // @ts-ignore
                    !containerRef.current?.contains(event.target)
                ) {
                    togglePanel();
                    setIsFocused(false);
                }
            };

            document.addEventListener('mousedown', onClickOutside);

            return () => {
                document.removeEventListener('mousedown', onClickOutside);
            };
        }

        return undefined;
    }, [isDesktop, isFocused, showPanel, togglePanel]);

    return {
        containerRef,
        isDesktop: !!isDesktop,
        isFocused,
        setIsFocused,
        showPanel,
        togglePanel,
    };
};

export default usePanel;
