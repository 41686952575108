import {FC} from 'react';
import VenueResultCard from 'components/Venue/VenueResultCard';
import {VenueSearchResultFragment} from 'gql/generated';
import {useLocale} from 'state/locale';
import {pickUpVenues} from '../marketing-content';

type HomePickUpVenues = {
    className?: string;
    name: string;
    venues: VenueSearchResultFragment[];
};

const HomePickUpVenuesGroup: FC<HomePickUpVenues> = ({
    className,
    name,
    venues,
}) => {
    const locale = useLocale();
    const localePickUpVenues = pickUpVenues[locale];

    const sortedVenues = [...venues].sort((v1, v2) => {
        const order1 = localePickUpVenues[v1.id].order;
        const order2 = localePickUpVenues[v2.id].order;

        return order1 - order2;
    });

    return (
        <div className={className}>
            <div className="sm:site-container bg-body sticky inset-x-0 top-[3.25rem] z-40 flex items-start justify-between px-4 py-1 sm:static">
                <div>
                    <h3 className="text-xl">{name}</h3>
                </div>
            </div>
            <div className="site-container mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:gap-2 2xl:gap-4">
                {sortedVenues.map((venue) => {
                    const urlSuffix = pickUpVenues[locale][venue.id].suffix;

                    return (
                        <VenueResultCard
                            key={venue.name}
                            minimal={true}
                            search={urlSuffix}
                            venue={venue}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default HomePickUpVenuesGroup;
