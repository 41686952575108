import {useEffect, useState} from 'react';

const BREAKPOINTS = {
    lg: 1024,
    md: 768,
    sm: 640,
    xl: 1280,
    xxl: 1536,
};

export type Breakpoint = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | '';

const useCurrentBreakpoint = (): Breakpoint => {
    const [breakpoint, setBreakpoint] = useState<Breakpoint>('');

    useEffect(() => {
        const onUpdate = () => {
            const {innerWidth} = window;
            const next =
                innerWidth >= BREAKPOINTS.xxl
                    ? 'xxl'
                    : innerWidth >= BREAKPOINTS.xl
                    ? 'xl'
                    : innerWidth >= BREAKPOINTS.lg
                    ? 'lg'
                    : innerWidth >= BREAKPOINTS.md
                    ? 'md'
                    : innerWidth >= BREAKPOINTS.sm
                    ? 'sm'
                    : 'xs';

            if (next !== breakpoint) {
                setBreakpoint(next);
            }
        };
        onUpdate();
        window.addEventListener('resize', onUpdate);

        return () => {
            window.removeEventListener('resize', onUpdate);
        };
    }, [breakpoint]);

    return breakpoint;
};

export default useCurrentBreakpoint;
