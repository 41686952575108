import {FC, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';
import Button from 'components/Button';
import MobilePanelHeader from 'components/MobilePanelHeader';
import {VenueWithCoursesFragment} from 'gql/generated';
import ReservationWidget from './ReservationWidget';

type MobileReservationWidgetProps = {
    isSecretSeat?: boolean;
    venue: VenueWithCoursesFragment;
};

type ModalContainerProps = {
    isSecretSeat?: boolean;
    toggleModal: () => void;
    venue: VenueWithCoursesFragment;
};

const ModalContainer: FC<ModalContainerProps> = ({
    isSecretSeat,
    toggleModal,
    venue,
}) => {
    // The useRef hook stores the reference to the container in which the content will be rendered.
    // If we don't store the reference to the container in a useRef hook the createPortal on line 63
    // will loose track of the component once it rerenders and not update it anymore.
    const container = useRef<HTMLDivElement>(null);
    const hiddenTimeoutRef = useRef<number | null>(null);
    const portalTimeoutRef = useRef<number | null>(null);

    if (!container.current) {
        // @ts-ignore We need to override the element here.
        container.current = document.querySelector('#reservation-widget');
    }

    useEffect(() => {
        document.documentElement.classList.add(
            'reservation-widget-open',
            'reservation-widget-in'
        );
        // wait for portal to slide in
        hiddenTimeoutRef.current = window.setTimeout(() => {
            document.documentElement.classList.add(
                'reservation-widget-visible'
            );
        }, 400);

        return () => {
            document.documentElement.classList.remove(
                'reservation-widget-in',
                'reservation-widget-visible'
            );
            // wait for portal to slide out
            portalTimeoutRef.current = window.setTimeout(() => {
                document.documentElement.classList.remove(
                    'reservation-widget-open'
                );
            }, 100);
        };
    }, []);

    return container.current
        ? createPortal(
              <div
                  className="bg-body relative h-full w-full overflow-y-auto"
                  role="dialog"
              >
                  <MobilePanelHeader label={venue.name} onClick={toggleModal} />
                  <ReservationWidget
                      isSecretSeat={isSecretSeat}
                      venue={venue}
                  />
              </div>,
              container.current
          )
        : null;
};

const MobileReservationWidget: FC<MobileReservationWidgetProps> = ({
    isSecretSeat = false,
    venue,
}) => {
    const {t} = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const [showPanel, setShowPanel] = useState(false);

    return (
        <div
            ref={containerRef}
            className="bg-step sticky bottom-0 z-40 flex w-full justify-center"
        >
            <div className="sticky w-full p-2">
                <Button
                    className="w-full"
                    onClick={() => {
                        setShowPanel(!showPanel);
                    }}
                >
                    {t('venue.reservation')}
                </Button>
            </div>
            {showPanel && (
                <ModalContainer
                    isSecretSeat={isSecretSeat}
                    toggleModal={() => setShowPanel(!showPanel)}
                    venue={venue}
                />
            )}
        </div>
    );
};

export default MobileReservationWidget;
