import {useEffect} from 'react';
import {useLocale} from 'state/locale';
import {trackPage} from 'utils/analytics';

export const useAnalytics = (pageName: string | undefined) => {
    const locale = useLocale();

    useEffect(() => {
        if (document.querySelector('script#adobe-analytics') === null) {
            const adobeScript = document.createElement('script');
            adobeScript.id = 'adobe-analytics';
            adobeScript.src = process.env.ADOBE_ANALYTICS_URL as string;
            adobeScript.async = true;
            adobeScript.defer = true;
            document.body.append(adobeScript);

            if (pageName) {
                digitalData.page.pageInfo.pageName = pageName;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        trackPage(pageName, locale);
    }, [locale, pageName]);
};
