import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import RadioButtons from 'components/Form/RadioButtons';
import {ReserveUserProfileFragment} from 'gql/generated';
import {Option} from 'types';
import {ReserveDetailsFormData} from 'types/reserve';
import {checkIfWaitlist} from 'utils/params';

const OPTIONS = ['myself', 'anotherPerson'];

export type OnBehalfOfProps = {
    user: ReserveUserProfileFragment;
};

const OnBehalfOf: FC<OnBehalfOfProps> = ({user}) => {
    const {t} = useTranslation();

    const [searchParams] = useSearchParams();
    const isReservation = !checkIfWaitlist(searchParams);

    const {watch} = useFormContext<ReserveDetailsFormData>();
    const onBehalfOf = watch('onBehalfOf');

    const options = OPTIONS.map((value) => ({
        label: t(`reserve.details.onBehalfOf.${value}`),
        value,
    })) as Option[];

    return (
        <>
            {isReservation && (
                <RadioButtons
                    isHorizontal={true}
                    label={t('reserve.details.onBehalfOf.label')}
                    name="onBehalfOf"
                    options={options}
                    size="sm"
                />
            )}
            {onBehalfOf === 'myself' && (
                <div className="mt-2">
                    <div className="text-sm">{t('name')}</div>
                    <div
                        className="text-sm font-semibold"
                        data-dd-privacy="mask"
                    >
                        {t('user.fullName', user)}
                    </div>
                </div>
            )}
        </>
    );
};

export default OnBehalfOf;
