import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import {ReserveVenueFragment} from 'gql/generated';
import {useAnalytics} from 'hooks/useAnalytics';
import useNavigateLocale from 'hooks/useNavigateLocale';
import {PageName} from 'pages/Analytics';
import {useLocale} from 'state/locale';
import {ReservationValues} from 'types/reservation';
import {toFullDate, toTime} from 'utils/date';
import RealTimeBookingConfirmation from './RealTimeBookingConfirmation';
import RequestConfirmation from './RequestConfirmation';
import WaitlistConfirmation from './WaitlistConfirmation';

type ReserveConfirmationProps = {
    id: string;
    values: ReservationValues;
    venue: ReserveVenueFragment;
};

const ReserveConfirmation: FC<ReserveConfirmationProps> = ({
    id,
    values,
    venue,
}) => {
    const {t} = useTranslation();
    const locale = useLocale();
    const navigate = useNavigateLocale();

    useAnalytics(`${PageName.Reserve}: Confirmation`);

    const {
        date,
        partySize,
        seatingType,
        time,
        waitlistArrivalEnd,
        waitlistArrivalStart,
        waitlistDeadline,
    } = values;

    const {name, realTimeBooking} = venue;

    const title = t(
        waitlistDeadline
            ? 'reserve.confirmation.waitlist.title'
            : realTimeBooking
            ? 'reserve.confirmation.realTimeBooking.title'
            : 'reserve.confirmation.reservationRequest.title'
    );

    const buttonTitle = t(
        waitlistDeadline
            ? 'reserve.details.title.waitlist'
            : realTimeBooking
            ? 'reserve.details.title.realTimeBooking'
            : 'reserve.details.title.reservationRequest'
    );

    const guestIcon =
        partySize === '1'
            ? 'user'
            : partySize === '2'
            ? 'user-friends'
            : 'users';

    const message = waitlistDeadline ? (
        <WaitlistConfirmation />
    ) : realTimeBooking ? (
        <RealTimeBookingConfirmation />
    ) : (
        <RequestConfirmation />
    );

    // we do not want the user to be able to click "back" to the /reserve page after navigating away
    const onReservationDetailsClick = () => {
        navigate(`/account/reservations/?id=${id}`, {replace: true});
    };

    const onMakeNewReservationClick = () => {
        navigate('/', {replace: true});
    };

    return (
        <div className="site-container sm:pt-8">
            <div className="sm:border-outline mx-auto py-4 sm:max-w-[40rem] sm:rounded-md sm:border sm:p-5">
                <div className="border-outline border-b pb-4 text-center text-lg">
                    <FontAwesomeIcon
                        className="text-2xl text-gold-200"
                        icon={['fas', 'check']}
                    />
                    <h3 className="text-golden mt-1">{title}</h3>
                </div>
                <div className="border-outline border-b py-4 text-center font-semibold">
                    {name}
                </div>
                <div className="border-outline mt-4 grid gap-3.5 border-b pb-4 sm:grid-cols-2">
                    <div className="flex items-center text-sm">
                        <div className="w-5 text-center">
                            <FontAwesomeIcon
                                className="icon"
                                icon={['far', 'calendar']}
                            />
                        </div>
                        {date && (
                            <span className="ml-2.5">
                                {toFullDate(date, locale)}
                            </span>
                        )}
                    </div>
                    <div className="flex items-center text-sm">
                        <div className="w-5 text-center">
                            <FontAwesomeIcon
                                className="icon"
                                icon={['fas', 'clock']}
                            />
                        </div>
                        {waitlistArrivalStart ? (
                            <div className="ml-2.5">
                                {toTime(
                                    new Date(Number(waitlistArrivalStart)),
                                    locale
                                )}
                                &nbsp;-&nbsp;
                                {toTime(
                                    new Date(Number(waitlistArrivalEnd)),
                                    locale
                                )}
                            </div>
                        ) : (
                            time && (
                                <div className="ml-2.5">
                                    {toTime(time, locale)}
                                </div>
                            )
                        )}
                    </div>
                    {partySize && (
                        <div className="flex items-center text-sm">
                            <div className="w-5 text-center">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={['fas', guestIcon]}
                                />
                            </div>
                            <span className="ml-2.5">
                                {t('reservation.guestCount', {
                                    count: Number(partySize),
                                })}
                            </span>
                        </div>
                    )}
                    {seatingType && (
                        <div className="flex items-center text-sm">
                            <div className="w-5 text-center">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={['fas', 'chair']}
                                />
                            </div>
                            <span className="ml-2.5">
                                {t(`seatingType.${seatingType}`)}
                            </span>
                        </div>
                    )}
                    {waitlistDeadline && (
                        <div className="col-span-full flex text-sm">
                            <div className="w-5 text-center">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={['far', 'calendar-times']}
                                />
                            </div>
                            <span className="ml-2.5">
                                {t('reserve.confirmation.waitlist.deadline')}
                                :&nbsp;
                                <br className="sm:hidden" />
                                {toFullDate(waitlistDeadline, locale)}
                            </span>
                        </div>
                    )}
                </div>
                <div className="mt-4">{message}</div>
                <div className="mt-8 justify-between sm:flex">
                    <Button
                        className="w-full sm:w-auto"
                        kind="secondary"
                        onClick={onReservationDetailsClick}
                    >
                        {buttonTitle}
                    </Button>
                    <Button
                        className="mt-4 w-full sm:mt-0 sm:w-auto"
                        onClick={onMakeNewReservationClick}
                    >
                        {t('reserve.confirmation.makeNewReservation')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ReserveConfirmation;
