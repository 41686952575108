import {useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useMutation} from '@apollo/client';
import PaymentMethod from 'components/PaymentMethod';
import {
    CreditCard,
    CreditCardSearchDocument,
    SetDefaultCardDocument,
} from 'gql/generated';
import {noop} from 'lodash';

type Props = {
    creditCardData: CreditCard[];
    isSelectable: boolean;
    onRemove?: (creditCardId: string) => void;
};

export const PaymentMethodsList = ({
    creditCardData,
    isSelectable,
    onRemove,
}: Props) => {
    const [creditCards, setCreditCards] = useState<null | CreditCard[]>(null);
    const [defaultCardId, setDefaultCardId] = useState<null | string>(null);
    const [positions, setPositions] = useState<number[]>([]);

    const {register} = useFormContext();

    const [setDefaultCard] = useMutation(SetDefaultCardDocument, {
        awaitRefetchQueries: true,
        refetchQueries: [CreditCardSearchDocument],
    });

    useEffect(() => {
        const defaultCreditCard = creditCardData.find(
            (creditCard) => creditCard.defaultCard
        ) as CreditCard | undefined;

        const otherCreditCards = creditCardData.filter(
            (creditCard) => !creditCard.defaultCard
        ) as CreditCard[];

        if (defaultCreditCard) {
            setDefaultCardId(defaultCreditCard.id);
            setCreditCards([defaultCreditCard, ...otherCreditCards]);
        } else {
            setCreditCards(otherCreditCards);
        }
        setPositions([]);
    }, [creditCardData]);

    const listRef = useRef<null | HTMLDivElement>(null);

    const moveToTop = (index: number, id: string) => {
        if (creditCardData && listRef.current) {
            setDefaultCard({
                variables: {
                    id,
                },
            });

            const listOfCardElements = Array.from(
                listRef.current.children
            ).filter((element) => element.matches('label'));

            const defaultCardRect =
                listOfCardElements[index].getBoundingClientRect();

            const rects = creditCardData.map((_, rectIndex) => {
                if (rectIndex === index && listRef.current) {
                    return listOfCardElements
                        .slice(0, index)
                        .reduce(
                            (acc, current) =>
                                acc - current.getBoundingClientRect().height,
                            0
                        );
                }

                if (rectIndex < index) {
                    return defaultCardRect.height;
                }

                return 0;
            });

            setPositions(rects);
            setDefaultCardId(id);
        }
    };

    return (
        <div
            ref={listRef}
            className="bg-step flex h-full flex-col justify-items-center rounded-md px-8 py-4"
        >
            {creditCards &&
                creditCards.map((creditCard, index) => {
                    // These two functions are necessary to determine if the last divider should be visible
                    const checkIfLastElementIsDefault =
                        index === creditCards.length - 1 &&
                        positions[index] < 0;

                    const checkIfSecondToLastElementIsLast =
                        index === creditCards.length - 2 &&
                        positions[index] > 0;

                    return (
                        <label
                            key={creditCard.id}
                            className="flex h-full w-full flex-col"
                            htmlFor={`creditCardId-${creditCard.id}`}
                            style={
                                positions[index] === undefined
                                    ? undefined
                                    : {
                                          transform: `translateY(${
                                              positions[index] || 0
                                          }px)`,
                                          transition: 'transform 1s',
                                      }
                            }
                        >
                            <div className="flex w-full items-center">
                                {isSelectable && (
                                    <input
                                        className="mx-2 justify-center"
                                        id={`creditCardId-${creditCard.id}`}
                                        type="radio"
                                        value={creditCard.id}
                                        {...register('creditCardId')}
                                    />
                                )}
                                <PaymentMethod
                                    card={creditCard}
                                    isDefaultCard={
                                        defaultCardId === creditCard.id
                                    }
                                    onRemove={onRemove || noop}
                                    onSetDefault={() =>
                                        moveToTop(index, creditCard.id)
                                    }
                                />
                            </div>
                            <hr
                                style={{
                                    visibility:
                                        (creditCards.length - 1 > index ||
                                            checkIfLastElementIsDefault) &&
                                        !checkIfSecondToLastElementIsLast
                                            ? 'visible'
                                            : 'hidden',
                                    width: '100%',
                                }}
                            />
                        </label>
                    );
                })}
        </div>
    );
};
