import clsx from 'clsx';
import styles from '../static.module.css';

export const CompanyProfilePage = () => (
    <div
        className={clsx(
            'prose prose-sm max-w-none px-4 pt-6 dark:prose-dark md:prose md:max-w-none md:px-0',
            styles.page
        )}
    >
        <h1>Company Profile</h1>
        <section>
            <h2>Our Goal</h2>
            <p>
                Pocket Concierge provides online booking and payment service
                with high-end restaurants in Japan for mobile & PC browser
                users.
            </p>
            <p>
                We cover finest restaurants in Tokyo, Yokohama, Kyoto and Osaka
                providing users both Japanese and English concierge service to
                book the best restaurants.
            </p>
            <p>
                Our goal is to create the world where everyone around the globe
                can enjoy the great food culture of Japan without any stress
                including language and payment barrier.
            </p>
            <p>Pocket Concierge is an American Express Company.</p>
        </section>
        <section>
            <h2>Company Overview</h2>
            <table>
                <tbody>
                    <tr>
                        <th>Company Name</th>
                        <td>Pocket Concierge Inc.</td>
                    </tr>
                    <tr>
                        <th>Parent Company</th>
                        <td>American Express International Inc.</td>
                    </tr>
                    <tr>
                        <th>Representative Director</th>
                        <td>Hiroyuki Ikeda</td>
                    </tr>
                    <tr>
                        <th>Our Business</th>
                        <td>
                            Development and management of the online restaurant
                            booking service &quot;Pocket Concierge&quot;
                        </td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>
                            4-chōme-1-1 Toranomon, Minato City, Tokyo 105-6920
                        </td>
                    </tr>
                    <tr>
                        <th>Founding Date</th>
                        <td>April, 2011</td>
                    </tr>
                    <tr>
                        <th>Capital Stock</th>
                        <td>JPY770,195,400 (equivalent to USD7MM)</td>
                    </tr>
                </tbody>
            </table>
        </section>
    </div>
);
