/* eslint-disable react/no-array-index-key */
import {FC, useMemo} from 'react';
import {
    addDays,
    differenceInDays,
    endOfMonth,
    endOfWeek,
    format,
    isSameDay,
    isSameMonth,
    isSameYear,
    isToday,
    setHours,
    startOfMonth,
    startOfWeek,
} from 'date-fns';
import {chunk} from 'lodash';
import {useLocale} from 'state/locale';
import {AvailabilityDates} from 'types/reservation';
import {toISO8601Date} from 'utils/date';
import {getLocaleOptions} from 'utils/locale';
import {isValidDate} from '../../utils';
import DateSelectorDate from './DateSelectorDate';

export type DateSelectorCalendarProps = {
    availabilityDates?: AvailabilityDates;
    currentFocus?: string;
    date?: Date;
    id: string;
    maxDate?: Date;
    minDate?: Date;
    onDateClick: (value: Date) => void;
    setCurrentFocus: (value: string) => void;
    visibleDate: Date;
};

const isSelected = (date: Date, theDate: Date) =>
    isSameYear(date, theDate) &&
    isSameMonth(date, theDate) &&
    isSameDay(date, theDate);

const DateSelectorCalendar: FC<DateSelectorCalendarProps> = (props) => {
    const {
        availabilityDates,
        currentFocus,
        date,
        id,
        maxDate,
        minDate,
        onDateClick,
        setCurrentFocus,
        visibleDate,
    } = props;

    const locale = useLocale();

    const [days, weeks] = useMemo(() => {
        // the 7 days of the week (Sun-Sat)
        const options = getLocaleOptions(locale);
        const hours = date?.getHours() || 12;
        const labels = Array.from({length: 7})
            .fill(setHours(startOfWeek(visibleDate), hours))
            .map((dateItem, index) =>
                format(addDays(dateItem as number, index), 'EEEEE', options)
            );
        // first day of current month view
        const start = setHours(startOfWeek(startOfMonth(visibleDate)), hours);
        // last day of current month view
        const end = setHours(endOfWeek(endOfMonth(visibleDate)), hours);
        // get all days and whether they are within the current month and range
        const dates = Array(differenceInDays(end, start) + 1)
            .fill(start)
            .map((s, index) => {
                const theDate = addDays(s, index);
                const isThisMonth = isSameMonth(visibleDate, theDate);
                const availabilityType =
                    availabilityDates?.[toISO8601Date(theDate)];
                // if not in range, no click action
                // if in this month, select the date
                // if out of this month, jump to the date
                const onClick =
                    isValidDate(theDate, minDate, maxDate) &&
                    (!availabilityDates || availabilityType)
                        ? onDateClick
                        : undefined;

                return {
                    availabilityType,
                    date: theDate,
                    isSelected: date && isSelected(date, theDate),
                    isThisMonth,
                    isToday: isToday(theDate),
                    onClick,
                };
            });

        return [labels, chunk(dates, 7)];
    }, [
        availabilityDates,
        date,
        locale,
        maxDate,
        minDate,
        onDateClick,
        visibleDate,
    ]);

    return (
        <table className="w-full table-fixed">
            <thead>
                <tr>
                    {days.map((day, dayIndex) => (
                        <th
                            key={`${day}${dayIndex}`}
                            className="text-body w-[14.28%] p-1 text-sm font-semibold"
                        >
                            {day}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {weeks.map((week, weekIndex) => (
                    <tr key={weekIndex}>
                        {week.map((theDate, theDateIndex) => (
                            <DateSelectorDate
                                key={theDate.date.toString()}
                                cell={weekIndex * 7 + theDateIndex}
                                currentFocus={currentFocus}
                                id={id}
                                setCurrentFocus={setCurrentFocus}
                                {...theDate}
                            />
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DateSelectorCalendar;
