import {FC, ReactNode} from 'react';
import useBreakpoint from 'hooks/useBreakpoint';

export type BreakpointWrapperProps = {
    breakpoint: 'sm' | 'md' | 'lg' | 'xl';
    children: ReactNode;
    className?: string;
    // eslint-disable-next-line react/boolean-prop-naming
    wrapBelowBreakpoint?: boolean;
};

// @ts-ignore
const BreakpointWrapper: FC<BreakpointWrapperProps> = ({
    breakpoint,
    children,
    className,
    wrapBelowBreakpoint = true,
}) => {
    const atBreakpoint = useBreakpoint(breakpoint);

    if (atBreakpoint === undefined) return null;

    const wrap =
        (atBreakpoint && !wrapBelowBreakpoint) ||
        (!atBreakpoint && wrapBelowBreakpoint);

    if (!wrap) return children;

    return <div className={className}>{children}</div>;
};

export default BreakpointWrapper;
