import {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import useScrollToTop from 'hooks/useScrollToTop';

const RequestConfirmation: FC = () => {
    const {t} = useTranslation();

    useScrollToTop();

    return (
        <div className="prose prose-sm max-w-none dark:prose-dark">
            <p className="text-golden text-center font-semibold">
                {t(
                    'reserve.confirmation.reservationRequest.importantInformation'
                )}
            </p>
            <p>{t('reserve.confirmation.reservationRequest.notFinalized')}</p>
            <p>{t('reserve.confirmation.reservationRequest.receiveEmail')}</p>
            <p className="text-invalid links-semibold">
                <Trans
                    components={{
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
                        a: <a />,
                    }}
                    i18nKey="reserve.confirmation.reservationRequest.noEmail"
                />
            </p>
            <p className="links-semibold">
                <Trans
                    components={{
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
                        a: <a />,
                    }}
                    i18nKey="reserve.confirmation.reservationRequest.liability"
                />
            </p>
        </div>
    );
};

export default RequestConfirmation;
