import {FC, ReactNode} from 'react';
import type {RailsContext} from 'react-on-rails/node_package/lib/types';
import {InMemoryCacheConfig} from '@apollo/client';
import {Credential} from 'gql/generated';
import Apollo from './apollo';
import {CredentialsProvider} from './credentials';
import {LocaleProvider} from './locale';
import {ToastNotificationProvider} from './toastNotification';
import UserProvider from './user';

export type StateProps = {
    cacheConfig?: InMemoryCacheConfig;
    credentials?: Credential;
    railsContext?: RailsContext;
    uri?: string;
};

const State: FC<StateProps & {children: ReactNode}> = ({
    cacheConfig,
    children,
    credentials,
    railsContext,
    uri,
}) => (
    <LocaleProvider>
        <ToastNotificationProvider>
            <CredentialsProvider initialState={credentials}>
                <Apollo
                    cacheConfig={cacheConfig}
                    isSSRMode={railsContext?.serverSide || false}
                    uri={uri}
                >
                    <UserProvider>{children}</UserProvider>
                </Apollo>
            </CredentialsProvider>
        </ToastNotificationProvider>
    </LocaleProvider>
);

export default State;
