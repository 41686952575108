import {FC, ReactNode} from 'react';
import clsx from 'clsx';

export type ButtonAnchorProps = {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
};

// A button that looks like an <a> tag
const ButtonAnchor: FC<ButtonAnchorProps> = ({
    children,
    className,
    disabled,
    onClick,
}) => (
    <button
        className={clsx(
            'text-golden select-none whitespace-nowrap',
            disabled ? 'cursor-not-allowed opacity-60' : 'hover:underline',
            className
        )}
        disabled={disabled}
        onClick={onClick}
        type="button"
    >
        {children}
    </button>
);

export default ButtonAnchor;
