import {FC, ReactNode, TextareaHTMLAttributes, useEffect, useRef} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import autosize from 'autosize';
import clsx from 'clsx';
import Field from 'components/Form/Field';
import {FormContextString, RequiredRules} from 'components/Form/types';
import {getRegisterRules} from 'components/Form/utils';

export type TextAreaResize = 'auto' | 'y';

export type TextAreaProps<T = HTMLTextAreaElement> = RequiredRules<
    TextareaHTMLAttributes<T>
> & {
    classNameTextArea?: string;
    disableNewLines?: boolean;
    focusOnMount?: boolean;
    helpText?: ReactNode;
    hideMaxLength?: boolean;
    label?: ReactNode;
    name: string;
    onAutoSize?: () => void;
    resize?: TextAreaResize;
    rows?: number;
    type?: never;
};

export const RESIZE: Record<TextAreaResize, string> = {
    auto: 'resize-none',
    y: 'resize-y',
};

const TextArea: FC<TextAreaProps> = ({
    className,
    classNameTextArea,
    disableNewLines,
    disabled,
    focusOnMount,
    helpText,
    hideMaxLength,
    id,
    label,
    maxLength,
    name,
    required,
    resize = 'auto',
    rows = 1,
    rules,
    ...rest
}) => {
    const {
        formState: {errors},
        register,
    } = useFormContext<FormContextString>();

    useWatch({disabled: resize === 'y', name});

    const registerRules = getRegisterRules({disabled, required, rules});

    const {ref, ...registration} = register(name, registerRules);

    const internalRef = useRef<HTMLTextAreaElement | null>(null);

    if (resize === 'auto' && internalRef.current) {
        autosize(internalRef.current);
    }

    useEffect(() => {
        if (focusOnMount) {
            internalRef.current?.focus();
        }
    }, [focusOnMount]);

    return (
        <Field
            className={className}
            disabled={registerRules?.disabled}
            error={errors[name]}
            helpText={helpText}
            label={label}
            maxLength={hideMaxLength ? undefined : maxLength}
            name={name}
            required={!!registerRules?.required}
            type="textarea"
        >
            <textarea
                ref={(element) => {
                    ref(element);
                    internalRef.current = element;
                }}
                aria-label={
                    rest['aria-label'] || label === null
                        ? undefined
                        : typeof label === 'string'
                        ? label
                        : name
                }
                className={clsx(
                    'w-full',
                    RESIZE[resize],
                    errors[name] && 'input-invalid',
                    classNameTextArea
                )}
                id={id || name}
                maxLength={maxLength}
                onKeyDown={
                    disableNewLines
                        ? (event) => {
                              if (event.key === 'Enter') event.preventDefault();
                          }
                        : undefined
                }
                rows={rows}
                {...registration}
                {...rest}
            />
        </Field>
    );
};

export default TextArea;
