import {FC} from 'react';
import clsx from 'clsx';
import FooterControls from './FooterControls';
import FooterLegal from './FooterLegal';
import FooterContact from './FooterLinks';
import FooterLogo from './FooterLogo';
import FooterSocialMedia from './FooterSocialMedia';

export type FooterProps = {
    className?: string;
    version?: string;
};

const Footer: FC<FooterProps> = ({className, version}) => (
    <footer
        className={clsx('bg-body hr-border mt-10 border-t pt-10', className)}
    >
        <div className="sm:site-container">
            {/* Desktop version */}
            <div className="hidden sm:flex sm:flex-row sm:justify-between">
                <div className="sm:space-y-3 sm:pb-1">
                    <FooterLogo version={version} />
                    <FooterSocialMedia className="hidden w-fit pl-3.5 pt-0.5 sm:flex" />
                </div>
                <div className="sm:space-y-3 sm:pb-1">
                    <FooterContact className="ml-6 hidden sm:block" />
                    <FooterControls />
                </div>
            </div>
            {/* Mobile version */}
            <div className="flex flex-col items-center justify-center gap-y-4 sm:hidden">
                <FooterLogo version={version} />
                <FooterContact className="" />
                <hr className="w-1/2" />
                <FooterSocialMedia className="flex w-fit pb-4" />
            </div>
            <FooterLegal />
        </div>
    </footer>
);

export default Footer;
