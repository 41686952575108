import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import Chain from 'components/Form/Chain';
import {useLocale} from 'state/locale';
import SkeletonFieldLabel from '../SkeletonField/SkeletonFieldLabel';
import SkeletonSelect from '../SkeletonSelect';

export type SkeletonYearMonthDayProps = {
    className?: string;
    required?: boolean;
    step?: boolean;
};

const SkeletonYearMonthDay: FC<SkeletonYearMonthDayProps> = ({
    className,
    required,
    step,
}) => {
    const locale = useLocale();
    const {t} = useTranslation();

    const yearSelect = (
        <SkeletonSelect
            classNameInput={clsx(
                locale === 'en' ? 'rounded-l-none' : 'rounded-r-none'
            )}
            label={t('date.year')}
            step={step}
        />
    );

    const monthSelect = (
        <SkeletonSelect
            classNameInput={clsx(
                locale === 'en' ? 'rounded-r-none' : 'rounded-none'
            )}
            label={t('date.month')}
            step={step}
        />
    );

    const daySelect = (
        <SkeletonSelect
            classNameInput={clsx(
                locale === 'en' ? 'rounded-none' : 'rounded-l-none'
            )}
            label={t('date.day')}
            step={step}
        />
    );

    return (
        <div className={className}>
            <SkeletonFieldLabel required={required} step={step}>
                {t('user.basicInformation.dob')}
            </SkeletonFieldLabel>
            <Chain className="mt-2" isFullWidth={true}>
                {locale === 'en' ? (
                    <>
                        {monthSelect}
                        {daySelect}
                        {yearSelect}
                    </>
                ) : (
                    <>
                        {yearSelect}
                        {monthSelect}
                        {daySelect}
                    </>
                )}
            </Chain>
        </div>
    );
};

export default SkeletonYearMonthDay;
