import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import InputEmail from 'components/Form/InputEmail';
import InputFullName from 'components/Form/InputFullName';
import InputKanaName from 'components/Form/InputKanaName';
import InputPhone from 'components/Form/InputPhone';
import {ReserveUserProfileFragment} from 'gql/generated';
import {ReserveDetailsFormData} from 'types/reserve';
import OnBehalfOf from '../OnBehalfOf';

type ContactInformationProps = {
    user: ReserveUserProfileFragment;
};

const ContactInformation: FC<ContactInformationProps> = ({user}) => {
    const {watch} = useFormContext<ReserveDetailsFormData>();
    const onBehalfOf = watch('onBehalfOf');

    const isAnotherPerson = onBehalfOf === 'anotherPerson';

    return (
        <>
            <OnBehalfOf user={user} />
            <fieldset className="space-y-4">
                {isAnotherPerson && <InputFullName />}
                <InputKanaName />
                <div className="flex flex-col gap-4 lg:flex-row">
                    <InputPhone className="lg:flex-1" required={true} />
                    {isAnotherPerson ? (
                        <InputEmail
                            className="lg:flex-1"
                            name="email"
                            required={true}
                        />
                    ) : (
                        <div className="hidden lg:block lg:flex-1" />
                    )}
                </div>
            </fieldset>
        </>
    );
};

export default ContactInformation;
