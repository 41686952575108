import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {FormContextBoolean} from 'components/Form/types';

export type RealTimeBookingProps = {
    className?: string;
};

const RealTimeBooking: FC<RealTimeBookingProps> = ({className}) => {
    const {t} = useTranslation();
    const {getValues, register, watch} = useFormContext<FormContextBoolean>();

    watch('realTimeBooking');

    const selected = getValues('realTimeBooking');

    return (
        <div
            className={clsx(
                'relative cursor-pointer rounded-md border',
                'border-grey-300 bg-white',
                'dark:border-grey-700 dark:bg-grey-900',
                className
            )}
            data-dd-privacy="allow"
        >
            <label
                aria-label={t('search.realTimeBooking')}
                className={clsx(
                    'group flex w-full cursor-pointer select-none items-center gap-2 px-3 py-2',
                    selected
                        ? 'text-grey-800 dark:text-white'
                        : 'text-grey-500 dark:text-grey-600'
                )}
                htmlFor="realTimeBooking"
            >
                <input
                    className="hidden"
                    type="checkbox"
                    {...register('realTimeBooking')}
                    id="realTimeBooking"
                />
                <FontAwesomeIcon icon={[selected ? 'fas' : 'far', 'bell']} />
                <div className="text-base">{t('search.realTimeBooking')}</div>
            </label>
        </div>
    );
};

export default RealTimeBooking;
