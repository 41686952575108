import {FC, FormEvent, useMemo, useRef} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getAsYouType, parsePhoneNumber} from 'awesome-phonenumber';
import clsx from 'clsx';
import Field from 'components/Form/Field';
import {FormContextString, InputProps} from 'components/Form/types';
import useBreakpoint from 'hooks/useBreakpoint';
import {useLocale} from 'state/locale';
import {cleanPhoneNumber} from 'utils/phone';
import whitelist from 'validator/lib/whitelist';
import {getRegisterRules} from '../utils';
import {countries, meta} from './countries';
import styles from './styles.module.css';

const validatePhone = (value = ''): boolean =>
    !value || parsePhoneNumber(value).valid;

const sanitizePhone =
    (countryCode: string) =>
    (value = '', prev = '') => {
        const white = whitelist(value, '-+0123456789 ');
        if (!white) return white;
        const clean = cleanPhoneNumber(white);
        const pn = parsePhoneNumber(clean);

        if (pn.valid) {
            return pn.number.international;
        }

        if (!countryCode || prev.length > value.length) {
            return clean;
        }
        const ayt = getAsYouType(countryCode);
        ayt.reset(clean);

        return ayt.number();
    };

export type InputPhoneProps = Omit<
    InputProps,
    'classNameIcon' | 'hideMaxLength' | 'icon' | 'maxLength' | 'name' | 'rules'
>;

const InputPhone: FC<InputPhoneProps> = ({
    className,
    disabled,
    helpText,
    id,
    label,
    required,
    ...rest
}) => {
    const locale = useLocale();
    const {t} = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const isDesktop = useBreakpoint('sm');

    const {
        formState: {errors},
        register,
        setValue,
        watch,
    } = useFormContext<FormContextString>();

    const tel = watch('tel', '+81 ');
    const countryCode = watch('countryCode', 'JP');

    const sanitizer = useMemo(
        () => sanitizePhone(countryCode || ''),
        [countryCode]
    );

    const options = useMemo(
        () =>
            Object.entries(countries[locale]).map(([key, name]) => ({
                country: `${name} (+${meta[key].code})`,
                flag: meta[key].flag,
                value: key,
            })),
        [locale]
    );

    const onCountryChange = (event: FormEvent<HTMLSelectElement>) => {
        const {value} = event.currentTarget;
        const clean = value ? sanitizer(meta[value].code) : '';
        setValue('tel', clean);
        setValue('countryCode', value);
        setTimeout(() => inputRef.current?.focus());
    };

    const onTelChange = (event: FormEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;
        const clean = sanitizer(value, tel);
        const parsed = parsePhoneNumber(clean);
        const detectedCountry = parsed.possibility.includes('invalid')
            ? null
            : parsed.regionCode;

        setValue('tel', clean);

        if (!detectedCountry) {
            // if country code cannot be detected, clear it
            setValue('countryCode', '');
        } else if (!countryCode || validatePhone(clean)) {
            setValue('countryCode', detectedCountry);
        }
    };

    const {ref, ...registration} = register('tel', {
        disabled,
        required,
        validate: (value) =>
            validatePhone(value) ||
            t('user.basicInformation.phoneNumber.invalid'),
    });

    const flag = meta[countryCode]?.flag;

    return (
        <Field
            className={className}
            disabled={disabled}
            error={errors.tel}
            helpText={helpText}
            label={label ?? t('user.basicInformation.phoneNumber.label')}
            name="tel"
            required={!!required}
            type="input"
        >
            <div className="relative">
                <select
                    key={countryCode}
                    aria-label={t(
                        'user.basicInformation.phoneNumber.countryCode'
                    )}
                    autoComplete="tel-country-code"
                    className={styles.countryCodeSelect}
                    {...register(
                        'countryCode',
                        getRegisterRules({disabled, required})
                    )}
                    onChange={onCountryChange}
                >
                    <option value="">
                        {t('user.basicInformation.phoneNumber.countryCode')}
                    </option>
                    {options.map((item) => (
                        <option
                            key={item.value}
                            className="text-grey-500 dark:text-grey-600"
                            value={item.value}
                        >
                            {isDesktop
                                ? item.country
                                : // eslint-disable-next-line no-irregular-whitespace
                                  `${item.flag} ${item.country}`}
                        </option>
                    ))}
                </select>
                {flag && (
                    <div
                        aria-label="flag"
                        className="pointer-events-none absolute left-[0.8rem] top-[0.575rem] z-10 select-none"
                    >
                        {flag}
                    </div>
                )}
                <div className={clsx(!flag && 'relative')}>
                    <input
                        autoComplete="tel"
                        className="w-full pl-20"
                        placeholder={t(
                            'user.basicInformation.phoneNumber.label'
                        )}
                        spellCheck={false}
                        type="tel"
                        {...rest}
                        {...registration}
                        ref={(element) => {
                            ref(element);
                            inputRef.current = element;
                        }}
                        onChange={onTelChange}
                    />
                    {!countryCode && (
                        <FontAwesomeIcon
                            className={clsx(
                                'absolute left-3 top-[0.825rem]',
                                'text-disabled'
                            )}
                            fixedWidth={true}
                            icon={['fas', 'globe']}
                        />
                    )}
                </div>
            </div>
        </Field>
    );
};

export default InputPhone;
