export default {
    authentication: {
        forgotPassword: {
            message:
                'We will send you an email with a link to reset your password.',
            success:
                'An email with a link to reset your password has been sent.',
            title: 'Forgot Password',
        },
        login: {
            linkToForgotPassword: 'I forgot my password',
            linkToResendConfirmation:
                'I did not receive the confirmation email',
            linkToSignup: 'New to Pocket? Register',
            title: 'Log In',
        },
        logout: 'Logout',
        resendConfirmation: {
            message:
                'Enter your email address and we will resend the instructions.',
            success:
                'If the entered email exists, we will resend the confirmation email.',
            title: 'Resend Confirmation Email',
        },
        resetPassword: {
            message: 'Please enter your new password.',
            success: 'Your password has been updated.',
            title: 'Reset Password',
        },
        signup: {
            buttonTitle: 'Sign Up',
            linkToLogin: 'Already have an account? Log in',
            privacyAndTerms:
                'I accept the <privacy>Privacy Policy</privacy> and the <terms>Terms of Use</terms> <required>Required</required>',
            privacyRequired: 'You must check the box',
            receiveNewsletter:
                'I want to receive the Pocket Concierge newsletter',
            title: 'Sign Up',
        },
        signupConfirmation: {
            error: `Your account has already been activated, or the token is invalid.<br/><br/>If you have any questions, please email <a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a>.`,
            noTokenMessage:
                'Check your email and click the link to confirm your account.<br/><br/>Gmail users:<br/>Please be sure to check your spam or junk folder for the confirmation email.',
            success: 'Your account has been confirmed.',
            title: 'Confirm Account',
        },
        toBookmark:
            'To bookmark a restaurant, please log in or register a new account.',
        toReserve:
            'To make a reservation, please log in or register a new account.',
        unlockAccount: {
            error: `Your account has already been unlocked, or the token is invalid.<br/><br/>If you have any questions, please email <a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a>.`,
            success: 'Your account has been unlocked.',
            title: 'Unlock Account',
        },
    },
    back: 'Back',
    bannerNotice: "Regarding New Year's Holiday",
    bookmarks: {
        add: 'Bookmark',
        addVenue: 'Bookmark {{- name}}',
        added: 'Bookmarked',
        none: 'You currently do not have any bookmarked restaurants.',
        remove: 'Remove bookmark',
        removed: 'Removed bookmark',
        successfullyAdded: 'Bookmarked Venue',
        title: 'Bookmarks',
    },
    contactForm: {
        email: 'Email',
        faq: 'FAQ',
        inquiry: 'Inquiry',
        message: 'Message',
        messageReceived: 'Thank you for your email.',
        name: 'Name',
        newsletter: {
            changeEmail: 'Change email',
            newsletter: 'Newsletter',
            title: 'Newsletter',
            unsubscribe: 'Unsubscribe',
        },
        owner: {
            title: 'Venue Introduction',
        },
        payment: {
            authorization: 'Authorization',
            onlineAdvancePayment: 'Online advance payment',
            paymentMethods: 'Payment methods',
            refunds: 'Refunds',
            title: 'Payment',
        },
        privacyPolicy: 'I accept the terms of the <Link>Privacy Policy</Link>',
        registration: {
            changeEmail: 'Change email',
            changePassword: 'Change Password',
            deleteAccount: 'Delete account',
            registration: 'Registration',
            registrationEmail: 'Registration email',
            registrationStatus: 'Registration status',
            title: 'Registration',
        },
        reservations: {
            changeBookingDetailsCancel: 'Change booking details or cancel',
            howToBook: 'How to book',
            reservationCancellation: 'Reservation cancellation',
            reservationConfirmation: 'Reservation confirmation',
            title: 'Reservations',
        },
        restaurants: {
            allergy: 'Allergies',
            anniversaryRequests: 'Anniversary requests',
            availableTimes: 'Available times',
            children: 'Children',
            childrenTopics: {
                kidsAge: 'Childrens Age',
                kidsAllergy: 'Childrens Allergy',
                kidsMenu: 'Menu for children',
            },
            courseDetails: 'Course details',
            numberOfGuests: 'Number of guests',
            seatTypes: 'Seat types',
            title: 'Restaurants',
        },
        submit: 'Send',
        title: 'Contact',
        topic: 'Topic',
    },
    date: {
        day: 'Day',
        month: 'Month',
        year: 'Year',
    },
    details: 'Details',
    error: {
        404: {
            message: '404: The requested URL has no content.',
            title: 'Page not found',
        },
        500: {
            message: '500: An unexpected error occurred.',
            title: 'Server Error',
        },
        emailInvalid: 'Please enter a valid email',
        noSecretSeats:
            'The seat linked to this page is now invalid.<br/><br/>Please contact Concierge via <Link>Contact Form</Link>.',
        passwordIncorrect: 'Current password is incorrect',
        sessionExpired: 'Your session has expired. Please log in again',
        threeDSecure: 'We are unable to authenticate your payment method',
        unexpected: `An unexpected error occurred. Please try again.<br/>If the error persists, please email <a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a>.`,
    },
    errorCodes: {
        accountDeletionError:
            'Accounts cannot be deleted if you have an upcoming or unsettled reservation',
        authenticationError: 'Please log in and try again',
        creditCardError: 'There was an error with the credit or debit card',
        creditCardRemovalError:
            'Unable to remove card as it is currently used for an upcoming reservation',
        emailTakenError: 'Please enter a valid email',
        emailUpdateError:
            'There was an error updating this accounts email address',
        genericError: 'Oops, something went wrong',
        invalidCourse: 'There was an error retrieving the course or value',
        invalidReservationForMessageError:
            'Can not send a message for a Waitlist request',
        notUniqueError: 'The card is already on file, please try another card',
        passwordUpdateError: 'There was an error updating your password',
        questionnaireStatusError:
            'You have already submitted a questionnaire for this reservation',
        questionnaireSubmitError:
            'You cannot submit a questionnaire for this reservation at this time',
        reservationCancelError: 'An error occurred cancelling your reservation',
        reservationGenericError:
            'An Error has occurred. Reservation not created',
        reservationIneligibleBenefitsCardError:
            'Please use an eligible American Express credit card',
        reservationIneligibleBenefitsUserError:
            'Please use an eligible American Express credit card',
        reservationStatusError:
            'Your reservation cannot be cancelled at this time',
        reservationUnavailableError:
            'The selected reservation date or time is no longer available',
        resetPassword: {
            notFoundError: 'Your token is invalid',
            tokenExpirationError: 'Your token has expired',
            userInputError: 'Invalid password reset token',
        },
        serverError: 'An unexpected server error occurred.',
        traceId: 'ErrorId: {{traceId}}',
        userError: 'Your Email or Password is incorrect',
        userInputError: {
            birthday:
                'Please check the birthday you have entered and try again',
            company_name:
                'Please check the company name you have entered and try again',
            email: 'Please check the email you have entered and try again',
            firstname:
                'Please check the first name you have entered and try again',
            lastname:
                'Please check the last name you have entered and try again',
            password:
                'Please check the password you have entered and try again',
            tel: 'Please check the telephone number you have entered and try again',
            unknown: 'Please check your input and try again',
            user_resign_reasons:
                'Please check the explanation you have entered and try again',
        },
        userNewReservationsForbidden:
            'Reservations are currently restricted.<br/>Please contact the administrator using the <Link>contact form</Link>.',
        waitlistDuplicatedError:
            'You cannot make multiple Waitlist requests for the same day on the same venue',
    },
    form: {
        clear: 'Clear',
        fieldIsRequired: '{{label}} is required',
        no: 'No',
        pleaseWait: 'Please wait',
        required: 'Required',
        save: 'Save',
        select: 'Select',
        selectDate: 'Select Date',
        submit: 'Send',
        thisFieldIsRequired: 'This field is required',
        yes: 'Yes',
    },
    home: {
        firstRow: 'Recommended Areas by Dining Experts',
        makeReservationsThroughPocket:
            'Discover and access the best restaurants in Japan',
        newArrivals: 'New Arrivals',
        pickUpVenues: 'Featured Venues',
        secondRow: 'Popular Cuisine',
    },
    imageCarousel: {
        imageName: '{{name}} Image {{n}}',
        nextImage: 'Next Image',
        previousImage: 'Previous Image',
    },
    name: 'Name',
    pagination: {
        firstPage: 'First Page',
        lastPage: 'Last Page',
        nextPage: 'Next Page',
        page: 'Page {{page}}',
        prevPage: 'Previous Page',
        reservation_one: '{{start}}-{{end}} of {{count}} reservation',
        reservation_other: '{{start}}-{{end}} of {{count}} reservations',
        restaurant_one: '{{start}}-{{end}} of {{count}} restaurant',
        restaurant_other: '{{start}}-{{end}} of {{count}} restaurants',
    },
    readLess: 'Read less',
    readMore: 'Read more',
    realTimeBooking: 'Instant Reservation',
    redirectCountdown:
        "<a href='/'>Click to go to Pocket Concierge.</a><br/><br/>Automatically redirecting in {{seconds}} seconds...",
    redirecting: 'Redirecting',
    reservation: {
        costPerGroupCurrency: '¥{{costPerGroup}} / Group',
        costPerGuest: 'Cost per guest',
        costPerGuestCurrency: '¥{{costPerGuest}} / Guest',
        guestCount_one: '{{count}} Guest',
        guestCount_other: '{{count}} Guests',
        taxSurcharge: '(tax / surcharge)',
        timeOfArrival: 'Time of arrival between',
        totalAmount: 'Total amount',
    },
    reservationRequest: 'Reservation Request',
    reserve: {
        confirmation: {
            makeNewReservation: 'Make a new reservation',
            realTimeBooking: {
                case1: "A guest's breaking of rules clearly established by the restaurant.",
                case2: "An overwhelming difficulty adhering to guest's allergies and other food-related needs.",
                case3: 'An inability to receive guests due to other such sudden difficulties (excluding natural disasters).',
                contact:
                    "Finally, please understand that in the case that your contact information is found to be false or unavailable, your reservation may be cancelled at the restaurant's volition.",
                importantInformation:
                    'Important information about your reservation',
                liability:
                    'Furthermore, please be aware that in the case of the above causes for cancellation neither Pocket Concierge nor the restaurant will be held responsible.',
                smartPayment:
                    "You won't have to pay at the restaurant on the day, and any additional orders will be charged to your card via <Link>Smart Payment</Link>.",
                title: 'Your online payment has been received and reservation has been confirmed',
                understand:
                    'Please understand that we may rescind accepted reservations in the cases underlined below:',
            },
            reservationRequest: {
                importantInformation:
                    'Important information about your reservation request',
                liability: `Pocket Concierge will not be held liable for any losses incurred due to customers not receiving any emails. You are accountable for notifying <a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a> if you have not received the aforementioned email.`,
                noEmail: `If you still have not received the email, please contact <a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a> immediately.`,
                notFinalized:
                    'Please be aware that your reservation has not yet been finalized. Once our Concierge Team have confirmed the reservation with your requested restaurant, you will receive an email with full confirmation details.',
                receiveEmail:
                    'An email containing details of your request has now been sent to the email address associated with your account. If you have not received it within 5 minutes, please check your spam or junk folder.',
                title: 'Your reservation request has been received',
            },
            waitlist: {
                cancellations:
                    'Please note that cancellations on the day of your reservation or no-shows will incur a 100% cancellation fee.',
                deadline: 'Waitlist deadline',
                importantInformation:
                    'Important information about your Waitlist request',
                onceTableOpens:
                    'Once a table becomes available, <b>your reservation will be confirmed automatically</b>.',
                title: 'Your Waitlist request has been registered successfully',
            },
        },
        details: {
            allergy: 'Allergies',
            allergyExample:
                '(Example) 1 adult is mildly allergic to shellfish. Shellfish used in stock also needs to be avoided.',
            allergyMultiLabel:
                'Allergies <ul><li>In order to serve you better, please let us know the type of allergy, which guest in your party this applies to, and their severity level. </li><li>Please be informed that not all requests may be accommodated.</li></ul>',
            contactInformation: 'Contact Information',
            hotel: {
                guest: 'Name of guest',
                label: 'Are you staying in a hotel?',
                name: 'Hotel name',
                note: 'Note: Pocket Concierge will only use this information for emergency contact purposes',
            },
            id: {
                realTimeBooking: 'Reservation ID',
                reservationRequest: 'Request ID',
                waitlist: 'Waitlist ID',
            },
            memo: 'Special requests',
            onBehalfOf: {
                anotherPerson: 'Another person',
                label: 'Who is this reservation for?',
                myself: 'Myself',
            },
            preferences: 'Special Requests (optional)',
            specialRequestOption: {
                label: 'Anniversary requests',
                message: 'Anniversary message',
                unselected: 'Not necessary',
            },
            submit: 'Proceed',
            supplementaryInformation: 'Supplementary Information',
            title: {
                realTimeBooking: 'Reservation Details',
                reservationRequest: 'Reservation Request Details',
                waitlist: 'Waitlist Details',
            },
            visitFrequency: {
                FIRST_VISIT: 'First time',
                SECOND_VISIT: 'Second time',
                THIRD_OR_MORE_VISIT: 'Three or more times',
                label: 'How many times have you visited {{- name}}?',
                summary: 'How many times',
            },
            visitPurpose: {
                ANNIVERSARY: 'Anniversary',
                CLIENT_DINNER: 'Business dinner',
                DATE: 'Date',
                FAMILY: 'With family',
                JOB_FRIEND_DINNER: 'With colleagues',
                MEAL_WITH_FRIEND: 'With friends',
                OTHER: 'Other',
                label: 'Purpose of Visit',
            },
        },
        payment: {
            onlinePayment: 'Online payment with credit card',
            onlinePaymentRequired:
                'Your credit card is required at the time of booking so that an authorization request can be made to your payment provider.',
            required: 'You must select a form of payment',
            ryoshusho: {
                label: 'Ryoshusho',
                placeholder: 'Name on ryoshusho',
            },
            selectPaymentMethod: 'Select credit card for payment',
            submit: 'Proceed',
            title: 'Payment Details',
        },
        summary: {
            about: 'Reservation Request – Important Notices',
            agreeToTerms:
                "I agree with the <a href='/en/terms'>Terms of Use</a>, <a href='/en/privacy'>Privacy Policy</a>, and all the above notices.",
            checkReservationStatus:
                '<span>A reservation is confirmed when its status changes to "Reservation Completed" on the user\'s personal account page on the site.</span><br/> A confirmation email will be sent to you separately, but this email is for confirmation purposes only, so please be sure to check your personal account page after making a reservation. We will not provide compensation for any losses incurred due to "not receiving an email" or "not checking an email".',
            noEmail: `If you still have not received the email, please contact <a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a> immediately.`,
            notGuaranteed:
                'This form is used to make a reservation request and is not a guarantee of any seat(s) being reserved successfully. Once the restaurant has been contacted on your behalf, you will receive a further email notifying you if the reservation can, or can not, be confirmed.',
            realTimeBooking: {
                submit: 'Confirm and Pay',
                title: 'Reservation Summary',
            },
            receiveEmail:
                'You will receive an email confirming your requested details immediately after you click the button below. If you have not received an email within 5 minutes of the request being finalized, please check your spam or junk folder.',
            reservationPolicy: 'Reservation Policy',
            reservationRequest: {
                submit: 'Request Reservation',
                title: 'Reservation Request Summary',
            },
            waitlist: {
                about: 'About the Waitlist',
                checkReservationStatus:
                    'A reservation is confirmed when its status changes to "Reservation Completed" on the user\'s personal account page on the site.',
                confirmReservation:
                    'Until the expiration of your Waitlist request, Pocket Concierge will continue to attempt to confirm your reservation should any seats become available.',
                confirmationEmail:
                    'A confirmation email will be sent to you separately, but this email is for confirmation purposes only, so please be sure to check your personal account page after making a reservation. We will not provide compensation for any losses incurred due to "not receiving an email" or "not checking an email".',
                submit: 'Join Waitlist',
                title: 'Waitlist Summary',
            },
        },
    },
    returnToTop: 'Return to Top',
    search: {
        advancedSearch: 'Advanced Search',
        allDistricts: 'All {{prefecture}} Areas',
        clear: 'Clear',
        keywordResults: 'Keywords',
        label: 'Search',
        noRestaurantsFound:
            'No restaurants were found matching your search criteria.',
        pageTitle: 'Best {{-cuisine}} in {{-area}} | Pocket Concierge',
        pageTitleDefault: 'Reserve top restaurants in Japan | Pocket Concierge',
        placeholderKeyword: 'Restaurant, keyword',
        priceRange: 'Price range',
        priceRanges: {
            '1': '¥10,000 or less',
            '2': '¥10,000 - ¥20,000',
            '3': '¥20,000 - ¥30,000',
            '4': '¥30,000 and above',
        },
        realTimeBooking: 'Book Instantly',
        restaurants: 'Restaurants',
        searchByNameOrKeyword: 'Search by name or keyword',
        seatsAvailable: 'Seats available',
        selectADate: 'Select a date',
        selectArea: 'Location',
        selectCuisine: 'Cuisine',
        selectDate: 'Select date',
        selectGuests: 'Guests',
        selectSeating: 'Seating Options',
        selectServiceType: 'Time',
        selectServiceTypeWaitlist: 'Meal',
        useCurrentLocation: 'Use Current Location',
        waitlistOnly: 'Waitlist only',
    },
    seatingType: {
        COUNTER: 'Counter',
        NONE: 'Unspecified',
        PRIVATE_COUNTER: 'Private Counter',
        PRIVATE_ROOM: 'Private Room',
        TABLE: 'Table',
        TAKEOUT: 'Takeout',
        label: 'Seat type',
    },
    select: 'Select',
    selectLanguage: 'Select Language',
    serviceTypes: {
        DINNER: 'Dinner',
        LUNCH: 'Lunch',
    },
    siteName: 'Pocket Concierge',
    staticPages: {
        companyProfile: 'Company Profile',
        contact: 'Contact',
        faq: 'FAQ',
        privacyPolicy: 'Privacy Policy',
        termsOfUse: 'Terms of Use',
        transaction:
            'Notation based on the Specified Commercial Transaction Act',
    },
    theme: {
        darkMode: 'Dark Mode',
        enableDarkMode: 'Enable Dark Mode',
        enableLightMode: 'Enable Light Mode',
        lightMode: 'Light Mode',
    },
    underConstruction: 'Under construction',
    user: {
        basicInformation: {
            companyName: 'Company Name',
            dob: 'Date of Birth',
            email: 'Email',
            gender: {
                female: 'Female',
                label: 'Gender',
                male: 'Male',
                preferNotToSay: 'Prefer not to say',
            },
            name: {
                first: {
                    kana: 'First Name (Kana)',
                    label: 'First Name',
                    placeholder: 'First',
                },
                furigana: 'Furigana',
                last: {
                    kana: 'Last Name (Kana)',
                    label: 'Last Name',
                    placeholder: 'Last',
                },
            },
            phoneNumber: {
                countryCode: 'Country code',
                invalid: 'Invalid number',
                label: 'Phone Number',
            },
            title: 'Basic Information',
            updated: 'Your profile has been updated',
        },
        changeEmail: {
            currentEmail: 'Current email',
            newEmail: 'New Email',
            success: 'Confirmation email sent to:',
            title: 'Change Email',
            toastMessage:
                'A confirmation email has been sent to your new email address',
            warning: 'Emails are the same',
        },
        changePassword: {
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            title: 'Change Password',
            updated: 'Your password has been updated',
        },
        deleteAccount: {
            BAD_RESPONSE: 'Poor customer service',
            HARD_TO_USE_SERVICE: 'The service is hard to use',
            LIFESTYLE_MISMATCH: 'The restaurants are too expensive',
            NO_ATTRACTIVE_RESTAURANTS:
                'Not interested in any of the restaurants',
            NO_CAMPAIGN: 'No discount campaigns available',
            OTHER: 'Other',
            confirmation: 'Permanently delete my account',
            farewell: 'Sorry to see you go!',
            note: 'Please provide a written explanation',
            reasonRequired: 'Please select at least one reason',
            reasons: 'Why are you deleting your account?',
            title: 'Delete Account',
            warning:
                '<p>Please use this page for a full account removal from our service.</p>After your account has been deactivated:<ul><li>All personally identifiable information (PII) will be removed from our system, and we will be unable to reactivate the account.</li><li>You may rejoin at a later date with the same email address by creating a new user account. Please note that existing reservation history will not be available in the new account.</li><li>You will be removed from our mailing list if you are signed up (<Link>Click here</Link> if you only wish to unsubscribe from our mailing list).</li></ul><p>All user feedback is important to us, and it would be greatly appreciated if you could take a moment to let us know the reason that you intend to delete your account.</p>',
        },
        emailPreferences: {
            featuredMailNotification: 'Vacant Seats Notification',
            featuredMailNotificationTitle: 'Vacant Seats Notification',
            newsletter:
                'I want to receive the Pocket Concierge newsletter<br/><span>Please allow up to 10 days for your newsletter subscription to be processed in our system.</span>',
            newsletterTitle: 'Newsletter Subscription',
            title: 'Email Preferences',
            updated: 'Your email preferences have been updated',
        },
        foodPreferences: {
            allergy: 'Allergies',
            title: 'Food Preferences',
            updated: 'Your food preferences have been updated',
        },
        fullName: '{{firstName}} {{lastName}}',
        password: {
            confirm: 'Confirm Password',
            input: 'Password',
            lowercase: 'At least 1 lowercase letter',
            min: 'At least 8 characters',
            mismatch: 'Passwords do not match',
            number: 'At least 1 number',
            showHide: 'Show/Hide Password',
            uppercase: 'At least 1 uppercase letter',
        },
        paymentMethods: {
            acceptedCards: 'Pocket Concierge accepts the following cards',
            addNewCard: 'Add a new card',
            cardEndingIn: '****{{cardLast4}}',
            cardList: 'Card List',
            cardNumber: 'Card Number',
            defaultCard: 'Default Card',
            expirationDate: 'Expiration Date',
            expired: 'Expired {{- expires}}',
            expires: 'Expiry {{- expires}}',
            nameOnCard: 'Name on Card',
            noCards: 'You have not added any cards, yet.',
            removeCard: 'Remove',
            saveCard: 'Save Card',
            securityCode: 'Security Code',
            setDefaultCard: 'Set as default',
            threeDButtonTitle: 'Authenticate',
            threeDSubTitle:
                'In order to use online payments, identity authentication is needed',
            threeDTitle: 'Security check required',
            title: 'Payment Methods',
        },
        profile: 'Profile',
        reservations: {
            bookedOnBehalfOf: 'This reservation was booked on behalf of',
            button: {
                back: 'Back',
                cancel: 'Cancel',
                cancelReservation: 'Cancel this reservation',
                inquiry: 'Changes - Inquiries',
                invoice: 'Invoice',
                withdraw: 'Withdraw Request',
            },
            cancelDayBeforeInformation:
                'For cancellations, changes, and inquiries to the reservation after 6PM on the day before the visit day, please call the restaurant directly. Tel: {{tel}}',
            cancelReason: 'Reason for cancelling',
            cancelReservation: 'Cancel Reservation',
            cancelReservationCancel: 'Do not cancel',
            cancellation: {
                confirmation: 'Your cancellation request has been sent',
                fee: 'Cancellation fee: ¥{{amount}} ({{percentage}}%)',
                notice: 'Please be aware of the <Link>cancellation policy</Link>',
                reason: 'Are you sure you want to cancel your reservation?',
                subTitle: 'Would you like to cancel this reservation?',
                subTitleWaitlist: 'Would you like to withdraw this request?',
                title: 'Cancel Reservation',
                titleWaitlist: 'Withdraw Request',
            },
            details: {
                order: 'Order',
                price: 'Price',
                quantity: 'Quantity',
                statement: 'Detailed Statement',
                total: 'Total',
                totalAmount: 'Total Amount',
            },
            filters: {
                aria: 'Filter',
                cancellations: 'Cancellations',
                completed: 'Completed',
                confirmed: 'Confirmed',
                past: 'Past',
                pending: 'Pending',
            },
            hideReservationDetails: 'Hide reservation details',
            inquiry: {
                subTitle: 'The restaurant will shortly respond to you by email',
                title: 'Inquiries about your reservation',
            },
            noReservations: 'You have not made any reservations, yet.',
            noReservationsFiltered:
                'None of your reservations match the selected filter.',
            payment: 'Payment',
            paymentDate: 'Payment date',
            questionnaire: {
                header: 'Give us your feedback!',
                label: 'Please give some brief feedback',
                rating: 'How satisfied were you with your dining experience?',
                satisfactionRating: {
                    DISSATISFIED: 'Dissatisfied',
                    SATISFIED: 'Satisfied',
                    VERY_DISSATISFIED: 'Very dissatisfied',
                    VERY_SATISFIED: 'Very satisfied',
                },
                success: 'Thank you for your feedback!',
            },
            reservationStatus: 'Status',
            selection: {
                all: 'All',
                canceled: 'Canceled',
                completed: 'Completed',
                past: 'Past',
                pending: 'Pending',
                reserved: 'Reserved',
                upcoming: 'Upcoming',
            },
            sendMessage:
                'Please send us any inquiries including changes and cancellations on your reservation by the below form.',
            sendMessageSuccess: 'Your request has been sent to the restaurant.',
            showReservationDetails: 'Show reservation details',
            status: {
                CANCELLATION_CONFIRMED: {
                    label: 'Cancellation complete',
                },
                CANCELLATION_REQUESTED: {
                    label: 'Cancellation requested',
                    message:
                        'We are reviewing your cancellation request. Please wait.',
                },
                CONFIRMED: {
                    label: 'Reservation confirmed',
                    message: 'Your reservation has been confirmed.',
                },
                DECLINED: {
                    label: 'Declined',
                    message: 'The venue has declined your reservation.',
                },
                NO_SHOW: {
                    label: 'No show',
                    message:
                        'As per our policy, your payment will not be refunded.',
                },
                PENDING: {
                    reservation: {
                        label: 'Confirming reservation',
                        message:
                            'Your reservation is being confirmed. Please wait.',
                    },
                    waitlist: {
                        label: 'Waitlist',
                    },
                },
                SAME_DAY_CANCELLATION: {
                    label: 'Same-day cancellation',
                },
                VISITED: {
                    label: 'Visited',
                    message: 'Thank you for your visit.',
                },
                WAITLIST_PERIOD_EXPIRED: {
                    label: 'Waitlist Status Cancelled',
                    message: 'Your waitlist status has been cancelled.',
                },
            },
            title: 'Reservations',
            waitlist: 'Waitlist',
            waitlistDeadline: 'Deadline',
            withdrawRequest: 'Withdraw Request',
            withdrawRequestCancel: 'Do not withdraw',
            withdrawRequestConfirm:
                'Are you sure you want to withdraw your Waitlist request?',
        },
        settings: 'Settings',
        welcome: 'Welcome',
    },
    venue: {
        address: '{{town}} {{street}}, {{city}}, {{prefecture}}',
        addressHidden:
            'The address will be provided upon confirmation of the reservation.',
        businessHours: 'Hours of operation',
        cuisine: 'Cuisine',
        dateOfVisit: 'Date of Visit:',
        faq: 'FAQ',
        holidays: 'Holidays',
        name: 'Restaurant name',
        noRecommendationsAvailable: 'Reviews are not yet available',
        noSeats: 'No reservable dates are currently available',
        numberOfVisits: {
            FIRST_VISIT: '1',
            SECOND_VISIT: '2',
            THIRD_OR_MORE_VISIT: '3+',
            label: 'Number of visits:',
        },
        pageTitle: '{{-name}} Reservations | Pocket Concierge',
        pageTitleDefault: 'Reserve top restaurants in Japan | Pocket Concierge',
        purposeOfVisit: {
            ANNIVERSARY: 'Anniversary',
            CLIENT_DINNER: 'Client dinner',
            DATE: 'Date',
            EXTERNAL_SYSTEM_RESERVATION: 'External system reservation',
            FAMILY: 'Family',
            JOB_FRIEND_DINNER: 'Work colleague dinner',
            MEAL_WITH_FRIEND: 'Meal with friend',
            OTHER: 'Other',
            label: 'Purpose:',
        },
        reservation: 'Reservation',
        secretSeat: {
            expirationText:
                'This seat will expire <br/> on {{date}} at {{time}}',
            title: 'Secret Seats',
        },
        services: {
            anniversaryServices: 'Anniversary Services',
            childFriendly: 'Child Friendly',
            childrenOver12Accepted: 'Children over 12 accepted',
            englishMenu: 'English Menu',
            englishSpeakingStaff: 'English Speaking Staff',
            partyReservations: 'Party Reservations',
            privateRooms: 'Private Rooms',
            smokingArea: 'Smoking Area',
            taxiAvailable: 'Taxi Available',
        },
        tabs: {
            access: {
                label: 'Access',
            },
            courses: {
                confirmReservation: 'Confirm Reservation',
                confirmWaitlist: 'Register for Waitlist',
                course: 'Course',
                label: 'Courses',
                noCoursesAvailable:
                    'There are no availabilities on<br/>{{date}}.',
                selectTime: 'Select time',
                time: 'Time',
                waitlistDeadlineWarning:
                    'Your Waitlist request will automatically be cancelled at {{time}} (JST) on the deadline date.',
                youWontBeChargedYet: "You won't be charged yet",
            },
            info: {
                label: 'Info',
            },
            recommendations: {
                label: 'Reviews',
            },
        },
        viewAllReviews: 'View all {{count}} reviews',
        waitlist: {
            explanation:
                'If you choose to join the Waitlist and a seat becomes available, we will confirm your reservation automatically and notify you immediately.',
            footnote:
                'Please note that joining the Waitlist does not guarantee your reservation.',
            title: 'Waitlist Registration',
        },
        website: 'Website',
    },
    viewAll: 'View all',
    viewCourses: {
        DINNER: 'View Dinner Courses',
        LUNCH: 'View Lunch Courses',
    },
    waitlist: 'Waitlist',
};
