import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from 'components/Button';
import VenueModal from 'components/Venue/VenueModal/VenueModal';
import {Course, ServiceType} from 'gql/generated';
import {commify} from 'utils/string';
import styles from './styles.module.css';

type CoursesListProps = {
    courses: Course[];
    serviceType: ServiceType;
};

const CoursesList: FC<CoursesListProps> = ({courses, serviceType}) => {
    const {t} = useTranslation();
    const [selectedCourse, setSelectedCourse] = useState<null | Course>(null);

    return (
        <>
            {selectedCourse && (
                <VenueModal
                    closeModal={() => setSelectedCourse(null)}
                    course={selectedCourse}
                />
            )}
            {courses
                .filter((course) => course.serviceType === serviceType)
                .map((course) => (
                    <div
                        key={course.id}
                        className={`bg-step relative mt-2 items-center justify-between rounded-md p-4 ${styles.shadow} grid grid-cols-[1fr,_6rem] gap-x-2`}
                    >
                        <div>
                            <h3 className="font-semibold">{course.name}</h3>
                            <div className="mt-1 text-sm">
                                {t('reservation.costPerGuestCurrency', {
                                    ...course,
                                    costPerGuest: commify(course.costPerGuest),
                                })}
                            </div>
                        </div>
                        <Button
                            className="w-24 border-grey-700 text-grey-700"
                            data-dd-action-name="CourseListDetails"
                            kind="secondary"
                            onClick={() => setSelectedCourse(course)}
                            size="sm"
                        >
                            {t('details')}
                        </Button>
                    </div>
                ))}
        </>
    );
};

export default CoursesList;
