import {FC, useEffect} from 'react';
import clsx from 'clsx';
import {AvailabilityType} from 'types/reservation';

export type DateSelectorDateProps = {
    availabilityType?: AvailabilityType;
    cell?: number;
    currentFocus?: string;
    date: Date;
    id: string;
    isSelected?: boolean;
    isThisMonth?: boolean;
    isToday?: boolean;
    onClick?: (value: Date) => void;
    setCurrentFocus: (value: string) => void;
};

const DateSelectorDate: FC<DateSelectorDateProps> = (props) => {
    const {
        availabilityType,
        cell,
        currentFocus,
        date,
        id,
        isSelected,
        isThisMonth,
        isToday,
        onClick,
        setCurrentFocus,
    } = props;

    const dateId = `${id}-date-selector-date-${cell}`;

    useEffect(() => {
        if (!currentFocus && isSelected) {
            setCurrentFocus(dateId);
        }
    }, [cell, currentFocus, dateId, isSelected, setCurrentFocus]);

    return (
        <td className="w-8 text-center">
            <button
                className={clsx(
                    'mt-3 h-9 w-9 rounded-full border-2',
                    availabilityType === 'reservation'
                        ? 'border-gold-200'
                        : availabilityType === 'waitlist'
                        ? 'border-outline'
                        : 'border-transparent',
                    !isSelected &&
                        availabilityType === 'waitlist' &&
                        'bg-grey-100 dark:bg-grey-800',
                    /*isToday &&
                        !isSelected &&
                        'bg-grey-100 dark:bg-grey-600',*/
                    isToday && !isSelected && onClick && 'text-golden',
                    !isThisMonth &&
                        !isSelected &&
                        onClick &&
                        'text-grey-600 dark:text-grey-400',
                    !onClick && 'cursor-not-allowed',
                    isSelected
                        ? 'bg-gold-200 font-bold text-white'
                        : onClick &&
                              'hover:bg-gold-200 hover:text-white dark:hover:bg-gold-200 dark:hover:text-white',
                    !onClick &&
                        !isSelected &&
                        'text-grey-300/50 dark:text-grey-600/50'
                )}
                data-dd-action-name="CalendarDate"
                disabled={!onClick}
                id={dateId}
                onClick={onClick ? () => onClick(date) : undefined}
                tabIndex={onClick ? 0 : -1}
                type="button"
            >
                {date.getDate()}
            </button>
        </td>
    );
};

export default DateSelectorDate;
