import {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useComponentRect from 'hooks/useComponentRect';
import FallbackImage from '../FallbackImage';
import styles from './styles.module.css';

export type ImageCarouselProps = {
    className?: string;
    images?: string[];
    name: string;
};

const ImageCarousel: FC<ImageCarouselProps> = ({
    className,
    images = [],
    name,
}) => {
    const {t} = useTranslation();

    const [index, setIndex] = useState(0);

    const hasImage = !!images?.length;
    const hasMultipleImages = hasImage && images.length > 1;
    const image = hasImage ? images[index] : undefined;

    const onPrevious = () => {
        setIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
    };

    const onNext = () => {
        setIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
    };

    const [minHeight, setMinHeight] = useState(0);
    const [trigger, setTrigger] = useState(0);
    const ref = useRef(null);

    const rect = useComponentRect(ref, trigger);

    useEffect(() => {
        if (rect.height > 0) {
            setMinHeight(rect.height);
        }
    }, [rect]);

    const [failedImagesIndices, setFailedImagesIndices] = useState<number[]>(
        []
    );

    const onImageLoad = () => {
        setTrigger((p) => p + 1);
    };

    const onImageError = () => {
        setFailedImagesIndices([...failedImagesIndices, index]);

        if (hasMultipleImages) {
            onNext();
        } else {
            setIndex(9999);
        }
    };

    return (
        <div className={className}>
            <figure className="relative" style={{minHeight}}>
                {image && !failedImagesIndices.includes(index) ? (
                    <img
                        key={index}
                        ref={ref}
                        alt={t('imageCarousel.imageName', {n: index + 1, name})}
                        className="h-full w-full overflow-hidden object-cover object-center md:rounded-t"
                        id="venueImage"
                        onError={onImageError}
                        onLoad={onImageLoad}
                        src={image}
                    />
                ) : (
                    <FallbackImage
                        alt={t('imageCarousel.imageName', {n: index + 1, name})}
                        className="h-full w-full overflow-hidden object-cover object-center md:rounded-t"
                        id="venueImage"
                    />
                )}
                <div className="absolute inset-0 flex items-center justify-between p-2 text-grey-50">
                    {hasMultipleImages && (
                        <>
                            <button
                                aria-label={t('carousel.previousImage')}
                                className="flex h-8 w-8 items-center justify-center focus-visible:ring-1"
                                data-dd-action-name="PreviousImage"
                                onClick={onPrevious}
                                type="button"
                            >
                                <FontAwesomeIcon
                                    className={styles.shadow}
                                    icon={['fas', 'chevron-left']}
                                />
                            </button>
                            <button
                                aria-label={t('carousel.nextImage')}
                                className="flex h-8 w-8 items-center justify-center focus-visible:ring-1"
                                data-dd-action-name="NextImage"
                                onClick={onNext}
                                type="button"
                            >
                                <FontAwesomeIcon
                                    className={styles.shadow}
                                    icon={['fas', 'chevron-right']}
                                />
                            </button>
                        </>
                    )}
                </div>
            </figure>
        </div>
    );
};

export default ImageCarousel;
