/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */
type TryCatchResult = {
    error?: Error;
    result?: unknown;
};

// @ts-ignore
export const tryCatch = (fn: Function, ...args: any[]): TryCatchResult => {
    let error;
    let result;

    try {
        result = fn(...args);
    } catch (caughtError) {
        error = caughtError as Error;
    }

    return {error, result};
};

export const sleep = (milliseconds: number) =>
    new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    });

export const compose = (...fns: Function[]): Function =>
    fns.reduce(
        (f, g) =>
            (...args: unknown[]) =>
                f(g(...args))
    );

export const handleError = (error: Error) => {
    // TODO: Send to datadog
    // eslint-disable-next-line no-console
    console.error(error);
};
