import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import FadeIn from 'components/FadeIn';
import NoResults from 'components/NoResults';
import VenueResultCard from 'components/Venue/VenueResultCard';
import {useUser} from 'state/user';

const Bookmarks: FC = () => {
    const {t} = useTranslation();

    const user = useUser();

    return (
        <div className="py-4">
            <h2 className="text-center text-2xl sm:text-left">
                {t('bookmarks.title')}
            </h2>
            <div className="mt-4 min-h-[23.375rem]">
                {user?.bookmarkedVenues?.length ? (
                    <FadeIn delay="delay-200">
                        <div className="grid grid-cols-1 place-content-center place-items-stretch gap-4 px-4 sm:grid-cols-2 md:px-0">
                            {user?.bookmarkedVenues.map((venue) => (
                                <VenueResultCard
                                    key={venue.id}
                                    isBookmark={true}
                                    venue={venue}
                                />
                            ))}
                        </div>
                    </FadeIn>
                ) : (
                    <NoResults icon="bookmarks" label={t('bookmarks.none')} />
                )}
            </div>
        </div>
    );
};

export default Bookmarks;
