import {FC, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollContainer} from 'components/ScrollContainer/ScrollContainer';
import {ChangeEmailContent} from './Contents/ChangeEmail';
import {ChangePasswordContent} from './Contents/ChangePassword';
import {DeleteAccountContent} from './Contents/DeleteAccount';

export const Settings: FC = () => {
    const changePasswordRef = useRef<HTMLDivElement | null>(null);
    const changeEmailRef = useRef<HTMLDivElement | null>(null);
    const deleteAccountRef = useRef<HTMLDivElement | null>(null);
    const {t} = useTranslation();

    const sections = useMemo(
        () => [
            {
                content: <ChangePasswordContent />,
                elementRef: changePasswordRef,
                title: t('user.changePassword.title'),
                value: 'changePassword',
            },
            {
                content: <ChangeEmailContent />,
                elementRef: changeEmailRef,
                title: t('user.changeEmail.title'),
                value: 'changeEmail',
            },
            {
                content: <DeleteAccountContent />,
                elementRef: deleteAccountRef,
                title: t('user.deleteAccount.title'),
                value: 'deleteAccount',
            },
        ],
        []
    );

    return ScrollContainer({headerTitle: t('user.settings'), sections});
};
