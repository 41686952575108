import {useEffect, useState} from 'react';

const BREAKPOINTS = {
    lg: 1024,
    md: 768,
    sm: 640,
    xl: 1280,
};

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl';

const useBreakpoint = (breakpoint: Breakpoint): boolean | undefined => {
    const [isBreakpoint, setIsBreakpoint] = useState<boolean | undefined>(() =>
        typeof window === 'undefined'
            ? undefined
            : window.innerWidth >= BREAKPOINTS[breakpoint]
    );

    useEffect(() => {
        const onUpdate = () => {
            setIsBreakpoint(window.innerWidth >= BREAKPOINTS[breakpoint]);
        };

        onUpdate();
        window.addEventListener('resize', onUpdate);

        return () => {
            window.removeEventListener('resize', onUpdate);
        };
    }, [breakpoint]);

    return isBreakpoint;
};

export default useBreakpoint;
