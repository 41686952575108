import {FC, InputHTMLAttributes, ReactNode} from 'react';
import {useFormContext} from 'react-hook-form';
import BaseCheckbox from 'components/Form/Base/BaseCheckbox';
import {FormContextBoolean, RequiredRules} from 'components/Form/types';
import {getRegisterRules} from 'components/Form/utils';
import {Size} from 'types';

export type CheckboxProps<T = HTMLInputElement> = RequiredRules<
    Omit<InputHTMLAttributes<T>, 'size'>
> & {
    helpText?: ReactNode;
    label?: ReactNode;
    name: string;
    size?: Size;
    type?: never;
};

const Checkbox: FC<CheckboxProps> = ({
    className,
    disabled,
    helpText,
    name,
    required,
    rules,
    ...rest
}) => {
    const {
        formState: {errors},
        register,
    } = useFormContext<FormContextBoolean>();

    const registerRules = getRegisterRules({disabled, required, rules});

    return (
        <BaseCheckbox
            className={className}
            error={errors[name]}
            helpText={helpText}
            required={!!registerRules?.required}
            {...register(name, registerRules)}
            {...rest}
        />
    );
};

export default Checkbox;
