import {useQuery} from '@apollo/client';
import {
    MarketingContentDocument,
    MarketingContentQuery,
    MarketingContentTypeEnum,
} from 'gql/generated';
import MarketingCard from './Shared/MarketingCard';

export const VenueCards = () => {
    const {data: marketingData} = useQuery<MarketingContentQuery>(
        MarketingContentDocument
    );

    const venueMarketingContent =
        marketingData?.retrieveMarketingContent?.filter(
            (item) => item.contentType === MarketingContentTypeEnum.Venue
        );

    return (
        <div className="mx-4 mt-4 flex flex-col gap-y-4 sm:mx-0">
            {venueMarketingContent?.map((item) => (
                <MarketingCard
                    key={item.imageUrl}
                    assetName={item.link.split('/')?.pop()}
                    imageClassName="aspect-[3.875/1]"
                    imageURL={item.imageUrl}
                    isExternal={item.external}
                    path={item.link}
                />
            ))}
        </div>
    );
};
