import {createContext, FC, ReactNode, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import {Locale} from 'types';

const LocaleContext = createContext<Locale>(
    (Cookies.get('pocket_locale') || 'en') as Locale
);

export const useLocale = (): Locale => {
    const context = useContext(LocaleContext);

    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error('useLocale must be used within a LocaleProvider');
    }

    return context;
};

export type LocaleProviderProps = {
    children: ReactNode;
};

export const LocaleProvider: FC<LocaleProviderProps> = ({children}) => {
    const {i18n} = useTranslation();
    const cookieLang = Cookies.get('pocket_locale');
    const locale = (cookieLang || i18n.language || 'en') as Locale;

    return (
        <LocaleContext.Provider value={locale as Locale}>
            {children}
        </LocaleContext.Provider>
    );
};

LocaleProvider.displayName = 'LocaleProvider';
