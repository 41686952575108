import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import SkeletonText from '../../../SkeletonText';

export type SkeletonFieldRequiredTextProps = {
    className?: string;
    step?: boolean;
};

const SkeletonFieldRequiredText: FC<SkeletonFieldRequiredTextProps> = ({
    className,
    step,
}) => {
    const {t} = useTranslation();

    return (
        <SkeletonText
            className={clsx(
                'ml-4 w-fit rounded border border-transparent py-px text-xs font-normal',
                className
            )}
            step={step}
        >
            {t('form.required')}
        </SkeletonText>
    );
};

export default SkeletonFieldRequiredText;
