import {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import {addHours} from 'date-fns';
import {Credential, Maybe} from 'gql/generated';
import Cookies from 'js-cookie';
import {tryCatch} from 'utils/functions';

const CredentialsStateContext = createContext<Maybe<Credential>>(null);

const CredentialsDispatchContext = createContext<
    Dispatch<SetStateAction<Maybe<Credential>>> | undefined
>(undefined);

export const useCredentialsState = (): Maybe<Credential> =>
    useContext(CredentialsStateContext);

export const useCredentialsDispatch = (): Dispatch<
    SetStateAction<Maybe<Credential>>
> => {
    const context = useContext(CredentialsDispatchContext);

    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error(
            'useCredentialsDispatch must be used within an CredentialsStateProvider'
        );
    }

    return context;
};

export const useCredentials = (): [
    Maybe<Credential>,
    Dispatch<SetStateAction<Maybe<Credential>>>
] => [useCredentialsState(), useCredentialsDispatch()];

export type CredentialsProviderProps = {
    children: ReactNode;
    initialState?: Credential;
};

const COOKIE_NAME = 'pocket_client_credentials';

export const CredentialsProvider: FC<CredentialsProviderProps> = ({
    children,
    initialState,
}) => {
    const hasInitialState = !!initialState?.accessToken;

    const [credentials, setCredentials] = useState<Maybe<Credential>>(() => {
        if (!hasInitialState) {
            const {result} = tryCatch(() =>
                JSON.parse(Cookies.get(COOKIE_NAME) || '')
            );

            return result as Credential;
        }

        return initialState;
    });

    useEffect(() => {
        if (!hasInitialState) {
            // Improvised fix until we fixed the subdomain problem on production
            const options: Cookies.CookieAttributes = {
                domain: 'pocket-concierge.jp',
                expires: addHours(new Date(), 1),
            };

            if (
                window.location.hostname === 'localhost' ||
                window.location.hostname === '127.0.0.1'
            ) {
                delete options.domain;
            }

            if (credentials) {
                Cookies.set(COOKIE_NAME, JSON.stringify(credentials), options);
            } else {
                Cookies.remove(COOKIE_NAME, options);
            }
        }
    }, [credentials, hasInitialState]);

    return (
        <CredentialsStateContext.Provider value={credentials}>
            <CredentialsDispatchContext.Provider value={setCredentials}>
                {children}
            </CredentialsDispatchContext.Provider>
        </CredentialsStateContext.Provider>
    );
};

CredentialsProvider.displayName = 'CredentialsProvider';
