import {FC, SyntheticEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import clsx from 'clsx';
import Button from 'components/Button';
import {RemoveBookmarkDocument, UserDocument} from 'gql/generated';
import {ID} from 'types';
import {handleError} from 'utils/functions';

type BookmarkRemoveButtonProps = {
    venueId: ID;
};

const BookmarkRemoveButton: FC<BookmarkRemoveButtonProps> = ({venueId}) => {
    const {t} = useTranslation();

    const [removeBookmark, {loading}] = useMutation(RemoveBookmarkDocument, {
        awaitRefetchQueries: true,
        refetchQueries: [UserDocument],
    });

    const onClick = async (event: SyntheticEvent) => {
        event.preventDefault();
        await removeBookmark({variables: {venueId}}).catch(handleError);
    };

    return (
        <Button
            className={clsx('w-full', loading && 'cursor-wait')}
            kind="primary"
            loading={loading ? t('bookmarks.remove') : undefined}
            onClick={onClick}
        >
            <span>{t('bookmarks.remove')}</span>
        </Button>
    );
};

export default BookmarkRemoveButton;
