import {Navigate, Route} from 'react-router-dom';
import Server404 from 'components/Errors/Server404';

/*
 * TODO: This can be removed once the NFE is released AND the FAQ links have been adjusted to support the NFE.
 */

export const oldFrontEndRedirects = () => {
    return (
        <Route>
            <Route path="users/*">
                <Route element={<Server404 />} path="*" />
                {/* /users/confirmation?token=XXXX -> /confirmation?token=XXXX */}
                <Route
                    element={
                        <Navigate
                            to={{
                                pathname: `${
                                    window.location.pathname.includes('/en/')
                                        ? '/en'
                                        : ''
                                }/confirmation`,
                                search: window.location.search,
                            }}
                        />
                    }
                    path="confirmation"
                />
                {/* /users/edit -> /account/profile */}
                <Route
                    element={
                        <Navigate
                            to={{
                                pathname: `${
                                    window.location.pathname.includes('/en/')
                                        ? '/en'
                                        : ''
                                }/account/profile`,
                            }}
                        />
                    }
                    path="edit"
                />
                {/* /users/password/edit?token=XXXX -> /reset-password?token=XXXX */}
                <Route
                    element={
                        <Navigate
                            to={{
                                pathname: `${
                                    window.location.pathname.includes('/en/')
                                        ? '/en'
                                        : ''
                                }/reset-password`,
                                search: window.location.search,
                            }}
                        />
                    }
                    path="password/edit"
                />
                {/* /users/unlock?token=XXXX -> /unlock?token=XXXX */}
                <Route
                    element={
                        <Navigate
                            to={{
                                pathname: `${
                                    window.location.pathname.includes('/en/')
                                        ? '/en'
                                        : ''
                                }/unlock`,
                                search: window.location.search,
                            }}
                        />
                    }
                    path="unlock"
                />
                {/* /users/resign -> /account/settings?activeSelection=deleteAccount */}
                <Route
                    element={
                        <Navigate
                            to={{
                                pathname: `${
                                    window.location.pathname.includes('/en/')
                                        ? '/en'
                                        : ''
                                }/account/settings`,
                                search: 'activeSection=deleteAccount',
                            }}
                        />
                    }
                    path="resign"
                />
                {/* /users/sign_in -> home page (with open login modal) */}
                <Route
                    element={
                        <Navigate
                            to={{
                                pathname: `${
                                    window.location.pathname.includes('/en/')
                                        ? '/en'
                                        : ''
                                }/`,
                                search: 'modalType=signup',
                            }}
                        />
                    }
                    path="sign_in"
                />
            </Route>
            {/* /featured_restaurants -> /account/bookmarks */}
            <Route
                element={
                    <Navigate
                        to={{
                            pathname: `${
                                window.location.pathname.includes('/en/')
                                    ? '/en'
                                    : ''
                            }/account/bookmarks`,
                        }}
                    />
                }
                path="featured_restaurants"
            />
            {/* /payment_methods -> /account/payment-methods */}
            <Route
                element={
                    <Navigate
                        to={{
                            pathname: `${
                                window.location.pathname.includes('/en/')
                                    ? '/en'
                                    : ''
                            }/account/payment-methods`,
                        }}
                    />
                }
                path="payment_methods"
            />
            {/* /reservations/* -> /account/reservations */}
            <Route
                element={
                    <Navigate
                        to={{
                            pathname: `${
                                window.location.pathname.includes('/en/')
                                    ? '/en'
                                    : ''
                            }/account/reservations`,
                        }}
                    />
                }
                path="reservations/*"
            />
            {/* /waitings/* -> /account/reservations */}
            <Route
                element={
                    <Navigate
                        to={{
                            pathname: `${
                                window.location.pathname.includes('/en/')
                                    ? '/en'
                                    : ''
                            }/account/reservations`,
                        }}
                    />
                }
                path="waitings/*"
            />
            {/* /reservations/1234/questionnaire -> /account/reservations?status=completed&time=past&page=1&id=1234 */}
            <Route
                element={
                    <Navigate
                        to={{
                            pathname: `${
                                window.location.pathname.includes('/en/')
                                    ? '/en'
                                    : ''
                            }/account/reservations`,
                            search: `status=completed&time=past&page=1&id=${window.location.pathname.match(
                                /\d{5,}/
                            )}`,
                        }}
                    />
                }
                path="reservations/:id/questionnaire"
            />
            {/* /term -> /terms */}
            <Route
                element={
                    <Navigate
                        to={{
                            pathname: `${
                                window.location.pathname.includes('/en/')
                                    ? '/en'
                                    : ''
                            }/terms`,
                        }}
                    />
                }
                path="term"
            />
        </Route>
    );
};
