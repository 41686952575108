import {FC} from 'react';
import VenueImageCard from 'components/Venue/VenueImageCard';
import {VenueSearchResultFragment} from 'gql/generated';

interface HomeFeaturedGroupProps {
    venues: VenueSearchResultFragment[];
}

const HomeFeaturedGroup: FC<HomeFeaturedGroupProps> = ({venues}) => {
    return (
        <div className="bg-grey-800 pb-12 pt-8 -mb-12">
            <div className="site-container mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:gap-2 2xl:gap-4">
                {venues.map((venue) => (
                    <VenueImageCard key={venue.id} venue={venue} />
                ))}
            </div>
        </div>
    );
};

export default HomeFeaturedGroup;
