import {FC, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import Form from 'components/Form';
import InputEmail from 'components/Form/InputEmail';
import SubmitButton from 'components/Form/SubmitButton';
import {ForgotPasswordDocument} from 'gql/generated';
import {FormData} from 'types';

// Request an email to reset password with a token

type ForgotPasswordFormData = FormData<{email: string}>;

const ForgotPasswordPage: FC = () => {
    const {t} = useTranslation();
    const [submit] = useMutation(ForgotPasswordDocument);
    const [sent, setSent] = useState(false);
    const methods = useForm<ForgotPasswordFormData>({
        defaultValues: {email: ''},
    });
    const {handleSubmit} = methods;

    const onSubmit = async (formData: ForgotPasswordFormData) => {
        await submit({
            variables: {
                email: formData.email,
                redirectUrl: window.location.href,
            },
        })
            .then(() => {
                setSent(true);
            })
            .catch(() => {
                // Ignore errors to avoid bad actors from testing which emails exist
                setSent(true);
            });
    };

    return (
        <div className="sm:mt-5 sm:flex sm:justify-center">
            <div className="bg-body border-outline space-y-5 p-5 sm:min-w-[36rem] sm:max-w-xl sm:rounded-md sm:border sm:shadow-lg">
                <h2 className="border-outline border-b pb-2 text-lg">
                    {t('authentication.forgotPassword.title')}
                </h2>
                {sent ? (
                    <div className="py-5 text-center">
                        {t('authentication.forgotPassword.success')}
                    </div>
                ) : (
                    <FormProvider {...methods}>
                        <p>{t('authentication.forgotPassword.message')}</p>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <InputEmail name="email" required={true} />
                            <SubmitButton className="mx-auto w-11/12 sm:w-1/2" />
                        </Form>
                    </FormProvider>
                )}
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
