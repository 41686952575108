import {FC, ReactNode} from 'react';
import clsx from 'clsx';

export type SkeletonFormProps = {
    children: ReactNode;
    className?: string;
    isStacked?: boolean;
};

const SkeletonForm: FC<SkeletonFormProps> = ({
    children,
    className,
    isStacked = true,
}) => (
    <div
        className={clsx(
            isStacked && 'flex flex-col',
            isStacked && !className?.includes('gap-y-') && 'gap-y-8',
            className
        )}
    >
        {children}
    </div>
);

export default SkeletonForm;
