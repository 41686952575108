import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseSelectProps} from 'components/Form/Base/BaseSelect';
import Select from 'components/Form/Select';
import {ItemType} from 'types';
import {toSelectOptions} from 'utils/array';

export type CuisineSelectProps = {cuisines: ItemType[]} & Omit<
    BaseSelectProps,
    'name' | 'options'
>;

const CuisineSelect: FC<CuisineSelectProps> = (props) => {
    const {t} = useTranslation();
    const {cuisines} = props;

    const options = toSelectOptions(cuisines, ['fas', 'utensils']);

    return (
        <Select
            aria-label={t('search.selectCuisine')}
            data-dd-privacy="allow"
            unselected={t('search.selectCuisine')}
            unselectedIcon={['fas', 'utensils']}
            {...props}
            name="cuisine"
            options={options}
        />
    );
};

export default CuisineSelect;
