import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Link from 'components/Link';

const Server404: FC = () => {
    const {t} = useTranslation();

    return (
        <div className="w-full py-8">
            <div className="site-container text-center sm:pt-0 sm:text-left">
                <h1 className="flex items-center justify-center text-xl sm:justify-start sm:text-2xl md:text-3xl">
                    <FontAwesomeIcon
                        icon={['far', 'question-circle']}
                        size="2x"
                    />
                    <span className="ml-3">{t('error.404.title')}</span>
                </h1>
                <p className="mt-4">{t('error.404.message')}</p>
                <p className="mt-4">
                    <Link to="/">{t('returnToTop')}</Link>
                </p>
            </div>
        </div>
    );
};

export default Server404;
