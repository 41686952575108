import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Gender} from 'gql/generated';
import useBreakpoint from 'hooks/useBreakpoint';
import RadioButtons from '../RadioButtons';

export type GenderSelectorProps = {
    className?: string;
};

const GenderSelector: FC<GenderSelectorProps> = ({className}) => {
    const {t} = useTranslation();

    const isDesktop = useBreakpoint('sm');

    const options = useMemo(
        () => [
            {label: t('user.basicInformation.gender.male'), value: Gender.Male},
            {
                label: t('user.basicInformation.gender.female'),
                value: Gender.Female,
            },
            {
                label: t('user.basicInformation.gender.preferNotToSay'),
                value: Gender.Unknown,
            },
        ],
        [t]
    );

    return (
        <RadioButtons
            className={className}
            isHorizontal={isDesktop}
            label={t('user.basicInformation.gender.label')}
            name="gender"
            options={options}
            required={true}
            size={isDesktop ? 'base' : 'sm'}
        />
    );
};

export default GenderSelector;
