/* eslint-disable react/no-danger */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import CreditCardImage from 'components/CreditCardImage';
import {BRANDS} from 'components/PaymentMethod';
import {CreditCard, ReservationCreateInput} from 'gql/generated';
import {capitalize} from 'lodash';
import {toMY} from 'utils/date';

export type SummaryPaymentProps = {
    creditCards: CreditCard[];
    input: ReservationCreateInput;
};

const SummaryPayment: FC<SummaryPaymentProps> = ({creditCards, input}) => {
    const {t} = useTranslation();

    const {creditCardId, ryoshushoName} = input;
    const card = creditCardId
        ? creditCards.find(({id}) => id === creditCardId)
        : undefined;

    return (
        <>
            <h3 className="border-outline mt-4 border-t pt-4 md:mt-6">
                {t('reserve.payment.title')}
            </h3>
            <div className="mt-3 grid gap-5 md:grid-cols-2">
                <div>
                    {card && (
                        <div className="flex items-center">
                            <CreditCardImage size="lg" type={card.cardBrand} />
                            <div className="text-sub ml-4 text-xs">
                                <span>
                                    {t('user.paymentMethods.cardEndingIn', {
                                        brand:
                                            BRANDS[card.cardBrand] ||
                                            card.cardBrand
                                                .split('_')
                                                .map(capitalize)
                                                .join(' '),
                                        cardLast4: card.cardLast4,
                                    })}
                                </span>
                                <br />
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'user.paymentMethods.expires',
                                            {
                                                expires: toMY(
                                                    new Date(
                                                        card.cardExpiryYear,
                                                        card.cardExpiryMonth - 1
                                                    )
                                                ),
                                            }
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {ryoshushoName && (
                    <div>
                        <div className="text-xs">
                            {t('reserve.payment.ryoshusho.label')}
                        </div>
                        <div className="text-sm font-semibold">
                            {ryoshushoName}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SummaryPayment;
