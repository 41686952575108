import {FC, ReactNode, useEffect} from 'react';
import {createPortal} from 'react-dom';
import clsx from 'clsx';
import useBreakpointChange from 'hooks/useBreakpointChange';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';
import ModalBody from './ModalBody';

export type ModalProps = {
    children: ReactNode;
    onClose: () => void;
    title?: string;
};

const Modal: FC<ModalProps> = ({children, onClose, title}) => {
    const breakpoint = useCurrentBreakpoint();
    const isDesktop = breakpoint !== 'xs';

    useBreakpointChange(onClose);

    useEffect(() => {
        document.documentElement.classList.add('portal-open', 'portal-modal');

        if (!isDesktop) {
            document.documentElement.classList.add('portal-visible');
        }

        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
            document.documentElement.classList.remove(
                'portal-modal',
                'portal-open'
            );

            if (!isDesktop) {
                document.documentElement.classList.remove('portal-visible');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop]);

    const portal = document.querySelector('#portal');
    const portalDiv = (
        <div className="relative h-full w-full" role="dialog">
            <div
                className={clsx(
                    'absolute inset-0',
                    isDesktop ? 'bg-black opacity-80' : 'bg-body'
                )}
            />
            <div
                className={clsx(
                    'relative h-full w-full overflow-y-auto',
                    isDesktop && 'py-8'
                )}
            >
                {title ? (
                    <ModalBody onClose={onClose} title={title}>
                        {children}
                    </ModalBody>
                ) : (
                    children
                )}
            </div>
        </div>
    );

    return portal ? createPortal(portalDiv, portal) : null;
};

export default Modal;
