import {useApolloClient, useMutation} from '@apollo/client';
import {LogoutDocument} from 'gql/generated';
import {useCredentialsDispatch} from 'state/credentials';
import useNavigateLocale from './useNavigateLocale';

export const useLogout = (closeCallback?: () => void) => {
    const [logoutMutation] = useMutation(LogoutDocument);
    const setCredentials = useCredentialsDispatch();
    const client = useApolloClient();
    const navigate = useNavigateLocale();

    return () => {
        logoutMutation().then(
            () => {
                closeCallback?.();
                setCredentials(null);

                client.clearStore();

                navigate('/');
            },
            () => {
                /* 
                    In case the access token is expired graphql devise in the BE will throw an error,
                    but this shouldn't keep us from removing all user related data on the FE because they're willingly trying to logout
                */

                closeCallback?.();
                setCredentials(null);

                client.clearStore();

                navigate('/');
            }
        );
    };
};
