import {FC} from 'react';
import clsx from 'clsx';
import ReservationInformation from '../index';
import {useReservationInformationState} from '../provider';

type MobileReservationInformationProps = {
    className?: string;
};

const MobileReservationInformation: FC<MobileReservationInformationProps> = ({
    className,
}) => {
    const {venue} = useReservationInformationState();

    if (!venue?.name) return null;

    return (
        <div className={clsx('px-4 pb-4 md:hidden', className)}>
            <h3>{venue.name}</h3>
            <ReservationInformation className="mt-4" />
            <hr className="mt-5" />
        </div>
    );
};

export default MobileReservationInformation;
