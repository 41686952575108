import {FC} from 'react';

type Props = {
    onClick: () => void;
    title: string;
    value: string;
    visibleSection: string;
};

export const SectionSegmentButton: FC<Props> = ({
    onClick,
    title,
    value,
    visibleSection,
}) => (
    <button
        className={`flex-1 text-sm ${
            visibleSection === value
                ? 'border-b-4 border-gold-200'
                : 'border-b border-grey-300 text-grey-500'
        } `}
        onClick={onClick}
        type="button"
    >
        {title}
    </button>
);
