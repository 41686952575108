import {FC, ReactNode} from 'react';
import {FieldError} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

export type HelpErrorProps = {
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
    error?: FieldError;
    helpText?: ReactNode;
    label?: ReactNode;
};

const HelpError: FC<HelpErrorProps> = ({
    children,
    className,
    disabled,
    error,
    helpText,
    label,
}) => {
    const {t} = useTranslation();

    const errorMessage =
        error?.message ||
        (error?.type === 'required'
            ? typeof label === 'string'
                ? t('form.fieldIsRequired', {label})
                : t('form.thisFieldIsRequired')
            : undefined);

    return (
        <div className={clsx('ml-px mt-1', className)} role="status">
            <div
                className={clsx(children && 'flex items-start justify-between')}
            >
                {helpText ? (
                    <span
                        className={clsx(
                            'block whitespace-pre-line text-sm',
                            children && 'flex-1 pr-1',
                            disabled && 'text-disabled'
                        )}
                        role="note"
                    >
                        {helpText}
                    </span>
                ) : (
                    children && <span className="flex-1 text-sm">&nbsp;</span>
                )}
                {children}
            </div>
            {errorMessage && (
                <div
                    className="text-invalid whitespace-pre-wrap text-sm"
                    role="alert"
                >
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default HelpError;
