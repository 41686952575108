import {FC, ReactNode} from 'react';
import {Outlet} from 'react-router-dom';
import Authenticate from 'components/User/Authentication';
import {useUser} from 'state/user';

export type AuthenticatedPageProps = {
    children?: ReactNode;
};

const AuthenticatedPage: FC<AuthenticatedPageProps> = () => {
    const user = useUser();

    if (!user) {
        return <Authenticate className="mt-3 sm:mt-5" login={true} />;
    }

    return <Outlet />;
};

export default AuthenticatedPage;
