import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';
import {
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import BreakpointWrapper from 'components/BreakpointWrapper';
import Checkbox from 'components/Form/Checkbox';
import FieldRequiredText from 'components/Form/Field/FieldLabel/FieldRequiredText';
import InputText from 'components/Form/InputText';
import MutationStatus from 'components/Form/MutationStatus';
import SubmitButton from 'components/Form/SubmitButton';

type StripeAddCardProps = {
    onError: (message: string) => void;
};

const LABEL_CLASS =
    'text-body mb-1 ml-px flex select-none items-center justify-between text-sm';

const StripeAddCard: FC<StripeAddCardProps> = ({onError}) => {
    const {t} = useTranslation();
    const ref = useRef<HTMLFieldSetElement>(null);

    const {watch} = useFormContext();

    const isDefaultCard = watch('isDefaultCard');

    const [isDark, setIsDark] = useState(() =>
        document.documentElement.classList.contains('dark')
    );

    useEffect(() => {
        const observer = new MutationObserver(
            (mutationsList: MutationRecord[]) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const mutation of mutationsList) {
                    if (
                        mutation.type === 'attributes' &&
                        mutation.attributeName === 'class'
                    ) {
                        setIsDark(
                            document.documentElement.classList.contains('dark')
                        );
                    }
                }
            }
        );
        observer.observe(document.documentElement, {attributes: true});

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (ref.current && ref.current.closest('.dark')) {
            setIsDark(true);
        }
    }, [ref]);

    const options = useMemo(
        () => ({
            style: {
                base: {
                    '::placeholder': {
                        color: isDark ? '#92887a' : '#a29a8f',
                    },
                    '::selection': {
                        backgroundColor: '#6c8dc1',
                        color: '#fff',
                    },
                    backgroundColor: isDark ? '#1d1a16' : '#fff',
                    color: isDark ? '#fff' : '#342f28',
                    fontFamily: 'Open Sans, Roboto, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    iconColor: isDark ? '#fff' : '#342f28',
                },
                invalid: {
                    color: isDark ? '#c55e4a' : '#ba482e',
                    iconColor: isDark ? '#c55e4a' : '#ba482e',
                },
            },
        }),
        [isDark]
    );

    const onCardNumberChange = (event: StripeCardNumberElementChangeEvent) => {
        onError(event.error?.message || '');
    };

    const onCardExpiryChange = (event: StripeCardExpiryElementChangeEvent) => {
        onError(event.error?.message || '');
    };

    const onCardCvcChange = (event: StripeCardCvcElementChangeEvent) => {
        onError(event.error?.message || '');
    };

    return (
        <>
            <fieldset ref={ref}>
                <BreakpointWrapper
                    breakpoint="lg"
                    className="flex flex-row"
                    wrapBelowBreakpoint={false}
                >
                    <div className="mt-3 lg:mr-1.5 lg:flex-1">
                        <label className="relative block" htmlFor="cc-number">
                            <div className={LABEL_CLASS}>
                                <span>
                                    {t('user.paymentMethods.cardNumber')}
                                </span>
                                <FieldRequiredText />
                            </div>
                            <CardNumberElement
                                id="cc-number"
                                onChange={onCardNumberChange}
                                options={options}
                            />
                        </label>
                    </div>
                    <div className="mt-3 lg:ml-1.5 lg:flex-1">
                        <label className="relative block" htmlFor="cc-expiry">
                            <div className={LABEL_CLASS}>
                                <span>
                                    {t('user.paymentMethods.expirationDate')}
                                </span>
                                <FieldRequiredText />
                            </div>
                            <CardExpiryElement
                                id="cc-expiry"
                                onChange={onCardExpiryChange}
                                options={options}
                            />
                        </label>
                    </div>
                </BreakpointWrapper>
                <BreakpointWrapper
                    breakpoint="lg"
                    className="flex flex-row"
                    wrapBelowBreakpoint={false}
                >
                    <div className="mt-3 lg:mr-1.5 lg:flex-1">
                        <label className="relative block" htmlFor="ccv">
                            <div className={LABEL_CLASS}>
                                <span>
                                    {t('user.paymentMethods.securityCode')}
                                </span>
                                <FieldRequiredText />
                            </div>
                            <CardCvcElement
                                id="cc-cvc"
                                onChange={onCardCvcChange}
                                options={options}
                            />
                        </label>
                    </div>
                    <InputText
                        className="mt-3 lg:ml-1.5 lg:flex-1"
                        hideMaxLength={true}
                        label={t('user.paymentMethods.nameOnCard')}
                        maxLength={100}
                        name="creditCardName"
                        placeholder={t('user.paymentMethods.nameOnCard')}
                        required={true}
                    />
                </BreakpointWrapper>
                <MutationStatus className="mt-4" />
            </fieldset>
            <div className="mt-4 flex items-center justify-end">
                <Checkbox
                    className="mr-6 items-center text-gold-400 dark:text-gold-200"
                    label={t('user.paymentMethods.setDefaultCard')}
                    name="isDefaultCard"
                    size="sm"
                    style={{
                        backgroundColor: isDefaultCard
                            ? undefined
                            : 'transparent',
                        borderColor: 'rgba(193, 160, 108)',
                        borderRadius: '0.25rem',
                        height: '16px',
                        width: '16px',
                    }}
                />
                <SubmitButton
                    className="w-auto"
                    kind="secondary"
                    loading={t('user.paymentMethods.addNewCard')}
                >
                    {t('user.paymentMethods.saveCard')}
                </SubmitButton>
            </div>
        </>
    );
};

export default StripeAddCard;
