import {Locale} from 'types';

type MarketingContent = {
    assetName: string;
    imageUrl: string;
    isExternal: boolean;
    path: string;
};

export type MarketingContentGroup = {
    [locale in Locale]: MarketingContent[];
};

type PickUpVenueContent = {
    [venueId: string]: {
        order: number;
        suffix: string;
    };
};

export type PickUpVenueContentGroup = {
    [locale in Locale]: PickUpVenueContent;
};

// These venue IDs are reflected in (1) "Featured Venues" on the English
// homepage and (2) "ピックアップレストラン" on the Japanese homepage.
//
// When updating venue IDs here, (1) also reflect the updates in the `pickUp`
// section of home.graphql, then (2) run `yard codegen` to update generated.ts.
export const pickUpVenues: PickUpVenueContentGroup = {
    en: {
        '244966': {order: 3, suffix: '?intlink=jp-ICS-Top_PickUp_en_caenne'},
        '245273': {order: 4, suffix: '?intlink=jp-ICS-Top_PickUp_en_Tenjaku'},
        '245459': {
            order: 1,
            suffix: '?intlink=jp-ICS-Top_PickUp_en_Sushi_Mamoru',
        },
        '245461': {order: 2, suffix: '?intlink=jp-ICS-Top_PickUp_en_Unotoki'},
    },
    ja: {
        '244966': {order: 3, suffix: '?intlink=jp-ICS-Top_PickUp_caenne'},
        '245273': {order: 4, suffix: '?intlink=jp-ICS-Top_PickUp_Tenjaku'},
        '245459': {order: 1, suffix: '?intlink=jp-ICS-Top_PickUp_Sushi_Mamoru'},
        '245461': {order: 2, suffix: '?intlink=jp-ICS-Top_PickUp_Unotoki'},
    },
};
