import {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from 'react';
import {ToastNotification} from 'components/Toast/ToastNotification';
import {ErrorEnum, useErrorHandling} from 'hooks/useServerErrorHandling';

export enum ToastType {
    'Error' = 'Error',
    'Success' = 'Success',
}

export type Toast = {
    displaySeconds?: number;
    message: ReactNode | null;
    supplementaryInformation?: string;
    type: ToastType | null;
};

const ToastContext = createContext<Dispatch<SetStateAction<Toast>> | undefined>(
    undefined
);

type ToastWrapper = {
    onFulfilled?: (input: any) => void;
    onRejected?: (error: ErrorEnum | undefined) => void;
    page?: string;
    promise: Promise<unknown>;
    successMessage?: string;
};

export const useToastNotification = () => {
    const showToast = useContext(ToastContext) as Dispatch<
        SetStateAction<Toast>
    >;
    const {handleServerError} = useErrorHandling();

    const toastWrapper = async ({
        onFulfilled,
        onRejected,
        page,
        promise,
        successMessage,
    }: ToastWrapper) =>
        promise
            .then((data) => {
                if (successMessage) {
                    showToast({
                        message: successMessage,
                        type: ToastType.Success,
                    });
                }

                onFulfilled?.(data);
            })
            .catch((error) => {
                const {errorEntry, message, traceId} = handleServerError(
                    error,
                    page
                );

                showToast({
                    message,
                    supplementaryInformation: traceId || undefined,
                    type: ToastType.Error,
                });

                onRejected?.(errorEntry);
            });

    return {showToast, toastWrapper};
};

export type ToastNotificationProviderProviderProps = {
    children: ReactNode;
};

const INITIAL_STATE = {message: null, type: null};

export const ToastNotificationProvider: FC<
    ToastNotificationProviderProviderProps
> = ({children}) => {
    const [toast, setToast] = useState<Toast>(INITIAL_STATE);

    return (
        <ToastContext.Provider value={setToast}>
            <ToastNotification
                {...toast}
                reset={() => setToast(INITIAL_STATE)}
            />
            {children}
        </ToastContext.Provider>
    );
};

ToastNotificationProvider.displayName = 'ToastNotificationProvider';
