import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import VenueModal from 'components/Venue/VenueModal/VenueModal';
import {Course, ServiceType} from 'gql/generated';
import useNavigateLocale from 'hooks/useNavigateLocale';
import {commify} from 'utils/string';
import styles from './styles.module.css';

type CourseListProps = {
    activeServiceType: ServiceType;
    availableCourseIds: string[] | undefined;
    courses: Course[];
    isSubmittable: boolean;
    query: string;
    selectableCourseIds: string[] | undefined;
    venueId: string;
};

const CourseList: FC<CourseListProps> = ({
    activeServiceType,
    availableCourseIds,
    courses,
    isSubmittable,
    query,
    selectableCourseIds,
    venueId,
}) => {
    const {t} = useTranslation();
    const navigate = useNavigateLocale();

    const [selectedCourse, setSelectedCourse] = useState<null | Course>(null);

    const getUrl = (courseId: string) =>
        `/reserve?courseId=${courseId}&venueId=${venueId}&${query}`;

    return (
        <div>
            {selectedCourse && (
                <VenueModal
                    closeModal={() => setSelectedCourse(null)}
                    course={selectedCourse}
                    isReservable={
                        isSubmittable &&
                        activeServiceType === selectedCourse.serviceType &&
                        selectableCourseIds?.includes(selectedCourse.id)
                    }
                    onReserve={() => navigate(getUrl(selectedCourse.id))}
                />
            )}
            <legend className="text-sm font-semibold">
                {t('venue.tabs.courses.label')}
            </legend>
            {[...courses]
                .sort((a, b) => {
                    /*
                     * The sorting algorithm for the courses sorts courses based on their costs in ascending order.
                     * Whereas selectable courses are always displayed at the top.
                     * If multiple courses are selectable they will be sorted based on their costs as well.
                     * If "a" should be above "b", return a negative value, if "b" should be below "a" return a positive value. If they're equal return 0.
                     */
                    const aIncluded = selectableCourseIds?.includes(a.id);
                    const bIncluded = selectableCourseIds?.includes(b.id);

                    if (a.serviceType === activeServiceType) {
                        if (a.serviceType === b.serviceType) {
                            // If both courses are selectable, sort them based on their cost
                            if (aIncluded && bIncluded) {
                                return a.costPerGuest - b.costPerGuest;
                            }

                            // If "a" is a selectable course but not "b", sort "a" above
                            if (aIncluded) return -1;
                            // If "b" is a selectable course but not "a", sort "b" above
                            if (bIncluded) return 1;

                            // They must be equal, so return 0
                            return 0;
                        }

                        // "a" is the active service type but "b" is not, therefore put "a" above "b"
                        return -1;
                    }

                    // If "b" is the active service and "a" is not put it above "a"
                    if (b.serviceType === activeServiceType) {
                        return 1;
                    }

                    // Otherwise sort them based on their cost (cheaper goes top)
                    return a.costPerGuest - b.costPerGuest;
                })
                .map((course) => (
                    <div
                        key={course.id}
                        className={`bg-body mt-2 grid grid-cols-[1fr,_6rem] justify-between gap-x-2 rounded-md p-3 ${
                            styles.shadow
                        } ${
                            activeServiceType === course.serviceType &&
                            availableCourseIds?.includes(course.id)
                                ? ''
                                : 'text-grey-200 dark:bg-grey-700 dark:text-grey-400'
                        }`}
                    >
                        <div className="flex grow flex-col justify-between">
                            <h3 className="font-semibold">{course.name}</h3>
                            <div className="mt-2 flex flex-row items-center">
                                <FontAwesomeIcon
                                    className={`bg-body border-outline mr-2 ${
                                        activeServiceType ===
                                            course.serviceType &&
                                        availableCourseIds?.includes(course.id)
                                            ? 'text-gold-200'
                                            : 'text-grey-200 dark:bg-grey-700 dark:text-grey-400'
                                    }`}
                                    icon={
                                        course.serviceType ===
                                        ServiceType.Dinner
                                            ? ['fas', 'moon']
                                            : ['fas', 'sun']
                                    }
                                />
                                <div className="text-sm">
                                    {t('reservation.costPerGuestCurrency', {
                                        ...course,
                                        costPerGuest: commify(
                                            course.costPerGuest
                                        ),
                                    })}
                                    {course.fixedPrice &&
                                        ` + ${t(
                                            'reservation.costPerGroupCurrency',
                                            {
                                                costPerGroup: commify(
                                                    course.fixedPrice
                                                ),
                                            }
                                        )}`}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-rows-2 place-items-end justify-end gap-y-2">
                            <Button
                                className={`w-24 bg-gold-200 font-semibold text-white dark:text-grey-900 ${
                                    isSubmittable &&
                                    activeServiceType === course.serviceType &&
                                    selectableCourseIds?.includes(course.id)
                                        ? ''
                                        : 'invisible'
                                }`}
                                data-dd-action-name="CourseSelect"
                                kind="secondary"
                                onClick={() => navigate(getUrl(course.id))}
                                size="sm"
                            >
                                {t('select')}
                            </Button>
                            <Button
                                className="bg-body w-24 border-grey-700 text-grey-700"
                                data-dd-action-name="CourseDetails"
                                kind="secondary"
                                onClick={() => setSelectedCourse(course)}
                                size="sm"
                            >
                                {t('details')}
                            </Button>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default CourseList;
