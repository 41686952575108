/* eslint-disable react/no-danger */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format} from 'date-fns';
import {useLocale} from 'state/locale';
import {getLocaleOptions} from 'utils/locale';

type SecretSeatNotificationProps = {
    dateString: string;
};

const SecretSeatNotification: FC<SecretSeatNotificationProps> = ({
    dateString,
}) => {
    const {t} = useTranslation();
    const locale = useLocale();
    const options = getLocaleOptions(locale);
    const formatString = locale === 'en' ? 'MMMM d, yyyy' : 'PPP';
    const date = new Date(dateString);

    const formattedDateString = format(date, formatString, options);
    const formattedDateStringTime = format(date, 'H:mm');

    return (
        <div className="my-2 flex flex-col items-center justify-center rounded bg-grey-100 p-3 text-grey-700">
            <div className="flex flex-row items-center gap-x-2 text-sm">
                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                {t('venue.secretSeat.title')}
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: t('venue.secretSeat.expirationText', {
                        date: formattedDateString,
                        time: formattedDateStringTime,
                    }),
                }}
                className="flex items-center justify-center whitespace-pre-wrap text-center text-base"
            />
        </div>
    );
};

export default SecretSeatNotification;
