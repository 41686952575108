import {FC, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {CollectionMetadata} from 'gql/generated';
import useComponentRef from 'hooks/useComponentRect';
import {getRange} from 'utils/array';

const linkClass =
    'flex flex-none justify-center items-center w-9 h-9 rounded-full border border-transparent link-item';
const enabledLinkClass =
    'hover:text-gold-400 dark:hover:text-gold-200 hover:border-gold-400 dark:hover:border-gold-200';
const disabledLinkClass = 'text-grey-400 dark:text-grey-600 cursor-not-allowed';
const currentLinkClass = 'font-bold text-white bg-gold-200 border-gold-100';

type Pages = {
    currentPage: number;
    totalPages: number;
    visiblePages: number;
};

const getPages = ({currentPage, totalPages, visiblePages}: Pages): number[] => {
    if (visiblePages > 1) {
        const threshold = Math.floor(visiblePages * 0.75);

        if (totalPages <= visiblePages || currentPage <= threshold) {
            return getRange(1, Math.min(totalPages, visiblePages));
        }
        const lastPage = Math.min(
            totalPages,
            currentPage + (visiblePages - threshold)
        );
        const firstPage = Math.max(1, lastPage - visiblePages + 1);

        return getRange(firstPage, lastPage);
    }

    if (visiblePages === 1) {
        return [currentPage];
    }

    return [];
};

type Counts = {
    count: number;
    end: number;
    start: number;
};

const getCounts = ({
    currentPage,
    limitValue,
    totalCount,
    totalPages,
}: CollectionMetadata): Counts | undefined => {
    if (!currentPage || !limitValue || !totalPages || !totalCount) {
        return undefined;
    }
    const start = 1 + (currentPage - 1) * limitValue;
    const end = Math.min(start + limitValue - 1, totalCount);
    const count = totalCount;

    return {count, end, start};
};

export type PaginationProps = {
    className?: string;
    metadata: CollectionMetadata;
    name: string;
};

const Pagination: FC<PaginationProps> = ({className, metadata, name}) => {
    const ref = useRef(null);
    const {width} = useComponentRef(ref);
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const visiblePages = useMemo(
        () => Math.max(Math.floor((width - 108) / 52), 0),
        [width]
    );

    const {currentPage = 0, totalPages = 1} = metadata || {};

    const pages = useMemo(
        () => getPages({currentPage, totalPages, visiblePages}),
        [currentPage, totalPages, visiblePages]
    );

    if (currentPage === 0) return null;

    const counts = getCounts(metadata);

    return (
        <div className={className}>
            <nav
                ref={ref}
                className="flex items-center justify-center"
                role="navigation"
            >
                {pages.length > 0 && (
                    <>
                        <button
                            aria-disabled={currentPage === 1}
                            aria-label={t('pagination.firstPage')}
                            className={clsx(
                                linkClass,
                                currentPage > 1
                                    ? enabledLinkClass
                                    : disabledLinkClass
                            )}
                            onClick={() => {
                                searchParams.set('page', '1');
                                setSearchParams(searchParams);
                            }}
                        >
                            <FontAwesomeIcon
                                className="relative -mr-0.5"
                                icon={['fas', 'chevron-left']}
                            />
                            <FontAwesomeIcon
                                className="relative"
                                icon={['fas', 'chevron-left']}
                            />
                        </button>
                        <button
                            aria-disabled={currentPage === 1}
                            aria-label={t('pagination.prevPage')}
                            className={clsx(
                                linkClass,
                                currentPage > 1
                                    ? enabledLinkClass
                                    : disabledLinkClass
                            )}
                            onClick={() => {
                                searchParams.set(
                                    'page',
                                    (
                                        currentPage - Number(currentPage > 1)
                                    ).toString()
                                );
                                setSearchParams(searchParams);
                            }}
                        >
                            <FontAwesomeIcon
                                className="relative"
                                icon={['fas', 'chevron-left']}
                            />
                        </button>
                        {pages.map((page) =>
                            currentPage === page ? (
                                <span
                                    key={page}
                                    className={`ml-4 ${linkClass} ${currentLinkClass}`}
                                >
                                    {page}
                                </span>
                            ) : (
                                <button
                                    key={page}
                                    aria-label={t('pagination.page', {page})}
                                    className={`ml-4 ${linkClass} ${enabledLinkClass}`}
                                    onClick={() => {
                                        searchParams.set(
                                            'page',
                                            page.toString()
                                        );
                                        setSearchParams(searchParams);
                                    }}
                                >
                                    {page}
                                </button>
                            )
                        )}
                        <button
                            aria-disabled={currentPage < totalPages}
                            aria-label={t('pagination.nextPage')}
                            className={clsx(
                                `ml-4 ${linkClass}`,
                                currentPage < totalPages
                                    ? enabledLinkClass
                                    : disabledLinkClass
                            )}
                            onClick={() => {
                                searchParams.set(
                                    'page',
                                    (
                                        currentPage +
                                        Number(currentPage < totalPages)
                                    ).toString()
                                );
                                setSearchParams(searchParams);
                            }}
                        >
                            <FontAwesomeIcon
                                className="relative left-px"
                                icon={['fas', 'chevron-right']}
                            />
                        </button>
                        <button
                            aria-disabled={currentPage < totalPages}
                            aria-label={t('pagination.lastPage')}
                            className={clsx(
                                linkClass,
                                currentPage < totalPages
                                    ? enabledLinkClass
                                    : disabledLinkClass
                            )}
                            onClick={() => {
                                searchParams.set(
                                    'page',

                                    totalPages.toString()
                                );
                                setSearchParams(searchParams);
                            }}
                        >
                            <FontAwesomeIcon
                                className="relative -mr-0.5"
                                icon={['fas', 'chevron-right']}
                            />
                            <FontAwesomeIcon
                                className="relative"
                                icon={['fas', 'chevron-right']}
                            />
                        </button>
                    </>
                )}
            </nav>
            {counts && (
                <div className="mt-2.5 text-center text-xs">
                    {t(`pagination.${name}`, counts)}
                </div>
            )}
        </div>
    );
};

export default Pagination;
