import {FC, ReactNode} from 'react';
import useFadeIn from 'hooks/useFadeIn';

export type FadeInProps = {
    children: ReactNode;
    delay?: string;
    duration?: string;
};

const FadeIn: FC<FadeInProps> = ({children, delay, duration}) => {
    const fadeInClass = useFadeIn({delay, duration});

    return <div className={fadeInClass}>{children}</div>;
};

export default FadeIn;
