import {
    FeaturedMailNotificationEnum,
    Gender,
    UserProfileFragment,
} from 'gql/generated';
import {FormData} from 'types';
import {fromPhoneNumber} from './phone';

export type ProfileFormData = FormData<{
    birthday: string;
    companyName: string;
    countryCode?: string;
    featuredMailNotification: boolean;
    firstName: string;
    firstNameKana?: string;
    gender: Gender;
    lastName: string;
    lastNameKana: string;
    newsletter: boolean;
    tel?: string;
}>;

export const getDefaultValues = (userProfile: UserProfileFragment) => {
    if (!userProfile) return undefined;

    const {
        birthday,
        companyName,
        featuredMailNotification,
        firstName,
        firstNameKana,
        gender,
        lastName,
        lastNameKana,
        newsletter,
        phoneNumber,
    } = userProfile;

    return {
        birthday,
        companyName,
        featuredMailNotification:
            featuredMailNotification === FeaturedMailNotificationEnum.Accept,
        firstName,
        firstNameKana,
        gender,
        lastName,
        lastNameKana,
        newsletter,
        ...fromPhoneNumber(phoneNumber),
    } as ProfileFormData;
};
