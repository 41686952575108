import {v4} from 'uuid';
import {compose} from './functions';

// not testable but works
// https://github.com/uuidjs/uuid#getrandomvalues-not-supported
/* istanbul ignore next */
export const uuid = (mustStartWithLetter = false): string => {
    const id = v4();
    if (!mustStartWithLetter || /^[a-z].*/.test(id)) return id;

    return uuid(mustStartWithLetter);
};

export const commify = (value: number | string): string =>
    Number(value).toLocaleString();

export const commaSpacing = (value: string): string =>
    value.split(', ').join(',').split(',').join(', ');

export const toLowerCase = (value: string): string => value.toLowerCase();

export const stripNewlines = (value = ''): string =>
    value.replace(/\r\n|\r|\n/g, '');

export const stripTags = (value = ''): string => {
    const div = document.createElement('div');
    div.innerHTML = value;

    // eslint-disable-next-line unicorn/prefer-dom-node-text-content
    return stripNewlines(div.textContent || div.innerText);
};

export const convertHyphenLinesToHR = (value = ''): string =>
    value.replace(/-{2,}/g, '<hr/>').split('<hr/><br/>').join('<hr/>');

export const normalizeBHR = (value = '') =>
    value
        .split('<br>')
        .join('<br/>')
        .split('<br />')
        .join('<br/>')
        .split('<hr />')
        .join('<hr/>');

export const sanitizeParagraphs = (value = ''): string => {
    const paragraphs = value.match(/<p>(.*?)<\/p>/g);
    if (!paragraphs?.length) return value;

    return paragraphs
        .map(
            (paragraph) =>
                `<p>${paragraph
                    .slice(3, -4)
                    .trim() // trim paragraph contents
                    .split('<hr/>')
                    .join('</p><hr/><p>')}</p>` // split paragraphs between <hr/>
        )
        .map((paragraph) =>
            paragraph
                .split('<p></p>') // remove empty paragraphs
                .join('')
                .split('<p><br/>') // remove unnecessary <br/> tags
                .join('<p>')
                .split('<br/></p>')
                .join('</p>')
                .split('<p> <br/>')
                .join('<p>')
                .split('<br/> </p>')
                .join('</p>')
        )
        .join('');
};

export const convertNewlinesToBR = (value = '') =>
    value.replace(/\r\n|\r|\n/g, '<br/>');

export const collapseTripleBRs = (value = '') =>
    value.split('<br/><br/><br/>').join('<br/><br/>');

export const convertToRelative = (link: string) => {
    const localeIndex = link.indexOf('locale=');
    const locale =
        localeIndex > -1 ? link.slice(localeIndex + 7, localeIndex + 9) : 'ja';
    const lang = locale === 'ja' ? '' : '/en';

    return `${lang}${link.slice(
        link.indexOf('/restaurants'),
        link.includes('?') ? link.indexOf('?') : link.length - 1
    )}`;
};

export const sanitizeInternalLinks = (value = ''): string =>
    value.replace(/href="(.*?)"/g, (match) =>
        // WTF: Some links on the Landing Pages have /restaurants in it, to prevent the creation of the wrong URL we check for /lp in it as well
        match.includes('/restaurants') && !match.includes('/lp')
            ? `href="${convertToRelative(match)}"`
            : match
    );

// compose goes from right to left (bottom to top here)
export const sanitizeTextBlock = compose(
    sanitizeInternalLinks,
    collapseTripleBRs,
    sanitizeParagraphs,
    convertHyphenLinesToHR,
    convertNewlinesToBR,
    normalizeBHR
);
