import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as ls from 'local-storage';

export type DarkModeToggleProps = {
    className?: string;
};

const DarkModeToggle: FC<DarkModeToggleProps> = ({className}) => {
    const [isDark, setIsDark] = useState<boolean | undefined>(() => {
        const colorScheme = ls.get('color-scheme');

        if (colorScheme === null) {
            return true;
        }

        return colorScheme === 'dark';
    });

    const {t} = useTranslation();

    if (isDark === undefined) return null;

    const onToggle = () => {
        if (isDark) {
            document.documentElement.classList.remove('dark');
            setIsDark(false);
            ls.set('color-scheme', 'light');
        } else {
            document.documentElement.classList.add('dark');
            setIsDark(true);
            ls.set('color-scheme', 'dark');
        }
    };

    return (
        <div className={className}>
            <label
                className="border-outline bg-body relative flex h-8 w-[3.5rem] items-center justify-between rounded-full border"
                htmlFor="dark-mode-toggle"
            >
                <FontAwesomeIcon
                    className="ml-[0.55rem]"
                    icon={['fas', 'moon']}
                    size="sm"
                    transform={{rotate: -20}}
                />
                <FontAwesomeIcon
                    className="mr-[0.55rem]"
                    icon={['fas', 'sun']}
                    size="sm"
                />
                <div className="absolute h-5 w-5 translate-x-1 rounded-full bg-grey-900 transition-transform duration-200 dark:translate-x-7 dark:bg-white" />
                <input
                    aria-label={t(
                        isDark
                            ? 'theme.enableLightMode'
                            : 'theme.enableDarkMode'
                    )}
                    checked={isDark}
                    className="absolute h-full w-full cursor-pointer bg-transparent opacity-0"
                    onChange={onToggle}
                    type="checkbox"
                />
            </label>
        </div>
    );
};

export default DarkModeToggle;
