import {FC, InputHTMLAttributes, ReactNode} from 'react';
import {useFormContext} from 'react-hook-form';
import clsx from 'clsx';
import BaseRadioButtons from 'components/Form/Base/BaseRadioButtons';
import Field from 'components/Form/Field';
import {FormContextBoolean, RequiredRules} from 'components/Form/types';
import {getRegisterRules} from 'components/Form/utils';
import {Option, Size} from 'types';

export type RadioButtonsProps<T = HTMLInputElement> = Omit<
    RequiredRules<InputHTMLAttributes<T>>,
    'size'
> & {
    classNameGroup?: string;
    classnameLabel?: string;
    helpText?: ReactNode;
    isFullWidth?: boolean;
    isHorizontal?: boolean;
    isItemsCenter?: boolean;
    label?: ReactNode;
    name: string;
    options: Option[];
    size?: Size;
    type?: never;
};

const RadioButtons: FC<RadioButtonsProps> = ({
    className,
    classNameGroup,
    classnameLabel,
    disabled,
    helpText,
    isFullWidth,
    isHorizontal,
    isItemsCenter,
    label,
    name,
    required,
    rules,
    size = 'base',
    ...rest
}) => {
    const {
        formState: {errors},
    } = useFormContext<FormContextBoolean>();

    const registerRules = getRegisterRules({disabled, required, rules});

    return (
        <Field
            className={className}
            disabled={registerRules?.disabled}
            error={errors[name]}
            helpText={helpText}
            label={label}
            required={!!registerRules?.required}
            type="radio"
        >
            <BaseRadioButtons
                className={clsx(label && 'mt-2', classNameGroup)}
                classnameLabel={classnameLabel}
                disabled={registerRules?.disabled}
                error={errors[name]}
                isFullWidth={isFullWidth}
                isHorizontal={isHorizontal}
                isItemsCenter={isItemsCenter}
                name={name}
                required={!!registerRules?.required}
                rules={registerRules}
                size={size}
                {...rest}
            />
        </Field>
    );
};

export default RadioButtons;
