import {FC, ReactNode} from 'react';
import {useQuery} from '@apollo/client';
import {datadogRum} from '@datadog/browser-rum';
import {UserDocument, UserQuery} from 'gql/generated';

export type UserProviderProps = {
    children: ReactNode;
};

export const useUser = () => {
    const {data} = useQuery<UserQuery>(UserDocument, {
        fetchPolicy: 'cache-and-network',
    });
    if (!data?.user?.id) return null;

    if (!datadogRum.getUser().id) {
        datadogRum.setUser({id: data.user.id});
    }

    return data.user;
};

const UserProvider: FC<UserProviderProps> = ({children}) => {
    const {loading} = useQuery<UserQuery>(UserDocument, {
        fetchPolicy: 'network-only',
    });

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return loading ? <div /> : <>{children}</>;
};

export default UserProvider;
