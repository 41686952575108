import {createSearchParams} from 'react-router-dom';
import {parse, stringify} from 'query-string';
import {ReservationValues} from 'types/reservation';
import {SearchValues, VenueSearchValues} from 'types/search';
import {toISO8601Date, toJST, toTime24} from 'utils/date';
import {toCamelCase, withValues} from 'utils/object';
import {ReserveValues} from '../types/reserve';

const YMD_REGEX = /(20[2-9]\d)-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
const TIME24_REGEX = /^(0\d|1\d|2[0-3]):[0-5]\d$/;

const PAGE_SIZE = 12;

export const parseSearch = (search: string): Record<string, string> =>
    toCamelCase(parse(search)) as Record<string, string>;

export const parseSearchParams = (
    searchParams: URLSearchParams
): SearchValues => {
    const params = parseSearch(searchParams.toString());
    const date = YMD_REGEX.test(String(params.date))
        ? toJST(new Date(`${String(params.date)}`))
        : undefined;

    return withValues({
        area: params.area,
        cuisine: params.cuisine,
        date,
        keyword: params.keyword,
        page: params.page ? Number(params.page) : 1,
        partySize: params.partySize,
        priceRange: params.priceRange,
        realTimeBooking: params.realTimeBooking === 'true',
        seatingType: params.seatingType,
        serviceType: params.serviceType,
    }) as SearchValues;
};

export const parseVenueSearchParams = (
    searchParams: URLSearchParams
): VenueSearchValues => {
    const params = parseSearch(searchParams.toString());
    const date = YMD_REGEX.test(String(params.date))
        ? toJST(new Date(`${String(params.date)}`))
        : undefined;

    return withValues({
        date,
        partySize: params.partySize,
        seatingType: params.seatingType,
        serviceType: params.serviceType,
    }) as SearchValues;
};

export const stringifySearchValues = (
    values: Record<number | string, unknown>
): string => stringify(values);

export const getSafeVenueSearchValues = (
    searchValues: SearchValues,
    omitPage?: boolean
): Record<string, unknown> => {
    const {date, page, seatingType, ...cleanSearch} = withValues(
        searchValues
    ) as SearchValues;

    return withValues(
        toCamelCase({
            date: date ? toISO8601Date(date) : undefined,
            page: omitPage ? undefined : page && page !== 1 ? Number(page) : 1,
            seatingType,
            ...cleanSearch,
        })
    );
};

export const getVenueQueryFromValues = (
    searchValues: SearchValues,
    omitPage?: boolean
): string => {
    const query = stringifySearchValues(
        getSafeVenueSearchValues(searchValues, omitPage)
    );

    return query ? `?${query}` : '';
};

export const getURLSearchParams = (
    searchValues: SearchValues,
    omitPage?: boolean
): URLSearchParams => {
    const query = stringifySearchValues(
        getSafeVenueSearchValues(searchValues, omitPage)
    );

    return createSearchParams(query);
};

export const getVenueSearchFromValues = ({
    date,
    partySize,
    serviceType,
}: SearchValues) =>
    getVenueQueryFromValues({date, partySize, serviceType}, true);

const PRICE_RANGES: Record<string, [number, number]> = {
    '1': [0, 10_000],
    '2': [10_000, 20_000],
    '3': [20_000, 30_000],
    '4': [30_000, 1_000_000],
};

export const getSearchVariables = (values: SearchValues) => {
    const areaIds = values.area ? [values.area] : undefined;
    const cuisines = values.cuisine ? [values.cuisine] : undefined;
    const partySize = values.partySize ? Number(values.partySize) : undefined;
    const [minPricePerPerson, maxPricePerPerson] = values.priceRange
        ? PRICE_RANGES[values.priceRange] || []
        : [];
    const seatingTypes = values.seatingType ? [values.seatingType] : undefined;
    const serviceTypes = values.serviceType ? [values.serviceType] : undefined;
    const pagination = {limit: PAGE_SIZE, page: values.page || 1};
    const {date, keyword, realTimeBooking} = values;

    return withValues({
        areaIds,
        cuisines,
        date,
        keyword,
        maxPricePerPerson,
        minPricePerPerson,
        pagination,
        partySize,
        realTimeBooking,
        seatingTypes,
        serviceTypes,
    });
};

export const getReserveQueryFromValues = (
    reservationValues?: ReservationValues
) => {
    if (!reservationValues) return '';
    const {
        date,
        time,
        waitlistArrivalEnd,
        waitlistArrivalStart,
        waitlistDeadline,
        ...rest
    } = reservationValues;
    const safeDate = toISO8601Date(date) || '';
    const safeTime = time ? toTime24(time) : '';
    const safeWaitlistArrivalStart = waitlistArrivalStart
        ? toTime24(new Date(Number(waitlistArrivalStart)))
        : '';
    const safeWaitlistArrivalEnd = waitlistArrivalEnd
        ? toTime24(new Date(Number(waitlistArrivalEnd)))
        : '';
    const safeWaitlistDeadline = waitlistDeadline
        ? toISO8601Date(waitlistDeadline)
        : '';

    return stringifySearchValues(
        toCamelCase(
            withValues({
                date: safeDate,
                time: safeTime,
                waitlistArrivalEnd: safeWaitlistArrivalEnd,
                waitlistArrivalStart: safeWaitlistArrivalStart,
                waitlistDeadline: safeWaitlistDeadline,
                ...rest,
            })
        )
    );
};

export const getSafeReserveValues = (
    searchParams: URLSearchParams
): ReserveValues | undefined => {
    const params = parseSearch(searchParams.toString());
    const date = YMD_REGEX.test(String(params.date))
        ? toJST(new Date(`${String(params.date)}`))
        : undefined;
    const time = TIME24_REGEX.test(String(params.time))
        ? new Date(`${String(params.date)}T${params.time}:00.000+09:00`)
        : undefined;
    const waitlistArrivalStart = TIME24_REGEX.test(
        String(params.waitlistArrivalStart)
    )
        ? new Date(
              `${String(params.date)}T${
                  params.waitlistArrivalStart
              }:00.000+09:00`
          )
        : undefined;
    const waitlistArrivalEnd = TIME24_REGEX.test(
        String(params.waitlistArrivalEnd)
    )
        ? new Date(
              `${String(params.date)}T${params.waitlistArrivalEnd}:00.000+09:00`
          )
        : undefined;
    const waitlistDeadline = YMD_REGEX.test(String(params.waitlistDeadline))
        ? toJST(new Date(`${String(params.waitlistDeadline)}`))
        : undefined;

    const parsed = withValues({
        courseId: params.courseId,
        date,
        partySize: params.partySize,
        seatingType: params.seatingType,
        time,
        venueId: params.venueId,
        waitlistArrivalEnd,
        waitlistArrivalStart,
        waitlistDeadline,
    }) as ReserveValues;

    // only return if proper params are present
    if (
        parsed.courseId &&
        parsed.venueId &&
        parsed.date &&
        parsed.partySize &&
        ((parsed.seatingType && parsed.time) ||
            (parsed.waitlistArrivalEnd &&
                parsed.waitlistArrivalStart &&
                parsed.waitlistDeadline))
    ) {
        return parsed;
    }

    // invalid params
    return undefined;
};

export const checkIfWaitlist = (searchParams: URLSearchParams) =>
    !!(
        searchParams.get('waitlistArrivalStart') &&
        searchParams.get('waitlistArrivalEnd') &&
        searchParams.get('waitlistDeadline')
    );
