/* eslint-disable react/no-danger */
import {FC, useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import Link from 'components/Link';
import Spinner from 'components/Loaders/Spinner';
import RedirectCountdown from 'components/RedirectCountdown';
import {
    SignupConfirmationDocument,
    SignupConfirmationMutation,
} from 'gql/generated';
import useNavigateLocale from 'hooks/useNavigateLocale';
import {useCredentialsDispatch, useCredentialsState} from 'state/credentials';
import {ToastType, useToastNotification} from 'state/toastNotification';

const SignupConfirmation: FC = () => {
    const {t} = useTranslation();

    const [searchParams] = useSearchParams();
    const token = searchParams.get('confirmation_token');
    const redirectUrl = searchParams.get('redirectUrl');
    const navigate = useNavigateLocale();

    const {showToast} = useToastNotification();

    const [submit, {called, error, loading}] =
        useMutation<SignupConfirmationMutation>(SignupConfirmationDocument);

    const setCredentials = useCredentialsDispatch();
    const credentials = useCredentialsState();

    useEffect(() => {
        if (token && !called) {
            submit({variables: {token}})
                .then(({data}) => {
                    showToast({
                        message: t('authentication.signupConfirmation.success'),
                        type: ToastType.Success,
                    });

                    if (
                        data?.userConfirmRegistrationWithToken?.credentials &&
                        !credentials
                    ) {
                        setCredentials(
                            data.userConfirmRegistrationWithToken.credentials
                        );
                    }

                    navigate(redirectUrl || '/', {replace: true});
                })
                .catch(() => {
                    // do nothing because Apollo logs the error for us
                });
        }
    }, [
        called,
        credentials,
        navigate,
        redirectUrl,
        setCredentials,
        showToast,
        submit,
        t,
        token,
    ]);

    return (
        <div className="site-container space-y-5 p-4">
            <h1 className="text-center text-3xl sm:text-left">
                {t('authentication.signupConfirmation.title')}
            </h1>
            {token ? (
                loading ? (
                    <Spinner
                        className="py-2 sm:w-fit"
                        size="3x"
                        title={t('authentication.signupConfirmation.title')}
                    />
                ) : error ? (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t(
                                'authentication.signupConfirmation.error'
                            ),
                        }}
                        className="text-center sm:text-left"
                        role="alert"
                    />
                ) : (
                    <RedirectCountdown
                        message={t('authentication.signupConfirmation.success')}
                        redirectUrl={redirectUrl || '/'}
                    />
                )
            ) : (
                <>
                    <p>
                        <Trans i18nKey="authentication.signupConfirmation.noTokenMessage" />
                    </p>
                    <p>
                        <Link to="/">{t('returnToTop')}</Link>
                    </p>
                </>
            )}
        </div>
    );
};

export default SignupConfirmation;
