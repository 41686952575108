import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {ReservationStatus as GqlReservationStatus} from 'gql/generated';
import {useLocale} from 'state/locale';
import {toFullDate, toJST} from 'utils/date';

const NEUTRAL = {
    border: 'border-grey-400 dark:border-grey-600',
    text: 'text-grey-400 dark:text-grey-600',
};

const PENDING = {
    border: 'border-orange-800 dark:border-orange-700',
    text: 'text-orange-800 dark:text-orange-700',
};

const POSITIVE = {
    border: 'border-green-500 dark:border-green-400',
    text: 'text-green-500 dark:text-green-400',
};

const NEGATIVE = {
    border: 'border-red-500 dark:border-red-500',
    text: 'text-red-500 dark:text-red-500',
};

const GOLD = {
    border: 'border-gold-200',
    text: 'text-gold-200',
};

const COLORS: Record<GqlReservationStatus, {border: string; text: string}> = {
    [GqlReservationStatus.CancellationConfirmed]: NEUTRAL,
    [GqlReservationStatus.CancellationRequested]: PENDING,
    [GqlReservationStatus.Confirmed]: POSITIVE,
    [GqlReservationStatus.Declined]: NEUTRAL,
    [GqlReservationStatus.NoShow]: NEGATIVE,
    [GqlReservationStatus.Pending]: PENDING,
    [GqlReservationStatus.SameDayCancellation]: NEGATIVE,
    [GqlReservationStatus.Visited]: GOLD,
    [GqlReservationStatus.WaitlistPeriodExpired]: NEUTRAL,
};

const ICONS: Record<GqlReservationStatus, IconProp> = {
    [GqlReservationStatus.CancellationConfirmed]: ['fas', 'times'],
    [GqlReservationStatus.CancellationRequested]: ['far', 'hourglass'],
    [GqlReservationStatus.Confirmed]: ['fas', 'check'],
    [GqlReservationStatus.Declined]: ['fas', 'store-alt-slash'],
    [GqlReservationStatus.NoShow]: ['fas', 'question'],
    [GqlReservationStatus.Pending]: ['far', 'hourglass'],
    [GqlReservationStatus.SameDayCancellation]: ['fas', 'times'],
    [GqlReservationStatus.Visited]: ['far', 'check-circle'],
    [GqlReservationStatus.WaitlistPeriodExpired]: ['fas', 'times'],
};

type UserReservationStatusProps = {
    className?: string;
    status: GqlReservationStatus;
    waitlistDeadline?: string;
};

export const ReservationStatus: FC<UserReservationStatusProps> = ({
    className,
    status,
    waitlistDeadline,
}) => {
    const {t} = useTranslation();
    const locale = useLocale();

    const color = COLORS[status];

    const icon = ICONS[status];

    const label = t(
        status === GqlReservationStatus.Pending
            ? waitlistDeadline
                ? `user.reservations.status.${status}.waitlist.label`
                : `user.reservations.status.${status}.reservation.label`
            : `user.reservations.status.${status}.label`
    );

    const message = waitlistDeadline
        ? status === GqlReservationStatus.WaitlistPeriodExpired
            ? t(`user.reservations.status.${status}.message`)
            : `${t('user.reservations.waitlistDeadline')}: ${toFullDate(
                  // The BE sends a ISO8601Date without a time zone, we therefore need to explicitly cast it to JST
                  toJST(new Date(waitlistDeadline)),
                  locale
              )}`
        : status === GqlReservationStatus.Pending
        ? t(`user.reservations.status.${status}.reservation.message`)
        : // Either show the string declared under "user.reservations.status.${status}.message" or, if not available, don't show anything at all
          t([`user.reservations.status.${status}.message`, '']);

    return (
        <div
            className={clsx(
                'bg-step flex items-center rounded-md py-4 pr-4',
                className
            )}
        >
            <div className="w-14 flex-none text-center text-2xl">
                <FontAwesomeIcon className={color.text} icon={icon} size="sm" />
            </div>
            <div className={clsx('border-l-[3px] pl-4', color.border)}>
                <div className="text-xs">
                    {t('user.reservations.reservationStatus')}
                </div>
                <div className="font-semibold">{label}</div>
                <div className="text-sm">{message}</div>
            </div>
        </div>
    );
};
