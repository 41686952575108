import {
    faBell,
    faBookmark,
    faCalendar,
    faCalendarTimes,
    faCheckCircle,
    faClock,
    faEnvelope,
    faEye,
    faEyeSlash,
    faHourglass,
    faImage,
    faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

export default [
    faBell,
    faBookmark,
    faCalendar,
    faCalendarTimes,
    faCheckCircle,
    faClock,
    faEnvelope,
    faEye,
    faEyeSlash,
    faHourglass,
    faImage,
    faQuestionCircle,
];
