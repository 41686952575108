import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
    ReservationCreateInput,
    ReserveUserProfileFragment,
} from 'gql/generated';
import {toFormattedPhoneNumber} from 'utils/phone';

export type SummaryContactInformationProps = {
    input: ReservationCreateInput;
    user: ReserveUserProfileFragment;
};

const SummaryContactInformation: FC<SummaryContactInformationProps> = ({
    input,
    user,
}) => {
    const {t} = useTranslation();
    const {onBehalfOfGuest, userGuest} = input;

    const firstName = onBehalfOfGuest?.firstName || user.firstName;
    const lastName = onBehalfOfGuest?.lastName || user.lastName;
    const firstNameKana =
        onBehalfOfGuest?.firstNameKana || userGuest?.firstNameKana;
    const lastNameKana =
        onBehalfOfGuest?.lastNameKana || userGuest?.lastNameKana;
    const email = onBehalfOfGuest?.email || user.email;
    const phoneNumber = toFormattedPhoneNumber(
        onBehalfOfGuest?.phoneNumber || userGuest?.phoneNumber
    );

    return (
        <>
            <h3 className="md:border-outline pb-1.5 md:border-b">
                {t('reserve.details.contactInformation')}
            </h3>
            <div className="mt-2 grid gap-5 md:mt-4 md:grid-cols-2">
                <div>
                    <div className="text-xs">{t('name')}</div>
                    <div
                        className="text-sm font-semibold"
                        data-dd-privacy="mask"
                    >
                        {t('user.fullName', {firstName, lastName})}
                    </div>
                </div>
                {(firstNameKana || lastNameKana) && (
                    <div>
                        <div className="text-xs">
                            {t('user.basicInformation.name.furigana')}
                        </div>
                        <div
                            className="text-sm font-semibold"
                            data-dd-privacy="mask"
                        >
                            {lastNameKana} {firstNameKana}
                        </div>
                    </div>
                )}
                <div>
                    <div className="text-xs">
                        {t('user.basicInformation.phoneNumber.label')}
                    </div>
                    <div
                        className="text-sm font-semibold"
                        data-dd-privacy="mask"
                    >
                        {phoneNumber}
                    </div>
                </div>
                <div>
                    <div className="text-xs">
                        {t('user.basicInformation.email')}
                    </div>
                    <div
                        className="text-sm font-semibold"
                        data-dd-privacy="mask"
                    >
                        {email}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SummaryContactInformation;
