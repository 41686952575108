import {FC} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {format} from 'date-fns';
import {useLocale} from 'state/locale';
import {Locale} from 'types';

export type DateSelectorNavProps = {
    id: string;
    onNext?: () => void;
    onPrev?: () => void;
    visibleDate: Date;
};

export const getMonthDateString = (date: Date, locale: Locale): string => {
    if (locale === 'en') {
        return `${format(date, 'MMMM')} ${format(date, 'y')}`;
    }

    return `${format(date, 'y')}年${format(date, 'M')}月`;
};

const DateSelectorNav: FC<DateSelectorNavProps> = (props) => {
    const {id, onNext, onPrev, visibleDate} = props;

    const locale = useLocale();

    return (
        <div className="flex items-center justify-between py-2">
            <button
                aria-label="Previous Month"
                className={clsx(
                    'flex h-8 w-[2.375rem] items-center justify-center rounded transition duration-100 ease-in-out',
                    onPrev
                        ? 'cursor-pointer text-grey-800 hover:bg-grey-300 dark:text-grey-50 dark:hover:bg-grey-600'
                        : 'text-disabled cursor-not-allowed'
                )}
                disabled={!onPrev}
                id={`${id}-date-selector-nav-previous`}
                onClick={onPrev}
                tabIndex={0}
                type="button"
            >
                <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            </button>
            <div className="flex-none">
                <span className="text-body font-bold">
                    {getMonthDateString(visibleDate, locale)}
                </span>
            </div>
            <button
                aria-label="Next Month"
                className={clsx(
                    'ml-1 flex h-8 w-[2.375rem] items-center justify-center rounded transition duration-100 ease-in-out',
                    onNext
                        ? 'cursor-pointer text-grey-800 hover:bg-grey-300 dark:text-grey-50 dark:hover:bg-grey-600'
                        : 'text-disabled cursor-not-allowed'
                )}
                disabled={!onNext}
                id={`${id}-date-selector-nav-next`}
                onClick={onNext}
                tabIndex={0}
                type="button"
            >
                <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </button>
        </div>
    );
};

export default DateSelectorNav;
