import {FC, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import ServiceError from 'components/Errors/ServiceError';
import Skeleton from 'components/Loaders/Skeleton';
import NoResults from 'components/NoResults';
import {isAfter, isBefore, isSameDay} from 'date-fns';
import {
    CollectionMetadata,
    ReservationBaseFragment,
    ReservationsDocument,
    ReservationsQuery,
    ReservationStatus,
    ReservationWaitingListRequestFragment,
    ReserveUserProfileFragment,
} from 'gql/generated';
import useScrollToTop from 'hooks/useScrollToTop';
import {sortBy} from 'lodash';
import {toJST} from 'utils/date';
import {withISO8601Dates} from 'utils/object';
import {HEADER_OFFSET, scrollTo} from 'utils/scroll';
import Pagination from '../../../Pagination';
import {
    FullReservationWithoutCancellationInfo,
    ListElement,
} from './ListElement/ListElement';
import {RadioFilterSelection} from './ListElement/RadioFilterSelection';
import {ReservationListSkeleton} from './ReservationListSkeleton';

const withDates = (
    reservation: ReservationBaseFragment & ReservationWaitingListRequestFragment
) =>
    ({
        ...withISO8601Dates(reservation),
        waitingListRequest: reservation.waitingListRequest
            ? withISO8601Dates(reservation.waitingListRequest)
            : reservation.waitingListRequest,
    } as FullReservationWithoutCancellationInfo);

enum Time {
    'past' = 'past',
    'upcoming' = 'upcoming',
}

enum StatusPast {
    'all' = 'all',
    'canceled' = 'canceled',
    'completed' = 'completed',
}

enum StatusUpcoming {
    'all' = 'all',
    'canceled' = 'canceled',
    'pending' = 'pending',
    'reserved' = 'reserved',
}

const TIME_FILTERS = {
    [Time.past]: (reservation: ReservationBaseFragment) =>
        isBefore(reservation.dateTime, toJST()) &&
        !isSameDay(reservation.dateTime, toJST()),
    [Time.upcoming]: (reservation: ReservationBaseFragment) =>
        isAfter(reservation.dateTime, toJST()) ||
        isSameDay(reservation.dateTime, toJST()),
};

const STATUS_FILTERS = {
    [Time.past]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [StatusPast.all]: (_reservation: ReservationBaseFragment) => true,
        [StatusPast.canceled]: (reservation: ReservationBaseFragment) =>
            [
                ReservationStatus.CancellationConfirmed,
                ReservationStatus.CancellationRequested,
                ReservationStatus.SameDayCancellation,
                ReservationStatus.WaitlistPeriodExpired,
                ReservationStatus.NoShow,
            ].includes(reservation.status),
        [StatusPast.completed]: (reservation: ReservationBaseFragment) =>
            reservation.status === ReservationStatus.Visited,
    },
    [Time.upcoming]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [StatusUpcoming.all]: (_reservation: ReservationBaseFragment) => true,
        [StatusUpcoming.canceled]: (reservation: ReservationBaseFragment) =>
            [
                ReservationStatus.CancellationConfirmed,
                ReservationStatus.CancellationRequested,
                ReservationStatus.SameDayCancellation,
                ReservationStatus.WaitlistPeriodExpired,
            ].includes(reservation.status),
        [StatusUpcoming.pending]: (reservation: ReservationBaseFragment) =>
            reservation.status === ReservationStatus.Pending,
        [StatusUpcoming.reserved]: (reservation: ReservationBaseFragment) =>
            reservation.status === ReservationStatus.Confirmed,
    },
};

type DropdownProps<T> = {
    onSelect: (value: T) => void;
    options: {
        label: string;
        value: T;
    }[];
    value: T;
};

const MobileDropdown = <T extends string>({
    onSelect,
    options,
    value,
}: DropdownProps<T>) => {
    const {t} = useTranslation();

    return (
        <select
            className="w-full select-none"
            onChange={(event) => onSelect(event.currentTarget.value as T)}
            value={value}
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {t(`user.reservations.selection.${option.label}`)}
                </option>
            ))}
        </select>
    );
};

const PAGE_LIMIT_VALUE = 10;

export const DEFAULT_STATUS = StatusUpcoming.all;

export const DEFAULT_TIME = Time.upcoming;

export const ReservationList: FC = () => {
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams({
        status: DEFAULT_STATUS,
        time: DEFAULT_TIME,
    });

    const time = (searchParams.get('time') || Time.upcoming) as Time;
    const status = (searchParams.get('status') || StatusUpcoming.all) as
        | StatusPast
        | StatusUpcoming;
    const id = searchParams.get('id');

    const optionsTime = [
        {label: 'upcoming', value: Time.upcoming},
        {label: 'past', value: Time.past},
    ];

    const activeReservation = useRef<HTMLDivElement | null>(null);

    const optionsStatus = {
        [Time.past]: [
            {label: 'all', value: StatusPast.all},
            {label: 'completed', value: StatusPast.completed},
            {label: 'canceled', value: StatusPast.canceled},
        ],
        [Time.upcoming]: [
            {label: 'all', value: StatusUpcoming.all},
            {label: 'reserved', value: StatusUpcoming.reserved},
            {label: 'pending', value: StatusUpcoming.pending},
            {label: 'canceled', value: StatusUpcoming.canceled},
        ],
    };

    useEffect(() => {
        setSearchParams((prev) => {
            if (time !== undefined) {
                if (time) {
                    prev.set('time', time);
                } else {
                    prev.delete('time');
                }
            }

            return prev;
        });
    }, [setSearchParams, time]);

    const {data, error, loading} = useQuery<ReservationsQuery>(
        ReservationsDocument,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    // Scroll to the appropriate reservation when an "id" parameter was provided
    useEffect(() => {
        if (id && activeReservation.current && !loading) {
            scrollTo(activeReservation.current, HEADER_OFFSET);
        }
    }, [id, loading]);

    const safeData = useMemo(
        () => data?.user?.reservations?.map(withDates),
        [data]
    );

    const filteredData = useMemo(() => {
        if (!safeData) return [];

        const timeFiltered = safeData.filter((reservation) =>
            TIME_FILTERS[time](reservation)
        );

        const statusFiltered = timeFiltered.filter((reservation) =>
            // @ts-ignore TS can not interfere this type correctly
            STATUS_FILTERS[time][status](reservation)
        );

        return time === Time.upcoming
            ? sortBy(statusFiltered, 'dateTime')
            : [...sortBy(statusFiltered, 'dateTime').reverse()];
    }, [safeData, status, time]);

    const totalPages = filteredData?.length
        ? Math.ceil(filteredData.length / PAGE_LIMIT_VALUE)
        : 1;

    const searchParameterPage = Number(searchParams.get('page'));

    useEffect(() => {
        if (!loading && filteredData) {
            setSearchParams((prev) => {
                if (
                    filteredData.length < PAGE_LIMIT_VALUE ||
                    searchParameterPage > totalPages
                ) {
                    prev.set('page', '1');
                }

                return prev;
            });
        }
    }, [
        filteredData,
        loading,
        searchParameterPage,
        searchParams,
        setSearchParams,
        status,
        totalPages,
    ]);

    const metadata: CollectionMetadata = {
        currentPage:
            searchParameterPage !== 0 && searchParameterPage <= totalPages
                ? searchParameterPage
                : 1,
        limitValue: PAGE_LIMIT_VALUE,
        totalCount: filteredData?.length || 0,
        totalPages,
    };

    useScrollToTop(metadata.currentPage);

    return (
        <div className="min-h-[20.375rem] p-4 md:px-0">
            <div className="flex flex-col items-start justify-between gap-2 sm:flex-row sm:gap-0 md:items-center">
                <h2 className="text-2xl">{t('user.reservations.title')}</h2>
                <div className="flex w-full items-center gap-4 sm:w-fit sm:flex-none md:hidden">
                    <MobileDropdown
                        onSelect={(newStatus) => {
                            setSearchParams(
                                (prev) => {
                                    prev.set('status', newStatus);

                                    return prev;
                                },
                                {replace: true}
                            );
                        }}
                        options={optionsStatus[time]}
                        value={status}
                    />
                    <MobileDropdown
                        onSelect={(newTime) => {
                            setSearchParams(
                                (prev) => {
                                    prev.set('status', DEFAULT_STATUS);
                                    prev.set('time', newTime);

                                    return prev;
                                },
                                {replace: true}
                            );
                        }}
                        options={optionsTime}
                        value={time}
                    />
                </div>
                <div className="hidden w-full items-center gap-4 sm:w-fit sm:flex-none md:flex">
                    <RadioFilterSelection
                        onSelect={(newStatus) => {
                            setSearchParams(
                                (prev) => {
                                    prev.set('status', newStatus);

                                    return prev;
                                },
                                {replace: true}
                            );
                        }}
                        options={optionsStatus[time]}
                        selection={status}
                    />
                    <RadioFilterSelection
                        onSelect={(newTime) => {
                            setSearchParams(
                                (prev) => {
                                    prev.set('status', DEFAULT_STATUS);
                                    prev.set('time', newTime);

                                    return prev;
                                },
                                {replace: true}
                            );
                        }}
                        options={optionsTime}
                        selection={time}
                    />
                </div>
            </div>
            <div className="mt-4">
                {error ? (
                    <ServiceError error={error} />
                ) : (loading && !filteredData) || !data?.user ? (
                    <Skeleton className="flex flex-col gap-4">
                        <ReservationListSkeleton />
                        <ReservationListSkeleton />
                    </Skeleton>
                ) : filteredData.length === 0 ? (
                    <NoResults
                        icon="reservations"
                        label={t(
                            status === StatusUpcoming.all
                                ? 'user.reservations.noReservations'
                                : 'user.reservations.noReservationsFiltered'
                        )}
                    />
                ) : (
                    <div className="flex flex-col gap-4">
                        {filteredData
                            .slice(
                                (metadata.currentPage - 1) * PAGE_LIMIT_VALUE,
                                (metadata.currentPage - 1) * PAGE_LIMIT_VALUE +
                                    PAGE_LIMIT_VALUE
                            )
                            .map((reservation) => {
                                const isActive = id === reservation.id;

                                return (
                                    <ListElement
                                        key={reservation.id}
                                        elementRef={
                                            isActive ? activeReservation : null
                                        }
                                        isPreExpanded={isActive}
                                        reservation={reservation}
                                        user={
                                            data.user as ReserveUserProfileFragment
                                        }
                                    />
                                );
                            })}
                        <div className="mt-6 flex min-h-[3.875rem] justify-center">
                            <Pagination
                                className="w-full max-w-md lg:max-w-2xl xl:max-w-3xl"
                                metadata={metadata}
                                name="reservation"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
