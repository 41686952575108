import {FC} from 'react';
import clsx from 'clsx';
import {CreditCardBrand} from 'gql/generated';
import amex from './amex.svg';
import diners_club from './diners_club.svg';
import discover from './discover.svg';
import generic from './generic.svg';
import jcb from './jcb.svg';
import mastercard from './mastercard.svg';
import unionpay from './unionpay.svg';
import visa from './visa.svg';

export const CARDS: Record<CreditCardBrand, string> = {
    [CreditCardBrand.Amex]: amex,
    [CreditCardBrand.DinersClub]: diners_club,
    [CreditCardBrand.Discover]: discover,
    [CreditCardBrand.Jcb]: jcb,
    [CreditCardBrand.Mastercard]: mastercard,
    [CreditCardBrand.Unionpay]: unionpay,
    [CreditCardBrand.Visa]: visa,
};

export type CreditCardImageProps = {
    className?: string;
    size?: 'sm' | 'md' | 'lg';
    type: CreditCardBrand;
};

const sizes = {
    lg: 'w-[3.125rem] h-8',
    md: 'w-[2.375rem] h-6',
    sm: 'w-[1.5625rem] h-4',
};

const CreditCardImage: FC<CreditCardImageProps> = ({
    className,
    size = 'md',
    type,
}) => (
    <div className={clsx(sizes[size], className)}>
        <img alt={type} className="h-full" src={CARDS[type] || generic} />
    </div>
);

export default CreditCardImage;
