import {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import useScrollToTop from 'hooks/useScrollToTop';
import {useLocale} from 'state/locale';

const RealTimeBookingConfirmation: FC = () => {
    const {t} = useTranslation();
    const locale = useLocale();

    useScrollToTop();

    return (
        <div className="prose prose-sm max-w-none dark:prose-dark">
            <p className="text-golden text-center font-semibold">
                {t('reserve.confirmation.realTimeBooking.importantInformation')}
            </p>
            <p className="mb-0">
                <Trans
                    components={{
                        Link: (
                            <a
                                href={
                                    locale === 'ja'
                                        ? process.env
                                              .CUSTOMER_HELP_SMART_PAYMENT_JA
                                        : process.env
                                              .CUSTOMER_HELP_SMART_PAYMENT_EN
                                }
                                rel="noreferrer"
                                target="_blank"
                            >
                                {' '}
                            </a>
                        ),
                    }}
                    i18nKey="reserve.confirmation.realTimeBooking.smartPayment"
                />
            </p>
            <p className="mb-0">
                {t('reserve.confirmation.realTimeBooking.understand')}
            </p>
            <ul className="mt-0">
                <li>{t('reserve.confirmation.realTimeBooking.case1')}</li>
                <li>{t('reserve.confirmation.realTimeBooking.case2')}</li>
                <li>{t('reserve.confirmation.realTimeBooking.case3')}</li>
            </ul>
            <p>{t('reserve.confirmation.realTimeBooking.liability')}</p>
            <p>{t('reserve.confirmation.realTimeBooking.contact')}</p>
        </div>
    );
};

export default RealTimeBookingConfirmation;
