import {FC, SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Spinner from 'components/Loaders/Spinner';
import Modal from 'components/Modal';
import Authenticate from 'components/User/Authentication';
import {
    AddBookmarkDocument,
    RemoveBookmarkDocument,
    UserDocument,
    VenueSearchResultFragment,
} from 'gql/generated';
import {ToastType, useToastNotification} from 'state/toastNotification';
import {useUser} from 'state/user';
import {handleError} from 'utils/functions';

export type BookmarkButtonProps = {
    className?: string;
    showLabel?: boolean;
    venue: VenueSearchResultFragment;
};

const BookmarkButton: FC<BookmarkButtonProps> = ({
    className,
    showLabel = false,
    venue,
}) => {
    const {t} = useTranslation();

    const user = useUser();
    const {bookmarkedVenues, id} = user || {};

    const [showAuthModal, setShowAuthModal] = useState(false);

    const {showToast} = useToastNotification();

    const onClose = useCallback(() => {
        setShowAuthModal(false);
    }, []);

    const isBookmarked = useMemo(
        () =>
            bookmarkedVenues?.find(
                (bookmarkedVenue) => venue.id === bookmarkedVenue.id
            ),
        [bookmarkedVenues, venue.id]
    );

    const [addBookmark, addBookmarkStatus] = useMutation(AddBookmarkDocument, {
        awaitRefetchQueries: true,
        refetchQueries: [UserDocument],
    });
    const [removeBookmark, removeBookmarkStatus] = useMutation(
        RemoveBookmarkDocument,
        {
            awaitRefetchQueries: true,
            refetchQueries: [UserDocument],
        }
    );

    const isLoading = addBookmarkStatus.loading || removeBookmarkStatus.loading;

    const onClick = async (event: SyntheticEvent) => {
        event.preventDefault();

        if (!id) {
            setShowAuthModal(true);
        } else if (isBookmarked) {
            await removeBookmark({variables: {venueId: venue.id}})
                .then(() => {
                    showToast({
                        message: t('bookmarks.removed'),
                        type: ToastType.Success,
                    });
                })
                .catch(handleError);
        } else {
            await addBookmark({variables: {venueId: venue.id}})
                .then(() => {
                    showToast({
                        message: t('bookmarks.successfullyAdded'),
                        type: ToastType.Success,
                    });
                })
                .catch(handleError);
        }
    };

    return (
        <>
            <button
                aria-label={t('bookmarks.addVenue', {name: venue.name})}
                className={`flex items-center justify-center rounded bg-gold-200 px-2 py-1 focus-visible:ring-1 ${
                    isLoading
                        ? `${
                              showLabel ? 'w-12' : 'h-9 w-9 rounded-full'
                          } cursor-wait`
                        : showLabel
                        ? isBookmarked
                            ? 'hover:bg-body border border-gold-200 bg-gold-200 text-white hover:text-gold-200 dark:text-grey-900 dark:hover:text-gold-200'
                            : 'bg-body border border-gold-200 text-gold-200 hover:bg-gold-200 hover:text-white dark:text-gold-200 dark:hover:text-grey-900'
                        : 'h-9 w-9 rounded-full text-white'
                } ${className}`}
                data-dd-action-name="BookmarkVenue"
                onClick={isLoading ? undefined : onClick}
                type="button"
            >
                {isLoading ? (
                    <Spinner
                        color="gold"
                        size="sm"
                        title={
                            isBookmarked
                                ? t('bookmarks.remove')
                                : t('bookmarks.add')
                        }
                    />
                ) : (
                    <span className="flex items-center justify-center gap-x-2">
                        <FontAwesomeIcon
                            icon={[isBookmarked ? 'fas' : 'far', 'bookmark']}
                        />
                        {showLabel &&
                            (isBookmarked
                                ? t('bookmarks.remove')
                                : t('bookmarks.add'))}
                    </span>
                )}
            </button>
            {showAuthModal && (
                <Modal onClose={onClose}>
                    <Authenticate
                        login={true}
                        message={t('authentication.toBookmark')}
                        onClose={onClose}
                    />
                </Modal>
            )}
        </>
    );
};

export default BookmarkButton;
