import {FC, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import ServiceError from 'components/Errors/ServiceError';
import {ScrollContainer} from 'components/ScrollContainer/ScrollContainer';
import {UserProfileDocument, UserProfileQuery} from 'gql/generated';
import {BasicInformationContent} from './Contents/BasicInformation/BasicInformation';
import {BasicInformationSkeleton} from './Contents/BasicInformation/BasicInformationSkeleton';
import {EmailPreferencesContent} from './Contents/EmailPreferences/EmailPreferences';
import {EmailPreferencesSkeleton} from './Contents/EmailPreferences/EmailPreferencesSkeleton';

export const Profile: FC = () => {
    const basicInformationRef = useRef<HTMLDivElement | null>(null);
    const emailPreferencesRef = useRef<HTMLDivElement | null>(null);
    const {t} = useTranslation();

    const {data, error, loading} = useQuery<UserProfileQuery>(
        UserProfileDocument,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    const sections = useMemo(
        () => [
            {
                content:
                    loading && !data ? (
                        <BasicInformationSkeleton />
                    ) : error ? (
                        <ServiceError className="w-full" error={error} />
                    ) : (
                        <BasicInformationContent
                            user={(data as UserProfileQuery).user}
                        />
                    ),
                elementRef: basicInformationRef,
                title: t('user.basicInformation.title'),
                value: 'basicInformation',
            },
            {
                content: loading ? (
                    <EmailPreferencesSkeleton />
                ) : error ? (
                    <ServiceError className="w-full" error={error} />
                ) : (
                    <EmailPreferencesContent
                        user={(data as UserProfileQuery).user}
                    />
                ),
                elementRef: emailPreferencesRef,
                title: t('user.emailPreferences.title'),
                value: 'emailPreferences',
            },
        ],
        [data, error, loading, t]
    );

    return ScrollContainer({
        headerTitle: t('user.profile'),
        isLoading: loading,
        sections,
    });
};
