import {FC} from 'react';
import {getS3ImageURL} from 'utils/environment';

export type FallbackImageProps = {
    alt?: string;
    className?: string;
    id?: string;
};

const FallbackImage: FC<FallbackImageProps> = ({alt, className, id}) => (
    <img
        alt={alt}
        className={className}
        id={id}
        src={getS3ImageURL('venue_fallback', 'png')}
    />
);

export default FallbackImage;
