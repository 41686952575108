import {FC} from 'react';
import VenueResultCardSkeleton from 'components/Venue/VenueResultCardSkeleton';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';

const VenueSearchLoader: FC = () => {
    const breakpoint = useCurrentBreakpoint();
    if (!breakpoint) return null;

    return (
        <div
            className="grid grid-cols-1 place-content-center place-items-stretch gap-4 sm:grid-cols-2 lg:grid-cols-3"
            role="progressbar"
        >
            {breakpoint === 'xs' ? (
                <VenueResultCardSkeleton />
            ) : breakpoint === 'sm' || breakpoint === 'md' ? (
                <>
                    <VenueResultCardSkeleton />
                    <VenueResultCardSkeleton />
                </>
            ) : (
                <>
                    <VenueResultCardSkeleton />
                    <VenueResultCardSkeleton />
                    <VenueResultCardSkeleton />
                </>
            )}
        </div>
    );
};

export default VenueSearchLoader;
