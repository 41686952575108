/*
 * These are the offsets we are taking into account when we scroll to a segment of the page when the user clicks on the appropriate title in the header.
 * These offsets are based on the tailwindcss classes we use and just slightly adjusted to fit correctly.
 */
export const HEADER_OFFSET = 62;

export const SEGMENT_HEADER_OFFSET = 32;

// Helper function to get the dimensions of a <div> to calculate how far we need to scroll and where we are on the page.
export const getDimensions = (element: HTMLDivElement) => {
    const {height, top} = element.getBoundingClientRect();
    const {offsetTop} = element;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetBottom,
        offsetTop,
        top,
    };
};

export const scrollTo = (
    element: HTMLDivElement | null,
    additionalOffset = 0
) => {
    // This if clause is only here to satisfy Typescript
    if (!element) {
        console.error('Ref is not defined correctly');

        return;
    }

    const {top} = getDimensions(element);
    const offsetPosition = top + window.scrollY - additionalOffset;

    window.scrollTo({
        behavior: 'smooth',
        top: offsetPosition,
    });
};
