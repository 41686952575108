import {FC, ReactNode, RefObject, useEffect, useRef} from 'react';
import clsx from 'clsx';
import CloseButton from 'components/CloseButton';
import useBreakpoint from 'hooks/useBreakpoint';

export type ModalBodyProps = {
    children: ReactNode;
    className?: string;
    onClose: () => void;
    title: string;
};

const ModalBody: FC<ModalBodyProps> = ({
    children,
    className,
    onClose,
    title,
}) => {
    const isDesktop = useBreakpoint('sm');
    const containerRef: RefObject<HTMLDivElement> = useRef(null);

    useEffect(() => {
        if (isDesktop) {
            const onClickOutside = (event: MouseEvent) => {
                // @ts-ignore
                if (!containerRef.current?.contains(event.target)) {
                    onClose();
                }
            };
            document.addEventListener('mousedown', onClickOutside);

            return () =>
                document.removeEventListener('mousedown', onClickOutside);
        }

        return undefined;
    }, [isDesktop, onClose]);

    return (
        <div
            className={clsx(
                'relative sm:flex sm:items-center sm:justify-center',
                className
            )}
        >
            <div
                ref={containerRef}
                className="bg-body border-outline relative px-5 pt-4 pb-5 sm:min-w-[36rem] sm:max-w-xl sm:rounded-md sm:border sm:shadow-lg"
            >
                <div className="border-outline flex items-center justify-between gap-4 border-b px-0.5 pb-4">
                    <h2 className="text-lg">{title}</h2>
                    <CloseButton onClick={onClose} />
                </div>
                {children}
            </div>
        </div>
    );
};

export default ModalBody;
