import {FC, ReactNode} from 'react';
import clsx from 'clsx';
import {Size} from 'types';
import styles from './styles.module.css';

export type CheckboxRadioGroupProps = {
    children: ReactNode;
    className?: string;
    isHorizontal?: boolean;
    size?: Size;
};

const SIZE: Record<Size, string>[] = [
    {
        base: 'gap-3',
        lg: 'gap-3.5',
        sm: 'gap-2.5',
        xl: 'gap-4',
        xs: 'gap-2',
    },
    {
        base: 'gap-6',
        lg: 'gap-6',
        sm: 'gap-6',
        xl: 'gap-6',
        xs: 'gap-6',
    },
];

const CheckboxRadioGroup: FC<CheckboxRadioGroupProps> = ({
    children,
    className,
    isHorizontal,
    size = 'base',
}) => (
    <div
        className={clsx(
            styles.group,
            'flex',
            !isHorizontal && 'flex-col',
            SIZE[Number(!!isHorizontal)][size],
            className
        )}
        role="group"
    >
        {children}
    </div>
);

export default CheckboxRadioGroup;
