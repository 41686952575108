import * as ls from 'local-storage';

const checkColorScheme = () => {
    if (typeof window !== 'undefined') {
        const colorScheme = ls.get('color-scheme');

        // Only enable light mode initially if it was stored in the local storage
        if (colorScheme && colorScheme === 'light') {
            document.documentElement.classList.add('light');
        } else {
            document.documentElement.classList.add('dark');
        }
    }
};

export default checkColorScheme;
