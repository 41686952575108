import clsx from 'clsx';
import styles from './static.module.css';

export const TransactionPage = () => (
    <div
        className={clsx(
            'prose prose-sm max-w-none px-4 pt-6 dark:prose-dark md:prose md:max-w-none md:px-0',
            styles.transaction,
            styles.page
        )}
    >
        <h1>特定商取引法に基づく表記</h1>
        <section>
            <table>
                <tbody>
                    <tr>
                        <th>事業者名</th>
                        <td>株式会社ポケットコンシェルジュ</td>
                    </tr>
                    <tr>
                        <th>親会社</th>
                        <td>
                            アメリカン・エキスプレス・インターナショナル・インコーポレイテッド
                        </td>
                    </tr>
                    <tr>
                        <th>運営統括責任者</th>
                        <td>池田 博之</td>
                    </tr>
                    <tr>
                        <th>所在地</th>
                        <td>〒105-6920 東京都港区虎ノ門４－１－１</td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td>03-6625-9221</td>
                    </tr>
                    <tr>
                        <th>お支払方法</th>
                        <td>クレジットカード決済</td>
                    </tr>
                    <tr>
                        <th>販売価格</th>
                        <td>
                            各レストランページに記載のコース価格をご確認ください。
                        </td>
                    </tr>
                    <tr>
                        <th className="w-28">申込み後のキャンセル</th>
                        <td>
                            予約確定後のキャンセルにつきましては、各レストランが定めるキャンセルポリシーに基づきキャンセル料を頂戴しております。
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </div>
);
