import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import Skeleton from 'components/Loaders/Skeleton';
import SkeletonText from 'components/Loaders/Skeleton/SkeletonText';
import {useLocale} from 'state/locale';

type DetailsBlockSkeletonProps = {
    className?: string;
    label: string;
    value: string;
};

const DetailsBlockSkeleton: FC<DetailsBlockSkeletonProps> = ({
    className,
    label,
    value,
}) => (
    <div className={className}>
        <SkeletonText className="text-sub text-xs">{label}</SkeletonText>
        <SkeletonText className="mt-1 h-4 text-sm font-semibold">
            {value}
        </SkeletonText>
    </div>
);

type DetailsSkeletonProps = {
    className?: string;
};

export const DetailsSkeleton: FC<DetailsSkeletonProps> = ({className}) => {
    const {t} = useTranslation();
    const locale = useLocale();

    const phoneNumber =
        locale === 'ja' ? '+81 99 1234 5678' : '+1 555 555-5555';

    return (
        <Skeleton className={className}>
            <div className="grid grid-cols-1 gap-3 sm:pb-3 md:grid-cols-2">
                <SkeletonText className="border-outline col-span-full hidden border-b pb-2 pt-3 font-semibold sm:block">
                    {t('reserve.details.title.realTimeBooking')}
                </SkeletonText>
                <DetailsBlockSkeleton
                    className="mt-4 sm:mt-0"
                    label={t('venue.tabs.courses.course')}
                    value={t('venue.tabs.courses.course').repeat(1)}
                />
                <DetailsBlockSkeleton
                    label={t('seatingType.label')}
                    value={t('seatingType.TABLE')}
                />
                <DetailsBlockSkeleton
                    label={t('reserve.details.visitPurpose.label')}
                    value={t('reserve.details.visitPurpose.OTHER')}
                />
                <DetailsBlockSkeleton
                    label={t('reserve.details.visitFrequency.summary')}
                    value={t('reserve.details.visitFrequency.FIRST_VISIT')}
                />
                <SkeletonText className="border-outline col-span-full border-b pb-2 pt-3 font-semibold">
                    {t('reservations.payment')}
                </SkeletonText>
                <div className="flex items-center">
                    <div className="skeleton h-8 w-[3.125rem] rounded-sm" />
                    <div className="ml-4">
                        <div className="skeleton h-4 w-32 md:w-28 lg:w-32" />
                        <div className="skeleton mt-2 h-4 w-64 md:w-32 lg:w-56 xl:w-64" />
                    </div>
                </div>
                <SkeletonText className="border-outline col-span-full border-b pb-2 pt-3 font-semibold">
                    {t('reserve.details.contactInformation')}
                </SkeletonText>
                <DetailsBlockSkeleton
                    className={clsx(locale !== 'ja' && 'col-span-full')}
                    label={t('name')}
                    value={t('user.fullName', {
                        firstName: t('firstName'),
                        lastName: t('lastName'),
                    })}
                />
                {locale === 'ja' && (
                    <DetailsBlockSkeleton
                        className={clsx(locale !== 'ja' && 'col-span-full')}
                        label={t('user.basicInformation.name.furigana')}
                        value={t('user.fullName', {
                            firstName: 'ファーストネーム',
                            lastName: 'ラストネーム',
                        })}
                    />
                )}
                <DetailsBlockSkeleton
                    label={t('user.basicInformation.phoneNumber.label')}
                    value={phoneNumber}
                />
                <DetailsBlockSkeleton
                    label={t('user.basicInformation.email')}
                    value="loading@email.com"
                />
            </div>
        </Skeleton>
    );
};
