import {FC, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import Select from 'components/Form/Select';
import {VisitPurpose} from 'gql/generated';
import {ReserveDetailsFormData} from 'types/reserve';

const VisitPurposeSelect: FC = () => {
    const {t} = useTranslation();

    const {watch} = useFormContext<ReserveDetailsFormData>();

    watch('visitPurpose');

    const options = useMemo(
        () =>
            Object.values(VisitPurpose)
                .filter((value) => value !== 'EXTERNAL_SYSTEM_RESERVATION')
                .map((value) => ({
                    label: t(`reserve.details.visitPurpose.${String(value)}`),
                    value,
                })) as SelectOption[],
        [t]
    );

    return (
        <fieldset className="mt-4 flex flex-col md:flex-row md:gap-2 lg:gap-4">
            <Select
                aria-label={t('reserve.details.visitPurpose.label')}
                className="md:flex-1"
                data-dd-privacy="allow"
                label={t('reserve.details.visitPurpose.label')}
                name="visitPurpose"
                options={options}
                required={true}
                unselected={t('form.select')}
            />
            <div className="hidden lg:block lg:flex-1" />
        </fieldset>
    );
};

export default VisitPurposeSelect;
