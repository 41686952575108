/* eslint-disable react/no-danger */
import {RefObject, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import Modal from 'components/Modal';
import {Course, ServiceType} from 'gql/generated';
import useBreakpoint from 'hooks/useBreakpoint';
import {commify, sanitizeTextBlock} from 'utils/string';
import CloseButton from './CloseButton';

const ICONS: Record<ServiceType, IconName> = {
    [ServiceType.Lunch]: 'sun',
    [ServiceType.Dinner]: 'moon',
};

type Props = {
    closeModal: () => void;
    course: Course;
    isReservable?: boolean;
    onReserve?: () => void;
};

const VenueModal = ({
    closeModal,
    course,
    isReservable = false,
    onReserve,
}: Props) => {
    const {
        costPerGuest,
        fixedPrice,
        name,
        serviceType,
        summary,
        supplementaryInformation,
    } = course;
    const {t} = useTranslation();

    const isDesktop = useBreakpoint('sm');
    const containerRef: RefObject<HTMLDivElement> = useRef(null);

    useEffect(() => {
        if (isDesktop) {
            const onClickOutside = (event: MouseEvent) => {
                // @ts-ignore
                if (!containerRef.current?.contains(event.target)) {
                    closeModal();
                }
            };
            document.addEventListener('mousedown', onClickOutside);

            return () =>
                document.removeEventListener('mousedown', onClickOutside);
        }

        return undefined;
    }, [closeModal, isDesktop]);

    return (
        <Modal onClose={() => closeModal()}>
            <div className="relative sm:flex sm:items-center sm:justify-center">
                <div
                    ref={containerRef}
                    className="bg-body relative sm:min-w-[36rem] sm:max-w-xl sm:rounded-md sm:border sm:shadow-lg"
                >
                    <div className="flex items-center justify-between gap-4 rounded-t-md border-b bg-gold-200 p-4 text-white">
                        <h2 className="text-lg">{name}</h2>
                        <CloseButton onClick={() => closeModal()} />
                    </div>
                    <div className="p-4">
                        <div className="flex flex-row justify-between">
                            <div>
                                <FontAwesomeIcon
                                    className="text-gold-200"
                                    icon={['fas', ICONS[serviceType]]}
                                />
                                <span className="ml-2">
                                    {t(`serviceTypes.${serviceType}`)}
                                </span>
                            </div>
                            <div className="mt-1 text-sm">
                                {t('reservation.costPerGuestCurrency', {
                                    ...course,
                                    costPerGuest: commify(costPerGuest),
                                })}
                                {fixedPrice &&
                                    ` + ${t(
                                        'reservation.costPerGroupCurrency',
                                        {
                                            costPerGroup: commify(fixedPrice),
                                        }
                                    )}`}
                            </div>
                        </div>
                        <hr className="my-3" />
                        {summary && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeTextBlock(summary),
                                }}
                                className="mt-3 text-sm leading-snug"
                            />
                        )}
                        {supplementaryInformation && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeTextBlock(
                                        supplementaryInformation
                                    ),
                                }}
                                className="mt-4 text-sm"
                            />
                        )}
                    </div>
                    {isReservable && onReserve && (
                        <div className="mb-3 flex w-full justify-center">
                            <Button onClick={onReserve}>{t('select')}</Button>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default VenueModal;
