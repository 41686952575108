/* eslint-disable react/no-danger */
import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import Button from 'components/Button';

export type MutationStatusProps = {
    className?: string;
    onClick?: () => void;
};

const MutationStatus: FC<MutationStatusProps> = ({className, onClick}) => {
    const {t} = useTranslation();

    const {clearErrors, getFieldState} = useFormContext();
    const serverError = getFieldState('serverError');
    const successMessage = getFieldState('successMessage');

    const error = serverError.error || successMessage.error;

    if (!error) return null;

    const isError = !!serverError?.error;

    const onClear = () => {
        onClick?.();
        clearErrors();
    };

    return (
        <div
            className={clsx(
                'flex items-center rounded-md border py-2.5 pl-4 pr-1.5',
                isError
                    ? 'border-red-600 bg-red-400/30 dark:border-red-600 dark:bg-red-500/30'
                    : 'border-green-500 bg-green-400/30 dark:border-green-600 dark:bg-green-600/30',
                className
            )}
            role={isError ? 'alert' : 'status'}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html: error.message || t('error.unexpected'),
                }}
                className="flex-1 whitespace-pre-wrap text-base"
            />
            <Button
                className="ml-3 flex-none"
                icon="close"
                kind="custom"
                onClick={onClear}
                size="xs"
            />
        </div>
    );
};

export default MutationStatus;
