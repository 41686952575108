import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseSelectProps} from 'components/Form/Base/BaseSelect';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import Select from 'components/Form/Select';

export type PriceRangeSelectProps = Omit<BaseSelectProps, 'name' | 'options'>;

const PriceRangeSelect: FC<PriceRangeSelectProps> = (props) => {
    const {t} = useTranslation();

    const options = [
        {
            icon: ['fas', 'coins'],
            label: t('search.priceRanges.1'),
            value: '1',
        },
        {
            icon: ['fas', 'coins'],
            label: t('search.priceRanges.2'),
            value: '2',
        },
        {
            icon: ['fas', 'coins'],
            label: t('search.priceRanges.3'),
            value: '3',
        },
        {
            icon: ['fas', 'coins'],
            label: t('search.priceRanges.4'),
            value: '4',
        },
    ] as SelectOption[];

    return (
        <Select
            aria-label={t('search.priceRange')}
            data-dd-privacy="allow"
            unselected={t('search.priceRange')}
            unselectedIcon={['fas', 'coins']}
            {...props}
            name="priceRange"
            options={options}
        />
    );
};

export default PriceRangeSelect;
