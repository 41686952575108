import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as ReactRouterLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {useLocale} from 'state/locale';

const LINK_CLASS = 'link-item space-x-2 hover:underline';

type FooterContactProps = {
    className?: string;
};

const FooterContact: FC<FooterContactProps> = ({className}) => {
    const {t} = useTranslation();
    const locale = useLocale();

    return (
        <div
            className={clsx(
                'flex flex-col items-center items-center space-y-2 pl-px text-sm sm:mx-0 sm:flex-row sm:pl-0 sm:pt-1.5',
                className
            )}
        >
            <ReactRouterLink
                className={LINK_CLASS}
                rel="noopener noreferrer"
                target="_blank"
                to={
                    locale === 'ja'
                        ? (process.env.CUSTOMER_HELP_JA as string)
                        : (process.env.CUSTOMER_HELP_EN as string)
                }
            >
                <FontAwesomeIcon
                    height={24}
                    icon={['far', 'envelope']}
                    width={24}
                />
                <span>{t('staticPages.contact')}</span>
            </ReactRouterLink>
            {locale === 'ja' && (
                <ReactRouterLink
                    className={`${LINK_CLASS} sm:ml-2`}
                    rel="noopener noreferrer"
                    target="_blank"
                    to={process.env.RESTAURANT_HELP_INQUIRY_JA as string}
                >
                    <FontAwesomeIcon
                        height={24}
                        icon={['fas', 'shop']}
                        width={24}
                    />
                    <span>{t('staticPages.contactVenue')}</span>
                </ReactRouterLink>
            )}
        </div>
    );
};

export default FooterContact;
