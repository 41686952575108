import {FC, useEffect} from 'react';
import {createPortal} from 'react-dom';
import Button from 'components/Button';
import {Toast, ToastType} from 'state/toastNotification';

export type ToastNotificationProps = {
    reset: () => void;
} & Toast;

export const ToastNotification: FC<ToastNotificationProps> = ({
    displaySeconds,
    message,
    reset,
    supplementaryInformation,
    type,
}) => {
    // Display errors longer than success message because of difference in text length
    const timeoutSeconds = displaySeconds || type === ToastType.Error ? 10 : 4;

    useEffect(() => {
        if (!message || !type) {
            return;
        }

        document.documentElement.classList.add('toast-open');

        const timer = setTimeout(() => {
            document.documentElement.classList.remove('toast-open');
            reset();
        }, timeoutSeconds * 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [message, reset, timeoutSeconds, type]);

    if (!message || !type) {
        return null;
    }

    const isError = type === ToastType.Error;

    const toast = document.querySelector('#toast');
    const toastNotification = (
        <div
            className={`flex w-screen items-center border py-2.5 pl-4 pr-1.5 text-center sm:w-fit sm:rounded-md sm:text-left ${
                isError
                    ? 'border-red-600 bg-red-300 dark:border-red-600 dark:bg-red-400'
                    : 'border-green-500 bg-green-300 dark:border-green-600 dark:bg-green-500'
            }`}
            role={isError ? 'alert' : 'status'}
        >
            <div className="flex flex-1 flex-col gap-y-1">
                <div className="whitespace-pre-wrap text-base">{message}</div>
                {supplementaryInformation && (
                    <div className="whitespace-pre-wrap text-sm">
                        {supplementaryInformation}
                    </div>
                )}
            </div>
            <Button
                className="ml-3 flex-none"
                icon="close"
                kind="custom"
                onClick={reset}
                size="xs"
            />
        </div>
    );

    return toast ? createPortal(toastNotification, toast) : null;
};
