import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'components/Loaders/Skeleton';
import SkeletonButton from 'components/Loaders/Skeleton/SkeletonButton';
import SkeletonCheckbox from 'components/Loaders/Skeleton/SkeletonCheckbox';
import SkeletonForm from 'components/Loaders/Skeleton/SkeletonForm';
import {useLocale} from 'state/locale';

export const EmailPreferencesSkeleton: FC = () => {
    const locale = useLocale();
    const {t} = useTranslation();

    return (
        <Skeleton className="bg-body md:border-outline mt-4 px-4 md:rounded-md md:border md:p-4 lg:p-5">
            <SkeletonForm className="bg-step p-4">
                <SkeletonCheckbox
                    label={t('authentication.signup.receiveNewsletter')}
                    size="sm"
                    step={true}
                />
                {locale === 'ja' && (
                    <SkeletonCheckbox
                        label={t(
                            'authentication.signup.featuredMailNotification'
                        )}
                        size="sm"
                        step={true}
                    />
                )}
                <div className="flex justify-center pb-2 sm:pb-0 md:justify-end">
                    <SkeletonButton className="w-11/12 md:w-auto" step={true}>
                        {t('form.save')}
                    </SkeletonButton>
                </div>
            </SkeletonForm>
        </Skeleton>
    );
};
