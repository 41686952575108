/* eslint-disable react/no-danger */
import {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import Spinner from 'components/Loaders/Spinner';
import RedirectCountdown from 'components/RedirectCountdown';
import {UnlockAccountDocument} from 'gql/generated';
import {useErrorHandling} from 'hooks/useServerErrorHandling';

export const UnlockAccount: FC = () => {
    const {t} = useTranslation();

    const [searchParams] = useSearchParams();
    const token = searchParams.get('unlock_token');
    const {logError} = useErrorHandling();

    const [submit, {data, error, loading}] = useMutation(UnlockAccountDocument);

    useEffect(() => {
        if (token) {
            submit({variables: {token}}).catch(logError);
        }
    }, [logError, submit, token]);

    return (
        <div className="site-container space-y-5 p-4">
            <h1 className="text-center text-3xl sm:text-left">
                {t('authentication.unlockAccount.title')}
            </h1>
            {loading ? (
                <Spinner
                    className="py-2 sm:w-fit"
                    size="3x"
                    title={t('authentication.unlockAccount.title')}
                />
            ) : error || !token ? (
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('authentication.unlockAccount.error'),
                    }}
                    className="text-center sm:text-left"
                    role="alert"
                />
            ) : data ? (
                <RedirectCountdown
                    message={t('authentication.unlockAccount.success')}
                    redirectUrl="/"
                />
            ) : null}
        </div>
    );
};
