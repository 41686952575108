import {FC, ReactNode} from 'react';
import {useAnalytics} from 'hooks/useAnalytics';

export enum PageName {
    Bookmarks = 'Bookmarks',
    Cancel = 'Cancel',
    CompanyProfile = 'Company Profile',
    ConfirmAccount = 'Confirm Account',
    ContactForm = 'Contact Form',
    ForgotPassword = 'Forgot Password',
    Login = 'Login',
    Message = 'Message',
    PaymentMethods = 'Payment Methods',
    PrivacyPolicy = 'Privacy Policy',
    Profile = 'Profile',
    ResendConfirmation = 'Resend Confirmation',
    Reservations = 'Reservations',
    Reserve = 'Reserve',
    ResetPassword = 'Reset Password',
    Restaurant = 'Restaurant',
    Search = 'Search',
    SecretSeat = 'Secret Seat',
    Settings = 'Settings',
    SignUp = 'Sign Up',
    TermsOfUse = 'Terms of Use',
    Top = 'Top',
    Transaction = 'Transaction',
    UnlockAccount = 'Unlock Account',
}

type Props = {
    children: ReactNode;
    pageName: PageName;
};

export const Analytics: FC<Props> = ({children, pageName}) => {
    useAnalytics(pageName);

    return children;
};
