import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import InputText from 'components/Form/InputText';
import Select from 'components/Form/Select';
import {ReserveVenueFragment} from 'gql/generated';
import {useLocale} from 'state/locale';
import {ReserveDetailsFormData} from 'types/reserve';

type SpecialRequestOptionProps = {
    venue: ReserveVenueFragment;
};

const SpecialRequestOption: FC<SpecialRequestOptionProps> = ({venue}) => {
    const {t} = useTranslation();
    const locale = useLocale();

    const {watch} = useFormContext<ReserveDetailsFormData>();

    if (!venue.specialRequestOptions?.length || locale === 'en') return null;

    const specialRequestOptionId = watch('specialRequestOptionId');

    const options = venue.specialRequestOptions.map(({id, title}) => ({
        label: title,
        value: id,
    })) as SelectOption[];

    const messageRequired = venue.specialRequestOptions.find(
        ({id}) => id === specialRequestOptionId
    )?.messageRequired;

    return (
        <fieldset className="mt-6">
            <h3 className="md:border-outline px-4 pb-1.5 md:border-b md:px-0">
                {t('reserve.details.specialRequestOption.label')}
            </h3>
            <div className="bg-step space-y-4 px-4 py-3 md:mt-2">
                <Select
                    aria-label={t('reserve.details.specialRequestOption.label')}
                    name="specialRequestOptionId"
                    options={options}
                    unselected={t(
                        'reserve.details.specialRequestOption.unselected'
                    )}
                />
                {messageRequired && (
                    <InputText
                        aria-label={t(
                            'reserve.details.specialRequestOption.message'
                        )}
                        label={t(
                            'reserve.details.specialRequestOption.message'
                        )}
                        maxLength={1000}
                        name="specialRequestOptionMessage"
                        placeholder={t(
                            'reserve.details.specialRequestOption.message'
                        )}
                        required={messageRequired}
                    />
                )}
            </div>
        </fieldset>
    );
};

export default SpecialRequestOption;
