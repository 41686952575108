/* eslint-disable unicorn/prefer-module */
/* eslint-disable @typescript-eslint/no-var-requires */
const tailwind = require('../styles/tailwind.css');
const fontawesome = require('@fortawesome/fontawesome-svg-core/styles.css');

// This looks like useless code, but it forces webpack to pick these sheets up as assets
// that must be included in the pack bundle CSS and prevent a flash of unstyled content.
const loadStyles = () => {
    if (tailwind) {
        (() => {})();
    }

    if (fontawesome) {
        (() => {})();
    }
};

export default loadStyles;
