import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseSelectProps} from 'components/Form/Base/BaseSelect';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import Select from 'components/Form/Select';
import {SeatingType} from 'gql/generated';

export type SeatingSelectProps = Omit<BaseSelectProps, 'name' | 'options'>;

const SeatingSelect: FC<SeatingSelectProps> = (props) => {
    const {t} = useTranslation();

    const options = Object.values(SeatingType).map((value) => ({
        icon: ['fas', 'chair'],
        label: t(`seatingType.${String(value)}`),
        value,
    })) as SelectOption[];

    return (
        <Select
            aria-label={t('search.selectSeating')}
            data-dd-privacy="allow"
            unselected={t('search.selectSeating')}
            unselectedIcon={['fas', 'chair']}
            {...props}
            name="seatingType"
            options={options}
        />
    );
};

export default SeatingSelect;
