import {FC, Fragment} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {PriceRange} from 'gql/generated';
import {commify} from 'utils/string';

export type VenuePriceRangeProps = {
    className?: string;
    priceRanges?: PriceRange[];
};

const VenuePriceRange: FC<VenuePriceRangeProps> = ({className, priceRanges}) =>
    priceRanges?.length ? (
        <div className={clsx('flex gap-4 text-xs font-bold', className)}>
            {priceRanges
                .filter((priceRange) => priceRange.serviceType === 'LUNCH')
                .map((priceRange) => (
                    <Fragment key={priceRange.serviceType}>
                        <FontAwesomeIcon
                            className="relative top-0.5 text-gold-200"
                            icon={['fas', 'sun']}
                        />
                        <span className="text-body -ml-2">
                            ¥{commify(priceRange.min)}
                            {priceRange.max === priceRange.min
                                ? ' '
                                : ` - ¥${commify(priceRange.max)} `}
                        </span>
                    </Fragment>
                ))}
            {priceRanges
                .filter((priceRange) => priceRange.serviceType === 'DINNER')
                .map((priceRange) => (
                    <Fragment key={priceRange.serviceType}>
                        <FontAwesomeIcon
                            className="relative top-0.5 text-gold-200"
                            icon={['fas', 'moon']}
                        />
                        <span className="text-body -ml-2">
                            ¥{commify(priceRange.min)}
                            {priceRange.max === priceRange.min
                                ? ' '
                                : ` - ¥${commify(priceRange.max)} `}
                        </span>
                    </Fragment>
                ))}
        </div>
    ) : null;

export default VenuePriceRange;
