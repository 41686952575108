import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import Form from 'components/Form';
import Checkboxes from 'components/Form/Checkboxes';
import SubmitButton from 'components/Form/SubmitButton';
import TextArea from 'components/Form/TextArea';
import Link from 'components/Link';
import {AccountDeletionReason, DeleteAccountDocument} from 'gql/generated';
import useNavigateLocale from 'hooks/useNavigateLocale';
import {useCredentialsDispatch} from 'state/credentials';
import {ToastType, useToastNotification} from 'state/toastNotification';
import {FormData} from 'types';

const REASONS: AccountDeletionReason[] = [
    AccountDeletionReason.LifestyleMismatch,
    AccountDeletionReason.HardToUseService,
    AccountDeletionReason.NoAttractiveRestaurants,
    AccountDeletionReason.NoCampaign,
    AccountDeletionReason.BadResponse,
    AccountDeletionReason.Other,
];

type DeleteAccountFormData = FormData<{
    note: string;
    [AccountDeletionReason.LifestyleMismatch]?: boolean;
    [AccountDeletionReason.HardToUseService]?: boolean;
    [AccountDeletionReason.NoAttractiveRestaurants]?: boolean;
    [AccountDeletionReason.NoCampaign]?: boolean;
    [AccountDeletionReason.BadResponse]?: boolean;
    [AccountDeletionReason.Other]?: boolean;
}>;

export const DeleteAccountContent: FC = () => {
    const {t} = useTranslation();
    const {showToast, toastWrapper} = useToastNotification();
    const navigate = useNavigateLocale();

    const methods = useForm<DeleteAccountFormData>();
    const {handleSubmit} = methods;

    const [deleteAccount] = useMutation(DeleteAccountDocument);

    const setCredentials = useCredentialsDispatch();

    const onSubmit = async (formData: DeleteAccountFormData) => {
        const {note, serverError, successMessage, ...rest} = formData;
        const reasons = Object.entries(rest).reduce(
            (acc, [key, value]) => (value ? [...acc, key] : acc),
            [] as string[]
        );

        if (note.length > 0 && reasons.length > 0) {
            await toastWrapper({
                onFulfilled: () => {
                    setCredentials(null);
                    navigate('/');
                },
                promise: deleteAccount({variables: {note, reasons}}),
                successMessage: t('user.deleteAccount.farewell'),
            });
        } else {
            showToast({
                message: t('user.deleteAccount.reasonRequired'),
                type: ToastType.Error,
            });
        }
    };

    const options = REASONS.map((value) => ({
        label: t(`user.deleteAccount.${value}`),
        name: value,
        value,
    }));

    return (
        <>
            <div>
                <div className="prose prose-sm pb-2 dark:prose-dark md:prose md:max-w-none md:px-0">
                    <Trans
                        components={{
                            Link: (
                                <Link
                                    key={0}
                                    to="/account/profile?activeSection=emailPreferences"
                                >
                                    {' '}
                                </Link>
                            ),
                            li: <li />,
                            p: <p />,
                            ul: <ul />,
                        }}
                        i18nKey="user.deleteAccount.warning"
                    />
                </div>
            </div>
            <FormProvider {...methods}>
                <Form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-step flex flex-col gap-y-4 p-4">
                        <Checkboxes
                            data-dd-privacy="allow"
                            label={t('user.deleteAccount.reasons')}
                            options={options}
                            required={true}
                        />
                        <TextArea
                            label={t('user.deleteAccount.note')}
                            maxLength={500}
                            name="note"
                            required={t('form.thisFieldIsRequired')}
                            resize="auto"
                            rows={2}
                        />
                    </div>
                    <div className="flex justify-center pb-2 sm:pb-0 md:justify-end">
                        <SubmitButton
                            className="w-11/12 md:w-auto"
                            kind="destructive"
                            loading={t('form.pleaseWait')}
                        >
                            {t('user.deleteAccount.title')}
                        </SubmitButton>
                    </div>
                </Form>
            </FormProvider>
        </>
    );
};
