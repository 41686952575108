import {FC, ReactNode} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import Button from 'components/Button';
import Form from 'components/Form';
import Checkbox from 'components/Form/Checkbox';
import InputEmail from 'components/Form/InputEmail';
import InputFullName from 'components/Form/InputFullName';
import InputKanaName from 'components/Form/InputKanaName';
import InputPassword from 'components/Form/InputPassword';
import SubmitButton from 'components/Form/SubmitButton';
import YearMonthDay from 'components/Form/YearMonthDay';
import {SignupDocument} from 'gql/generated';
import useNavigateLocale from 'hooks/useNavigateLocale';
import {useToastNotification} from 'state/toastNotification';
import {FormData} from 'types';

type SignupFormData = FormData<{
    birthday: Date;
    email: string;
    firstName: string;
    firstNameKana?: string;
    lastName: string;
    lastNameKana?: string;
    newsletter: boolean;
    password: string;
    passwordConfirm: string;
    privacy: boolean;
}>;

export type SignupProps = {
    children?: ReactNode;
    message?: string;
    onLogin: () => void;
    onSuccess?: () => void;
};

const Signup: FC<SignupProps> = ({children, message, onLogin, onSuccess}) => {
    const {t} = useTranslation();
    const navigate = useNavigateLocale();
    const {toastWrapper} = useToastNotification();

    const methods = useForm<SignupFormData>();
    const {handleSubmit} = methods;

    const [signup] = useMutation(SignupDocument);

    const onSubmit = async (formData: SignupFormData) => {
        await toastWrapper({
            onFulfilled: () => {
                onSuccess?.();
                navigate('/confirmation');
            },
            promise: signup({variables: {...formData}}),
        });
    };

    return (
        <div className="bg-body border-outline relative px-5 pb-5 pt-4 sm:min-w-[36rem] sm:max-w-xl sm:rounded-md sm:border sm:shadow-lg">
            {children ? (
                <div className="border-outline flex items-center justify-between gap-4 border-b px-0.5 pb-4">
                    <h2 className="text-lg">
                        {t('authentication.signup.title')}
                    </h2>
                    {children}
                </div>
            ) : (
                <h2 className="border-outline border-b pb-2 text-lg">
                    {t('authentication.signup.title')}
                </h2>
            )}
            <FormProvider {...methods}>
                <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    {message && <div>{message}</div>}
                    <InputEmail name="email" required={true} />
                    <InputPassword
                        autoComplete="new-password"
                        name="password"
                        required={true}
                        showPasswordRules={true}
                    />
                    <InputFullName />
                    <InputKanaName />
                    <YearMonthDay name="birthday" required={true} />
                    <Checkbox
                        label={t('authentication.signup.receiveNewsletter')}
                        name="newsletter"
                        size="sm"
                    />
                    <Checkbox
                        label={
                            <Trans
                                components={{
                                    privacy: (
                                        <a
                                            key="0"
                                            className="font-semibold"
                                            href="/privacy"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {' '}
                                        </a>
                                    ),
                                    required: (
                                        <span className="text-golden ml-1 select-none px-1 text-xs" />
                                    ),
                                    terms: (
                                        <a
                                            key="1"
                                            className="font-semibold"
                                            href="/terms"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {' '}
                                        </a>
                                    ),
                                }}
                                i18nKey="authentication.signup.privacyAndTerms"
                            />
                        }
                        name="privacy"
                        required={t('authentication.signup.privacyRequired')}
                        size="sm"
                    />
                    <SubmitButton className="mx-auto w-11/12 sm:w-1/2">
                        {t('authentication.signup.buttonTitle')}
                    </SubmitButton>
                </Form>
            </FormProvider>
            <div className="mt-6 text-center">
                <Button
                    className="mx-auto w-11/12 sm:w-3/4"
                    kind="tertiary"
                    onClick={onLogin}
                >
                    {t('authentication.signup.linkToLogin')}
                </Button>
            </div>
        </div>
    );
};

export default Signup;
