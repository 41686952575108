import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import Form from 'components/Form';
import InputPassword from 'components/Form/InputPassword';
import SubmitButton from 'components/Form/SubmitButton';
import {UpdatePasswordDocument} from 'gql/generated';
import {useToastNotification} from 'state/toastNotification';
import {FormData} from 'types';

type ChangePasswordFormData = FormData<{
    currentPassword: string;
    newPassword: string;
    passwordConfirmation: string;
}>;

export const ChangePasswordContent: FC = () => {
    const {t} = useTranslation();
    const {toastWrapper} = useToastNotification();

    const methods = useForm<ChangePasswordFormData>();
    const {handleSubmit, reset} = methods;

    const [updatePassword] = useMutation(UpdatePasswordDocument);

    const onSubmit = async (formData: ChangePasswordFormData) => {
        await toastWrapper({
            onFulfilled: () => reset(),
            promise: updatePassword({variables: {...formData}}),
            successMessage: t('user.changePassword.updated'),
        });
    };

    return (
        <FormProvider {...methods}>
            <Form className="gap-y-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-step flex flex-col gap-y-4 rounded-md p-4">
                    <InputPassword
                        autoComplete="current-password"
                        label={t('user.changePassword.currentPassword')}
                        name="currentPassword"
                        placeholder={t('user.changePassword.currentPassword')}
                        required={true}
                    />
                    <InputPassword
                        autoComplete="new-password"
                        className="mt-4"
                        label={t('user.changePassword.newPassword')}
                        name="newPassword"
                        placeholder={t('user.changePassword.newPassword')}
                        required={true}
                        showPasswordRules={true}
                    />
                    <InputPassword
                        autoComplete="new-password"
                        className="mt-4"
                        name="passwordConfirmation"
                        rules={{
                            required: true,
                            validate: (value: string) =>
                                value === methods.getValues('newPassword') ||
                                t('user.password.mismatch'),
                        }}
                        showPasswordRules={false}
                    />
                </div>
                <div className="flex justify-center pb-2 sm:pb-0 md:justify-end">
                    <SubmitButton
                        className="w-11/12 md:w-auto"
                        loading={t('user.changePassword.title')}
                    >
                        {t('form.save')}
                    </SubmitButton>
                </div>
            </Form>
        </FormProvider>
    );
};
