import {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import Link from 'components/Link';

type DesktopUserMenuItemProps = {
    children: ReactNode;
    onClick?: () => void;
    path?: string;
};

const DesktopUserMenuItem = ({
    children,
    onClick,
    path,
}: DesktopUserMenuItemProps) => {
    const {pathname} = useLocation();

    return (
        <li className="first:rounded-t first:pt-px last:rounded-b last:pb-px sm:hover:bg-gold-200 dark:sm:hover:bg-grey-800">
            {onClick ? (
                <button
                    className="hover:text-body inline-block w-40 py-1.5 pr-6 text-right"
                    onClick={onClick}
                >
                    {children}
                </button>
            ) : path && !pathname.includes(path) ? (
                path.startsWith('http') ? (
                    <a
                        className="link-item hover:text-body inline-block w-40 py-1.5 pr-6 text-right"
                        href={path}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {children}
                    </a>
                ) : (
                    <Link
                        className="link-item hover:text-body inline-block w-40 py-1.5 pr-6 text-right"
                        to={path}
                    >
                        {children}
                    </Link>
                )
            ) : (
                <div className="text-golden hover:text-body inline-block w-40 py-1.5 pr-6 text-right">
                    {children}
                </div>
            )}
        </li>
    );
};

export default DesktopUserMenuItem;
