import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';
import Cookies from 'js-cookie';
import en from './locale/en';
import ja from './locale/ja';

export const initializeI18n = () => {
    i18n.use(initReactI18next).init({
        fallbackLng: 'en',
        lng: Cookies.get('pocket_locale') || 'en',
        ns: ['common'],
        react: {
            useSuspense: false,
        },
        resources: {
            en: {common: en},
            ja: {common: ja},
        },
        supportedLngs: ['en', 'ja'],
    });
};

export default i18n;
