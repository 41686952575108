import {FC} from 'react';
import {Outlet} from 'react-router-dom';
import {Sections} from 'types/routes';
import UserAccountMenu from './UserAccountMenu';
import UserAccountMobileMenu from './UserAccountMobileMenu';

type MenuSidebarProps = {
    sections: Sections;
    showHeader?: boolean;
};

const MenuSidebarLayout: FC<MenuSidebarProps> = ({sections, showHeader}) => (
    <div className="split-view md:mt-4">
        <div className="split-view-narrow">
            <UserAccountMenu sections={sections} showHeader={showHeader} />
        </div>
        <div className="split-view-wide">
            <UserAccountMobileMenu sections={sections} />
            <Outlet />
        </div>
    </div>
);

export default MenuSidebarLayout;
