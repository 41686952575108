import {FC, ReactNode} from 'react';
import clsx from 'clsx';
import SkeletonField from '../SkeletonField';

export type SkeletonSelectProps = {
    className?: string;
    classNameInput?: string;
    helpText?: ReactNode;
    label?: ReactNode;
    required?: boolean;
    step?: boolean;
};

const SkeletonSelect: FC<SkeletonSelectProps> = ({
    className,
    classNameInput,
    helpText,
    label,
    required,
    step,
}) => (
    <SkeletonField
        className={className}
        helpText={helpText}
        label={label}
        required={required}
        step={step}
        type="select"
    >
        <div
            className={clsx(
                'skeleton h-[2.625rem] w-full',
                step && 'step',
                !classNameInput?.includes('rounded') && 'rounded',
                classNameInput
            )}
        />
    </SkeletonField>
);

export default SkeletonSelect;
