import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BreakpointWrapper from 'components/BreakpointWrapper';
import Server500 from 'components/Errors/Server500';
import ServiceError from 'components/Errors/ServiceError';
import DesktopReservationInformation from 'components/ReservationInformation/DesktopReservationInformation';
import {ReservationInformationProvider} from 'components/ReservationInformation/provider';
import ReserveConfirmation from 'components/Reserve/ReserveConfirmation';
import ReserveForm from 'components/Reserve/ReserveForm';
import ReserveDetailsSkeleton from 'components/Reserve/ReserveForm/ReserveDetails/ReserveDetailsSkeleton';
import {
    CreditCardSearchDocument,
    CreditCardSearchQuery,
    ReserveDocument,
    ReserveQuery,
} from 'gql/generated';
import {ReservationInformationType} from 'types/reservation';
import {getSafeReserveValues} from 'utils/params';

const ReservePage: FC = () => {
    const [searchParams] = useSearchParams();

    // The secret seat id can be anything, we therefore set it as a "true"-string; For normal seats it will not be present and thus null
    const secretSeatId = searchParams.get('isSecretSeat');

    const reserveValues = getSafeReserveValues(searchParams);

    const {courseId, venueId, ...values} = reserveValues || {};

    const {t} = useTranslation();
    const {data, error, loading} = useQuery<ReserveQuery>(ReserveDocument, {
        fetchPolicy: 'cache-and-network',
        variables: {courseId, venueId},
    });

    const venue = data?.venue;

    const course = data?.coursesSearch?.[0];

    const reservationInformation: ReservationInformationType = {
        course,
        values,
        venue,
    };

    const creditCardSearch = useQuery<CreditCardSearchQuery>(
        CreditCardSearchDocument,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                includeExpired: false,
            },
        }
    );

    const creditCards = creditCardSearch.data?.creditCardSearch || [];

    const [reservationId, setReservationId] = useState(() => '');

    if (!reserveValues) {
        return <Server500 error={new Error('invalid url search parameters')} />;
    }

    if (reservationId && course && data && values) {
        return (
            <ReserveConfirmation
                id={reservationId}
                values={values}
                venue={data.venue}
            />
        );
    }

    return (
        <ReservationInformationProvider initialState={reservationInformation}>
            <div className="split-view md:my-4">
                <div className="split-view-wide">
                    <BreakpointWrapper
                        breakpoint="md"
                        className="relative grid h-full grid-rows-mobile-2 overflow-hidden"
                    >
                        {error ? (
                            <ServiceError className="mt-4" error={error} />
                        ) : loading || !data?.user ? (
                            <ReserveDetailsSkeleton />
                        ) : course && values ? (
                            <ReserveForm
                                course={course}
                                creditCards={creditCards}
                                onConfirm={setReservationId}
                                secretSeatId={secretSeatId}
                                user={data.user}
                                values={values}
                                venue={data.venue}
                            />
                        ) : (
                            <div
                                className="bg-step mt-4 flex flex-col items-center justify-center py-6 text-sm md:text-base"
                                role="alert"
                            >
                                <FontAwesomeIcon
                                    className="text-invalid-step w-full"
                                    icon={['fas', 'exclamation-circle']}
                                    size="3x"
                                />
                                <div className="text-sub mt-3 text-center leading-loose">
                                    {t('errorCodes.invalidCourse')}
                                </div>
                            </div>
                        )}
                    </BreakpointWrapper>
                </div>
                <DesktopReservationInformation className="split-view-narrow" />
            </div>
        </ReservationInformationProvider>
    );
};

export default ReservePage;
