export const DefaultCardIcon = ({className = ''}: {className?: string}) => {
    return (
        <svg
            className={className}
            fill="none"
            height="16"
            viewBox="0 0 13 12"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.5 9L9.5 10L11.5 8M11.5 5H1.5M11.5 6V4.1C11.5 3.53995 11.5 3.25992 11.391 3.04601C11.2951 2.85785 11.1422 2.70487 10.954 2.60899C10.7401 2.5 10.4601 2.5 9.9 2.5H3.1C2.53995 2.5 2.25992 2.5 2.04601 2.60899C1.85785 2.70487 1.70487 2.85785 1.60899 3.04601C1.5 3.25992 1.5 3.53995 1.5 4.1V7.9C1.5 8.46005 1.5 8.74008 1.60899 8.95399C1.70487 9.14215 1.85785 9.29513 2.04601 9.39101C2.25992 9.5 2.53995 9.5 3.1 9.5H6.5"
                stroke="#EFEDEA"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
