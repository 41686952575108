import {FC, ReactNode} from 'react';
import clsx from 'clsx';

export type SkeletonTextProps = {
    children?: ReactNode;
    className?: string;
    step?: boolean;
};

const SkeletonText: FC<SkeletonTextProps> = ({children, className, step}) => (
    <div className={className}>
        <div className={clsx('skeleton inline-block', step && 'step')}>
            <span className="text-transparent">{children}</span>
        </div>
    </div>
);

export default SkeletonText;
