import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import ServiceError from 'components/Errors/ServiceError';
import NoResults from 'components/NoResults';
import Stripe from 'components/Stripe';
import {
    CreditCard,
    CreditCardSearchDocument,
    CreditCardSearchQuery,
} from 'gql/generated';
import {PaymentMethodsList} from './PaymentMethodsList';
import UserPaymentMethodsSkeleton from './PaymentMethodsSkeleton';
import UserPaymentStripeForm from './PaymentStripeForm';

const UserPaymentMethods: FC = () => {
    const {t} = useTranslation();

    const methods = useForm();

    const {data, error, loading} = useQuery<CreditCardSearchQuery>(
        CreditCardSearchDocument,
        {
            variables: {
                includeExpired: true,
            },
        }
    );

    return (
        <FormProvider {...methods}>
            <div className="bg-body md:border-outline mt-4 px-4 md:rounded-md md:border md:p-4 lg:p-5">
                <h2 className="px-4 pb-1 text-base font-semibold md:block md:px-0">
                    {t('user.paymentMethods.addNewCard')}
                </h2>
                <hr className="w-full py-2" />
                {loading ? (
                    <UserPaymentMethodsSkeleton />
                ) : error ? (
                    <ServiceError className="mt-4" error={error} />
                ) : (
                    <Stripe>
                        <div className="bg-step mb-8 rounded-md p-4">
                            <UserPaymentStripeForm />
                        </div>
                        <h2 className="px-4 pb-1 text-base font-semibold md:block md:px-0">
                            {t('user.paymentMethods.cardList')}
                        </h2>
                        <hr className="w-full py-2" />
                        {data?.creditCardSearch &&
                        data?.creditCardSearch.length > 0 ? (
                            <PaymentMethodsList
                                creditCardData={
                                    data.creditCardSearch as CreditCard[]
                                }
                                isSelectable={false}
                            />
                        ) : (
                            <NoResults
                                icon="reservations"
                                label={t('user.paymentMethods.noCards')}
                            />
                        )}
                    </Stripe>
                )}
            </div>
        </FormProvider>
    );
};

export default UserPaymentMethods;
