/* eslint-disable @typescript-eslint/no-explicit-any */
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import {ID, ItemType} from 'types';
import {gen, rng} from './rng';

export const getRange = (start: number, end: number): number[] =>
    Array(end - start + 1)
        .fill(start)
        .map((value, index) => value + index);

export const shuffle = (array: any[], seed = Date.now()): any[] => {
    const result = [...array];
    const length = result.length - 1;

    // eslint-disable-next-line no-plusplus
    for (let index = length; index > -1; index--) {
        const next = rng(seed, length + 1);
        [result[index], result[next]] = [result[next], result[index]];
        // eslint-disable-next-line no-param-reassign
        seed = gen(seed);
    }

    return result;
};

export const randomItem = (array: any[], seed = Date.now()): any =>
    array[rng(seed, array.length)];

export const numericSort = (array: number[]): number[] =>
    [...array].sort((a, b) => a - b);

export const sum = (array: number[]): number =>
    array.reduce((acc, value) => acc + value, 0);

export const toRecord = (array: ItemType[]): Record<ID, string> =>
    array.reduce((acc, item) => {
        acc[item.id] = item.name;

        return acc;
    }, {} as Record<ID, string>);

export const toSelectOptions = (
    array: ItemType[],
    icon?: IconProp
): SelectOption[] =>
    array.map((item) => ({
        icon,
        label: item.name,
        value: String(item.id),
    })) as SelectOption[];
