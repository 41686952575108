import {useEffect, useState} from 'react';
import useCurrentBreakpoint from './useCurrentBreakpoint';

const useBreakpointChange = (callback: () => void) => {
    const breakpoint = useCurrentBreakpoint();
    const [lastBreakpoint, setLastBreakpoint] = useState('');

    useEffect(() => {
        if (breakpoint !== lastBreakpoint) {
            // only trigger callback after first time
            if (lastBreakpoint) callback();
            setLastBreakpoint(breakpoint);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, lastBreakpoint]);
};

export default useBreakpointChange;
