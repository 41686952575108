import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseSelectProps} from 'components/Form/Base/BaseSelect';
import Select from 'components/Form/Select';
import {ItemType} from 'types';
import {toSelectOptions} from 'utils/array';

export type AreaSelectProps = {areas: ItemType[]} & Omit<
    BaseSelectProps,
    'name' | 'options'
>;

const AreaSelect: FC<AreaSelectProps> = (props) => {
    const {t} = useTranslation();
    const {areas} = props;

    const options = toSelectOptions(areas, ['fas', 'map-marker-alt']);

    return (
        <Select
            aria-label={t('search.selectArea')}
            data-dd-privacy="allow"
            unselected={t('search.selectArea')}
            unselectedIcon={['fas', 'map-marker-alt']}
            {...props}
            name="area"
            options={options}
        />
    );
};

export default AreaSelect;
