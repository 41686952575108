import {createContext, FC, ReactNode, useContext, useMemo} from 'react';
import {ReservationInformationType} from 'types/reservation';

const ReservationInformationContext = createContext<ReservationInformationType>(
    {}
);

export const useReservationInformationState =
    (): ReservationInformationType => {
        const context = useContext(ReservationInformationContext);

        if (context === undefined) {
            throw new Error(
                'useReservationInformationState must be used within a ReservationInformationProvider'
            );
        }

        return context;
    };

export type ReservationInformationProviderProps = {
    children: ReactNode;
    initialState: ReservationInformationType;
};

export const ReservationInformationProvider: FC<
    ReservationInformationProviderProps
> = ({children, initialState}) => {
    const state = useMemo(() => initialState, [initialState]);

    return (
        <ReservationInformationContext.Provider value={state}>
            {children}
        </ReservationInformationContext.Provider>
    );
};
