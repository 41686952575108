/* eslint-disable react/boolean-prop-naming */
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {endOfDay, isAfter, isBefore, startOfDay} from 'date-fns';
import usePanel from 'hooks/usePanel';
import {hasOwn} from 'hooks/useServerErrorHandling';
import {useLocale} from 'state/locale';
import {AvailabilityDates} from 'types/reservation';
import {getDateString} from 'utils/date';
import DateSelectorPanel from './DateSelectorPanel';
import {getSafeDate} from './utils';

export type DateSelectorChangeEvent = {
    id: string;
    value?: Date;
};

export type DateSelectorProps = {
    availabilityDates?: AvailabilityDates;
    className?: string;
    closeOnSelect?: boolean;
    /**
     * https://date-fns.org/v1.28.5/docs/format
     * @default 'eee MMM dd, yyyy'
     */
    disabled?: boolean;
    id: string;
    isClearable?: boolean;
    /**
     * Restrict dates to before
     */
    maxDate?: Date;
    /**
     * Restrict dates to after
     */
    minDate?: Date;
    /**
     * @type ({id: string, value?: Date}) => void
     * @default () => {}
     */
    onChange?: (event: DateSelectorChangeEvent) => void;
    showFullDate?: boolean;
    unselected?: string;
    value?: Date;
};

const DateSelector: FC<DateSelectorProps> = ({
    availabilityDates,
    className,
    closeOnSelect = false,
    disabled,
    id,
    isClearable = false,
    maxDate,
    minDate,
    onChange = () => {},
    showFullDate = false,
    unselected,
    value,
}) => {
    const locale = useLocale();
    const {t} = useTranslation();

    const [date, setDate] = useState<Date | undefined>(undefined);
    const [isCloseOnSelect, setIsCloseOnSelect] = useState(closeOnSelect);

    const {
        containerRef,
        isDesktop,
        isFocused,
        setIsFocused,
        showPanel,
        togglePanel,
    } = usePanel();

    const minimumDate = useMemo(
        () => (minDate ? startOfDay(minDate) : undefined),
        [minDate]
    );
    const maximumDate = useMemo(
        () => (maxDate ? endOfDay(maxDate) : undefined),
        [maxDate]
    );

    const onClearDate = useCallback(() => {
        setDate(undefined);
        onChange({id, value: undefined});
        togglePanel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (value) {
            const validDate =
                !minimumDate || isAfter(value, minimumDate)
                    ? !maximumDate || isBefore(value, maximumDate)
                        ? value
                        : maximumDate
                    : minimumDate;
            setDate(getSafeDate(validDate));
        }
    }, [maximumDate, minimumDate, value]);

    useEffect(() => {
        const onResize = () => {
            setIsCloseOnSelect(closeOnSelect || isDesktop);
        };
        onResize();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, [closeOnSelect, isDesktop]);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            // keydown is also called for other events therefore we need to check for the key property
            if (!hasOwn(event, 'key')) return;

            if (event.key.includes('Arrow')) {
                if (!showPanel && event.key === 'ArrowDown') {
                    togglePanel();
                }
                event.preventDefault();
            } else if (event.key === 'Enter' || event.key === ' ') {
                togglePanel();
                event.preventDefault();
            } else if (event.key === 'Escape' || event.key === 'Tab') {
                if (showPanel) togglePanel();

                if (event.key === 'Tab') setIsFocused(false);
                else onClearDate();
            }
        };

        if (isFocused) {
            document.addEventListener('keydown', onKeyDown);
        } else {
            document.removeEventListener('keydown', onKeyDown);
        }

        return () => document.removeEventListener('keydown', onKeyDown);
    }, [id, isFocused, onClearDate, setIsFocused, showPanel, togglePanel]);

    const onBlur = () => setIsFocused(false);
    const onFocus = () => setIsFocused(true);

    const onDateClick = useCallback(
        (next: Date) => {
            setDate(next);
            onChange({id, value: next});

            if (isCloseOnSelect) {
                togglePanel();
            }
        },
        [id, isCloseOnSelect, onChange, togglePanel]
    );

    const text = useMemo(
        () =>
            date
                ? getDateString(date, locale, showFullDate)
                : unselected || t('search.selectDate'),
        [date, locale, showFullDate, t, unselected]
    );

    return (
        <div className={clsx('relative select-none', className)}>
            <div
                ref={containerRef}
                className="relative"
                onBlur={onBlur}
                onFocus={onFocus}
            >
                <div className="relative flex w-full">
                    <button
                        aria-label={t('search.selectDate')}
                        className={clsx(
                            'input-button relative min-w-[9.25rem] flex-1 cursor-pointer text-left',
                            disabled
                                ? 'text-disabled cursor-not-allowed'
                                : !date && 'text-grey-500 dark:text-grey-600'
                        )}
                        data-dd-action-name="Calendar"
                        data-dd-privacy="allow"
                        disabled={disabled}
                        onClick={togglePanel}
                        type="button"
                    >
                        <FontAwesomeIcon icon={['far', 'calendar']} />
                        <span className="ml-2">{text}</span>
                    </button>
                </div>
                {showPanel && (
                    <DateSelectorPanel
                        availabilityDates={availabilityDates}
                        date={date}
                        id={id}
                        isClearable={isClearable}
                        isCloseOnSelect={isCloseOnSelect}
                        isDesktop={isDesktop}
                        maxDate={maximumDate}
                        minDate={minimumDate}
                        onClearDate={onClearDate}
                        onDateClick={onDateClick}
                        onToggle={togglePanel}
                    />
                )}
            </div>
        </div>
    );
};

export default DateSelector;
