import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import clsx from 'clsx';
import {FormContextString} from '../types';

export type MaxLengthProps = {
    className?: string;
    maxLength: number;
    name: string;
};

const MIN_WIDTHS = [0, 34, 49, 65, 80, 95, 100, 111];

const MaxLength: FC<MaxLengthProps> = ({className, maxLength, name}) => {
    const {getValues, watch} = useFormContext<FormContextString>();

    watch(name);

    const length = getValues(name)?.length || '0';
    const minWidth = MIN_WIDTHS[String(maxLength).length];

    return (
        <div
            className={clsx(
                'flex-initial select-none px-1 pt-0.5 text-right text-xs',
                length === maxLength && 'text-invalid',
                className
            )}
            style={{minWidth}}
        >
            {length} / {maxLength}
        </div>
    );
};

export default MaxLength;
