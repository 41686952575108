import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {VenueWithCoursesFragment} from 'gql/generated';

export type VenueBasicProps = {
    venue: VenueWithCoursesFragment;
};

const VenueBasic: FC<VenueBasicProps> = ({venue}) => {
    const {t} = useTranslation();

    const businessHours = venue.businessHours?.split('\r\n').filter(Boolean);

    return (
        <ul className="space-y-4 break-words">
            <li>
                <h4 className="text-sm font-semibold">{t('venue.name')}</h4>
                <span>{venue.name}</span>
            </li>
            <li>
                <h4 className="text-sm font-semibold">{t('venue.cuisine')}</h4>
                <span>{venue.cuisines.map(({name}) => name).join(', ')}</span>
            </li>
            {businessHours && (
                <li>
                    <h4 className="text-sm font-semibold">
                        {t('venue.businessHours')}
                    </h4>
                    {businessHours.map((hours) =>
                        hours ? (
                            <span key={hours} className="block">
                                {hours}
                            </span>
                        ) : null
                    )}
                </li>
            )}
            {venue.holidays && (
                <li>
                    <h4 className="text-sm font-semibold">
                        {t('venue.holidays')}
                    </h4>
                    <span>{venue.holidays}</span>
                </li>
            )}
            {venue.websiteUrl && (
                <li>
                    <h4 className="text-sm font-semibold">
                        {t('venue.website')}
                    </h4>
                    <a
                        className="hover:underline"
                        href={venue.websiteUrl}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {venue.websiteUrl}
                    </a>
                </li>
            )}
        </ul>
    );
};

export default VenueBasic;
