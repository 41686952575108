import {FC} from 'react';
import {useSearchParams} from 'react-router-dom';
import clsx from 'clsx';
import Link from 'components/Link';
import {Cuisine} from 'gql/generated';

export type VenueCuisinesProps = {
    className?: string;
    cuisines: Cuisine[];
    isClickable?: boolean;
};

const VenueCuisines: FC<VenueCuisinesProps> = ({
    className,
    cuisines,
    isClickable = false,
}) => {
    const [searchParams] = useSearchParams();
    const params = searchParams.toString();

    if (!isClickable) {
        return (
            <div className={clsx('text-sub text-xs', className)}>
                {cuisines
                    .map(({name}) => name.split(',').join(', '))
                    .join(', ')}
            </div>
        );
    }

    const cuisineLinks = cuisines.map(({id, name}) => {
        const cuisineIdString = `cuisine=${id}`;

        return (
            <Link
                key={name}
                to={`/restaurants?${params}${
                    params.includes(cuisineIdString)
                        ? ''
                        : `&${cuisineIdString}`
                }`}
            >
                {name.split(',').join(', ')}
            </Link>
        );
    });

    return (
        <div className={clsx('text-sub text-xs', className)}>
            {cuisineLinks}
        </div>
    );
};

export default VenueCuisines;
