import {FC} from 'react';
import clsx from 'clsx';
import SkeletonText from '../SkeletonText';

const sizes = {
    md: 'overflow-hidden h-5 text-base',
    sm: 'overflow-hidden h-4 text-sm',
};

export type SkeletonToggleSwitchProps = {
    className?: string;
    isLeft?: boolean;
    label: string;
    size?: 'sm' | 'md';
    step?: boolean;
};

const SkeletonToggleSwitch: FC<SkeletonToggleSwitchProps> = ({
    className,
    isLeft,
    label,
    size = 'md',
    step,
}) => (
    <div className={className}>
        <div
            className={clsx(
                'flex items-center',
                isLeft ? 'flex-row-reverse' : 'justify-between'
            )}
        >
            <SkeletonText
                className={clsx(sizes[size], isLeft && 'ml-3')}
                step={step}
            >
                {label}
            </SkeletonText>
            <div
                className={clsx(
                    'skeleton relative rounded-full',
                    size === 'md' ? 'h-6 w-11' : 'h-5 w-9'
                )}
            >
                <div
                    className={clsx(
                        'absolute top-0.5 left-0.5 rounded-full bg-grey-100 dark:bg-grey-800',
                        size === 'md' ? 'h-5 w-5' : 'h-4 w-4'
                    )}
                />
            </div>
        </div>
    </div>
);

export default SkeletonToggleSwitch;
