/* eslint-disable react/boolean-prop-naming */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

export type VenueTagsProps = {
    className?: string;
    realTimeBooking?: boolean;
};

const VenueTags: FC<VenueTagsProps> = ({className, realTimeBooking}) => {
    const {t} = useTranslation();

    return realTimeBooking ? (
        <div
            className={clsx(
                'gap-3 text-xs font-semibold text-white dark:text-grey-900',
                className
            )}
        >
            <div className="rounded-md bg-gold-200 px-2 py-px">
                {t('search.realTimeBooking')}
            </div>
        </div>
    ) : null;
};

export default VenueTags;
