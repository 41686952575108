import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import BreakpointWrapper from 'components/BreakpointWrapper';
import Skeleton from 'components/Loaders/Skeleton';
import SkeletonButton from 'components/Loaders/Skeleton/SkeletonButton';
import SkeletonInput from 'components/Loaders/Skeleton/SkeletonInput';
import SkeletonRadioButtons from 'components/Loaders/Skeleton/SkeletonRadioButtons';
import SkeletonSelect from 'components/Loaders/Skeleton/SkeletonSelect';
import SkeletonText from 'components/Loaders/Skeleton/SkeletonText';
import SkeletonToggleSwitch from 'components/Loaders/Skeleton/SkeletonToggleSwitch';
import MobileReservationInformation from 'components/ReservationInformation/MobileReservationInformation';
import {VisitFrequency} from 'gql/generated';
import useBreakpoint from 'hooks/useBreakpoint';
import {useLocale} from 'state/locale';
import {useUser} from 'state/user';
import {Option} from 'types';
import ReserveSteps from '../../ReserveSteps';

const ReserveDetailsSkeleton: FC = () => {
    const {t} = useTranslation();
    const locale = useLocale();

    const user = useUser();
    const fullName = user ? t('user.fullName', user) : undefined;

    const isDesktop = useBreakpoint('md');
    const isVisitFrequencyHorizontal = useBreakpoint('lg');

    return (
        <>
            <ReserveSteps
                isRealTimeBooking={false}
                onBack={() => {}}
                step={1}
            />
            <Skeleton className="md:border-outline grid grid-rows-mobile-2-reverse overflow-hidden md:mt-4 md:block md:overflow-auto md:rounded-md md:border md:p-5">
                <BreakpointWrapper
                    breakpoint="md"
                    className="overflow-y-auto pt-4"
                >
                    <MobileReservationInformation />
                    <SkeletonText className="md:border-outline mt-2 px-4 pb-1.5 md:border-b md:px-0">
                        {t('reserve.details.contactInformation')}
                    </SkeletonText>
                    <div className="bg-step space-y-6 px-4 py-3 md:mt-2">
                        <SkeletonRadioButtons
                            isHorizontal={true}
                            label={t('reserve.details.onBehalfOf.label')}
                            options={[
                                {
                                    label: t(
                                        'reserve.details.onBehalfOf.myself'
                                    ),
                                    value: 'false',
                                },
                                {
                                    label: t(
                                        'reserve.details.onBehalfOf.anotherPerson'
                                    ),
                                    value: 'true',
                                },
                            ]}
                            size="sm"
                            step={true}
                        />
                        <div className="mt-2">
                            <SkeletonText className="text-sm" step={true}>
                                {t('name')}
                            </SkeletonText>
                            <SkeletonText
                                className="text-sm font-semibold"
                                step={true}
                            >
                                {fullName}
                            </SkeletonText>
                        </div>
                        {locale === 'ja' && (
                            <div className="flex gap-2 lg:gap-4">
                                <SkeletonInput
                                    className="flex-1"
                                    label={t(
                                        'user.basicInformation.name.last.kana'
                                    )}
                                    required={true}
                                    step={true}
                                />
                                <SkeletonInput
                                    className="flex-1"
                                    label={t(
                                        'user.basicInformation.name.first.kana'
                                    )}
                                    required={true}
                                    step={true}
                                />
                            </div>
                        )}
                        <div className="flex flex-col gap-4 lg:flex-row">
                            <SkeletonInput
                                className="lg:flex-1"
                                label={t(
                                    'user.basicInformation.phoneNumber.label'
                                )}
                                required={true}
                                step={true}
                            />
                            <div className="hidden lg:block lg:flex-1" />
                        </div>
                        <div className="mt-4 flex flex-col md:flex-row md:gap-2 lg:gap-4">
                            <SkeletonSelect
                                className="md:flex-1"
                                label={t('reserve.details.visitPurpose.label')}
                                required={true}
                                step={true}
                            />
                            <div className="hidden lg:block lg:flex-1" />
                        </div>
                        <SkeletonRadioButtons
                            isHorizontal={isVisitFrequencyHorizontal}
                            label={t('reserve.details.visitFrequency.label')}
                            options={
                                Object.values(VisitFrequency).map((value) => ({
                                    label: t(
                                        `reserve.details.visitFrequency.${String(
                                            value
                                        )}`
                                    ),
                                    value,
                                })) as Option[]
                            }
                            required={true}
                            size="sm"
                            step={true}
                        />
                        <div className="mt-4">
                            <SkeletonRadioButtons
                                isHorizontal={true}
                                label={t('reserve.details.hotel.label')}
                                options={[
                                    {
                                        label: t('form.no'),
                                        value: '',
                                    },
                                    {
                                        label: t('form.yes'),
                                        value: 'yes',
                                    },
                                ]}
                                size="sm"
                                step={true}
                            />
                        </div>
                    </div>
                    <div className="mt-6">
                        <SkeletonText className="md:border-outline px-4 pb-1.5 md:border-b md:px-0">
                            {t('reserve.details.specialRequestOption.label')}
                        </SkeletonText>
                        <div className="bg-step space-y-4 px-4 py-3 md:mt-2">
                            <SkeletonSelect step={true} />
                        </div>
                    </div>
                    <div className="mt-6">
                        <SkeletonText className="md:border-outline px-4 pb-1.5 md:mt-6 md:border-b md:px-0">
                            {t('reserve.details.preferences')}
                        </SkeletonText>
                        <div className="md:mt-2">
                            <SkeletonToggleSwitch
                                className="flex px-4 py-3"
                                isLeft={true}
                                label={t('reserve.details.allergy')}
                                size="sm"
                            />
                            <SkeletonToggleSwitch
                                className="flex px-4 py-3"
                                isLeft={true}
                                label={t('reserve.details.memo')}
                                size="sm"
                            />
                        </div>
                    </div>
                </BreakpointWrapper>
                <div className="shadow-top md:bg-body z-20 p-4 md:mt-4 md:flex md:justify-end md:px-0 md:pb-0 md:shadow-none">
                    <SkeletonButton
                        className="w-full md:w-auto"
                        step={!isDesktop}
                    >
                        {t('proceed')}
                    </SkeletonButton>
                </div>
            </Skeleton>
        </>
    );
};

export default ReserveDetailsSkeleton;
