import {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import {trackClick} from 'utils/analytics';
import transparentImg from '../transparent.webp';

type MarketingCardProps = {
    assetName?: string;
    className?: string;
    imageClassName?: string;
    imageURL: string | undefined;
    isExternal?: boolean;
    path: string | undefined;
};

/*
 * Generic Marketing Card mainly used on the Venue Search Page and Venue Page
 */
const MarketingCard: FC<MarketingCardProps> = ({
    assetName,
    className = '',
    imageClassName = '',
    imageURL,
    isExternal = false,
    path = '',
}) => {
    const [imageError, setImageError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const imageName = imageURL?.split('/')?.pop() || 'marketing-material';

    if (imageError) {
        return null;
    }

    return (
        <Link
            aria-label={imageName}
            className={`border-venue-result flex select-none flex-col overflow-hidden rounded ${className} ${
                isLoading ? 'grow' : ''
            }`}
            data-dd-action-name={assetName || imageName}
            onClick={() => trackClick(assetName || imageName)}
            rel={isExternal ? 'noopener noreferrer' : undefined}
            reloadDocument={true}
            target={isExternal ? '_blank' : undefined}
            to={path}
        >
            <figure className="relative">
                <picture>
                    {(!imageURL || !path || isLoading) && (
                        <img
                            alt="loading"
                            className={`skeleton w-full ${imageClassName}`}
                            src={transparentImg}
                        />
                    )}
                    {imageURL && path && (
                        <img
                            alt={imageName}
                            className={`${imageClassName} ${
                                isLoading ? 'invisible' : ''
                            }`}
                            onError={() => setImageError(true)}
                            onLoad={() => setIsLoading(false)}
                            src={imageURL}
                        />
                    )}
                </picture>
            </figure>
        </Link>
    );
};

export default MarketingCard;
