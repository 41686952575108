import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import useBreakpoint from 'hooks/useBreakpoint';
import SkeletonRadioButtons from '../SkeletonRadioButtons';

export type SkeletonGenderSelectorProps = {
    className?: string;
    required?: boolean;
    step?: boolean;
};

const SkeletonGenderSelector: FC<SkeletonGenderSelectorProps> = ({
    className,
    required,
    step,
}) => {
    const {t} = useTranslation();

    const isDesktop = useBreakpoint('sm');

    const options = useMemo(
        () => [
            {label: t('user.basicInformation.gender.male'), value: 'MALE'},
            {label: t('user.basicInformation.gender.female'), value: 'FEMALE'},
            {
                label: t('user.basicInformation.gender.preferNotToSay'),
                value: 'UNKNOWN',
            },
        ],
        [t]
    );

    return (
        <SkeletonRadioButtons
            className={clsx(className)}
            isHorizontal={isDesktop}
            label={t('user.basicInformation.gender.label')}
            options={options}
            required={required}
            size={isDesktop ? 'base' : 'sm'}
            step={step}
        />
    );
};

export default SkeletonGenderSelector;
