import {FC, MutableRefObject, ReactNode} from 'react';

type Props = {
    children: ReactNode | ReactNode[];
    elementRef: MutableRefObject<HTMLDivElement | null>;
    title: string;
};

export const SegmentContainer: FC<Props> = ({children, elementRef, title}) => (
    <div
        ref={elementRef}
        className="md:border-outline bg-body px-4 pt-4 md:rounded-md md:border md:p-4 lg:p-5"
    >
        <h2 className="px-4 pb-1 text-base font-semibold md:block md:px-0">
            {title}
        </h2>
        <hr className="w-full py-2" />
        {children}
    </div>
);
