import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {useLocale} from 'state/locale';
import InputText from '../InputText';

export type InputFullNameProps = {
    className?: string;
};

const InputFullName: FC<InputFullNameProps> = ({className}) => {
    const locale = useLocale();
    const {t} = useTranslation();

    return (
        <div className={clsx('flex gap-2 sm:gap-4', className)}>
            <InputText
                autoComplete={locale === 'ja' ? 'family-name' : 'given-name'}
                className="flex-1"
                hideMaxLength={true}
                label={t(
                    locale === 'ja'
                        ? 'user.basicInformation.name.last.label'
                        : 'user.basicInformation.name.first.label'
                )}
                maxLength={40}
                name={locale === 'ja' ? 'lastName' : 'firstName'}
                placeholder={t(
                    locale === 'ja'
                        ? 'user.basicInformation.name.last.placeholder'
                        : 'user.basicInformation.name.first.placeholder'
                )}
                required={true}
            />
            <InputText
                autoComplete={locale === 'en' ? 'family-name' : 'given-name'}
                className="flex-1"
                hideMaxLength={true}
                label={t(
                    locale === 'en'
                        ? 'user.basicInformation.name.last.label'
                        : 'user.basicInformation.name.first.label'
                )}
                maxLength={40}
                name={locale === 'en' ? 'lastName' : 'firstName'}
                placeholder={t(
                    locale === 'en'
                        ? 'user.basicInformation.name.last.placeholder'
                        : 'user.basicInformation.name.first.placeholder'
                )}
                required={true}
            />
        </div>
    );
};

export default InputFullName;
