import {FC, ReactNode} from 'react';
import clsx from 'clsx';
import styles from './styles.module.css';

export type ChainProps = {
    children: ReactNode;
    className?: string;
    isFullWidth?: boolean;
};

const Chain: FC<ChainProps> = ({children, className, isFullWidth}) => (
    <div
        className={clsx(
            styles.chain,
            isFullWidth && styles.fullWidth,
            className
        )}
        role="group"
    >
        {children}
    </div>
);

export default Chain;
