import {useCallback, useEffect, useRef, useState} from 'react';

const usePanelToggle = (
    isDesktop: boolean,
    closeInstantly?: boolean
): [boolean, () => void] => {
    const portalTimeoutRef = useRef<number | null>(null);
    const panelTimeoutRef = useRef<number | null>(null);
    const hiddenTimeoutRef = useRef<number | null>(null);

    const [showPortal, setShowPortal] = useState(false);
    const [showPanel, setShowPanel] = useState(false);

    useEffect(
        () => () => {
            // when component unmounts, clear pending timers and close portal
            if (portalTimeoutRef.current) {
                clearTimeout(portalTimeoutRef.current);
            }

            if (panelTimeoutRef.current) {
                clearTimeout(panelTimeoutRef.current);
            }

            if (hiddenTimeoutRef.current) {
                clearTimeout(hiddenTimeoutRef.current);
            }
            document.documentElement.classList.remove(
                'portal-in',
                'portal-open',
                'portal-visible'
            );
        },
        []
    );

    useEffect(() => {
        if (portalTimeoutRef.current) {
            clearTimeout(portalTimeoutRef.current);
        }

        if (hiddenTimeoutRef.current) {
            clearTimeout(hiddenTimeoutRef.current);
        }

        if (!showPortal && (closeInstantly || isDesktop)) {
            document.documentElement.classList.remove(
                'portal-in',
                'portal-open',
                'portal-visible'
            );
        } else if (!isDesktop) {
            if (showPortal) {
                document.documentElement.classList.add(
                    'portal-open',
                    'portal-in'
                );
                // wait for portal to slide in
                hiddenTimeoutRef.current = window.setTimeout(() => {
                    document.documentElement.classList.add('portal-visible');
                }, 400);
            } else {
                document.documentElement.classList.remove(
                    'portal-in',
                    'portal-visible'
                );

                if (document.documentElement.hasAttribute('portal-open')) {
                    // wait for portal to slide out
                    portalTimeoutRef.current = window.setTimeout(() => {
                        document.documentElement.classList.remove(
                            'portal-open'
                        );
                    }, 100);
                }
            }
        }
    }, [closeInstantly, isDesktop, showPortal]);

    const togglePanel = useCallback(() => {
        if (panelTimeoutRef.current) {
            clearTimeout(panelTimeoutRef.current);
        }
        setShowPortal((prev) => {
            const next = !prev;
            panelTimeoutRef.current = window.setTimeout(
                () => setShowPanel(next),
                Number(prev) * 100
            );

            return next;
        });
    }, []);

    return [showPanel, togglePanel];
};

export default usePanelToggle;
