/* eslint-disable react/no-danger */
import {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Maybe} from 'gql/generated';
import useNavigateLocale from 'hooks/useNavigateLocale';

type RedirectCountdownProps = {
    className?: string;
    message: string;
    redirectUrl?: Maybe<string>;
};

const RedirectCountdown: FC<RedirectCountdownProps> = ({
    className,
    message,
    redirectUrl,
}) => {
    const {t} = useTranslation();
    const navigate = useNavigateLocale();

    const intervalRef = useRef<number | null>(null);
    const [seconds, setSeconds] = useState(() => 10);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (redirectUrl) {
            intervalRef.current = window.setInterval(
                () => setSeconds((p) => p - 1),
                1000
            );
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [redirectUrl]);

    useEffect(() => {
        if (seconds === 0) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            if (redirectUrl) {
                navigate(redirectUrl, {replace: true});
            }
        }
    }, [navigate, redirectUrl, seconds]);

    return (
        <div className={className}>
            <p>{message}</p>
            <br />
            <p
                dangerouslySetInnerHTML={{
                    __html: t('redirectCountdown', {
                        seconds,
                    }),
                }}
            />
        </div>
    );
};

export default RedirectCountdown;
