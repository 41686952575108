import {FC} from 'react';

export type PocketConciergeLogoProps = {
    className?: string;
    fill?: string;
};

const PocketConciergeLogo: FC<PocketConciergeLogoProps> = ({
    className,
    fill = '#1c273a',
}) => (
    <svg
        className={className}
        version="1.1"
        viewBox="0 0 320 60"
        x="0px"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        y="0px"
    >
        <g>
            <path
                d="M45.69,6.5H13.42c-0.41,0-0.83,0.16-1.14,0.47c-0.35,0.35-0.5,0.81-0.46,1.27l-0.01,0.01l0.02,27.01
c0.01,0.39,0.17,0.78,0.46,1.07c0.05,0.05,0.11,0.09,0.17,0.14l12.61,8.44l0,0c0.26,0.19,0.58,0.3,0.93,0.3
c0.31,0,0.59-0.09,0.84-0.24h0.02l13.19-8.78l0.01-0.02c0.09-0.07,0.15-0.18,0.15-0.3l0.01-0.01l0-3.76c0-0.22-0.18-0.4-0.4-0.4
c-0.09,0-0.16,0.03-0.23,0.08l-0.01,0l-13.17,8.77c-0.26,0.16-0.58,0.15-0.83,0l-9.4-5.87h0c-0.22-0.13-0.37-0.37-0.38-0.65V11.33
c0.03-0.42,0.37-0.75,0.79-0.75l0,0h25.72v0c0.38,0,0.7,0.26,0.78,0.62v26.32l-0.01,0c0,0.3-0.17,0.56-0.41,0.7L26.31,48.88
c-0.22,0.09-0.47,0.07-0.68-0.05L9.26,38.16c-0.18-0.13-0.3-0.33-0.33-0.56V6.9c0,0,0,0,0,0c0-0.22-0.18-0.4-0.4-0.4c0,0,0,0,0,0
H5.12v0c-0.1,0-0.21,0.04-0.29,0.12C4.75,6.7,4.71,6.81,4.72,6.92L4.72,6.93v31.9l0.01,0.01c0.02,0.38,0.17,0.75,0.46,1.04
c0.1,0.1,0.21,0.17,0.32,0.24l0.01,0.02L25,53.14c0.28,0.23,0.63,0.37,1.01,0.37c0.38,0,0.73-0.14,1.01-0.36
c0,0,19.73-13.16,19.82-13.25c0.27-0.27,0.41-0.61,0.45-0.96L47.3,8.31c0.01-0.63-0.11-0.98-0.47-1.34
C46.52,6.65,46.1,6.5,45.69,6.5z"
                fill={fill}
            />
            <g>
                <polygon
                    fill={fill}
                    points="129.05,18.23 126.95,18.23 118,29.13 118,18.23 116.23,18.23 116.23,38.53 118,38.53 118,32.03
118.01,32.03 120.22,29.27 127.09,38.53 129.19,38.53 121.49,27.68 		"
                />
                <path
                    d="M87.34,17.94c-6.23,0-7.89,3.87-7.89,10.54c0,6.75,1.63,10.34,7.89,10.34c6.26,0,7.92-3.38,7.92-10.34
C95.26,21.76,93.57,17.94,87.34,17.94z M87.34,37.11c-5.01,0-6.03-2.94-6.03-8.62c0-5.65,1.08-8.83,6.03-8.83
c4.92,0,6.06,3.21,6.06,8.83C93.39,34.37,92.38,37.11,87.34,37.11z"
                    fill={fill}
                />
                <path
                    d="M106.47,19.66c2.15,0,5.24,0.55,5.24,0.55l0.12-1.66c0,0-2.8-0.61-5.5-0.61c-6.06,0-7.34,3.85-7.34,10.51
c0,6.58,1.31,10.37,7.34,10.37c2.3,0,5.47-0.64,5.47-0.64l-0.08-1.63c0,0-3.29,0.52-5.21,0.52c-4.87,0-5.65-3.03-5.65-8.62
C100.85,22.78,101.61,19.66,106.47,19.66z"
                    fill={fill}
                />
                <path
                    d="M69.4,18.23h-7.37v20.3h1.78v-7.05h5.59c4.34,0,6.29-2.56,6.29-6.9C75.69,20.24,73.74,18.23,69.4,18.23z
M69.4,29.77H63.8v-9.85h5.59c3.14,0,4.46,1.49,4.46,4.66C73.86,27.79,72.54,29.77,69.4,29.77z"
                    fill={fill}
                />
                <polygon
                    fill={fill}
                    points="216.15,34.28 215.66,34.28 209.25,18.38 203.72,18.38 203.72,38.53 207.13,38.53 207.13,22.63
207.62,22.63 214.2,38.53 219.59,38.53 219.59,18.38 216.15,18.38 		"
                />
                <path
                    d="M231.74,21.41c1.75,0,5.15,0.44,5.15,0.44l0.12-3c0,0-3.2-0.79-5.74-0.79c-5.71,0-7.49,3.09-7.49,10.43
c0,6.87,1.4,10.37,7.49,10.37c2.3,0,5.71-0.72,5.71-0.72l-0.09-3.09c0,0-3.46,0.46-5.04,0.46c-3.85,0-4.54-1.89-4.54-7.02
C227.31,23.1,228.21,21.41,231.74,21.41z"
                    fill={fill}
                />
                <path
                    d="M175.34,21.41c1.75,0,5.15,0.44,5.15,0.44l0.12-3c0,0-3.21-0.79-5.74-0.79c-5.71,0-7.49,3.09-7.49,10.43
c0,6.87,1.4,10.37,7.49,10.37c2.3,0,5.71-0.72,5.71-0.72l-0.09-3.09c0,0-3.46,0.46-5.04,0.46c-3.84,0-4.54-1.89-4.54-7.02
C170.91,23.1,171.81,21.41,175.34,21.41z"
                    fill={fill}
                />
                <rect
                    fill={fill}
                    height="20.16"
                    width="3.44"
                    x="241.8"
                    y="18.38"
                />
                <path
                    d="M191.93,18.06c-6.24,0-8.13,3.79-8.13,10.54c0,6.76,1.89,10.25,8.13,10.25c6.23,0,8.12-3.43,8.12-10.25
C200.05,21.84,198.16,18.06,191.93,18.06z M191.93,35.56c-3.73,0-4.6-2.27-4.6-6.97c0-4.69,0.9-7.25,4.6-7.25
c3.7,0,4.6,2.56,4.6,7.25C196.53,33.35,195.66,35.56,191.93,35.56z"
                    fill={fill}
                />
                <path
                    d="M292.26,21.38c2.65,0,6.2,0.46,6.2,0.46l0.08-2.97c0,0-3.73-0.82-6.78-0.82c-5.95,0-7.89,3.32-7.89,10.37
c0,6.7,1.78,10.42,8.01,10.42c2.54,0,6.67-0.67,6.67-0.67v-10.2h-5.68v3.06h2.3v4.28c-0.03,0-1.63,0.2-3,0.2
c-3.96,0-4.78-1.92-4.78-7.1C287.4,23.1,288.42,21.38,292.26,21.38z"
                    fill={fill}
                />
                <polygon
                    fill={fill}
                    points="315.28,21.64 315.28,18.38 302.7,18.38 302.7,38.53 315.28,38.53 315.28,35.27 306.08,35.27
306.08,30.12 313.54,30.12 313.54,26.91 306.08,26.91 306.08,21.64 		"
                />
                <polygon
                    fill={fill}
                    points="249.95,38.53 262.53,38.53 262.53,35.27 253.33,35.27 253.33,30.12 260.78,30.12 260.78,26.91
253.33,26.91 253.33,21.64 262.53,21.64 262.53,18.38 249.95,18.38 		"
                />
                <path
                    d="M280.72,24.87c0-4.6-2.21-6.5-6.81-6.5h-7.6v20.16h3.41v-6.87h4.48l2.77,6.87h3.76l-3.17-7.86
C279.62,29.65,280.72,27.9,280.72,24.87z M273.97,28.4h-4.25v-6.76h4.19c2.21,0,3.29,1.08,3.29,3.29
C277.2,27,276.33,28.4,273.97,28.4z"
                    fill={fill}
                />
                <polygon
                    fill={fill}
                    points="147.78,19.92 154.21,19.92 154.21,38.53 155.99,38.53 155.99,19.92 162.34,19.92 162.34,18.23
147.78,18.23 		"
                />
                <polygon
                    fill={fill}
                    points="132.5,38.53 144.62,38.53 144.62,36.84 134.28,36.84 134.28,29.04 142.87,29.04 142.87,27.35
134.28,27.35 134.28,19.92 144.62,19.92 144.62,18.23 132.5,18.23 		"
                />
            </g>
        </g>
    </svg>
);

export default PocketConciergeLogo;
