import {FC} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import useScrollToTop from 'hooks/useScrollToTop';

const ScrollToTop: FC = () => {
    const {key} = useLocation();
    useScrollToTop(key);

    return <Outlet />;
};

export default ScrollToTop;
