import {FormHTMLAttributes, forwardRef} from 'react';
import clsx from 'clsx';

export type FormProps<T = HTMLFormElement> = FormHTMLAttributes<T> & {
    isStacked?: boolean;
};

const Form = forwardRef<HTMLFormElement, FormProps>(
    ({className, isStacked = true, ...rest}, ref) => (
        <form
            ref={ref}
            className={clsx(
                isStacked && 'flex flex-col',
                isStacked && !className?.includes('gap-y-') && 'gap-y-8',
                className
            )}
            {...rest}
        />
    )
);

Form.displayName = 'Form';

export default Form;
