import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SegmentContainer} from 'components/ScrollContainer/Content/SegmentContainer';
import {SectionSegmentButton} from 'components/ScrollContainer/ScrollContainerHeader/SegmentButton';
import {VenueWithCoursesFragment} from 'gql/generated';
import {
    getDimensions,
    HEADER_OFFSET,
    scrollTo,
    SEGMENT_HEADER_OFFSET,
} from 'utils/scroll';
import CoursesTab from './CoursesTab/CoursesTab';
import VenueAccess from './VenueAccess';
import VenueInfo from './VenueInfo';
import VenueRecommendations from './VenueRecommendations';

export type VenueTabsProps = {
    venue: VenueWithCoursesFragment;
};

const VenueTabs: FC<VenueTabsProps> = ({venue}) => {
    const {t} = useTranslation();

    const [activeSection, setActiveSection] = useState('courses');

    const coursesRef = useRef<HTMLDivElement | null>(null);
    const infoRef = useRef<HTMLDivElement | null>(null);
    const accessRef = useRef<HTMLDivElement | null>(null);
    const recommendationsRef = useRef<HTMLDivElement | null>(null);

    const sections = useMemo(
        () => [
            {
                content: <CoursesTab venue={venue} />,
                elementRef: coursesRef,
                title: t('venue.tabs.courses.label'),
                value: 'courses',
            },
            {
                content: <VenueInfo venue={venue} />,
                elementRef: infoRef,
                title: t('venue.tabs.info.label'),
                value: 'info',
            },
            {
                content: <VenueAccess venue={venue} />,
                elementRef: accessRef,
                title: t('venue.tabs.access.label'),
                value: 'access',
            },
            {
                content: <VenueRecommendations venue={venue} />,
                elementRef: recommendationsRef,
                title: t('venue.tabs.recommendations.label'),
                value: 'recommendations',
            },
        ],
        [t, venue]
    );

    useEffect(() => {
        const venueImageHTMLElement = document.querySelector('#venueImage');

        const handleScroll = () => {
            const height =
                venueImageHTMLElement?.getBoundingClientRect().height;

            const scrollPosition =
                window.scrollY + HEADER_OFFSET - (height || 0);

            const selected = sections.find(({elementRef}) => {
                const ele = elementRef.current;

                if (ele) {
                    const {offsetBottom, offsetTop} = getDimensions(ele);

                    return (
                        scrollPosition > offsetTop &&
                        scrollPosition < offsetBottom
                    );
                }

                return null;
            });

            if (selected && selected.value !== activeSection) {
                setActiveSection(selected.value);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeSection, sections, setActiveSection]);

    return (
        <div>
            <div className="bg-body sticky top-[3.25rem] z-40 flex w-full flex-row pt-2">
                {sections.map(({elementRef, title, value}) => (
                    <SectionSegmentButton
                        key={value}
                        onClick={() =>
                            scrollTo(elementRef.current, SEGMENT_HEADER_OFFSET)
                        }
                        title={title}
                        value={value}
                        visibleSection={activeSection as string}
                    />
                ))}
            </div>
            <div className="mt-2 flex flex-col gap-y-4">
                {sections.map((child) => (
                    <SegmentContainer
                        key={child.title}
                        elementRef={child.elementRef}
                        title={child.title}
                    >
                        {child.content}
                    </SegmentContainer>
                ))}
            </div>
        </div>
    );
};

export default VenueTabs;
