import {FC, useMemo} from 'react';
import {isWithinInterval} from 'date-fns';
import {useLocale} from 'state/locale';
import {toTime} from 'utils/date';

type TimeSlot = {
    label: string;
    value: Date;
};

type TimeSlotSelectionProps = {
    highlightsInterval:
        | {
              end: Date;
              start: Date;
          }
        | undefined;
    onSelect: (date: Date) => void;
    time: Date | undefined;
    times: Date[];
};

const TimeSlotSelection: FC<TimeSlotSelectionProps> = ({
    highlightsInterval,
    onSelect,
    time,
    times,
}) => {
    const locale = useLocale();

    // generate time slots intervals from the earliest to latest in the filtered list
    const timeSlots = useMemo<TimeSlot[]>(
        () =>
            times.map((value) => ({
                label: toTime(value, locale),
                value,
            })),
        [locale, times]
    );

    const selectedTime = useMemo(() => {
        if (time) {
            return toTime(time, locale);
        }

        return '';
    }, [locale, time]);

    return (
        <fieldset className="pb-4">
            <div className="grid grid-cols-3 gap-1 sm:grid-cols-4">
                {timeSlots.map((timeSlot) => (
                    <button
                        key={timeSlot.label}
                        className={`items-center justify-center rounded-md border p-1.5 text-sm font-semibold
                            ${
                                timeSlot.label === selectedTime
                                    ? 'button-secondary-selected dark:bg-gold-200'
                                    : 'border-outline'
                            } 
                            ${
                                highlightsInterval
                                    ? isWithinInterval(
                                          timeSlot.value,
                                          highlightsInterval
                                      )
                                        ? 'bg-body'
                                        : 'border-dashed bg-grey-100 dark:border-grey-800 dark:bg-grey-700 dark:text-grey-800'
                                    : 'bg-body'
                            }
                        `}
                        data-dd-action-name="TimeSlot"
                        disabled={timeSlot.label === selectedTime}
                        onClick={() => onSelect(timeSlot.value)}
                        type="button"
                    >
                        <span>{timeSlot.label}</span>
                    </button>
                ))}
            </div>
        </fieldset>
    );
};

export default TimeSlotSelection;
