/* eslint-disable react/no-danger */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {VenueWithCoursesFragment} from 'gql/generated';
import {sanitizeTextBlock} from 'utils/string';

export type VenueReservationTermsProps = {
    venue: VenueWithCoursesFragment;
};

const VenueReservationTerms: FC<VenueReservationTermsProps> = ({venue}) => {
    const {t} = useTranslation();

    if (!venue.reservationTerms) return null;

    return (
        <div className="mt-8">
            <hr />
            <h3 className="mt-8 text-lg">
                {t('reserve.summary.reservationPolicy')}
            </h3>
            <div
                dangerouslySetInnerHTML={{
                    __html: sanitizeTextBlock(venue.reservationTerms),
                }}
                className="mt-5"
            />
        </div>
    );
};

export default VenueReservationTerms;
