import {FC, useState} from 'react';
import {RegisterOptions, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import Button from 'components/Button';
import Field from 'components/Form/Field';
import {FormContextString, InputProps} from 'components/Form/types';
import Chain from '../Chain';
import PasswordRules, {checkPasswordConditions} from './PasswordRules';

const defaultRules: RegisterOptions = {
    validate: (value) => checkPasswordConditions(value).valid,
};

export type InputPasswordProps = InputProps & {
    classNameButton?: string;
    classNameChain?: string;
    showPasswordRules?: boolean;
};

const InputPassword: FC<InputPasswordProps> = ({
    className,
    classNameButton,
    classNameChain,
    classNameInput,
    disabled,
    helpText,
    id,
    label,
    name,
    placeholder,
    required,
    rules,
    showPasswordRules,
    ...rest
}) => {
    const {t} = useTranslation();

    const {
        formState: {errors},
        getValues,
        register,
    } = useFormContext<FormContextString>();

    const [passwordValue, setPasswordValue] = useState(
        () => getValues(name) || ''
    );

    const [type, setType] = useState<'password' | 'text'>('password');

    const onClick = () => {
        setType((prev) => (prev === 'password' ? 'text' : 'password'));
    };

    const registerRules = rules || {
        ...defaultRules,
        disabled,
        required,
    };

    const showErrors = !!errors[name];

    const fieldLabel =
        label ||
        t(
            showPasswordRules === false
                ? 'user.password.confirm'
                : 'user.password.input'
        );

    const inputPlaceholder =
        placeholder ||
        t(
            showPasswordRules === false
                ? 'user.password.confirm'
                : 'user.password.input'
        );

    return (
        <Field
            className={className}
            disabled={registerRules.disabled}
            error={errors[name]}
            helpText={helpText}
            label={fieldLabel}
            name={name}
            required={!!registerRules.required}
            type={showPasswordRules ? 'password' : 'input'}
        >
            <Chain
                className={clsx(
                    'input-wrapper',
                    showErrors && 'input-invalid',
                    classNameChain
                )}
            >
                <input
                    aria-label={
                        rest['aria-label'] || label === null
                            ? undefined
                            : typeof label === 'string'
                            ? label
                            : name
                    }
                    className={clsx('w-full border-none', classNameInput)}
                    id={id || name}
                    maxLength={50}
                    placeholder={inputPlaceholder}
                    type={type}
                    {...register(name, {
                        ...registerRules,
                        onChange: (event) =>
                            setPasswordValue(event.target.value),
                    })}
                    {...rest}
                />
                <Button
                    aria-label={t('user.password.showHide')}
                    className={clsx(
                        showErrors && 'text-red-600 dark:text-red-400',
                        classNameButton
                    )}
                    icon={
                        type === 'password'
                            ? ['far', 'eye-slash']
                            : ['far', 'eye']
                    }
                    kind="secondary"
                    onClick={onClick}
                />
            </Chain>
            {showPasswordRules && (
                <PasswordRules showErrors={showErrors} value={passwordValue} />
            )}
        </Field>
    );
};

export default InputPassword;
