import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import DarkModeToggle from 'components/DarkModeToggle';

const FooterControls: FC = () => {
    const {t} = useTranslation();

    return (
        <div className="mx-auto hidden w-fit items-center gap-2 text-sm sm:flex sm:w-auto">
            <DarkModeToggle />
            <span className="hidden dark:inline">{t('theme.darkMode')}</span>
            <span className="dark:hidden">{t('theme.lightMode')}</span>
        </div>
    );
};

export default FooterControls;
