import {useTranslation} from 'react-i18next';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type PasswordResult = {
    label: string;
    valid: boolean;
};

export const checkPasswordConditions = (text = '') => {
    const results: PasswordResult[] = [
        {
            label: 'user.password.min',
            valid: text.length >= 8,
        },
        {
            label: 'user.password.lowercase',
            valid: /(?=.*[a-z])/.test(text),
        },
        {
            label: 'user.password.number',
            valid: /(?=.*\d)/.test(text),
        },
        {
            label: 'user.password.uppercase',
            valid: /(?=.*[A-Z])/.test(text),
        },
    ];

    return {
        results,
        valid: results.every(({valid}) => valid),
    };
};

type Status = 'default' | 'error' | 'passed';

const STATUS: Record<Status, string> = {
    default: 'unselected',
    error: 'text-invalid',
    passed: 'text-golden',
};

const ICONS: Record<Status, IconProp> = {
    default: 'check-circle',
    error: 'exclamation-circle',
    passed: 'check-circle',
};

const getStatus = (showErrors: boolean, valid: boolean) =>
    showErrors && !valid ? 'error' : valid ? 'passed' : 'default';

const PasswordRules = ({
    showErrors,
    value,
}: {
    showErrors: boolean;
    value: string;
}) => {
    const {t} = useTranslation();

    return (
        <div className="grid grid-cols-2">
            {checkPasswordConditions(value).results.map((data, index) => {
                const status = getStatus(showErrors, data.valid);

                return (
                    <div
                        key={data.label}
                        className={clsx(
                            'flex items-center pt-1 text-sm',
                            STATUS[status],
                            index % 2 === 0 ? 'pl-0.5' : 'pl-2.5'
                        )}
                    >
                        <FontAwesomeIcon
                            className="mr-2 flex-none"
                            icon={ICONS[status]}
                        />
                        {t(data.label)}
                    </div>
                );
            })}
        </div>
    );
};

export default PasswordRules;
