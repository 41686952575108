/* eslint-disable react/no-danger */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ApolloError} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Link from 'components/Link';

export type Server500Props = {
    error?: ApolloError | Error;
};

// Only render error output on localhost and staging
const SHOW_ERROR =
    typeof window !== 'undefined' &&
    ['http://localhost:3000', 'https://staging.pocket-concierge.jp'].includes(
        window.location.origin
    );

const Server500: FC<Server500Props> = ({error}) => {
    const {t} = useTranslation();

    return (
        <div className="w-full py-8">
            <div className="site-container text-center sm:pt-0 sm:text-left">
                <h1 className="text-invalid flex items-center justify-center text-xl sm:justify-start sm:text-2xl md:text-3xl">
                    <FontAwesomeIcon
                        icon={['fas', 'exclamation-circle']}
                        size="2x"
                    />
                    <span className="ml-3">{t('error.500.title')}</span>
                </h1>
                <p
                    dangerouslySetInnerHTML={{__html: t('error.500.message')}}
                    className="mt-4"
                />
                <p className="mt-4">
                    <Link to="/">{t('returnToTop')}</Link>
                </p>
                {error && SHOW_ERROR && (
                    <div className="bg-step border-invalid relative mt-4 w-fit border text-sm">
                        <div className="w-fit bg-red-600 px-2 pb-0.5 pt-1 text-white">
                            ERROR
                        </div>
                        <pre className="p-4">
                            {JSON.stringify(error, null, 2)}
                        </pre>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Server500;
