import {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

type Option<T> = {
    label: ReactNode;
    value: T;
};

type RadioFilterSelectionProps<T> = {
    onSelect: (value: T) => void;
    options: Option<T>[];
    selection: T;
};

type RadioButtonProps<T> = {
    option: Option<T>;
    selection: T;
    setSelection: (serviceType: T) => void;
};

const RadioButton = <T,>({
    option,
    selection,
    setSelection,
}: RadioButtonProps<T>) => {
    const {t} = useTranslation();

    const {label, value} = option;

    return (
        <button
            className={`flex items-center justify-center px-4 py-1.5 text-sm font-semibold first:rounded-l-md last:rounded-r-md
                ${
                    selection === value
                        ? 'button-secondary-selected'
                        : 'bg-body border-outline'
                }`}
            onClick={(event) => {
                setSelection(event.currentTarget.value as T);
            }}
            type="button"
            value={value as string}
        >
            <span>{t(`user.reservations.selection.${label}`)}</span>
        </button>
    );
};

export const RadioFilterSelection = <T,>({
    onSelect,
    options,
    selection,
}: RadioFilterSelectionProps<T>) => (
    <div
        className="flex flex-row divide-x rounded-md border border-grey-200 text-grey-500 dark:text-grey-600"
        role="group"
    >
        {options.map((option) => (
            <RadioButton
                key={(option as Option<string>).value}
                option={option}
                selection={selection}
                setSelection={onSelect}
            />
        ))}
    </div>
);
