import clsx from 'clsx';
import styles from '../static.module.css';

export const TransactionPage = () => (
    <div
        className={clsx(
            'prose prose-sm max-w-none px-4 pt-6 dark:prose-dark md:prose md:max-w-none md:px-0',
            styles.transaction,
            styles.page
        )}
    >
        <h1>Notation based on the Specified Commercial Transaction Act</h1>
        <section>
            <table>
                <tbody>
                    <tr>
                        <th>Company Name</th>
                        <td>Pocket Concierge Inc.</td>
                    </tr>
                    <tr>
                        <th>Parent Company</th>
                        <td>American Express International Inc.</td>
                    </tr>
                    <tr>
                        <th>General Manager</th>
                        <td>Hiroyuki Ikeda</td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>
                            4-chōme-1-1 Toranomon, Minato City, Tokyo 105-6920
                        </td>
                    </tr>
                    <tr>
                        <th>Contacts</th>
                        <td>+81 3-6625-9221</td>
                    </tr>
                    <tr>
                        <th>Payment Method</th>
                        <td>Credit Card</td>
                    </tr>
                    <tr>
                        <th>Sales Price</th>
                        <td>
                            Please check the price on each restaurants&apos;
                            page.
                        </td>
                    </tr>
                    <tr>
                        <th>Cancellations</th>
                        <td>
                            Cancellations made after a successful reservation
                            will be handled according to the cancellation
                            policies set by each restaurant.
                            <br />
                            Cancellations may only be made in advance of the
                            reservation and we are unable to process
                            cancellation requests after the reservation has
                            occurred.
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </div>
);
