import {parsePhoneNumber} from 'awesome-phonenumber';
import {Maybe, PhoneNumberInput} from 'gql/generated';

export type PhoneNumberType = {
    countryCode: string;
    tel: string;
};

export const cleanPhoneNumber = (value: string): string =>
    `+${value
        .split('+')
        .join('')
        .replace(/^(0|-)+/gm, '')
        .replace(/-{2,}/gm, '-')
        .replace(/\s{2,}/gm, ' ')
        .replace(/(\s-)|(-\s)/gm, '-')}`;

export const fromPhoneNumber = (
    phoneNumber?: Maybe<{
        countryCode?: Maybe<number>;
        significant?: Maybe<string>;
    }>
): PhoneNumberType => {
    if (!phoneNumber?.significant) return {countryCode: 'JP', tel: '+81 '};

    const parsed = parsePhoneNumber(
        `+${phoneNumber.countryCode || ''}${phoneNumber.significant}`
    );

    return {
        countryCode: parsed.regionCode || '',
        tel: parsed.number?.international || '',
    };
};

export const toPhoneNumber = (tel = ''): PhoneNumberInput => {
    const phoneNumber = parsePhoneNumber(tel);

    return {
        countryCode: Number(phoneNumber.countryCode),
        significant: phoneNumber.number?.significant || '',
    };
};

export const toFormattedPhoneNumber = (
    phoneNumber?: PhoneNumberInput
): string => {
    if (!phoneNumber) return '';

    const concatenated = `+${phoneNumber.countryCode}${phoneNumber.significant}`;
    const parsed = parsePhoneNumber(concatenated);

    if (parsed.valid) {
        return parsed.number.international;
    }

    return concatenated;
};
