import {FC} from 'react';
import {useSearchParams} from 'react-router-dom';
import {ServiceType, VenueWithCoursesFragment} from 'gql/generated';
import CoursesList from './CoursesList';
import CoursesListTitle from './CoursesListTitle';

type CoursesTabProps = {
    venue: VenueWithCoursesFragment;
};

const CoursesTab: FC<CoursesTabProps> = ({venue}) => {
    const [searchParams] = useSearchParams();

    const selectedServiceType =
        searchParams.get('serviceType') || ServiceType.Dinner;

    const renderCourses = (serviceType: ServiceType) => (
        <CoursesListTitle
            disabled={
                venue.courses.filter(
                    (course) => course.serviceType === serviceType
                ).length === 0
            }
            isExpandable={selectedServiceType !== serviceType}
            serviceType={serviceType}
        >
            <CoursesList courses={venue.courses} serviceType={serviceType} />
        </CoursesListTitle>
    );

    return (
        <div className="md:w-full">
            {venue.courses.length === 0 ? (
                <div className="p-4">
                    {/* We show nothing to be in alignment with the OFE */}
                </div>
            ) : (
                <>
                    {renderCourses(ServiceType.Dinner)}
                    {renderCourses(ServiceType.Lunch)}
                </>
            )}
        </div>
    );
};

export default CoursesTab;
