import {useQuery} from '@apollo/client';
import {
    MarketingContentDocument,
    MarketingContentQuery,
    MarketingContentTypeEnum,
} from 'gql/generated';
import useBreakpoint from 'hooks/useBreakpoint';
import MarketingCard from './Shared/MarketingCard';

type Props = {
    isOnlyMobile: boolean;
};

export const SearchCards = ({isOnlyMobile}: Props) => {
    const isDesktop = useBreakpoint('sm');

    const {data: marketingData} = useQuery<MarketingContentQuery>(
        MarketingContentDocument
    );

    const venueMajorContent = marketingData?.retrieveMarketingContent?.find(
        (item) => item.contentType === MarketingContentTypeEnum.VenueSearchMajor
    );

    const venueMinorContent = marketingData?.retrieveMarketingContent?.find(
        (item) => item.contentType === MarketingContentTypeEnum.VenueSearchMinor
    );

    if (isOnlyMobile) {
        return (
            <MarketingCard
                assetName={venueMinorContent?.link.split('/')?.pop()}
                className="mt-8 w-fit place-self-center"
                imageClassName="max-h-[120px] h-full object-scale-down"
                imageURL={venueMinorContent?.imageUrl}
                isExternal={venueMinorContent?.external}
                path={venueMinorContent?.link}
            />
        );
    }

    return (
        <div className="flex flex-row items-center justify-center gap-x-4 px-0 pb-4">
            <MarketingCard
                assetName={venueMajorContent?.link.split('/')?.pop()}
                imageClassName="max-h-[120px] h-full object-scale-down"
                imageURL={venueMajorContent?.imageUrl}
                isExternal={venueMajorContent?.external}
                path={venueMajorContent?.link}
            />
            {isDesktop && (
                <MarketingCard
                    assetName={venueMinorContent?.link.split('/')?.pop()}
                    imageClassName="max-h-[120px] h-full object-scale-down"
                    imageURL={venueMinorContent?.imageUrl}
                    isExternal={venueMinorContent?.external}
                    path={venueMinorContent?.link}
                />
            )}
        </div>
    );
};
