import {FC, useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import Form from 'components/Form';
import FormActions from 'components/Form/FormActions';
import InputPassword from 'components/Form/InputPassword';
import SubmitButton from 'components/Form/SubmitButton';
import RedirectCountdown from 'components/RedirectCountdown';
import {ResetPasswordDocument} from 'gql/generated';
import useNavigateLocale from 'hooks/useNavigateLocale';
import {useCredentialsDispatch} from 'state/credentials';
import {useToastNotification} from 'state/toastNotification';
import {FormData} from 'types';

// Reset password with a token from the forgot password email link

type ResetPasswordFormData = FormData<{
    password: string;
    passwordConfirmation: string;
}>;

const ResetPasswordPage: FC = () => {
    const {t} = useTranslation();
    const {toastWrapper} = useToastNotification();

    const [searchParams] = useSearchParams();
    const token = searchParams.get('reset_password_token');
    const navigate = useNavigateLocale();

    useEffect(() => {
        // if token is missing, user arrived at this page without using the email link
        // in which case, redirect to root
        if (!token) {
            navigate('/', {replace: true});
        }
    }, [navigate, token]);

    const [submit, {data}] = useMutation(ResetPasswordDocument);

    const setCredentials = useCredentialsDispatch();

    useEffect(() => {
        if (data?.userUpdatePasswordWithToken?.credentials) {
            setCredentials(data.userUpdatePasswordWithToken.credentials);
        }
    }, [data, setCredentials]);

    const methods = useForm<ResetPasswordFormData>({
        defaultValues: {password: '', passwordConfirmation: ''},
    });
    const {handleSubmit} = methods;

    const onSubmit = async (formData: ResetPasswordFormData) => {
        await toastWrapper({
            page: 'resetPassword',
            promise: submit({
                variables: {
                    password: formData.password,
                    passwordConfirmation: formData.passwordConfirmation,
                    token,
                },
            }),
        });
    };

    return (
        <div className="sm:mt-5 sm:flex sm:justify-center">
            <div className="bg-body border-outline space-y-5 p-5 sm:min-w-[36rem] sm:max-w-xl sm:rounded-md sm:border sm:shadow-lg">
                <h2 className="border-outline border-b pb-2 text-lg">
                    {t('authentication.resetPassword.title')}
                </h2>
                {data ? (
                    <RedirectCountdown
                        className="py-5 text-center"
                        message={t('authentication.resetPassword.success')}
                        redirectUrl="/"
                    />
                ) : (
                    <FormProvider {...methods}>
                        <p>{t('authentication.resetPassword.message')}</p>
                        <Form
                            className="relative text-center"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <InputPassword
                                label={t('user.changePassword.newPassword')}
                                name="password"
                                placeholder={t(
                                    'user.changePassword.newPassword'
                                )}
                                required={true}
                                showPasswordRules={true}
                            />
                            <InputPassword
                                name="passwordConfirmation"
                                rules={{
                                    required: true,
                                    validate: (value) =>
                                        value ===
                                            methods.getValues('password') ||
                                        t('user.password.mismatch'),
                                }}
                                showPasswordRules={false}
                            />
                            <FormActions>
                                <SubmitButton className="mx-auto w-11/12 sm:w-1/2" />
                            </FormActions>
                        </Form>
                    </FormProvider>
                )}
            </div>
        </div>
    );
};

export default ResetPasswordPage;
