import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BaseSelectProps} from 'components/Form/Base/BaseSelect';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import {ServiceType} from 'gql/generated';

export type ServiceSelectionProps = Omit<
    BaseSelectProps,
    'name' | 'options'
> & {
    availableServiceTypes: ServiceType[];
    isWaitlist?: boolean;
    onSelect: (value: ServiceType) => void;
    serviceType: ServiceType;
};

const OPTIONS: SelectOption[] = [
    {
        icon: ['fas', 'sun'],
        label: ServiceType.Lunch,
        value: ServiceType.Lunch,
    },
    {
        icon: ['fas', 'moon'],
        label: ServiceType.Dinner,
        value: ServiceType.Dinner,
    },
];

type ServiceTypeButtonProps = {
    disabled: boolean;
    option: SelectOption;
    selectedServiceType: ServiceType;
    setSelectedServiceType: (serviceType: ServiceType) => void;
};

const ServiceTypeButton = ({
    disabled,
    option,
    selectedServiceType,
    setSelectedServiceType,
}: ServiceTypeButtonProps) => {
    const {t} = useTranslation();

    const {icon, value} = option;

    return (
        <button
            className={`flex items-center justify-center gap-x-2 rounded-md border p-1.5 text-sm font-semibold
                ${
                    selectedServiceType === value
                        ? 'button-secondary-selected'
                        : 'bg-body border-outline'
                } ${
                disabled
                    ? 'text-grey-200 dark:bg-grey-700 dark:text-grey-400'
                    : ''
            }`}
            disabled={disabled}
            onClick={() => setSelectedServiceType(value as ServiceType)}
            type="button"
        >
            <FontAwesomeIcon className="ml-2" icon={icon as IconProp} />
            <span>{t(`serviceTypes.${value}`)}</span>
        </button>
    );
};

const ServiceSelection: FC<ServiceSelectionProps> = ({
    availableServiceTypes,
    isWaitlist,
    onSelect,
    serviceType,
}) => {
    const {t} = useTranslation();

    const options = OPTIONS.map((item) => ({
        ...item,
        disabled: !availableServiceTypes.includes(item.value as ServiceType),
        label: t(`serviceTypes.${item.label}`),
    }));

    return (
        <fieldset className="pb-2">
            <legend className="pb-1 text-sm font-semibold">
                {isWaitlist
                    ? t('search.selectServiceTypeWaitlist')
                    : t('search.selectServiceType')}
            </legend>
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-2">
                {options.map((option) => (
                    <ServiceTypeButton
                        key={option.label}
                        disabled={option.disabled}
                        option={option}
                        selectedServiceType={serviceType}
                        setSelectedServiceType={onSelect}
                    />
                ))}
            </div>
        </fieldset>
    );
};

export default ServiceSelection;
