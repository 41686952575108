import {FC, MutableRefObject, ReactNode, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import useScrollToTop from 'hooks/useScrollToTop';
import {
    getDimensions,
    HEADER_OFFSET,
    scrollTo,
    SEGMENT_HEADER_OFFSET,
} from 'utils/scroll';
import {SegmentContainer} from './Content/SegmentContainer';
import {SectionSegmentButton} from './ScrollContainerHeader/SegmentButton';

type Props = {
    headerTitle: string;
    isLoading?: boolean;
    sections: {
        content: ReactNode;
        elementRef: MutableRefObject<HTMLDivElement | null>;
        title: string;
        value: string;
    }[];
};

export const ScrollContainer: FC<Props> = ({
    headerTitle,
    isLoading = false,
    sections,
}) => {
    // To prevent scroll restoration
    useScrollToTop();

    const [searchParams, setSearchParams] = useSearchParams({
        activeSection: sections[0].value,
    });
    const activeSection = searchParams.get('activeSection');

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + HEADER_OFFSET;

            const selected = sections.find(({elementRef}) => {
                const ele = elementRef.current;

                if (ele) {
                    const {offsetBottom, offsetTop} = getDimensions(ele);

                    return (
                        scrollPosition > offsetTop &&
                        scrollPosition < offsetBottom
                    );
                }

                return null;
            });

            if (selected && selected.value !== activeSection) {
                setSearchParams(
                    {
                        activeSection: selected.value,
                    },
                    {
                        replace: true,
                    }
                );
            }
        };

        // If you reload your browser tab it will automatically scroll back to where it was before the reload
        // We therefore need to make sure to highlight the currect section in the header
        handleScroll();

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeSection, sections, setSearchParams]);

    // Scroll to the appropriate section when query parameter was provided initially
    useEffect(() => {
        if (
            activeSection &&
            activeSection !== sections[0].value &&
            !isLoading
        ) {
            const selected = sections.find(
                ({value}) => value === activeSection
            );

            if (!selected) {
                return;
            }

            scrollTo(
                selected.elementRef.current,
                HEADER_OFFSET + SEGMENT_HEADER_OFFSET
            );
        }
        // We only want to scroll once the data has been loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <div>
            <h2 className="hidden px-4 text-2xl md:block md:px-0">
                {headerTitle}
            </h2>
            <div className="bg-body sticky top-[3.25rem] z-40 flex w-full flex-row pt-2">
                {sections.map(({elementRef, title, value}) => (
                    <SectionSegmentButton
                        key={value}
                        onClick={() =>
                            scrollTo(elementRef.current, SEGMENT_HEADER_OFFSET)
                        }
                        title={title}
                        value={value}
                        visibleSection={activeSection as string}
                    />
                ))}
            </div>
            <div className="mt-2 flex flex-col gap-y-4">
                {sections.map((child) => (
                    <SegmentContainer
                        key={child.title}
                        elementRef={child.elementRef}
                        title={child.title}
                    >
                        {child.content}
                    </SegmentContainer>
                ))}
            </div>
        </div>
    );
};
