import {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import useScrollToTop from 'hooks/useScrollToTop';

const WaitlistConfirmation: FC = () => {
    const {t} = useTranslation();

    useScrollToTop();

    return (
        <div className="prose prose-sm max-w-none dark:prose-dark">
            <p className="text-golden text-center font-semibold">
                {t('reserve.confirmation.waitlist.importantInformation')}
            </p>
            <Trans
                components={{
                    b: <b />,
                    span: <span className="text-red-800 dark:text-red-400" />,
                }}
                i18nKey="reserve.confirmation.waitlist.onceTableOpens"
            />
            <p>{t('reserve.confirmation.waitlist.cancellations')}</p>
        </div>
    );
};

export default WaitlistConfirmation;
