import {FC} from 'react';
import {Link as ReactRouterLink, LinkProps} from 'react-router-dom';
import {useLocale} from 'state/locale';

const Link: FC<LinkProps> = (props) => {
    const locale = useLocale();
    const {to} = props;

    if (locale === 'ja') {
        return <ReactRouterLink {...props} />;
    }

    return <ReactRouterLink {...props} to={`/${locale}${to}`} />;
};

export default Link;
