import {FC, ReactNode} from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {
    ICON_MARGIN,
    ICON_SIZES,
    ICON_TEXT_SIZES,
    SIZES,
} from 'components/Button';
import {Size} from 'types';

export type SkeletonButtonProps = {
    children: ReactNode;
    className?: string;
    icon?: IconProp;
    size?: Size;
    step?: boolean;
};

const SkeletonButton: FC<SkeletonButtonProps> = ({
    children,
    className,
    icon,
    size = 'base',
    step,
}) => (
    <div
        className={clsx(
            'skeleton rounded-md border border-transparent text-transparent',
            step && 'step',
            icon
                ? children
                    ? `flex items-center ${ICON_TEXT_SIZES[size]}`
                    : ICON_SIZES[size]
                : SIZES[size],
            className
        )}
    >
        {icon && (
            <FontAwesomeIcon
                className={clsx(children && `flex-none ${ICON_MARGIN[size]}`)}
                fixedWidth={true}
                icon={icon}
                size="1x"
            />
        )}
        {children}
    </div>
);

export default SkeletonButton;
