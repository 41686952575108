import {isAfter, isBefore, isEqual, isWithinInterval, set} from 'date-fns';

export const getSafeDate = (date: Date): Date =>
    set(date, {
        hours: [12, 14, 17].includes(date.getHours()) ? date.getHours() : 12,
        milliseconds: 0,
        minutes: 0,
        seconds: 0,
    });

export type AdjustedPosition = {
    left?: number;
    opacity?: number;
    position?: 'fixed';
    top?: number;
};

const getEnabledDate = (id: string, index: number): string | undefined => {
    const dateId = `${id}-date-selector-date-${index}`;
    const element: HTMLButtonElement | null = document.querySelector(
        `#${dateId}`
    );

    return element && !element.disabled ? dateId : undefined;
};

/* eslint-disable no-plusplus */
export const getFirstEnabledDate = (id: string): string | undefined => {
    let index = -1;

    while (index++ < 42) {
        const date = getEnabledDate(id, index);
        if (date) return date;
    }

    return undefined;
};

export const getLastEnabledDate = (id: string): string | undefined => {
    let index = 42;

    while (index-- > 0) {
        const date = getEnabledDate(id, index);
        if (date) return date;
    }

    return undefined;
};

export const isValidDate = (
    date: Date,
    minDate?: Date,
    maxDate?: Date
): boolean => {
    if (!minDate && !maxDate) return true;

    if (minDate && maxDate) {
        return isWithinInterval(date, {end: maxDate, start: minDate});
    }

    if (maxDate) {
        return isBefore(date, maxDate) || isEqual(date, maxDate);
    }

    // TypeScript can't infer that minDate must have a value at this point
    // @ts-ignore
    return isAfter(date, minDate) || isEqual(date, minDate);
};
