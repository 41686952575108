import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'components/Loaders/Skeleton';
import SkeletonButton from 'components/Loaders/Skeleton/SkeletonButton';
import SkeletonCheckbox from 'components/Loaders/Skeleton/SkeletonCheckbox';
import SkeletonForm from 'components/Loaders/Skeleton/SkeletonForm';
import SkeletonGenderSelector from 'components/Loaders/Skeleton/SkeletonGenderSelector';
import SkeletonInput from 'components/Loaders/Skeleton/SkeletonInput';
import SkeletonYearMonthDay from 'components/Loaders/Skeleton/SkeletonYearMonthDay';
import {useLocale} from 'state/locale';

export const BasicInformationSkeleton: FC = () => {
    const locale = useLocale();
    const {t} = useTranslation();

    return (
        <Skeleton className="bg-body md:border-outline mt-4 px-4 md:rounded-md md:border md:p-4 lg:p-5">
            <SkeletonForm className="bg-step p-4">
                <div className="flex gap-2 sm:gap-4">
                    <SkeletonInput
                        className="flex-1"
                        label={t(
                            locale === 'ja'
                                ? 'user.basicInformation.name.last.label'
                                : 'user.basicInformation.name.first.label'
                        )}
                        required={true}
                        step={true}
                    />
                    <SkeletonInput
                        className="flex-1"
                        label={t(
                            locale === 'en'
                                ? 'user.basicInformation.name.last.label'
                                : 'user.basicInformation.name.first.label'
                        )}
                        required={true}
                        step={true}
                    />
                </div>
                {locale === 'ja' && (
                    <div className="flex gap-2 sm:gap-4">
                        <SkeletonInput
                            className="flex-1"
                            label={t('user.basicInformation.name.last.kana')}
                            required={true}
                            step={true}
                        />
                        <SkeletonInput
                            className="flex-1"
                            label={t('user.basicInformation.name.first.kana')}
                            required={true}
                            step={true}
                        />
                    </div>
                )}
                <div className="flex flex-col gap-4 md:flex-row md:gap-2 lg:gap-4">
                    <SkeletonInput
                        className="md:flex-1"
                        label={t('user.basicInformation.phoneNumber.label')}
                        required={true}
                        step={true}
                    />
                    <SkeletonInput
                        className="md:flex-1"
                        label={t('user.basicInformation.companyName')}
                        step={true}
                    />
                </div>
                <SkeletonGenderSelector required={true} step={true} />
                <SkeletonYearMonthDay required={true} step={true} />
                <SkeletonCheckbox
                    label={t('authentication.signup.receiveNewsletter')}
                    size="sm"
                    step={true}
                />
                <div className="flex justify-center pb-2 sm:pb-0 md:justify-end">
                    <SkeletonButton className="w-11/12 md:w-auto" step={true}>
                        {t('form.save')}
                    </SkeletonButton>
                </div>
            </SkeletonForm>
        </Skeleton>
    );
};
