import {FC} from 'react';
import clsx from 'clsx';
import Skeleton from 'components/Loaders/Skeleton';
import SkeletonImage from 'components/Loaders/Skeleton/SkeletonImage';

export type VenueResultCardSkeletonProps = {
    className?: string;
};

const VenueResultCardSkeleton: FC<VenueResultCardSkeletonProps> = ({
    className,
}) => (
    <Skeleton
        className={clsx(
            'relative flex select-none flex-col overflow-hidden rounded-md border border-transparent',
            className
        )}
    >
        <SkeletonImage roundedBottom={false} />
        <div className="p-4">
            <div className="skeleton h-4 w-5/6 rounded-md" />
            <div className="skeleton mt-2 h-3 w-1/2 rounded-md" />
            <div className="skeleton mt-2 h-3 w-1/3 rounded-md" />
            <div className="skeleton mt-2 h-3 w-2/3 rounded-md" />
            <div className="skeleton mt-2 h-16 rounded-md text-xs" />
        </div>
    </Skeleton>
);

export default VenueResultCardSkeleton;
