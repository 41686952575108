import {forwardRef, InputHTMLAttributes, ReactNode} from 'react';
import {FieldError} from 'react-hook-form';
import clsx from 'clsx';
import HelpError from 'components/Form/HelpError';
import {Size} from 'types';

export type BaseCheckboxProps<T = HTMLInputElement> = Omit<
    InputHTMLAttributes<T>,
    'size'
> & {
    error?: FieldError;
    helpText?: ReactNode;
    label?: ReactNode;
    name: string;
    size?: Size;
    type?: never;
};

export const SIZE: Record<Size, string> = {
    base: 'w-6 h-6',
    lg: 'w-7 h-7',
    sm: 'w-5 h-5',
    xl: 'w-8 h-8',
    xs: 'w-4 h-4',
};

export const TEXT: Record<Size, string> = {
    base: 'text-base',
    lg: 'text-lg',
    sm: 'text-sm',
    xl: 'text-xl',
    xs: 'text-xs',
};

const BaseCheckbox = forwardRef<HTMLInputElement, BaseCheckboxProps>(
    (
        {
            className,
            disabled,
            error,
            helpText,
            id,
            label,
            name,
            required,
            size = 'base',
            ...rest
        },
        ref
    ) => {
        const checkbox = (
            <input
                ref={ref}
                className={clsx(SIZE[size], !label && className)}
                disabled={disabled}
                id={id || name}
                name={name}
                required={required && !!error}
                type="checkbox"
                {...rest}
            />
        );

        const helpError =
            helpText || error ? (
                <HelpError
                    disabled={disabled}
                    error={error}
                    helpText={helpText}
                    label={label}
                />
            ) : null;

        if (!label) {
            return (
                <>
                    {checkbox}
                    {helpError}
                </>
            );
        }

        const field = (
            <label
                className={clsx(
                    'group inline-flex w-fit select-none items-start',
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    !helpError && className
                )}
                htmlFor={id || name}
            >
                {checkbox}
                <div
                    className={clsx(
                        'ml-2',
                        size === 'xl' && 'mt-[3px]',
                        TEXT[size],
                        disabled && 'text-disabled'
                    )}
                >
                    {label}
                </div>
            </label>
        );

        if (!helpError) return field;

        return (
            <div className={className}>
                {field}
                {helpError}
            </div>
        );
    }
);

BaseCheckbox.displayName = 'BaseCheckbox';

export default BaseCheckbox;
