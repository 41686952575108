import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import Form from 'components/Form';
import Checkbox from 'components/Form/Checkbox';
import SubmitButton from 'components/Form/SubmitButton';
import {
    FeaturedMailNotificationEnum,
    UpdateEmailPreferencesDocument,
    UserProfileDocument,
    UserProfileFragment,
} from 'gql/generated';
import {useLocale} from 'state/locale';
import {useToastNotification} from 'state/toastNotification';
import {getDefaultValues, ProfileFormData} from 'utils/user';

type EmailPreferencesContentProps = {
    user: UserProfileFragment;
};

export const EmailPreferencesContent: FC<EmailPreferencesContentProps> = ({
    user,
}) => {
    const {t} = useTranslation();
    const locale = useLocale();
    const {toastWrapper} = useToastNotification();

    const methods = useForm<ProfileFormData>({
        defaultValues: getDefaultValues(user),
    });

    const {handleSubmit} = methods;

    const [updateEmailPreferences] = useMutation(
        UpdateEmailPreferencesDocument,
        {
            awaitRefetchQueries: true,
            refetchQueries: [UserProfileDocument],
        }
    );

    const onSubmit = async (formData: ProfileFormData) => {
        await toastWrapper({
            promise: updateEmailPreferences({
                variables: {
                    ...formData,
                    featuredMailNotification: formData.featuredMailNotification
                        ? FeaturedMailNotificationEnum.Accept
                        : FeaturedMailNotificationEnum.Deny,
                },
            }),
            successMessage: t('user.emailPreferences.updated'),
        });
    };

    return (
        <FormProvider {...methods}>
            <Form className="gap-y-4" onSubmit={handleSubmit(onSubmit)}>
                {locale === 'ja' && (
                    <div>
                        <h3 className="pb-1 text-sm">
                            {t(
                                'user.emailPreferences.featuredMailNotificationTitle'
                            )}
                        </h3>
                        <div className="bg-step rounded-md p-6">
                            <Checkbox
                                label={
                                    <Trans
                                        components={{
                                            span: <span className="text-sub" />,
                                        }}
                                        i18nKey="user.emailPreferences.featuredMailNotification"
                                    />
                                }
                                name="featuredMailNotification"
                                size="sm"
                            />
                        </div>
                    </div>
                )}
                <div>
                    <h3 className="pb-1 text-sm">
                        {t('user.emailPreferences.newsletterTitle')}
                    </h3>
                    <div className="bg-step rounded-md p-6">
                        <Checkbox
                            label={
                                <Trans
                                    components={{
                                        span: <span className="text-sub" />,
                                    }}
                                    i18nKey="user.emailPreferences.newsletter"
                                />
                            }
                            name="newsletter"
                            size="sm"
                        />
                    </div>
                </div>
                <div className="flex justify-center pb-2 sm:pb-0 md:justify-end">
                    <SubmitButton className="w-11/12 md:w-auto">
                        {t('form.save')}
                    </SubmitButton>
                </div>
            </Form>
        </FormProvider>
    );
};
