import {FC, RefObject, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import CloseButton from 'components/CloseButton';
import {useAnalytics} from 'hooks/useAnalytics';
import useBreakpoint from 'hooks/useBreakpoint';
import {PageName} from 'pages/Analytics';
import Login from './Login';
import Signup from './Signup';

export type AuthenticateProps = {
    className?: string;
    // eslint-disable-next-line react/boolean-prop-naming
    login?: boolean;
    message?: string;
    onClose?: () => void;
    onLoginSuccess?: () => void;
};

const Authenticate: FC<AuthenticateProps> = ({
    className,
    login,
    message,
    onClose,
    onLoginSuccess,
}) => {
    const isDesktop = useBreakpoint('sm');

    const containerRef: RefObject<HTMLDivElement> = useRef(null);

    const [isLogin, setIsLogin] = useState(() => !!login);

    // Track Adobe Analytics page view event when the modal is opened
    useAnalytics(isLogin ? PageName.Login : PageName.SignUp);

    const onToggle = (value: boolean) => {
        setIsLogin(value);
        window.setTimeout(() => window.scrollTo(0, 0), 50);
    };

    useEffect(() => {
        if (isDesktop && onClose) {
            const onClickOutside = (event: MouseEvent) => {
                // @ts-ignore
                if (!containerRef.current?.contains(event.target)) {
                    onClose();
                }
            };
            document.addEventListener('mousedown', onClickOutside);

            return () =>
                document.removeEventListener('mousedown', onClickOutside);
        }

        return undefined;
    }, [isDesktop, onClose]);

    const closeButton = onClose ? <CloseButton onClick={onClose} /> : null;

    return (
        <div
            className={clsx(
                'relative sm:flex sm:items-center sm:justify-center',
                className
            )}
        >
            <div ref={containerRef}>
                {isLogin ? (
                    <Login
                        message={message}
                        onSignup={() => onToggle(false)}
                        onSuccess={onLoginSuccess || onClose}
                    >
                        {closeButton}
                    </Login>
                ) : (
                    <Signup
                        message={message}
                        onLogin={() => onToggle(true)}
                        onSuccess={onClose}
                    >
                        {closeButton}
                    </Signup>
                )}
            </div>
        </div>
    );
};

export default Authenticate;
