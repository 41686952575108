import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import InputText from 'components/Form/InputText';
import {InputProps} from 'components/Form/types';
import isEmail from 'validator/lib/isEmail';

export type InputEmailProps = InputProps & {
    hideMaxLength?: boolean;
};

const InputEmail: FC<InputEmailProps> = ({
    disabled,
    placeholder,
    required,
    rules,
    ...rest
}) => {
    const {t} = useTranslation();

    const validateEmail = (value: string) =>
        isEmail(value) || t('error.emailInvalid');

    const registerRules = rules || {disabled, required};

    return (
        <InputText
            autoComplete="email"
            hideMaxLength={true}
            label={t('user.basicInformation.email')}
            maxLength={254}
            placeholder={placeholder || t('user.basicInformation.email')}
            rules={{
                ...registerRules,
                validate: {...registerRules.validate, validateEmail},
            }}
            {...rest}
        />
    );
};

export default InputEmail;
