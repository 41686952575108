import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import BreakpointWrapper from 'components/BreakpointWrapper';
import Skeleton from 'components/Loaders/Skeleton';
import SkeletonButton from 'components/Loaders/Skeleton/SkeletonButton';
import SkeletonInput from 'components/Loaders/Skeleton/SkeletonInput';
import SkeletonText from 'components/Loaders/Skeleton/SkeletonText';

const UserPaymentMethodsSkeleton: FC = () => {
    const {t} = useTranslation();

    return (
        <Skeleton className="bg-body md:border-outline mt-4 px-4 md:rounded-md md:border md:p-4 lg:p-5">
            <div className="bg-step py-4 pr-4">
                <div className="ml-4 flex items-center">
                    <div className="skeleton step h-8 w-[3.125rem] rounded-sm" />
                    <div className="ml-2">
                        <div className="skeleton step h-3 w-24 sm:h-4 sm:w-32" />
                        <div className="skeleton step mt-1.5 h-3 w-36 sm:mt-2 sm:h-4 sm:w-64" />
                        <div className="skeleton step mt-1.5 h-3 w-24 sm:hidden" />
                    </div>
                </div>
            </div>
            <div className="bg-step mt-4 p-4">
                <SkeletonText className="font-semibold" step={true}>
                    {t('user.paymentMethods.addNewCard')}
                </SkeletonText>
                <BreakpointWrapper
                    breakpoint="lg"
                    className="flex flex-row"
                    wrapBelowBreakpoint={false}
                >
                    <div className="mt-3 lg:mr-1.5 lg:flex-1">
                        <SkeletonInput
                            label={t('user.paymentMethods.cardNumber')}
                            step={true}
                        />
                    </div>
                    <div className="mt-3 lg:ml-1.5 lg:flex-1">
                        <SkeletonInput
                            label={t('user.paymentMethods.expirationDate')}
                            step={true}
                        />
                    </div>
                </BreakpointWrapper>
                <BreakpointWrapper
                    breakpoint="lg"
                    className="flex flex-row"
                    wrapBelowBreakpoint={false}
                >
                    <div className="mt-3 lg:mr-1.5 lg:flex-1">
                        <SkeletonInput
                            label={t('user.paymentMethods.securityCode')}
                            step={true}
                        />
                    </div>
                    <div className="mt-3 lg:ml-1.5 lg:flex-1">
                        <SkeletonInput
                            label={t('user.paymentMethods.nameOnCard')}
                            step={true}
                        />
                    </div>
                </BreakpointWrapper>
                <div className="mt-4 md:flex md:justify-between">
                    <div />
                    <SkeletonButton className="w-full md:w-auto" step={true}>
                        {t('user.paymentMethods.saveCard')}
                    </SkeletonButton>
                </div>
            </div>
        </Skeleton>
    );
};

export default UserPaymentMethodsSkeleton;
