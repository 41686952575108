import {FC} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

export type FooterSocialMediaProps = {
    className?: string;
};

const FooterSocialMedia: FC<FooterSocialMediaProps> = ({className}) => (
    <div className={clsx('items-center gap-5 text-lg sm:gap-4', className)}>
        <a
            className="link-item"
            href="https://facebook.com/pocketmenu413"
            rel="noreferrer"
            target="_blank"
        >
            <FontAwesomeIcon icon={['fab', 'facebook']} />
        </a>
        <a
            className="link-item"
            href="https://instagram.com/pocket_concierge"
            rel="noreferrer"
            target="_blank"
        >
            <FontAwesomeIcon icon={['fab', 'instagram']} />
        </a>
    </div>
);

export default FooterSocialMedia;
