import {forwardRef, SelectHTMLAttributes} from 'react';
import {FieldError} from 'react-hook-form';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {SelectOption} from './types';

export type BaseSelectProps<T = HTMLSelectElement> = SelectHTMLAttributes<T> & {
    classNameIcon?: string;
    classNameSelect?: string;
    error?: FieldError;
    name: string;
    options: SelectOption[];
    /**
     * string to display if you want an unselected choice
     */
    unselected?: string;
    unselectedIcon?: IconProp;
};

const BaseSelect = forwardRef<HTMLSelectElement, BaseSelectProps>(
    (
        {
            className,
            classNameIcon,
            classNameSelect,
            defaultValue,
            disabled,
            error,
            id,
            name,
            options,
            required,
            unselected,
            unselectedIcon,
            value,
            ...rest
        },
        ref
    ) => {
        const isUnselected = unselected && !value;

        const icon = isUnselected
            ? unselectedIcon
            : options.find((option) => option.value === value)?.icon;

        return (
            <div className={clsx('relative', className)}>
                <select
                    ref={ref}
                    className={clsx(
                        'w-full select-none',
                        isUnselected && 'text-grey-500 dark:text-grey-600',
                        !!icon && 'pl-9',
                        classNameSelect
                    )}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    id={id || name}
                    name={name}
                    required={required && !!error}
                    value={value}
                    {...rest}
                >
                    {unselected && (
                        <option disabled={required} value="">
                            {unselected}
                        </option>
                    )}
                    {options.map((option) =>
                        option.options ? (
                            <optgroup key={option.label} label={option.label}>
                                {option.options.map((groupOption) => (
                                    <option
                                        key={`${option.label}-${groupOption.value}`}
                                        disabled={groupOption.disabled}
                                        value={groupOption.value}
                                    >
                                        {groupOption.label}
                                    </option>
                                ))}
                            </optgroup>
                        ) : (
                            <option
                                key={option.value}
                                disabled={option.disabled}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        )
                    )}
                </select>
                {icon && (
                    <div
                        className={clsx(
                            'pointer-events-none absolute left-[0.8rem]',
                            (isUnselected || disabled) && 'text-disabled',
                            !classNameIcon?.includes('top-') &&
                                'top-[0.575rem]',
                            classNameIcon
                        )}
                    >
                        <FontAwesomeIcon icon={icon} />
                    </div>
                )}
            </div>
        );
    }
);

BaseSelect.displayName = 'BaseSelect';

export default BaseSelect;
