export const ThreeDSecureIcon = ({className = ''}: {className?: string}) => (
    <svg
        className={className}
        fill="none"
        height="24"
        viewBox="0 0 25 25"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect fill="#C1A06C" height="24" rx="12" width="24" />
        <path
            d="M11.5346 18.4099C11.6822 18.496 11.756 18.539 11.8602 18.5614C11.941 18.5787 12.0589 18.5787 12.1397 18.5614C12.2439 18.539 12.3177 18.496 12.4653 18.4099C13.764 17.6522 17.3333 15.2723 17.3333 12V9.46663C17.3333 8.7507 17.3333 8.39274 17.2229 8.13863C17.1108 7.88036 16.999 7.74297 16.769 7.58057C16.5427 7.42078 16.099 7.32851 15.2118 7.14398C14.2338 6.94059 13.4828 6.57332 12.7962 6.04218C12.467 5.78748 12.3023 5.66012 12.1735 5.62539C12.0376 5.58874 11.9623 5.58874 11.8264 5.62539C11.6976 5.66012 11.533 5.78748 11.2037 6.04218C10.5171 6.57332 9.76609 6.94059 8.78816 7.14398C7.90088 7.32851 7.45723 7.42078 7.23092 7.58057C7.00091 7.74297 6.88915 7.88036 6.77698 8.13863C6.66663 8.39274 6.66663 8.7507 6.66663 9.46663V12C6.66663 15.2723 10.2359 17.6522 11.5346 18.4099Z"
            stroke="#1D1A16"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
