import {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import clsx from 'clsx';
import {HomeCards} from 'components/MarketingContents/HomeCards';
import {NavigatorCards} from 'components/MarketingContents/NavigatorCards';
import Search from 'components/Search';
import {
    HomePageDocument,
    HomePageQuery,
    VenueSearchResultFragment,
} from 'gql/generated';
import useFadeIn from 'hooks/useFadeIn';
import useNavigateLocale from 'hooks/useNavigateLocale';
import useScrollToTop from 'hooks/useScrollToTop';
import {useLocale} from 'state/locale';
import {Locale} from 'types';
import {SearchValues} from 'types/search';
import {getVenueQueryFromValues} from 'utils/params';
import HomeFeaturedGroup from './HomeFeaturedGroup';
import HomePickUpVenuesGroup from './HomePickUpVenues';
import HomeVenueGroup from './HomeVenueGroup';
import styles from './styles.module.css';

const {headerShadow, heroImage} = styles;

const featuredVenueIds: {[key in Locale]: string[]} = {
    en: [
        '243688',
        '243932',
        '18',
        '243849',
        '244119',
        '244896',
        '244047',
        '244072',
        '243674',
        '243897',
        '244219',
        '244052',
    ],
    ja: [
        '243688',
        '243932',
        '18',
        '243849',
        '244119',
        '244896',
        '244047',
        '244072',
        '243674',
        '243897',
        '244061',
        '244219',
    ],
};

const HomePage: FC = () => {
    const fadeIn = useFadeIn();
    const {t} = useTranslation();
    const navigate = useNavigateLocale();
    const locale = useLocale();

    const {data} = useQuery<HomePageQuery>(HomePageDocument, {
        variables: {
            featuredVenueIds: featuredVenueIds[locale],
        },
    });

    useScrollToTop();

    const onSearch = (formData: SearchValues) => {
        navigate(`/restaurants${getVenueQueryFromValues(formData)}`);
    };

    return (
        <div>
            <div>
                <div
                    className={`mx-auto h-[400px] bg-cover bg-no-repeat sm:h-[280px] ${heroImage}`}
                >
                    <div className="h-full w-full dark:bg-grey-900/40">
                        <div className="site-container flex h-full items-center">
                            <div className="w-full sm:mt-1 lg:-mt-2 xl:mt-8">
                                <div className="sm:max-w-[85%] md:max-w-[75%] lg:max-w-[55%] xl:max-w-[48%]">
                                    <h2
                                        className={clsx(
                                            'text-2xl font-light text-white sm:text-2xl lg:leading-relaxed',
                                            headerShadow,
                                            fadeIn
                                        )}
                                    >
                                        <Trans i18nKey="home.makeReservationsThroughPocket" />
                                    </h2>
                                </div>
                                <Search
                                    className={clsx(
                                        'mt-4 sm:mt-6 xl:mt-6',
                                        fadeIn
                                    )}
                                    isHomePage={true}
                                    onSearch={onSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-12 pt-5 sm:pt-10">
                <HomeCards />
                <NavigatorCards />
                {!!data?.pickUp?.collection?.length && (
                    <HomePickUpVenuesGroup
                        name={t('home.pickUpVenues')}
                        venues={
                            data.pickUp
                                .collection as VenueSearchResultFragment[]
                        }
                    />
                )}
                {!!data?.newArrivals?.collection?.length && (
                    <HomeVenueGroup
                        name={t('home.newArrivals')}
                        venues={
                            data.newArrivals
                                .collection as VenueSearchResultFragment[]
                        }
                    />
                )}
                {!!data?.featured?.collection?.length && (
                    <HomeFeaturedGroup
                        venues={
                            data.featured
                                .collection as VenueSearchResultFragment[]
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default HomePage;
