import {RegisterOptions} from 'react-hook-form';

type RegisterRules = {
    disabled?: boolean;
    required?: boolean | string;
    rules?: RegisterOptions;
};

// eslint-disable-next-line import/prefer-default-export
export const getRegisterRules = ({disabled, required, rules}: RegisterRules) =>
    rules ||
    (required && disabled
        ? {disabled, required}
        : required
        ? {required}
        : disabled
        ? {disabled}
        : undefined);
