import {FC, useEffect, useMemo, useState} from 'react';
import {
    FormProvider,
    useForm,
    useFormContext,
    UseFormSetValue,
} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {
    addDays,
    differenceInDays,
    endOfMonth,
    format,
    getDaysInMonth,
    lastDayOfMonth,
    set,
    startOfMonth,
} from 'date-fns';
import {useLocale} from 'state/locale';
import {getRange} from 'utils/array';
import {toISO8601Date} from 'utils/date';
import Chain from '../Chain';
import FieldLabel from '../Field/FieldLabel';
import Select from '../Select';
import {FormContextString} from '../types';

type FormValues = {
    date: string;
    month: string;
    year: string;
};

const getOptions = (array: number[]) =>
    array.map((value) => ({label: String(value), value: String(value)}));

const getSafeDate =
    (
        which: 'month' | 'year',
        next: string,
        setValue: UseFormSetValue<FormValues>
    ) =>
    (prev: Date) => {
        // ensure date is valid (i.e. no June 31, Feb 30, Feb 29 on non-leap years, etc.)
        const safe = {
            date: 1,
            month: which === 'month' ? Number(next) : prev.getMonth(),
            year: which === 'year' ? Number(next) : prev.getFullYear(),
        };
        const prevDate = prev.getDate();
        const daysInMonth = getDaysInMonth(set(prev, safe));

        if (prevDate > daysInMonth) {
            const lastDay = lastDayOfMonth(set(prev, safe)).getDate();

            setValue('date', String(lastDay));

            return set(prev, {
                ...safe,
                date: lastDay,
            });
        }

        return set(prev, {[which]: Number(next)});
    };

const TODAY = set(new Date(), {
    hours: 12,
    milliseconds: 0,
    minutes: 0,
    seconds: 0,
});

const THIS_YEAR = TODAY.getFullYear();
const YEARS = getOptions(getRange(THIS_YEAR - 120, THIS_YEAR - 12).reverse());
const MONTHS = getRange(0, 11);

const DEFAULT_DOB = set(TODAY, {
    date: 1,
    month: 0,
    year: 2000,
});

export type YearMonthDayProps = {
    className?: string;
    defaultValue?: Date;
    name: string;
    required?: boolean;
};

const YearMonthDay: FC<YearMonthDayProps> = ({
    className,
    defaultValue = DEFAULT_DOB,
    name,
    required,
}) => {
    const locale = useLocale();
    const {t} = useTranslation();

    const {
        formState: {errors},
        setValue,
    } = useFormContext<FormContextString>();

    const [safeDate, setSafeDate] = useState(() => defaultValue);

    const methods = useForm<FormValues>({
        defaultValues: {
            date: String(defaultValue.getDate()),
            month: String(defaultValue.getMonth()),
            year: String(defaultValue.getFullYear()),
        },
    });

    methods.watch();

    const [year, month, date] = methods.getValues(['year', 'month', 'date']);

    useEffect(() => {
        setSafeDate(getSafeDate('year', year, methods.setValue));
    }, [year, methods.setValue]);

    useEffect(() => {
        setSafeDate(getSafeDate('month', month, methods.setValue));
    }, [month, methods.setValue]);

    useEffect(() => {
        setSafeDate((prev) => set(prev, {date: Number(date)}));
    }, [date]);

    useEffect(() => {
        setValue(name, toISO8601Date(safeDate));
    }, [name, safeDate, setValue]);

    const months = useMemo(
        () =>
            MONTHS.map((m) => ({
                label:
                    locale === 'en'
                        ? format(set(DEFAULT_DOB, {month: m}), 'MMMM')
                        : String(m + 1),
                value: String(m),
            })),
        [locale]
    );

    const dates = useMemo(() => {
        const current = set(DEFAULT_DOB, {
            month: Number(month),
            year: Number(year),
        });
        const start = startOfMonth(current);
        const end = endOfMonth(current);

        return getOptions(
            Array(differenceInDays(end, start) + 1)
                .fill(start)
                .map((s, index) => addDays(s, index).getDate())
        );
    }, [month, year]);

    const yearSelect = (
        <Select
            aria-label={t('date.year')}
            id={`${name}-year`}
            label={t('date.year')}
            name="year"
            options={YEARS}
        />
    );

    const monthSelect = (
        <Select
            aria-label={t('date.month')}
            id={`${name}-month`}
            label={t('date.month')}
            name="month"
            options={months}
        />
    );

    const daySelect = (
        <Select
            aria-label={t('date.day')}
            id={`${name}-day`}
            label={t('date.day')}
            name="date"
            options={dates}
        />
    );

    return (
        <fieldset className={className}>
            <FieldLabel
                error={errors[name]}
                isLegend={true}
                required={required}
            >
                {t('user.basicInformation.dob')}
            </FieldLabel>
            <Chain className="mt-2" isFullWidth={true}>
                <FormProvider {...methods}>
                    {locale === 'en' ? (
                        <>
                            {monthSelect}
                            {daySelect}
                            {yearSelect}
                        </>
                    ) : (
                        <>
                            {yearSelect}
                            {monthSelect}
                            {daySelect}
                        </>
                    )}
                </FormProvider>
            </Chain>
        </fieldset>
    );
};

export default YearMonthDay;
