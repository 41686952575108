import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import {
    HomeCardSkeleton,
    HomeCardTitle,
    MarketingHomeCard,
} from 'components/MarketingContents/Shared';
import {
    MarketingContentDocument,
    MarketingContentQuery,
    MarketingContentTypeEnum,
} from 'gql/generated';

export const NavigatorCards: FC = () => {
    const {t} = useTranslation();

    const {data} = useQuery<MarketingContentQuery>(MarketingContentDocument);

    const marketingContent = data?.retrieveMarketingContent?.filter((item) => {
        return item.contentType === MarketingContentTypeEnum.Navigator;
    });

    return (
        <div>
            <HomeCardTitle name={t('home.secondRow')} />
            <div className="site-container mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:gap-2 2xl:gap-4">
                {!marketingContent &&
                    [1, 2, 3, 4].map((item) => <HomeCardSkeleton key={item} />)}
                {marketingContent &&
                    marketingContent.map((item) => (
                        <MarketingHomeCard
                            key={item.id}
                            imageUrl={item.imageUrl}
                            isExternal={item.external}
                            link={item.link}
                        />
                    ))}
            </div>
        </div>
    );
};
