import {FC, ReactNode} from 'react';
import clsx from 'clsx';
import ReservationInformation from '../index';
import {useReservationInformationState} from '../provider';

type DesktopReservationInformationProps = {
    children?: ReactNode;
    className?: string;
    isFixed?: boolean;
};

const DesktopReservationInformation: FC<DesktopReservationInformationProps> = ({
    children,
    className,
    isFixed,
}) => {
    const {venue} = useReservationInformationState();

    if (!venue?.name) return null;

    return (
        <div className={className}>
            <div
                className={clsx(
                    'border-venue-result rounded-md bg-grey-50 p-5 text-grey-800 dark:bg-grey-800 dark:text-grey-100',
                    isFixed && 'sticky top-[5rem]'
                )}
            >
                <h2 className="text-center">{venue.name}</h2>
                <hr className="mt-3" />
                <ReservationInformation className="mt-4" />
                {children}
            </div>
        </div>
    );
};

export default DesktopReservationInformation;
