import {FC, ReactNode} from 'react';
import clsx from 'clsx';

type Alignment = 'center' | 'left' | 'right';

export type FormActionsProps = {
    align?: Alignment;
    children: ReactNode;
    className?: string;
};

const ALIGN: Record<Alignment, string> = {
    center: 'justify-around',
    left: 'pl-0.5',
    right: 'justify-end',
};

const FormActions: FC<FormActionsProps> = ({
    align = 'right',
    className,
    ...rest
}) => <div className={clsx('flex gap-6', ALIGN[align], className)} {...rest} />;

export default FormActions;
