/* The order in the side bar is based on the order of the object */
/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */

import {Sections} from 'types/routes';
import {DEFAULT_STATUS, DEFAULT_TIME} from './Reservations/ReservationList';

const userAccountSections: Sections = {
    reservations: {
        path: `reservations?status=${DEFAULT_STATUS}&time=${DEFAULT_TIME}`,
        translationKey: 'user.reservations.title',
    },
    bookmarks: {
        path: 'bookmarks',
        translationKey: 'bookmarks.title',
    },
    profile: {
        path: 'profile',
        translationKey: 'user.profile',
    },
    paymentMethods: {
        path: 'payment-methods',
        translationKey: 'user.paymentMethods.title',
    },
    settings: {
        path: 'settings',
        translationKey: 'user.settings',
    },
};

export default userAccountSections;
