import {FC, ReactNode} from 'react';
import useTimeout from 'hooks/useTimeout';

export type SkeletonProps = {
    children: ReactNode;
    className?: string;
    delay?: number;
};

const DEFAULT_DELAY = process.env.NODE_ENV === 'test' ? 1 : 100;

const Skeleton: FC<SkeletonProps> = ({
    children,
    className,
    delay = DEFAULT_DELAY,
}) => {
    const show = useTimeout(delay);

    return show ? (
        <div className={className} role="progressbar">
            {children}
        </div>
    ) : null;
};

export default Skeleton;
