import {FC, ReactNode} from 'react';
import clsx from 'clsx';
import {SIZE, TEXT} from 'components/Form/Base/BaseRadioButtons';
import CheckboxRadioGroup from 'components/Form/CheckboxRadioGroup';
import {Option, Size} from 'types';
import SkeletonField from '../SkeletonField';
import SkeletonText from '../SkeletonText';

export type SkeletonRadioButtonsProps = {
    className?: string;
    classNameGroup?: string;
    helpText?: ReactNode;
    isHorizontal?: boolean;
    label?: ReactNode;
    options: Option[];
    required?: boolean;
    size?: Size;
    step?: boolean;
};

const SkeletonRadioButtons: FC<SkeletonRadioButtonsProps> = ({
    className,
    classNameGroup,
    helpText,
    isHorizontal,
    label,
    options,
    required,
    size = 'base',
    step,
}) => (
    <SkeletonField
        className={className}
        helpText={helpText}
        label={label}
        required={required}
        step={step}
        type="radio"
    >
        <CheckboxRadioGroup
            className={clsx(label && 'mt-2', classNameGroup)}
            isHorizontal={isHorizontal}
        >
            {options.map((option) => (
                <div
                    key={option.value}
                    className="flex w-fit select-none items-center"
                >
                    <div
                        className={clsx(
                            'skeleton flex items-center justify-center rounded-full',
                            step && 'step',
                            SIZE[size]
                        )}
                    />
                    <SkeletonText
                        className={clsx(
                            'ml-2',
                            TEXT[size],
                            size === 'xl' && 'mt-[3px]'
                        )}
                        step={step}
                    >
                        {option.label}
                    </SkeletonText>
                </div>
            ))}
        </CheckboxRadioGroup>
    </SkeletonField>
);

export default SkeletonRadioButtons;
