import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Button, {ButtonProps} from 'components/Button';

const SubmitButton: FC<ButtonProps> = ({
    children,
    className,
    disabled,
    loading,
    onClick,
    ...rest
}) => {
    const {t} = useTranslation();

    const {
        clearErrors,
        formState: {isSubmitting},
    } = useFormContext();

    return (
        <Button
            {...rest}
            className={className}
            disabled={isSubmitting || disabled}
            loading={isSubmitting ? loading || t('form.pleaseWait') : undefined}
            onClick={(event) => {
                clearErrors();
                onClick?.(event);
            }}
            type="submit"
        >
            <div>{children || t('form.submit')}</div>
        </Button>
    );
};

export default SubmitButton;
