import {FC} from 'react';
import {ApolloError} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {useErrorHandling} from 'hooks/useServerErrorHandling';

export type ServiceErrorProps = {
    className?: string;
    error: ApolloError;
};

const ServiceError: FC<ServiceErrorProps> = ({className, error}) => {
    const {handleServerError} = useErrorHandling();

    const {message, traceId} = handleServerError(error);

    return (
        <div
            className={clsx(
                'bg-step flex flex-col items-center justify-center py-6 text-sm md:text-base',
                className
            )}
            role="alert"
        >
            <FontAwesomeIcon
                className="text-invalid-step w-full"
                icon={['fas', 'exclamation-circle']}
                size="3x"
            />
            <div className="text-sub mt-3 text-center leading-loose">
                {message}
            </div>
            {traceId && <p className="text-center text-sm">{traceId}</p>}
        </div>
    );
};

export default ServiceError;
