import {FC} from 'react';
import type {RailsContext} from 'react-on-rails/node_package/lib/types';
import {BrowserRouter} from 'react-router-dom';
import Routes from 'routes';
import State from 'state';
import checkColorScheme from 'utils/color-scheme';
import initializeDatadogRUM from 'utils/datadog-rum';
import {SEMVER_COMMIT_HASH} from 'utils/environment';
import {initializeI18n} from '../i18n';
import '@stripe/stripe-js';

initializeI18n();
checkColorScheme();
initializeDatadogRUM();

const ClientEntrypoint = (_: unknown, railsContext?: RailsContext): FC =>
    (() => {
        // Log version to be able to check version in Production
        // eslint-disable-next-line no-console
        console.info(`On version: ${SEMVER_COMMIT_HASH}`);

        return (
            <State railsContext={railsContext}>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </State>
        );
    }) as FC;

export default ClientEntrypoint;
