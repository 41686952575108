import {useMemo} from 'react';
import {NavigateOptions, useNavigate} from 'react-router-dom';
import {useLocale} from 'state/locale';

const useNavigateLocale = () => {
    const locale = useLocale();
    const navigate = useNavigate();

    return useMemo(
        () => (path: string, options?: NavigateOptions) => {
            if (locale === 'en') {
                navigate(`/en${path}`, options);
            } else {
                navigate(path, options);
            }
        },
        [locale, navigate]
    );
};

export default useNavigateLocale;
