import {FC, ReactNode} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, Stripe as TStripe} from '@stripe/stripe-js';
import {useLocale} from 'state/locale';

// eslint-disable-next-line no-var
var stripePromise: TStripe | PromiseLike<TStripe | null> | null = null;

type StripeProps = {
    children: ReactNode;
};

const Stripe: FC<StripeProps> = ({children}) => {
    if (stripePromise === null) {
        stripePromise = loadStripe(
            process.env.STRIPE_PUBLISHABLE_KEY as string
        );
    }
    const locale = useLocale();

    return (
        <Elements key={locale} options={{locale}} stripe={stripePromise}>
            {children}
        </Elements>
    );
};

export default Stripe;
