import {FC, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as ReactRouterLink, useSearchParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import LocaleSelector from 'components/LocaleSelector';
import Modal from 'components/Modal';
import Authenticate from 'components/User/Authentication';
import useBreakpoint from 'hooks/useBreakpoint';
import {useLocale} from 'state/locale';
import {useUser} from 'state/user';
import {AuthenticationMode} from 'types';
import DesktopUserMenu from './DesktopUserMenu';
import MobileUserMenu from './MobileUserMenu';

export type HeaderUserMenuProps = {
    isAuthenticatedPage?: boolean;
};

const HeaderUserMenu: FC<HeaderUserMenuProps> = ({isAuthenticatedPage}) => {
    const {t} = useTranslation();
    const locale = useLocale();

    const [searchParams, setSearchParams] = useSearchParams();

    let selectedModal = searchParams.get('modalType') as AuthenticationMode;

    if (!(selectedModal === 'login' || selectedModal === 'signup')) {
        selectedModal = '';
    }

    const user = useUser();
    const fullName = user ? t('user.fullName', user) : undefined;

    const isDesktop = useBreakpoint('md');
    const [auth, setAuth] = useState<AuthenticationMode>(selectedModal);

    const onClose = useCallback(() => {
        setAuth('');
        setSearchParams((prev) => {
            prev.delete('modalType');

            return prev;
        });
    }, [setSearchParams]);

    if (!isDesktop) {
        if (!auth) {
            if (user || !isAuthenticatedPage) {
                return <MobileUserMenu fullName={fullName} setAuth={setAuth} />;
            }

            return null;
        }

        return (
            <Modal onClose={onClose}>
                <Authenticate login={auth === 'login'} onClose={onClose} />
            </Modal>
        );
    }

    if (!user) {
        if (!isAuthenticatedPage) {
            return (
                <div className="flex items-center justify-between gap-3">
                    <LocaleSelector
                        classNameSelect={`hover:cursor-pointer ${
                            locale === 'en' ? 'pr-8' : 'pr-7'
                        }`}
                    />
                    <ReactRouterLink
                        className="link-item hover:text-body mr-2 inline-block text-sm"
                        rel="noreferrer"
                        target="_blank"
                        to={
                            locale === 'ja'
                                ? (process.env.CUSTOMER_HELP_FAQ_JA as string)
                                : (process.env.CUSTOMER_HELP_FAQ_EN as string)
                        }
                    >
                        <FontAwesomeIcon icon={['far', 'question-circle']} />
                        <span className="ml-2">{t('staticPages.faq')}</span>
                    </ReactRouterLink>
                    <Button
                        className="min-w-0 px-4"
                        kind="tertiary"
                        onClick={() => setAuth('login')}
                        size="sm"
                    >
                        {t('authentication.login.title')}
                    </Button>
                    <Button
                        className="min-w-0 border-grey-700 bg-grey-700 px-4 font-semibold text-white"
                        kind="custom"
                        onClick={() => setAuth('signup')}
                        size="sm"
                    >
                        {t('authentication.signup.title')}
                    </Button>
                    {auth && (
                        <Modal onClose={onClose}>
                            <Authenticate
                                login={auth === 'login'}
                                onClose={onClose}
                            />
                        </Modal>
                    )}
                </div>
            );
        }

        return null;
    }

    return <DesktopUserMenu />;
};

export default HeaderUserMenu;
