import {ChangeEvent, FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import BaseSelect from 'components/Form/Base/BaseSelect';
import useNavigateLocale from 'hooks/useNavigateLocale';
import {Sections} from 'types/routes';

type UserAccountMobileMenuProps = {
    sections: Sections;
};

const UserAccountMobileMenu: FC<UserAccountMobileMenuProps> = ({sections}) => {
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const navigate = useNavigateLocale();

    const options = useMemo(
        () =>
            Object.values(sections).map(({path, translationKey}) => ({
                label: t(translationKey),
                value: path,
            })),
        [sections, t]
    );

    const section = pathname
        .split('/')
        .filter((segment) => segment !== '')
        .pop();
    const path = options.find((option) => option.value === section)?.value;

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const next = event.currentTarget.value as string;

        if (pathname.split('/').includes('account')) {
            navigate(`/account/${next}`);
        } else {
            navigate(`/${next}`);
        }
    };

    return (
        <div className="border-outline mb-4 border-b px-4 pb-1 pt-3 md:hidden">
            <BaseSelect
                key={path}
                className="mt-1 pb-2.5"
                classNameSelect="border-none bg-step"
                name="section"
                onChange={onChange}
                options={options}
                value={path}
            />
        </div>
    );
};

export default UserAccountMobileMenu;
