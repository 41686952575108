import {FC, ReactNode} from 'react';
import clsx from 'clsx';
import {SIZE, TEXT} from 'components/Form/Base/BaseCheckbox';
import {Size} from 'types';
import SkeletonHelp from '../SkeletonField/SkeletonHelp';
import SkeletonText from '../SkeletonText';

export type SkeletonCheckboxProps = {
    className?: string;
    helpText?: ReactNode;
    label?: ReactNode;
    size?: Size;
    step?: boolean;
};

const SkeletonCheckbox: FC<SkeletonCheckboxProps> = ({
    className,
    helpText,
    label,
    size = 'base',
    step,
}) => {
    const skeleton = (
        <div
            className={clsx(
                'skeleton rounded',
                SIZE[size],
                step && 'step',
                className
            )}
        />
    );

    const help = helpText ? (
        <SkeletonHelp helpText={helpText} step={step} />
    ) : null;

    if (!label) {
        return (
            <>
                {skeleton}
                {help}
            </>
        );
    }
    const field = (
        <div
            className={clsx(
                'group inline-flex select-none items-center',
                !help && className
            )}
        >
            {skeleton}
            <SkeletonText
                className={clsx(
                    'ml-2',
                    size === 'xl' && 'mt-[3px]',
                    TEXT[size]
                )}
                step={step}
            >
                {label}
            </SkeletonText>
        </div>
    );

    if (!help) {
        return field;
    }

    return (
        <div className={className}>
            {field}
            {help}
        </div>
    );
};

export default SkeletonCheckbox;
