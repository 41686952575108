import {FC, MutableRefObject, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as ReactRouterLink, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import ButtonAnchor from 'components/ButtonAnchor';
import FallbackImage from 'components/FallbackImage';
import Link from 'components/Link';
import {
    ReservationBaseFragment,
    ReservationQuestionnaireFragment,
    ReservationRyoshushoFragment,
    ReservationStatus as GqlReservationStatus,
    ReservationVenueFragment,
    ReservationWaitingListRequestFragment,
    ReserveUserProfileFragment,
} from 'gql/generated';
import useBreakpointChange from 'hooks/useBreakpointChange';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';
import {useLocale} from 'state/locale';
import {
    isAfterSixPmDayBefore,
    toFullDate,
    toISO8601Date,
    toJST,
    toTime,
} from 'utils/date';
import {Details} from './Details/Details';
import {Questionnaire} from './Questionnaire/Questionnaire';
import {QuestionnaireStatus} from './Questionnaire/QuestionnaireStatus';
import {ReservationStatus} from './ReservationStatus';

export type FullReservationWithoutCancellationInfo = ReservationBaseFragment &
    ReservationQuestionnaireFragment &
    ReservationRyoshushoFragment &
    ReservationVenueFragment &
    ReservationWaitingListRequestFragment;

type Props = {
    elementRef?: MutableRefObject<HTMLDivElement | null> | null;
    isPreExpanded?: boolean;
    reservation: FullReservationWithoutCancellationInfo;
    user: ReserveUserProfileFragment;
};

export const ListElement: FC<Props> = ({
    elementRef,
    isPreExpanded = false,
    reservation,
    user,
}) => {
    const {t} = useTranslation();
    const locale = useLocale();
    const breakpoint = useCurrentBreakpoint();
    const showFullDate = breakpoint !== 'md';
    const navigate = useNavigate();

    const {
        cancellationPossible,
        dateTime,
        id,
        numberOfPeople,
        questionnaire,
        ryoshusho,
        status,
        venue,
        waitingListRequest,
    } = reservation;

    const reservationDate = useMemo(
        () =>
            showFullDate
                ? // The BE sends a ISO8601Date without a time zone, we therefore need to explicitly cast it to JST
                  toFullDate(toJST(dateTime), locale)
                : toISO8601Date(toJST(dateTime)),
        [dateTime, locale, showFullDate]
    );

    const guestIcon =
        numberOfPeople === 1
            ? 'user'
            : numberOfPeople === 2
            ? 'user-friends'
            : 'users';

    const [showDetails, setShowDetails] = useState(isPreExpanded);

    const onDetailsToggle = useCallback(() => {
        setShowDetails((p) => !p);
    }, []);

    useBreakpointChange(() => setShowDetails(false));

    const showQuestionnaire =
        status === GqlReservationStatus.Visited &&
        !questionnaire?.satisfactionRating;

    const venueImage = venue.thumbnail || venue.imageUrls[0];

    const venueLinkId = venue.isPublished
        ? venue.id
        : venue.limitedScopeUrlHash;

    return (
        <div
            ref={elementRef}
            className="border-outline rounded-md border p-2.5 md:px-4 md:pb-4"
            id={id}
        >
            <h3 className="border-outline hidden min-h-[2.8125rem] border-b pb-2.5 md:flex md:items-center md:justify-between md:gap-4">
                <Link className="link-body" to={`/restaurants/${venueLinkId}`}>
                    <span className="line-clamp-2 font-semibold leading-snug">
                        {venue.name}
                    </span>
                </Link>
                <div className="flex flex-row gap-x-2">
                    {cancellationPossible ? (
                        <>
                            {!waitingListRequest && (
                                <Button
                                    className="flex-none"
                                    kind="secondary"
                                    onClick={() => navigate(`${id}/message`)}
                                    size="sm"
                                >
                                    {t('user.reservations.button.inquiry')}
                                </Button>
                            )}
                            <Button
                                className="flex-none"
                                kind="secondary"
                                onClick={() => navigate(`${id}/cancel`)}
                                size="sm"
                            >
                                {waitingListRequest
                                    ? t('user.reservations.button.withdraw')
                                    : t('user.reservations.button.cancel')}
                            </Button>
                        </>
                    ) : (
                        ryoshusho &&
                        ryoshusho.url && (
                            <ReactRouterLink
                                className="flex-none select-none whitespace-nowrap rounded-md border border-gold-200 px-2 py-1.5 text-sm text-gold-400 hover:no-underline dark:text-gold-200"
                                rel="noopener noreferrer"
                                target="_blank"
                                to={ryoshusho.url}
                            >
                                {t('user.reservations.button.invoice')}
                            </ReactRouterLink>
                        )
                    )}
                </div>
            </h3>
            <div className="grid grid-cols-12 gap-4 md:mt-2.5">
                <div className="col-span-4 hidden md:block lg:col-span-3">
                    <figure className="relative max-h-[12.375rem]">
                        {venueImage ? (
                            <div className="w-full pb-[56.5%]">
                                <picture>
                                    <img
                                        alt={venue.name}
                                        className="absolute left-0 top-0 h-full max-h-[12.375rem] w-full overflow-hidden rounded-md object-cover object-center"
                                        src={venueImage}
                                    />
                                </picture>
                            </div>
                        ) : (
                            <div className="w-full pb-[56.5%]">
                                <picture>
                                    <FallbackImage
                                        alt={venue.name}
                                        className="absolute left-0 top-0 h-full max-h-[12.375rem] w-full overflow-hidden rounded-md object-cover object-center"
                                    />
                                </picture>
                            </div>
                        )}
                    </figure>
                </div>
                <h3 className="col-span-full md:hidden">
                    <Link
                        className="link-body"
                        to={`/restaurants/${venueLinkId}`}
                    >
                        <span className="line-clamp-3 font-semibold leading-snug">
                            {venue.name}
                        </span>
                    </Link>
                </h3>
                <hr className="col-span-full -mt-2 md:hidden" />
                <div className="col-span-full -mt-2 grid grid-cols-1 items-start gap-4 md:col-span-8 md:mt-0 md:grid-cols-12 md:gap-2 lg:col-span-9 lg:gap-4">
                    <div className="col-span-1 grid grid-cols-1 gap-3.5 md:col-span-4 md:pt-3 lg:col-span-5">
                        <div className="flex items-center text-sm">
                            <div className="w-5 text-center">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={['far', 'calendar']}
                                />
                            </div>
                            <span className="ml-2.5">{reservationDate}</span>
                        </div>
                        <div className="flex items-center text-sm">
                            <div className="w-5 text-center">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={['fas', 'clock']}
                                />
                            </div>
                            <span className="ml-2.5">
                                {waitingListRequest ? (
                                    <>
                                        {/* Backend issue: we need to use reservation.dateTime
                                            instead of waitingListRequest.dateTimeRangeStart
                                            https://github.com/pocket-concierge/pokeme_rails/pull/9854#issuecomment-1384042575
                                        */}
                                        {toTime(dateTime, locale)}
                                        &nbsp;-&nbsp;
                                        {toTime(
                                            waitingListRequest.dateTimeRangeEnd,
                                            locale
                                        )}
                                    </>
                                ) : (
                                    toTime(dateTime, locale)
                                )}
                            </span>
                        </div>
                        <div className="flex items-center text-sm">
                            <div className="w-5 text-center">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={['fas', guestIcon]}
                                />
                            </div>
                            <span className="ml-2.5">
                                {t('reservation.guestCount', {
                                    count: numberOfPeople,
                                })}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-2 md:hidden">
                        {cancellationPossible ? (
                            <>
                                {!waitingListRequest && (
                                    <Button
                                        className="col-span-1 -mb-1 w-full grow"
                                        kind="secondary"
                                        onClick={() =>
                                            navigate(`${id}/message`)
                                        }
                                        size="sm"
                                    >
                                        {t('user.reservations.button.inquiry')}
                                    </Button>
                                )}
                                <Button
                                    className="col-span-1 -mb-1 w-full shrink"
                                    kind="secondary"
                                    onClick={() => navigate(`${id}/cancel`)}
                                    size="sm"
                                >
                                    {waitingListRequest
                                        ? t('user.reservations.button.withdraw')
                                        : t('user.reservations.button.cancel')}
                                </Button>
                            </>
                        ) : (
                            ryoshusho &&
                            ryoshusho.url && (
                                <ReactRouterLink
                                    className="grow select-none whitespace-nowrap rounded-md border border-gold-200 px-2 py-1.5 text-center text-gold-400 hover:no-underline dark:text-gold-200"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    to={ryoshusho.url}
                                >
                                    {t('user.reservations.button.invoice')}
                                </ReactRouterLink>
                            )
                        )}
                    </div>
                    {questionnaire?.satisfactionRating ? (
                        <QuestionnaireStatus
                            className="col-span-1 md:col-span-8 lg:col-span-7"
                            feedback={questionnaire.feedback}
                            satisfactionRating={
                                questionnaire.satisfactionRating
                            }
                        />
                    ) : (
                        <ReservationStatus
                            className="col-span-1 md:col-span-8 lg:col-span-7"
                            status={status}
                            waitlistDeadline={waitingListRequest?.deadline}
                        />
                    )}
                </div>
                {/* Only show the day before message if the it is not cancellable anymore and the reservation is upcoming */}
                {!cancellationPossible &&
                    status === GqlReservationStatus.Confirmed &&
                    isAfterSixPmDayBefore(new Date(dateTime)) && (
                        <div
                            className="bg-step col-span-full flex flex-row items-center rounded-md border border-orange-800 p-1 text-sm dark:border-orange-700 md:text-base"
                            role="alert"
                        >
                            <FontAwesomeIcon
                                className="pr-2 text-orange-800 dark:text-orange-700"
                                icon={['fas', 'exclamation-circle']}
                                size="1x"
                            />
                            <div className="text-sub text-center">
                                {t(
                                    'user.reservations.cancelDayBeforeInformation',
                                    {
                                        tel: venue.phoneNumber,
                                    }
                                )}
                            </div>
                        </div>
                    )}
                {showDetails && (
                    <Details
                        isModal={breakpoint === 'xs'}
                        onClose={onDetailsToggle}
                        reservation={reservation}
                        user={user}
                    />
                )}
                {showQuestionnaire && (
                    <Questionnaire reservation={reservation} />
                )}
                <div className="col-span-full place-self-end">
                    <ButtonAnchor
                        className="flex items-end gap-1.5 text-sm md:items-center"
                        onClick={onDetailsToggle}
                    >
                        <span>
                            {t(
                                showDetails
                                    ? 'user.reservations.hideReservationDetails'
                                    : 'user.reservations.showReservationDetails'
                            )}
                        </span>
                        <FontAwesomeIcon
                            icon={[
                                'fas',
                                showDetails ? 'chevron-up' : 'chevron-right',
                            ]}
                        />
                    </ButtonAnchor>
                </div>
            </div>
        </div>
    );
};
