/* eslint-disable no-console */
import {datadogRum} from '@datadog/browser-rum';
import {isProduction, SEMVER} from './environment';

const initializeDatadogRUM = () => {
    // Production and Staging are both using the same NODE environment variable
    const isProductionOrStaging = process.env.NODE_ENV === 'production';

    if (isProductionOrStaging) {
        const applicationId = process.env.DATADOG_RUM_APPLICATION_ID;
        const clientToken = process.env.DATADOG_RUM_CLIENT_TOKEN;

        if (!applicationId) {
            console.error('Datadog Application Id is not set.');

            return;
        }

        if (!clientToken) {
            console.error('Datadog Client Token is not set.');

            return;
        }

        // TODO: Reduce the sampleRates before the production release (PDE-2476)
        datadogRum.init({
            allowedTracingUrls: [/https:\/\/(.*\.)?pocket-concierge\.jp/],
            applicationId,
            clientToken,
            defaultPrivacyLevel: 'mask-user-input',
            // Heatmap/Clickmap is still an experimental feature but is desired by the stakeholders
            enableExperimentalFeatures: ['clickmap'],
            // We log based on the URL to the right Datadog RUM environment
            env: isProduction() ? 'production' : 'staging',
            service: 'pocket-concierge-fe',
            sessionReplaySampleRate: 100,
            // Adjust this number if the Datadog billing is too high
            sessionSampleRate: 100,
            site: 'datadoghq.com',
            // We only log 20% of the backlog traces because it significantly increases the usage/cost of APM
            traceSampleRate: 20,
            trackLongTasks: true,
            trackResources: true,
            trackUserInteractions: true,
            // Caution: The version has to be in alignment with the version in the "upload-source-maps"-command!
            version: SEMVER,
        });

        datadogRum.startSessionReplayRecording();
    }
};

export default initializeDatadogRUM;
