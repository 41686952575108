import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {SeatingType} from 'gql/generated';

type SeatingSelectionProps = {
    availableSeatingOptions: SeatingType[];
    onSelect: (value: SeatingType) => void;
    resetTimeSlots: () => void;
    seatingOptions: SeatingType[];
    seatingType: SeatingType | undefined;
};

const SeatingSelection: FC<SeatingSelectionProps> = ({
    availableSeatingOptions,
    onSelect,
    resetTimeSlots,
    seatingOptions,
    seatingType,
}) => {
    const {t} = useTranslation();

    return (
        <fieldset className="pb-4">
            <legend className="text-sm font-semibold">
                {t('search.selectSeating')}
            </legend>
            <div className="mt-2 grid grid-cols-3 gap-2">
                {seatingOptions.map((option) => (
                    <button
                        key={option}
                        className={`flex min-w-[5rem] items-center justify-center rounded-md border p-1.5 text-sm font-semibold
                            ${
                                option === seatingType
                                    ? 'button-secondary-selected dark:bg-gold-200'
                                    : 'border-outline'
                            } 
                                ${
                                    availableSeatingOptions.includes(option)
                                        ? 'bg-body'
                                        : 'border-dashed bg-grey-100 dark:border-grey-800 dark:bg-grey-700 dark:text-grey-800'
                                }
                        `}
                        data-dd-action-name="SeatingType"
                        onClick={() => {
                            onSelect(option);

                            if (!availableSeatingOptions.includes(option)) {
                                resetTimeSlots();
                            }
                        }}
                        type="button"
                    >
                        <span>{t(`seatingType.${option}`)}</span>
                    </button>
                ))}
            </div>
        </fieldset>
    );
};

export default SeatingSelection;
