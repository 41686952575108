/* eslint-disable react/no-danger */
import {FC} from 'react';
import clsx from 'clsx';

type ReserveStepProps = {
    className?: string;
    isSelected: boolean;
    label: string;
    step: number;
};

const ReserveStep: FC<ReserveStepProps> = ({
    className,
    isSelected,
    label,
    step,
}) => (
    <div
        className={clsx(
            'items-center text-left',
            isSelected ? 'flex' : 'hidden md:flex',
            className
        )}
    >
        <div
            className={clsx(
                'flex h-5 w-5 flex-none items-center justify-center rounded-full font-bold text-white md:h-9 md:w-9',
                isSelected ? 'bg-gold-200' : 'bg-outline'
            )}
        >
            <span className="text-sm md:text-base">{step}</span>
        </div>
        <div
            dangerouslySetInnerHTML={{__html: label.split(' ').join('<br/>')}}
            className={clsx(
                'ml-2 hidden text-xs font-semibold leading-tight md:block md:text-sm',
                !isSelected && 'unselected'
            )}
        />
        <div className="ml-2 text-sm font-semibold md:hidden">{label}</div>
    </div>
);

export default ReserveStep;
