/* eslint-disable react/boolean-prop-naming */
import {FC, ReactNode} from 'react';
import clsx from 'clsx';
import SkeletonText from '../../SkeletonText';
import SkeletonFieldRequiredText from './SkeletonFieldRequiredText';

export type SkeletonFieldLabelProps = {
    children: ReactNode;
    className?: string;
    htmlFor?: boolean;
    required?: boolean;
    step?: boolean;
};

const SkeletonFieldLabel: FC<SkeletonFieldLabelProps> = ({
    children,
    className,
    htmlFor,
    required,
    step,
}) => {
    const innerClassName = clsx(htmlFor && 'flex-initial');

    const label = (
        <div className={innerClassName}>
            <SkeletonText className="truncate text-sm" step={step}>
                {children}
            </SkeletonText>
        </div>
    );

    const requiredLabel = required ? (
        <SkeletonFieldRequiredText step={step} />
    ) : null;

    const outerClassName = clsx(
        'ml-px mb-1 flex items-center',
        htmlFor && 'justify-between',
        className
    );

    return (
        <div className={outerClassName}>
            {label}
            {requiredLabel}
        </div>
    );
};

export default SkeletonFieldLabel;
