import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import Form from 'components/Form';
import GenderSelector from 'components/Form/GenderSelector';
import InputFullName from 'components/Form/InputFullName';
import InputKanaName from 'components/Form/InputKanaName';
import InputPhone from 'components/Form/InputPhone';
import InputText from 'components/Form/InputText';
import SubmitButton from 'components/Form/SubmitButton';
import YearMonthDay from 'components/Form/YearMonthDay';
import {
    UserProfileDocument,
    UserProfileFragment,
    UserUpdateProfileDocument,
} from 'gql/generated';
import {useToastNotification} from 'state/toastNotification';
import {fromISO8601Date} from 'utils/date';
import {toPhoneNumber} from 'utils/phone';
import {getDefaultValues, ProfileFormData} from 'utils/user';

type UserProfileFormProps = {
    user: UserProfileFragment;
};

export const BasicInformationContent: FC<UserProfileFormProps> = ({user}) => {
    const {t} = useTranslation();
    const {toastWrapper} = useToastNotification();

    const methods = useForm<ProfileFormData>({
        defaultValues: getDefaultValues(user),
    });

    const {handleSubmit} = methods;

    const [userUpdateProfile] = useMutation(UserUpdateProfileDocument, {
        awaitRefetchQueries: true,
        refetchQueries: [UserProfileDocument],
    });

    const onSubmit = async ({
        countryCode,
        tel,
        ...formData
    }: ProfileFormData) => {
        await toastWrapper({
            promise: userUpdateProfile({
                variables: {
                    ...formData,
                    phoneNumber: toPhoneNumber(tel),
                },
            }),
            successMessage: t('user.basicInformation.updated'),
        });
    };

    return (
        <FormProvider {...methods}>
            <Form className="gap-y-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-step flex flex-col gap-y-4 rounded-md p-4">
                    <InputFullName />
                    <InputKanaName />
                    <div className="flex flex-col gap-4 md:gap-8 lg:flex-row lg:gap-4">
                        <InputPhone className="lg:flex-1" required={true} />
                        <InputText
                            className="lg:flex-1"
                            label={t('user.basicInformation.companyName')}
                            name="companyName"
                            placeholder={t('user.basicInformation.companyName')}
                        />
                    </div>
                    <GenderSelector />
                    <YearMonthDay
                        defaultValue={fromISO8601Date(
                            user.birthday || '2000-01-01'
                        )}
                        name="birthday"
                        required={true}
                    />
                </div>
                <div className="flex justify-center pb-2 sm:pb-0 md:justify-end">
                    <SubmitButton className="w-11/12 md:w-auto">
                        {t('form.save')}
                    </SubmitButton>
                </div>
            </Form>
        </FormProvider>
    );
};
