/* eslint-disable react/no-danger */
import {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Course, ReserveVenueFragment} from 'gql/generated';
import {sanitizeTextBlock} from 'utils/string';

export type SummaryPoliciesProps = {
    course: Course;
    isWaitlist: boolean;
    venue: ReserveVenueFragment;
};

const SummaryPolicies: FC<SummaryPoliciesProps> = ({
    course,
    isWaitlist,
    venue,
}) => {
    const {t} = useTranslation();

    return (
        <div className="space-y-4">
            {venue.reservationTerms && (
                <>
                    <h3 className="border-outline mt-4 border-t pt-4">
                        {t('reserve.summary.reservationPolicy')}
                    </h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: sanitizeTextBlock(venue.reservationTerms),
                        }}
                        className="prose prose-sm max-w-none dark:prose-dark"
                    />
                </>
            )}
            {course.supplementaryInformation && (
                <>
                    <h3 className="border-outline mt-4 border-t pt-4">
                        {t('reserve.details.supplementaryInformation')}
                    </h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: sanitizeTextBlock(
                                course.supplementaryInformation
                            ),
                        }}
                        className="prose prose-sm max-w-none dark:prose-dark"
                    />
                </>
            )}
            <div className="prose prose-sm max-w-none dark:prose-dark">
                {isWaitlist ? (
                    <>
                        <h3 className="border-outline border-t pt-4">
                            {t('reserve.summary.waitlist.about')}
                        </h3>
                        <p>
                            {t('reserve.summary.waitlist.confirmReservation')}
                        </p>
                        <p>{t('reserve.summary.waitlist.confirmationEmail')}</p>
                        <p>
                            {t(
                                'reserve.summary.waitlist.checkReservationStatus'
                            )}
                        </p>
                    </>
                ) : (
                    <>
                        <h3 className="border-outline border-t pt-4">
                            {t('reserve.summary.about')}
                        </h3>
                        <p>{t('reserve.summary.notGuaranteed')}</p>
                        <p>{t('reserve.summary.receiveEmail')}</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('reserve.summary.noEmail'),
                            }}
                            className="text-invalid links-semibold"
                        />
                        <p>
                            <Trans
                                components={{
                                    span: <span className="font-bold" />,
                                }}
                                i18nKey="reserve.summary.checkReservationStatus"
                            />
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default SummaryPolicies;
