import {FC, ReactNode} from 'react';
import {RegisterOptions, useFormContext} from 'react-hook-form';
import clsx from 'clsx';
import BaseCheckbox from 'components/Form/Base/BaseCheckbox';
import CheckboxRadioGroup from 'components/Form/CheckboxRadioGroup';
import Field from 'components/Form/Field';
import {FormContextBoolean} from 'components/Form/types';
import {getRegisterRules} from 'components/Form/utils';
import {Size} from 'types';

export type CheckboxOption = {
    disabled?: boolean;
    label: string;
    name: string;
    required?: boolean | string;
    rules?: RegisterOptions;
};

export type CheckboxesProps = {
    className?: string;
    classNameGroup?: string;
    helpText?: ReactNode;
    isHorizontal?: boolean;
    label?: ReactNode;
    options: CheckboxOption[];
    required?: boolean;
    size?: Size;
};

const Checkboxes: FC<CheckboxesProps> = ({
    className,
    classNameGroup,
    helpText,
    isHorizontal,
    label,
    options,
    required,
    size,
    ...rest
}) => {
    const {
        formState: {errors},
        register,
    } = useFormContext<FormContextBoolean>();

    const isDisabled = options.every(
        (option) => option.disabled || option.rules?.disabled
    );
    const isRequired =
        required ||
        options.every((option) => option.required || option.rules?.required);

    const errorName = options.find((option) => errors[option.name])?.name;

    return (
        <Field
            className={className}
            disabled={isDisabled}
            error={errorName ? errors[errorName] : undefined}
            helpText={helpText}
            label={label}
            required={isRequired}
            type="radio"
        >
            <CheckboxRadioGroup
                className={clsx(label && 'mt-2', classNameGroup)}
                isHorizontal={isHorizontal}
                size={size}
            >
                {options.map((option) => {
                    const rules = getRegisterRules(option);

                    return (
                        <BaseCheckbox
                            key={option.name}
                            disabled={rules?.disabled}
                            label={option.label}
                            size={size}
                            {...register(option.name, rules)}
                            required={
                                !!(rules?.required && errors[option.name])
                            }
                            {...rest}
                        />
                    );
                })}
            </CheckboxRadioGroup>
        </Field>
    );
};

export default Checkboxes;
