import {FC, ReactNode, useRef} from 'react';
import SkeletonFieldLabel from './SkeletonFieldLabel';
import SkeletonHelp from './SkeletonHelp';

type SkeletonFieldProps = {
    children: ReactNode;
    className?: string;
    helpText?: ReactNode;
    label?: ReactNode;
    required?: boolean;
    step?: boolean;
    type:
        | 'button'
        | 'checkbox'
        | 'input'
        | 'password'
        | 'radio'
        | 'select'
        | 'textarea'
        | 'value';
};

const HTML_FOR = {
    button: false,
    checkbox: false,
    input: true,
    password: true,
    radio: false,
    select: true,
    textarea: true,
    value: false,
};

const SkeletonField: FC<SkeletonFieldProps> = ({
    children,
    className,
    helpText,
    label,
    required,
    step,
    type,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const help = helpText ? (
        <SkeletonHelp helpText={helpText} step={step} />
    ) : null;

    const fieldLabel = label ? (
        <SkeletonFieldLabel
            htmlFor={HTML_FOR[type]}
            required={required}
            step={step}
        >
            {label}
        </SkeletonFieldLabel>
    ) : null;

    return (
        <div ref={ref} className={className} role="presentation">
            {fieldLabel}
            {children}
            {help}
        </div>
    );
};

export default SkeletonField;
