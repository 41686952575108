import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {useLocale} from 'state/locale';
import {toFullDate, toTime} from 'utils/date';
import {commify} from 'utils/string';
import {useReservationInformationState} from './provider';

type ReservationInformationProps = {
    className?: string;
};

const ReservationInformation: FC<ReservationInformationProps> = ({
    className,
}) => {
    const locale = useLocale();
    const {t} = useTranslation();

    const {course, values} = useReservationInformationState();

    if (!values?.date) return null;

    const {
        date,
        partySize,
        seatingType,
        time,
        waitlistArrivalEnd,
        waitlistArrivalStart,
        waitlistDeadline,
    } = values;

    const partySizeValue = Number(partySize || 0);
    const costPerGuest = Number(course?.costPerGuest || 0);
    const fixedPrice = Number(course?.fixedPrice || 0);

    const guestIcon =
        partySizeValue === 1
            ? 'user'
            : partySizeValue === 2
            ? 'user-friends'
            : 'users';

    const total = costPerGuest * partySizeValue + fixedPrice;

    return (
        <div className={clsx('grid grid-cols-1 gap-3.5', className)}>
            <div className="flex gap-2.5 text-sm">
                <div className="w-5 text-center">
                    <FontAwesomeIcon
                        className="icon"
                        icon={['far', 'calendar']}
                    />
                </div>
                <span>{toFullDate(date, locale)}</span>
            </div>
            <div
                className={clsx(
                    'grid grid-cols-2 gap-3.5',
                    !(partySizeValue || time || waitlistArrivalStart) &&
                        'hidden'
                )}
            >
                {partySizeValue > 0 && (
                    <div
                        className={clsx(
                            'text-sm',
                            waitlistArrivalStart && 'col-span-full'
                        )}
                    >
                        <div className="inline-block w-5 text-center">
                            <FontAwesomeIcon
                                className="icon"
                                icon={['fas', guestIcon]}
                            />
                        </div>
                        <span className="ml-2.5">
                            {t('reservation.guestCount', {
                                count: partySizeValue,
                            })}
                        </span>
                    </div>
                )}
                {time && (
                    <div className="text-sm">
                        <div className="inline-block w-5 text-center">
                            <FontAwesomeIcon
                                className="icon"
                                icon={['fas', 'clock']}
                            />
                        </div>
                        <span className="ml-2.5">{toTime(time, locale)}</span>
                    </div>
                )}
                {waitlistArrivalStart && waitlistArrivalEnd && (
                    <div className="col-span-full text-sm">
                        <div>
                            <div className="inline-block w-5 text-center">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={['fas', 'clock']}
                                />
                            </div>
                            <span className="ml-2.5 font-semibold">
                                {t('reservation.timeOfArrival')}
                            </span>
                        </div>
                        <div className="pl-[1.875rem]">
                            {toTime(
                                new Date(Number(waitlistArrivalStart)),
                                locale
                            )}
                            &nbsp;-&nbsp;
                            {toTime(
                                new Date(Number(waitlistArrivalEnd)),
                                locale
                            )}
                        </div>
                    </div>
                )}
                {waitlistArrivalEnd && waitlistDeadline && (
                    <div className="col-span-full text-sm">
                        <div>
                            <div className="inline-block w-5 text-center">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={['far', 'calendar']}
                                />
                            </div>
                            <span className="ml-2.5 font-semibold">
                                {t('reserve.confirmation.waitlist.deadline')}
                            </span>
                        </div>
                        <div className="pl-[1.875rem]">
                            {toFullDate(waitlistDeadline, locale)}
                        </div>
                    </div>
                )}
            </div>
            {seatingType && (
                <div className="flex gap-2.5 text-sm">
                    <div className="w-5 text-center">
                        <FontAwesomeIcon
                            className="icon"
                            icon={['fas', 'chair']}
                        />
                    </div>
                    <span>{t(`seatingType.${seatingType}`)}</span>
                </div>
            )}
            <hr className="mt-2" />
            {course?.name && (
                <div className="grid grid-cols-2 gap-2">
                    {!!costPerGuest && (
                        <>
                            <div className="text-sm">{course?.name}</div>
                            <div className="text-right text-sm">
                                {t('reservation.costPerGuestCurrency', {
                                    ...course,
                                    costPerGuest: commify(costPerGuest),
                                })}
                            </div>
                        </>
                    )}
                    {!!fixedPrice && (
                        <>
                            <div className="text-sm">{course.fixedTitle}</div>
                            <div className="text-right text-sm">
                                {t('reservation.costPerGroupCurrency', {
                                    costPerGroup: commify(fixedPrice),
                                })}
                            </div>
                        </>
                    )}
                    {!!total && (
                        <>
                            <div className="leading-none">
                                <span className="text-sm font-semibold">
                                    {t('reservation.totalAmount')}
                                </span>
                                <br />
                                <span className="text-xs">
                                    {t('reservation.taxSurcharge')}
                                </span>
                            </div>
                            <div className="text-right text-sm font-semibold">
                                ¥{commify(total)}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ReservationInformation;
