import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as ReactRouterLink, NavLink} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import clsx from 'clsx';
import {UserDocument, UserQuery} from 'gql/generated';
import {useLocale} from 'state/locale';
import {Sections} from 'types/routes';

const menuButtonClassName = (selected: boolean): string =>
    clsx(
        'text-left link-item',
        selected ? 'text-golden' : 'text-sub hover:text-body'
    );

type DesktopSideBarProps = {
    sections: Sections;
    showHeader?: boolean;
};

const DesktopSideBar: FC<DesktopSideBarProps> = ({
    sections,
    showHeader = false,
}) => {
    const {t} = useTranslation();
    const locale = useLocale();

    const {data} = useQuery<UserQuery>(UserDocument, {
        fetchPolicy: 'cache-only',
    });

    return (
        <div className="bg-step p-4">
            {showHeader && (
                <div className="border-outline mb-4 border-b px-4 pb-4">
                    <div className="text-xs">{t('user.welcome')}</div>
                    {data?.user?.id && (
                        <div className="text-2xl" data-dd-privacy="mask">
                            {t('user.fullName', data.user)}
                        </div>
                    )}
                </div>
            )}
            <div className="flex flex-col gap-4 px-4">
                {Object.values(sections).map(({path, translationKey}) => {
                    // The contact form is a salesforce page and therefore needs special treatment so that it is opened in a different tab
                    if (path === 'contact') {
                        return (
                            <ReactRouterLink
                                key={path}
                                className="link-item text-sub hover:text-body text-left"
                                rel="noopener noreferrer"
                                target="_blank"
                                to={
                                    locale === 'ja'
                                        ? (process.env
                                              .CUSTOMER_HELP_JA as string)
                                        : (process.env
                                              .CUSTOMER_HELP_EN as string)
                                }
                            >
                                {t(translationKey)}
                            </ReactRouterLink>
                        );
                    }

                    return (
                        <NavLink
                            key={path}
                            className={({isActive}) =>
                                menuButtonClassName(isActive)
                            }
                            to={path}
                        >
                            {t(translationKey)}
                        </NavLink>
                    );
                })}
            </div>
        </div>
    );
};

export default DesktopSideBar;
