import {Route, Routes} from 'react-router-dom';
import Server404 from 'components/Errors/Server404';
import AccountRoutes from './account';
import AuthenticationRoutes from './authentication';
import {oldFrontEndRedirects} from './oldFrontEndRedirects';
import PublicRoutes from './public';
import ReservationRoutes from './reservation';
import {StaticRoutesEN, StaticRoutesJA} from './static';

const AppRoutes = () => (
    <>
        {PublicRoutes()}
        {AuthenticationRoutes()}
        {ReservationRoutes()}
        {AccountRoutes()}
        {oldFrontEndRedirects()}
    </>
);

const LocalizedRoutes = () => {
    return (
        <Routes>
            <Route path="/en">
                {AppRoutes()}
                {StaticRoutesEN()}
            </Route>
            <Route path="/">
                {AppRoutes()}
                {StaticRoutesJA()}
            </Route>
            <Route element={<Server404 />} path="*" />
        </Routes>
    );
};

export default LocalizedRoutes;
