/* eslint-disable react/no-danger */
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Spinner from 'components/Loaders/Spinner';
import {VenueWithCoursesFragment} from 'gql/generated';

export type VenueAccessProps = {
    className?: string;
    venue: VenueWithCoursesFragment;
};

const VenueAccess: FC<VenueAccessProps> = ({className, venue}) => {
    const {googleMapUrl, localizedAddress, name, nearestStations} = venue;
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);

    const source = `${googleMapUrl}&width=100%25&height=400`;

    return (
        <div className={className}>
            {googleMapUrl === '' ? (
                <div className="mt-4 text-sm sm:px-0">
                    {t('venue.addressHidden')}
                </div>
            ) : (
                <>
                    <div className="text-sm sm:px-0">{localizedAddress}</div>
                    <div className="border-outline relative mt-4 border-y bg-[#f2f3f4] sm:mx-auto sm:border-x">
                        {loading && (
                            <Spinner
                                className="absolute left-0 top-0 h-[400px] w-full text-grey-800"
                                icon={['fas', 'compass']}
                                size="4x"
                                title="google"
                            />
                        )}
                        <iframe
                            allowFullScreen={false}
                            className="h-[400px] w-full overflow-hidden border-none"
                            loading="lazy"
                            onLoad={() => setLoading(false)}
                            src={source}
                            title={name}
                        />
                    </div>
                    {nearestStations?.length && (
                        <div className="mt-4 px-1 text-sm sm:px-0">
                            {nearestStations.map((item) => (
                                <div key={item} className="mt-2">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: item.trim(),
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default VenueAccess;
