/* eslint-disable react/jsx-newline */
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NoResults from 'components/NoResults';
import {VenueWithCoursesFragment} from 'gql/generated';

export type VenueRecommendationsProps = {
    venue: VenueWithCoursesFragment;
};

const VenueRecommendations: FC<VenueRecommendationsProps> = ({venue}) => {
    const {t} = useTranslation();

    const [viewAll, setViewAll] = useState(
        () => venue.recommendations?.length < 6
    );

    return (
        <div>
            {venue.recommendations && venue.recommendations.length > 0 ? (
                <ul>
                    {venue.recommendations
                        .slice(0, viewAll ? undefined : 5)
                        .map((recommendation) => (
                            <li
                                key={recommendation.comment}
                                className="border-outline mt-2.5 rounded-md border p-4"
                            >
                                <div className="grid grid-cols-8">
                                    <div className="justify-self-start">
                                        <div className="text-center">
                                            <FontAwesomeIcon
                                                icon={['fas', 'user']}
                                            />
                                            <div className="mt-px text-xs">
                                                {recommendation.ageRange}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-7">
                                        <div className="text-sub text-xs">
                                            {t('venue.dateOfVisit')}
                                            &nbsp;{recommendation.visitedAt}
                                        </div>
                                        <div className="mt-2 text-sm">
                                            {recommendation.comment}
                                        </div>
                                        <div className="text-sub mt-4 text-xs">
                                            {t('venue.numberOfVisits.label')}
                                            &nbsp;
                                            {t(
                                                `venue.numberOfVisits.${recommendation.visitFrequency}`
                                            )}
                                        </div>
                                        <div className="text-sub text-xs">
                                            {t('venue.purposeOfVisit.label')}
                                            &nbsp;
                                            {t(
                                                `venue.purposeOfVisit.${recommendation.visitPurpose}`
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    {!viewAll && (
                        <li className="mt-2.5 text-right">
                            <button
                                className="text-golden hover:underline"
                                onClick={() => setViewAll(true)}
                                type="button"
                            >
                                <span>
                                    {t('venue.viewAllReviews', {
                                        count: venue.recommendations.length,
                                    })}
                                </span>
                            </button>
                        </li>
                    )}
                </ul>
            ) : (
                <NoResults
                    delay={1}
                    icon="recommendations"
                    label={t('venue.noRecommendationsAvailable')}
                />
            )}
        </div>
    );
};

export default VenueRecommendations;
