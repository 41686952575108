import {logError} from 'hooks/useServerErrorHandling';
import {PageName} from 'pages/Analytics';
import {Locale} from 'types';

export const trackPage = (
    pageName: PageName | string | undefined,
    locale: Locale
) => {
    try {
        // Don't track if the URL hasn't changed. This happens for example if you add a query search parameter
        if (pageName && digitalData.page.pageInfo.pageName !== pageName) {
            digitalData.page.pageInfo.pageName = pageName;
            digitalData.page.pageInfo.language = locale;

            if (typeof _satellite !== 'undefined') {
                // Send the data to Adobe Analytics
                if (process.env.NODE_ENV === 'production') {
                    _satellite.track('page');
                } else {
                    console.log('track page', digitalData);
                }
            }
        }
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            logError(new Error('Could not track page', {cause: error}));
        }
    }
};

export const trackClick = (assetName: string) => {
    try {
        // We reset the events and only push what is important to the array before we send it to Adobe
        if (digitalData.event !== undefined) {
            digitalData.event.length = 0;
        }

        digitalData.event.push({
            eventInfo: {
                eventAction: 'Click',
                eventAsset: assetName,
                eventType: 'pageInteraction',
            },
        });

        if (typeof _satellite !== 'undefined') {
            // Send the data to Adobe Analytics
            if (process.env.NODE_ENV === 'production') {
                _satellite.track('event');
            } else {
                console.log('track event', digitalData);
            }
        }
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            logError(new Error('Could not track event', {cause: error}));
        }
    }
};
