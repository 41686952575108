import {FC} from 'react';
import {FieldError} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

export type FieldRequiredTextProps = {
    className?: string;
    disabled?: boolean;
    error?: FieldError;
};

const FieldRequiredText: FC<FieldRequiredTextProps> = ({
    className,
    disabled,
    error,
}) => {
    const {t} = useTranslation();

    return (
        <div
            className={clsx(
                'ml-4 w-fit select-none px-1 text-xs',
                disabled
                    ? 'text-disabled'
                    : error
                    ? 'text-invalid'
                    : 'text-golden',
                className
            )}
            role="status"
        >
            {t('form.required')}
        </div>
    );
};

export default FieldRequiredText;
