import {FC, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

export type GuestSizeSelectionProps = {
    onSelect: (value: string) => void;
    partySize?: string;
    range: number[];
};

const GuestSizeSelection: FC<GuestSizeSelectionProps> = ({
    onSelect,
    partySize,
    range,
}) => {
    const {t} = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    const options = useMemo(() => {
        const parsedOptions = range.map((value) => ({
            label: String(value),
            value: String(value),
        }));

        if (
            isExpanded ||
            range.length < 5 ||
            parsedOptions.findIndex(
                (option) => String(partySize) === option.value
            ) >= 4
        ) {
            return parsedOptions;
        }

        return parsedOptions.length > 3 && range.length < 10
            ? parsedOptions.slice(0, 4)
            : parsedOptions;
    }, [isExpanded, partySize, range]);

    return (
        <fieldset className="pb-4">
            <legend className="pt-3 pb-1 text-sm font-semibold">
                {t('search.selectGuests')}
            </legend>
            {range.length > 9 ? (
                <select
                    className="w-full select-none"
                    data-dd-privacy="allow"
                    name="partySize"
                    onChange={(event) => onSelect(event.target.value)}
                    value={partySize}
                >
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : (
                <div className="grid grid-cols-8 gap-1 sm:grid-cols-10">
                    {options.map(({value}) => (
                        <button
                            key={value}
                            className={`flex items-center justify-center rounded-md border p-1.5 text-sm font-semibold
                                    ${
                                        String(value) === partySize
                                            ? 'button-secondary-selected'
                                            : 'bg-body border-outline'
                                    }`}
                            onClick={() => onSelect(String(value))}
                            type="button"
                        >
                            <span>{value}</span>
                        </button>
                    ))}
                    {!isExpanded &&
                        range.length > 4 &&
                        options.findIndex(
                            (option) => String(partySize) === option.value
                        ) < 4 && (
                            <button
                                className="bg-body border-outline flex items-center justify-center rounded-md border p-1.5 text-sm font-semibold"
                                onClick={() => setIsExpanded(true)}
                                type="button"
                            >
                                <span>+</span>
                            </button>
                        )}
                </div>
            )}
        </fieldset>
    );
};

export default GuestSizeSelection;
