import {FC, ReactNode} from 'react';
import clsx from 'clsx';

export type SkeletonHelpProps = {
    className?: string;
    helpText: ReactNode;
    step?: boolean;
};

const SkeletonHelp: FC<SkeletonHelpProps> = ({className, helpText, step}) => (
    <div
        className={clsx(
            'skeleton mt-1 ml-px w-fit text-sm',
            step && 'step',
            className
        )}
        role="status"
    >
        <span className="text-transparent">{helpText}</span>
    </div>
);

export default SkeletonHelp;
