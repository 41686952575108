import {FC, ReactNode} from 'react';
import {FieldError} from 'react-hook-form';
import clsx from 'clsx';
import SpanOrLegend from '../../SpanOrLegend';
import FieldRequiredText from './FieldRequiredText';

export type FieldLabelProps = {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    error?: FieldError;
    htmlFor?: string;
    isLegend?: boolean;
    required?: boolean;
};

const FieldLabel: FC<FieldLabelProps> = ({
    children,
    className,
    disabled,
    error,
    htmlFor,
    isLegend,
    required,
}) => {
    const span = (
        <SpanOrLegend
            className={clsx(
                'text-sm',
                disabled ? 'text-disabled' : 'text-grey-900 dark:text-grey-50'
            )}
            isLegend={isLegend}
        >
            {children}
        </SpanOrLegend>
    );

    const requiredText = required ? (
        <FieldRequiredText disabled={disabled} error={error} />
    ) : null;

    const outerClassName = clsx(
        'ml-px mb-1 flex items-center',
        htmlFor && 'justify-between',
        className
    );

    if (htmlFor) {
        return (
            <label className={outerClassName} htmlFor={htmlFor}>
                {span}
                {requiredText}
            </label>
        );
    }

    return (
        <div className={outerClassName}>
            {span}
            {requiredText}
        </div>
    );
};

export default FieldLabel;
