/* The order in the sidebar is based on the order of the object */
/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */

import {Sections} from 'types/routes';

const staticMenuSections: Sections = {
    companyProfile: {
        path: 'company-profile',
        translationKey: 'staticPages.companyProfile',
    },
    termsOfUse: {
        path: 'terms',
        translationKey: 'staticPages.termsOfUse',
    },
    privacyPolicy: {
        path: 'privacy',
        translationKey: 'staticPages.privacyPolicy',
    },
    transaction: {
        path: 'transaction',
        translationKey: 'staticPages.transaction',
    },
    contact: {
        path: 'contact',
        translationKey: 'staticPages.contact',
    },
};

export default staticMenuSections;
