import {FC, ReactNode, useMemo} from 'react';
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    createHttpLink,
    from,
    HttpLink,
    InMemoryCache,
    InMemoryCacheConfig,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {datadogRum} from '@datadog/browser-rum';
import {isProduction} from 'utils/environment';
import {toKebabCase} from '../utils/object';
import {useCredentialsState} from './credentials';
import {useLocale} from './locale';

export type ApolloSettings = {
    cacheConfig?: InMemoryCacheConfig;
    children?: ReactNode;
    isSSRMode?: boolean;
    uri?: string;
};

type ErrorType = 'error' | 'warning';

const falsePositivesErrors = [
    ['userLogin', 'invalid login credentials. please try again.'],
];

const DEFAULT_URI =
    process.env.NODE_ENV === 'production'
        ? '/graphql'
        : process.env.GRAPHQL_URI;

const Apollo: FC<ApolloSettings> = ({
    cacheConfig,
    children,
    isSSRMode = false,
    uri = DEFAULT_URI,
}) => {
    const locale = useLocale();
    const credentials = useCredentialsState();

    const headersMiddleware = useMemo(
        () =>
            new ApolloLink((operation, forward) => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const {__typename, ...credentialHeaders} = credentials || {};

                operation.setContext(({headers = {}}) => ({
                    headers: {
                        ...headers,
                        ...toKebabCase(credentialHeaders),
                        'Accept-Language': locale,
                        'X-Auth-Origin': 'GUEST_ORIGIN',
                    },
                }));

                return forward(operation);
            }),
        [credentials, locale]
    );

    // Log any GraphQL errors or network error that occurred
    const errorLink = onError(({graphQLErrors, networkError}) => {
        if (process.env.NODE_ENV !== 'test') {
            if (graphQLErrors) {
                graphQLErrors.forEach((gqlError) => {
                    const {locations, message, path} = gqlError;
                    const locationMessage = locations
                        ? `, Location: ${JSON.stringify(locations)}`
                        : '';
                    const pathMessage = path ? `, Path: ${path}` : '';
                    // This is to replace emails with REDACTED when logging errors to Datadog RUM
                    const filteredMessage = message.replaceAll(
                        /([\w+.-]+@[\w.-]+\.[\w-]+)/gi,
                        'REDACTED'
                    );

                    // Filter errors and show only meaningful errors in the console and log to Datadog RUM
                    let errorType: ErrorType = 'error';

                    if (
                        path &&
                        path.length > 0 &&
                        falsePositivesErrors.some(
                            ([_path, _message]) =>
                                path[0] === _path &&
                                new RegExp(_message, 'i').test(message)
                        )
                    ) {
                        errorType = 'warning';
                    }

                    if (isProduction()) {
                        datadogRum.addError(
                            new Error(
                                `[GraphQL error]: Message: ${filteredMessage}${locationMessage}${pathMessage}`
                            )
                        );
                    } else if (errorType === 'warning') {
                        // eslint-disable-next-line no-console
                        console.warn(
                            `[GraphQL warning]: Message: ${filteredMessage}${locationMessage}${pathMessage}`
                        );
                    } else {
                        // eslint-disable-next-line no-console
                        console.error(
                            `[GraphQL error]: Message: ${filteredMessage}${locationMessage}${pathMessage}`
                        );
                    }
                });
            }
            // eslint-disable-next-line no-console
            if (networkError) console.error(`[Network error]: ${networkError}`);
        }
    });

    const httpLink = new HttpLink({
        uri,
    });

    const client = new ApolloClient({
        cache: new InMemoryCache(cacheConfig),
        link: isSSRMode
            ? createHttpLink({
                  ...from([headersMiddleware, errorLink, httpLink]),
                  fetch,
              })
            : from([headersMiddleware, errorLink, httpLink]),
        name: 'guest-portal',
        ssrMode: isSSRMode,
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
