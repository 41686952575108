import {FC, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import {
    addMinutes,
    differenceInMinutes,
    eachMinuteOfInterval,
    isAfter,
} from 'date-fns';
import {useLocale} from 'state/locale';
import {toTime} from 'utils/date';
import styles from './styles.module.css';

type Options = {
    end?: SelectOption[];
    start: SelectOption[];
};

const MINUTES_INTERVAL = 15;

type WaitlistArrivalProps = {
    interval: {
        end: Date;
        start: Date;
    };
    onWaitlistArrivalStart: (value: Date) => void;
    onWaitlistArrivalEnd: (value: Date) => void;
    waitlistArrivalEnd: Date | undefined;
    waitlistArrivalStart: Date | undefined;
};

const WaitlistArrival: FC<WaitlistArrivalProps> = ({
    interval,
    onWaitlistArrivalStart,
    onWaitlistArrivalEnd,
    waitlistArrivalEnd,
    waitlistArrivalStart,
}) => {
    const locale = useLocale();
    const {t} = useTranslation();

    const startValue = waitlistArrivalStart
        ? Number(waitlistArrivalStart)
        : undefined;
    const endValue = waitlistArrivalEnd
        ? Number(waitlistArrivalEnd)
        : undefined;

    const options = useMemo<Options>(() => {
        if (
            differenceInMinutes(interval.end, interval.start) < MINUTES_INTERVAL
        ) {
            return {
                end: [
                    {
                        label: toTime(interval.end, locale),
                        value: String(interval.end.getTime()),
                    },
                ],
                start: [
                    {
                        label: toTime(interval.start, locale),
                        value: String(interval.start.getTime()),
                    },
                ],
            };
        }

        const times = eachMinuteOfInterval(interval, {
            step: MINUTES_INTERVAL,
        }).map((value) => ({
            icon: ['fas', 'clock'] as IconProp,
            label: toTime(value, locale),
            value: String(value.getTime()),
        }));

        const endIndex = startValue
            ? times.findIndex(({value}) => Number(value) === startValue)
            : 0;

        return {
            end: times.slice(endIndex + 1),
            start: times.slice(0, -1),
        };
    }, [interval, locale, startValue]);

    useEffect(() => {
        if (startValue && endValue) {
            const startDate = new Date(startValue);
            const endDate = new Date(endValue);

            if (isAfter(startDate, endDate)) {
                onWaitlistArrivalEnd(
                    new Date(addMinutes(startDate, MINUTES_INTERVAL).getTime())
                );
            }
        }
    }, [endValue, onWaitlistArrivalEnd, startValue]);

    const unselectedLabel = t('venue.tabs.courses.selectTime');

    return (
        <fieldset
            className={`mt-2 pb-4
                ${options.end ? 'flex items-end justify-between gap-2 items-center' : ''}
            `}
        >
            <legend className="text-sm font-semibold pb-1">
                {t('reservation.timeOfArrival')}
            </legend>
            <div className='grid grid-cols-7 w-full place-items-center'>
                <select
                    data-dd-privacy="allow"
                    className={`col-span-3 w-full ${styles.label} ${waitlistArrivalStart ? "" : "text-grey-500 dark:text-grey-600"}`}
                    name="waitlistArrivalStart"
                    onChange={(event) => 
                        onWaitlistArrivalStart(new Date(Number(event.target.value)))
                    }
                    value={waitlistArrivalStart ? waitlistArrivalStart.getTime() : ""}
                >
                    <option value="">
                        {unselectedLabel}
                    </option>
                    {options.start.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                {options.end && (
                    <>
                        <div className="text-xl col-auto self-center">~</div>
                        <select
                            data-dd-privacy="allow"
                            className={`col-span-3 w-full ${styles.label} ${waitlistArrivalEnd ? "" : "text-grey-500 dark:text-grey-600"}`}
                            disabled={!waitlistArrivalStart}
                            name="waitlistArrivalEnd"
                            onChange={(event) => onWaitlistArrivalEnd(new Date(Number(event.target.value)))}
                            value={waitlistArrivalEnd? waitlistArrivalEnd.getTime() : ""}
                        >
                            <option value="">
                                {unselectedLabel}
                            </option>
                            {options.end.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </>
                )}
            </div>
        </fieldset>
    );
};

export default WaitlistArrival;
