import {FC, ReactNode} from 'react';
import TextArea from 'components/Form/TextArea';

type PreferenceProps = {
    id?: string;
    label: ReactNode;
    placeholder?: string;
    maxLength?: number;
    name: string;
};

const Preference: FC<PreferenceProps> = ({
    id,
    label,
    placeholder,
    maxLength = 150,
    name,
}) => (
    <TextArea
        disableNewLines={true}
        hideMaxLength={true}
        id={id}
        label={label}
        maxLength={maxLength}
        name={name}
        placeholder={placeholder}
    />
);

export default Preference;
