import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import InputText from 'components/Form/InputText';
import RadioButtons from 'components/Form/RadioButtons';
import {Option} from 'types';
import {ReserveDetailsFormData} from 'types/reserve';

const OPTIONS = ['no', 'yes'];

const Hotel: FC = () => {
    const {t} = useTranslation();
    const {watch} = useFormContext<ReserveDetailsFormData>();

    const [hotel, hotelGuestName, hotelName] = watch([
        'hotel',
        'hotelGuestName',
        'hotelName',
    ]);

    const options = OPTIONS.map((option) => ({
        label: t(`form.${option}`),
        value: option === 'yes' ? 'yes' : '',
    })) as Option[];

    return (
        <fieldset className="mt-4">
            <RadioButtons
                isHorizontal={true}
                label={t('reserve.details.hotel.label')}
                name="hotel"
                options={options}
                size="sm"
            />
            {hotel === 'yes' && (
                <>
                    <div className="mt-4 flex flex-col gap-4 md:flex-row md:gap-2 lg:gap-4">
                        <InputText
                            aria-label={t('reserve.details.hotel.name')}
                            className="md:flex-1"
                            label={t('reserve.details.hotel.name')}
                            name="hotelName"
                            placeholder={t('reserve.details.hotel.name')}
                            rules={{
                                validate: (value: string) =>
                                    (hotelGuestName.length > 0 &&
                                        value.length > 0) ||
                                    (hotelGuestName.length === 0 &&
                                        value.length === 0) ||
                                    t('form.thisFieldIsRequired'),
                            }}
                        />
                        <InputText
                            aria-label={t('reserve.details.hotel.guest')}
                            className="md:flex-1"
                            label={t('reserve.details.hotel.guest')}
                            name="hotelGuestName"
                            placeholder={t('reserve.details.hotel.guest')}
                            rules={{
                                validate: (value: string) =>
                                    (hotelName.length > 0 &&
                                        value.length > 0) ||
                                    (hotelName.length === 0 &&
                                        value.length === 0) ||
                                    t('form.thisFieldIsRequired'),
                            }}
                        />
                    </div>
                    <div className="pt-1 text-sm italic">
                        {t('reserve.details.hotel.note')}
                    </div>
                </>
            )}
        </fieldset>
    );
};

export default Hotel;
