import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import InputText from 'components/Form/InputText';

const Ryoshusho: FC = () => {
    const {t} = useTranslation();

    return (
        <fieldset className="px-4 md:px-0">
            <div className="mt-3 flex flex-col gap-4 md:flex-row md:gap-2 lg:gap-4">
                <InputText
                    aria-label={t('reserve.payment.ryoshusho.label')}
                    className="md:flex-1"
                    label={t('reserve.payment.ryoshusho.label')}
                    name="ryoshushoName"
                    placeholder={t('reserve.payment.ryoshusho.placeholder')}
                />
            </div>
        </fieldset>
    );
};

export default Ryoshusho;
