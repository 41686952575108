import {FC} from 'react';
import {useSearchParams} from 'react-router-dom';
import Link from 'components/Link';
import {VenueSearchResultFragment} from 'gql/generated';
import {useLocale} from 'state/locale';
import {Locale} from 'types';

export type VenueAreaProps = {
    className?: string;
    isClickable?: boolean;
    venue: VenueSearchResultFragment;
};

const getNameEntry = (
    venue: VenueSearchResultFragment,
    locale: Locale
): {id: string; name: string} => {
    const entry = {id: '0', name: 'NULL AREA!'};

    if (venue.area?.name) {
        // If the prefecture is Tokyo (id = 13) show prefecture name and town name
        if (venue.address.prefecture.id === '13') {
            entry.name = `${venue.address.prefecture.name}${
                locale === 'en' ? ',' : '、'
            }${venue.address.town.name}`;
        } else {
            entry.name = venue.address.prefecture.name;
        }

        // We use the area id because this is what the venue search page uses to filter for venues
        entry.id = venue.area.id;
    }

    return entry;
};

const VenueArea: FC<VenueAreaProps> = ({
    className,
    isClickable = false,
    venue,
}) => {
    const [searchParams] = useSearchParams();
    const params = searchParams.toString();
    const locale = useLocale();
    const {id, name} = getNameEntry(venue, locale);
    const areaIdString = `area=${id}`;

    if (!isClickable) {
        return <div className={`text-sub text-xs ${className}`}>{name}</div>;
    }

    return (
        <Link
            className={`text-sub text-xs ${className}`}
            to={`/restaurants?${params}${
                params.includes(areaIdString) ? '' : `&${areaIdString}`
            }`}
        >
            {name}
        </Link>
    );
};

export default VenueArea;
