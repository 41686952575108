/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
import {ReactNode} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ApolloError} from '@apollo/client';
import {datadogRum} from '@datadog/browser-rum';
import {useLocale} from 'state/locale';

export enum ErrorEnum {
    AccessTokenExpirationError = 'accessTokenExpirationError',
    AccountDeletionError = 'accountDeletionError',
    AuthenticationError = 'authenticationError',
    CreditCardError = 'creditCardError',
    CreditCardRemovalError = 'creditCardRemovalError',
    EmailTakenError = 'emailTakenError',
    EmailUpdateError = 'emailUpdateError',
    InvalidReservationForMessageError = 'invalidReservationForMessageError',
    NotFoundError = 'notFoundError',
    NotUniqueError = 'notUniqueError',
    PasswordUpdateError = 'passwordUpdateError',
    QuestionnaireStatusError = 'questionnaireStatusError',
    QuestionnaireSubmitError = 'questionnaireSubmitError',
    ReservationCancelError = 'reservationCancelError',
    ReservationGenericError = 'reservationGenericError',
    ReservationIneligibleBenefitsCardError = 'reservationIneligibleBenefitsCardError',
    ReservationIneligibleBenefitsUserError = 'reservationIneligibleBenefitsUserError',
    ReservationStatusError = 'reservationStatusError',
    ReservationUnavailableError = 'reservationUnavailableError',
    ServerError = 'serverError',
    TokenExpirationError = 'tokenExpirationError',
    UserError = 'userError',
    UserInputError = 'userInputError',
    UserNewReservationsForbidden = 'userNewReservationsForbidden',
    WaitlistDuplicatedError = 'waitlistDuplicatedError',
}

export const ERROR_DICT = new Map(
    Object.entries({
        // Generic
        INTERNAL_SERVER_ERROR: ErrorEnum.ServerError,
        NOT_FOUND_ERROR: ErrorEnum.NotFoundError,
        AUTHENTICATION_ERROR: ErrorEnum.AuthenticationError,
        TOKEN_EXPIRATION_ERROR: ErrorEnum.TokenExpirationError,
        ACCESS_TOKEN_EXPIRATION_ERROR: ErrorEnum.AccessTokenExpirationError,

        // ActiveRecord
        NOT_UNIQUE_ERROR: ErrorEnum.NotUniqueError,
        USER_INPUT_ERROR: ErrorEnum.UserInputError,

        // Account Deletion
        PENDING_RESERVATION_RECEIPT_OR_PAYMENT_AT_ACCOUNT_DELETION_ERROR:
            ErrorEnum.AccountDeletionError,

        // Reservation
        INVALID_RESERVATION_FOR_MESSAGE_ERROR:
            ErrorEnum.InvalidReservationForMessageError,
        RESERVATION_GENERIC_ERROR: ErrorEnum.ReservationGenericError,
        RESERVATION_UNAVAILABLE_ERROR: ErrorEnum.ReservationUnavailableError,
        RESERVATION_CANCELLATION_ERROR: ErrorEnum.ReservationCancelError,
        RESERVATION_CANNOT_BE_CANCELLED_DUE_TO_STATUS_ERROR:
            ErrorEnum.ReservationStatusError,
        WAITLIST_DUPLICATED_ERROR: ErrorEnum.WaitlistDuplicatedError,
        RESERVATION_INELIGIBLE_BENEFITS_USER_ERROR:
            ErrorEnum.ReservationIneligibleBenefitsUserError,
        RESERVATION_INELIGIBLE_BENEFITS_CARD_ERROR:
            ErrorEnum.ReservationIneligibleBenefitsCardError,
        USER_NEW_RESERVATIONS_FORBIDDEN: ErrorEnum.UserNewReservationsForbidden,

        // Questionnaire
        QUESTIONNAIRE_NOT_SUBMITTABLE_DUE_TO_RESERVATION_STATUS_ERROR:
            ErrorEnum.QuestionnaireSubmitError,
        QUESTIONNAIRE_ALREADY_SUBMITTED_ERROR:
            ErrorEnum.QuestionnaireStatusError,

        // Credit Card/Payment
        CREDIT_CARD_GENERAL_ERROR: ErrorEnum.CreditCardError,
        CREDIT_CARD_AUTHORIZATION_ERROR: ErrorEnum.CreditCardError,
        CREDIT_CARD_CONTACT_CARD_COMPANY_ERROR: ErrorEnum.CreditCardError,
        PENDING_RESERVATION_RECEIPT_OR_PAYMENT_AT_CREDIT_CARD_REMOVAL_ERROR:
            ErrorEnum.CreditCardRemovalError,

        // Login / Forgot Password
        USER_ERROR: ErrorEnum.UserError,
        EMAIL_TAKEN_ERROR: ErrorEnum.EmailTakenError,
    })
);

// This is a workaround until older Safari browsers start to support "Object.hasOwn"
export const hasOwn = (object: object, key: string) => {
    if (Object.hasOwn) {
        return Object.hasOwn(object, key);
    }

    // eslint-disable-next-line no-prototype-builtins
    return object.hasOwnProperty(key);
};

// Logs errors on staging and production to datadog
export const logError = (error: Error) => {
    if (process.env.NODE_ENV === 'production') {
        datadogRum.addError(error);
    } else {
        console.error(error);
    }
};

export const useErrorHandling = () => {
    const {t} = useTranslation();
    const locale = useLocale();

    return {
        logError,
        // Returns a translated error message and if available a traceId
        handleServerError: (error: ApolloError, page?: string) => {
            if (
                !hasOwn(error, 'graphQLErrors') ||
                error.graphQLErrors.length === 0
            ) {
                return {message: t('errorCodes.genericError')};
            }

            // We let the user only know about the first error which occured to not bombard them with notifications
            const firstError = error.graphQLErrors[0];

            if (hasOwn(firstError.extensions || {}, 'code')) {
                const errorCode = firstError.extensions.code as
                    | string
                    | undefined;

                if (errorCode && ERROR_DICT.has(errorCode)) {
                    const errorEntry = ERROR_DICT.get(errorCode);

                    const response: {
                        message: ReactNode;
                        traceId: string | undefined;
                    } = {
                        message: undefined,
                        traceId: undefined,
                    };

                    if (hasOwn(firstError.extensions, 'trace_id')) {
                        response.traceId = t('errorCodes.traceId', {
                            traceId: firstError.extensions.trace_id as string,
                        });
                    }

                    if (page) {
                        response.message = t([
                            `errorCodes.${page}.${ERROR_DICT.get(errorCode)}`,
                            `errorCodes.${page}.genericError`,
                            'errorCodes.genericError',
                        ]);
                    } else if (
                        errorEntry === ERROR_DICT.get('USER_INPUT_ERROR') &&
                        hasOwn(firstError.extensions, 'attribute')
                    ) {
                        response.message = t([
                            `errorCodes.userInputError.${
                                firstError.extensions.attribute as string
                            }`,
                            'errorCodes.userInputError.unknown',
                        ]);
                    } else if (
                        errorEntry ===
                        ERROR_DICT.get('USER_NEW_RESERVATIONS_FORBIDDEN')
                    ) {
                        response.message = (
                            <Trans
                                components={{
                                    Link: (
                                        <a
                                            href={
                                                locale === 'ja'
                                                    ? process.env
                                                          .CUSTOMER_HELP_JA
                                                    : process.env
                                                          .CUSTOMER_HELP_EN
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {' '}
                                        </a>
                                    ),
                                }}
                                i18nKey="errorCodes.userNewReservationsForbidden"
                            />
                        );
                    }

                    return {
                        message: (response.message ||
                            t([
                                `errorCodes.${errorEntry}`,
                                'errorCodes.genericError',
                            ])) as string,
                        traceId: response.traceId,
                        errorEntry,
                    };
                }
            }

            return {message: t('errorCodes.genericError')};
        },
    };
};
