import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {To} from 'react-router-dom';
import Link from 'components/Link';
import VenueResultCard from 'components/Venue/VenueResultCard';
import {VenueSearchResultFragment} from 'gql/generated';

type HomeVenueGroupProps = {
    blurb?: string;
    className?: string;
    name: string;
    quicklink?: To;
    venues: VenueSearchResultFragment[];
};

const HomeVenueGroup: FC<HomeVenueGroupProps> = ({
    blurb,
    className,
    name,
    quicklink,
    venues,
}) => {
    const {t} = useTranslation();

    return (
        <div className={className}>
            <div className="sm:site-container bg-body sticky inset-x-0 top-[3.25rem] z-40 flex items-start justify-between px-4 py-1 sm:static">
                <div>
                    <h3 className="text-xl">{name}</h3>
                    {blurb && (
                        <h4 className="text-sm text-grey-600 dark:text-grey-200">
                            {blurb}
                        </h4>
                    )}
                </div>
                {quicklink && (
                    <Link
                        className="relative top-[0.1875rem] ml-2 whitespace-nowrap text-sm text-gold-200"
                        to={quicklink}
                    >
                        {t('viewAll')}
                    </Link>
                )}
            </div>
            <div className="site-container mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:gap-2 2xl:gap-4">
                {venues.map((venue) => (
                    <VenueResultCard
                        key={venue.id}
                        minimal={true}
                        venue={venue}
                    />
                ))}
            </div>
        </div>
    );
};

export default HomeVenueGroup;
