import {FC} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

export type MobilePanelHeaderProps = {
    className?: string;
    label: string;
    onClick?: () => void;
};

const MobilePanelHeader: FC<MobilePanelHeaderProps> = ({
    className,
    label,
    onClick,
}) => (
    <button
        className={clsx(
            'bg-step border-outline relative z-10 flex w-full flex-none items-center justify-start rounded-none border-b px-3 pt-px sm:hidden',
            className
        )}
        onClick={onClick}
        type="button"
    >
        <FontAwesomeIcon
            className="flex-none"
            icon={['fas', 'chevron-left']}
            size="lg"
        />
        <div className="-ml-2.5 flex-1 py-2.5 text-center text-sm font-semibold">
            {label}
        </div>
    </button>
);

export default MobilePanelHeader;
