import {FC, ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ServiceType} from 'gql/generated';
import styles from './styles.module.css';

const ICONS: Record<ServiceType, IconName> = {
    [ServiceType.Lunch]: 'sun',
    [ServiceType.Dinner]: 'moon',
};

type ListTitleProps = {
    children: ReactNode;
    disabled?: boolean;
    isExpandable: boolean;
    serviceType: ServiceType;
};

export const CoursesListTitle: FC<ListTitleProps> = ({
    children,
    disabled = false,
    isExpandable,
    serviceType,
}) => {
    const {t} = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleServiceType = () => setIsExpanded(!isExpanded);

    return (
        <div className="last:pt-4">
            {isExpandable ? (
                isExpanded ? (
                    <>
                        <div
                            className={`bg-step flex flex-row justify-between rounded px-4 py-2 ${styles.shadow}`}
                            onClick={toggleServiceType}
                            onKeyDown={toggleServiceType}
                            role="button"
                            tabIndex={0}
                        >
                            <div>
                                <FontAwesomeIcon
                                    className="text-grey-700"
                                    icon={['fas', ICONS[serviceType]]}
                                />
                                <span className="ml-2">
                                    {t(`serviceTypes.${serviceType}`)}
                                </span>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={['fas', 'chevron-up']} />
                            </div>
                        </div>
                        {children}
                    </>
                ) : (
                    <div
                        className={`${
                            disabled ? 'text-disabled cursor-not-allowed' : ''
                        } bg-step flex flex-row justify-between px-4 py-2 ${
                            styles.shadow
                        } rounded`}
                        onClick={disabled ? () => null : toggleServiceType}
                        onKeyDown={disabled ? () => null : toggleServiceType}
                        role="button"
                        tabIndex={0}
                    >
                        <div>
                            <FontAwesomeIcon
                                className="text-grey-700"
                                icon={['fas', ICONS[serviceType]]}
                            />
                            <span className="ml-2">
                                {t(`viewCourses.${serviceType}`)}
                            </span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                        </div>
                    </div>
                )
            ) : (
                <>
                    <div className="mb-3">
                        <FontAwesomeIcon
                            className="text-gold-200"
                            icon={['fas', ICONS[serviceType]]}
                        />
                        <span className="ml-2">
                            {t(`serviceTypes.${serviceType}`)}
                        </span>
                    </div>
                    {children}
                </>
            )}
        </div>
    );
};

export default CoursesListTitle;
