/* eslint-disable react/no-danger */
import {FC} from 'react';
import clsx from 'clsx';
import useTimeout from 'hooks/useTimeout';
import bookmarks from './bookmarks.svg';
import dates from './dates.svg';
import recommendations from './recommendations.svg';
import reservations from './reservations.svg';
import restaurants from './restaurants.svg';

type Icon =
    | 'bookmarks'
    | 'dates'
    | 'reservations'
    | 'recommendations'
    | 'restaurants';

const Icons: Record<Icon, string> = {
    bookmarks,
    dates,
    recommendations,
    reservations,
    restaurants,
};

export type NoResultsProps = {
    className?: string;
    delay?: number;
    icon: Icon;
    label?: string;
};

const NoResults: FC<NoResultsProps> = ({
    className,
    delay = 500,
    icon,
    label,
}) => {
    const show = useTimeout(delay);
    if (!show) return null;

    return (
        <div
            className={clsx(
                'flex flex-col items-center justify-center py-6 text-sm text-grey-500 md:text-base',
                className
            )}
        >
            <div className="w-36">
                <img alt={icon} className="w-full" src={Icons[icon]} />
            </div>
            {label && (
                <p
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                    className="mt-3 text-center leading-loose"
                />
            )}
        </div>
    );
};

export default NoResults;
