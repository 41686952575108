import en from './en';
import ja from './ja';

export const countries = {en, ja};

type Country = {
    code: string;
    flag: string;
};

export const meta: Record<string, Country> = {
    AD: {code: '376', flag: '🇦🇩'},
    AE: {code: '971', flag: '🇦🇪'},
    AF: {code: '93', flag: '🇦🇫'},
    AG: {code: '1268', flag: '🇦🇬'},
    AI: {code: '1264', flag: '🇦🇮'},
    AL: {code: '355', flag: '🇦🇱'},
    AM: {code: '374', flag: '🇦🇲'},
    AO: {code: '244', flag: '🇦🇴'},
    AR: {code: '54', flag: '🇦🇷'},
    AS: {code: '1684', flag: '🇦🇸'},
    AT: {code: '43', flag: '🇦🇹'},
    AU: {code: '61', flag: '🇦🇺'},
    AW: {code: '297', flag: '🇦🇼'},
    AX: {code: '35818', flag: '🇦🇽'},
    AZ: {code: '994', flag: '🇦🇿'},
    BA: {code: '387', flag: '🇧🇦'},
    BB: {code: '1246', flag: '🇧🇧'},
    BD: {code: '880', flag: '🇧🇩'},
    BE: {code: '32', flag: '🇧🇪'},
    BF: {code: '226', flag: '🇧🇫'},
    BG: {code: '359', flag: '🇧🇬'},
    BH: {code: '973', flag: '🇧🇭'},
    BI: {code: '257', flag: '🇧🇮'},
    BJ: {code: '229', flag: '🇧🇯'},
    BL: {code: '590', flag: '🇧🇱'},
    BM: {code: '1441', flag: '🇧🇲'},
    BN: {code: '673', flag: '🇧🇳'},
    BO: {code: '591', flag: '🇧🇴'},
    BQ: {code: '599', flag: '🇧🇶'},
    BR: {code: '55', flag: '🇧🇷'},
    BS: {code: '1242', flag: '🇧🇸'},
    BT: {code: '975', flag: '🇧🇹'},
    BW: {code: '267', flag: '🇧🇼'},
    BY: {code: '375', flag: '🇧🇾'},
    BZ: {code: '501', flag: '🇧🇿'},
    CA: {code: '1', flag: '🇨🇦'},
    CC: {code: '6189162', flag: '🇨🇨'},
    CD: {code: '243', flag: '🇨🇩'},
    CF: {code: '236', flag: '🇨🇫'},
    CG: {code: '242', flag: '🇨🇬'},
    CH: {code: '41', flag: '🇨🇭'},
    CI: {code: '225', flag: '🇨🇮'},
    CK: {code: '682', flag: '🇨🇰'},
    CL: {code: '56', flag: '🇨🇱'},
    CM: {code: '237', flag: '🇨🇲'},
    CN: {code: '86', flag: '🇨🇳'},
    CO: {code: '57', flag: '🇨🇴'},
    CR: {code: '506', flag: '🇨🇷'},
    CU: {code: '53', flag: '🇨🇺'},
    CV: {code: '238', flag: '🇨🇻'},
    CW: {code: '599', flag: '🇨🇾'},
    CX: {code: '6189164', flag: '🇨🇽'},
    CY: {code: '357', flag: '🇨🇾'},
    CZ: {code: '420', flag: '🇨🇿'},
    DE: {code: '49', flag: '🇩🇪'},
    DJ: {code: '253', flag: '🇩🇯'},
    DK: {code: '45', flag: '🇩🇰'},
    DM: {code: '1767', flag: '🇩🇲'},
    DO: {code: '1809', flag: '🇩🇴'},
    DZ: {code: '213', flag: '🇩🇿'},
    EC: {code: '593', flag: '🇪🇨'},
    EE: {code: '372', flag: '🇪🇪'},
    EG: {code: '20', flag: '🇪🇬'},
    EH: {code: '212528', flag: '🇪🇭'},
    ER: {code: '291', flag: '🇪🇷'},
    ES: {code: '34', flag: '🇪🇸'},
    ET: {code: '251', flag: '🇪🇹'},
    FI: {code: '358', flag: '🇫🇮'},
    FJ: {code: '679', flag: '🇫🇯'},
    FK: {code: '500', flag: '🇫🇰'},
    FM: {code: '691', flag: '🇫🇲'},
    FO: {code: '298', flag: '🇫🇴'},
    FR: {code: '33', flag: '🇫🇷'},
    GA: {code: '241', flag: '🇬🇦'},
    GB: {code: '44', flag: '🇬🇧'},
    GD: {code: '1473', flag: '🇬🇩'},
    GE: {code: '995', flag: '🇬🇪'},
    GF: {code: '594', flag: '🇬🇫'},
    GH: {code: '233', flag: '🇬🇭'},
    GI: {code: '350', flag: '🇬🇮'},
    GL: {code: '299', flag: '🇬🇱'},
    GM: {code: '220', flag: '🇬🇲'},
    GN: {code: '224', flag: '🇬🇳'},
    GP: {code: '590', flag: '🇬🇵'},
    GQ: {code: '240', flag: '🇬🇶'},
    GR: {code: '30', flag: '🇬🇷'},
    GT: {code: '502', flag: '🇬🇹'},
    GU: {code: '1671', flag: '🇬🇺'},
    GW: {code: '245', flag: '🇬🇼'},
    GY: {code: '592', flag: '🇬🇾'},
    HK: {code: '852', flag: '🇭🇰'},
    HN: {code: '504', flag: '🇭🇳'},
    HR: {code: '385', flag: '🇭🇷'},
    HT: {code: '509', flag: '🇭🇹'},
    HU: {code: '36', flag: '🇭🇺'},
    ID: {code: '62', flag: '🇮🇩'},
    IE: {code: '353', flag: '🇮🇪'},
    IL: {code: '972', flag: '🇮🇱'},
    IM: {code: '4474576', flag: '🇮🇲'},
    IN: {code: '91', flag: '🇮🇳'},
    IO: {code: '246', flag: '🇮🇴'},
    IQ: {code: '964', flag: '🇮🇶'},
    IR: {code: '98', flag: '🇮🇷'},
    IS: {code: '354', flag: '🇮🇸'},
    IT: {code: '39', flag: '🇮🇹'},
    JE: {code: '44', flag: '🇯🇪'},
    JM: {code: '1876', flag: '🇯🇲'},
    JO: {code: '962', flag: '🇯🇴'},
    JP: {code: '81', flag: '🇯🇵'},
    KE: {code: '254', flag: '🇰🇪'},
    KG: {code: '996', flag: '🇰🇬'},
    KH: {code: '855', flag: '🇰🇭'},
    KI: {code: '686', flag: '🇰🇮'},
    KM: {code: '269', flag: '🇰🇲'},
    KN: {code: '1869', flag: '🇰🇳'},
    KP: {code: '850', flag: '🇰🇵'},
    KR: {code: '82', flag: '🇰🇷'},
    KW: {code: '965', flag: '🇰🇼'},
    KY: {code: '1345', flag: '🇰🇾'},
    KZ: {code: '7', flag: '🇰🇿'},
    LA: {code: '856', flag: '🇱🇦'},
    LB: {code: '961', flag: '🇱🇧'},
    LC: {code: '1758', flag: '🇱🇨'},
    LI: {code: '423', flag: '🇱🇮'},
    LK: {code: '94', flag: '🇱🇰'},
    LR: {code: '231', flag: '🇱🇷'},
    LS: {code: '266', flag: '🇱🇸'},
    LT: {code: '370', flag: '🇱🇹'},
    LU: {code: '352', flag: '🇱🇺'},
    LV: {code: '371', flag: '🇱🇻'},
    LY: {code: '218', flag: '🇱🇾'},
    MA: {code: '212', flag: '🇲🇦'},
    MC: {code: '377', flag: '🇲🇨'},
    MD: {code: '373', flag: '🇲🇩'},
    ME: {code: '382', flag: '🇲🇪'},
    MF: {code: '590', flag: '🇲🇫'},
    MG: {code: '261', flag: '🇲🇬'},
    MH: {code: '692', flag: '🇲🇭'},
    MK: {code: '389', flag: '🇲🇰'},
    ML: {code: '223', flag: '🇲🇱'},
    MM: {code: '95', flag: '🇲🇲'},
    MN: {code: '976', flag: '🇲🇳'},
    MO: {code: '853', flag: '🇲🇴'},
    MP: {code: '1670', flag: '🇲🇵'},
    MQ: {code: '596', flag: '🇲🇶'},
    MR: {code: '222', flag: '🇲🇷'},
    MS: {code: '1664', flag: '🇲🇸'},
    MT: {code: '356', flag: '🇲🇹'},
    MU: {code: '230', flag: '🇲🇺'},
    MV: {code: '960', flag: '🇲🇻'},
    MW: {code: '265', flag: '🇲🇼'},
    MX: {code: '52', flag: '🇲🇽'},
    MY: {code: '60', flag: '🇲🇾'},
    MZ: {code: '258', flag: '🇲🇿'},
    NA: {code: '264', flag: '🇳🇦'},
    NC: {code: '687', flag: '🇳🇨'},
    NE: {code: '227', flag: '🇳🇪'},
    NF: {code: '672', flag: '🇳🇫'},
    NG: {code: '234', flag: '🇳🇬'},
    NI: {code: '505', flag: '🇳🇮'},
    NL: {code: '31', flag: '🇳🇱'},
    NO: {code: '47', flag: '🇳🇴'},
    NP: {code: '977', flag: '🇳🇵'},
    NR: {code: '674', flag: '🇳🇷'},
    NU: {code: '683', flag: '🇳🇺'},
    NZ: {code: '64', flag: '🇳🇿'},
    OM: {code: '968', flag: '🇴🇲'},
    PA: {code: '507', flag: '🇵🇦'},
    PE: {code: '51', flag: '🇵🇪'},
    PF: {code: '689', flag: '🇵🇫'},
    PG: {code: '675', flag: '🇵🇬'},
    PH: {code: '63', flag: '🇵🇭'},
    PK: {code: '92', flag: '🇵🇰'},
    PL: {code: '48', flag: '🇵🇱'},
    PM: {code: '508', flag: '🇵🇲'},
    PR: {code: '1787', flag: '🇵🇷'},
    PT: {code: '351', flag: '🇵🇹'},
    PW: {code: '680', flag: '🇵🇼'},
    PY: {code: '595', flag: '🇵🇾'},
    QA: {code: '974', flag: '🇶🇦'},
    RE: {code: '262', flag: '🇷🇪'},
    RO: {code: '40', flag: '🇷🇴'},
    RS: {code: '381', flag: '🇷🇸'},
    RU: {code: '7', flag: '🇷🇺'},
    RW: {code: '250', flag: '🇷🇼'},
    SA: {code: '966', flag: '🇸🇦'},
    SB: {code: '677', flag: '🇸🇧'},
    SC: {code: '248', flag: '🇸🇨'},
    SD: {code: '249', flag: '🇸🇩'},
    SE: {code: '46', flag: '🇸🇪'},
    SG: {code: '65', flag: '🇸🇬'},
    SH: {code: '290', flag: '🇸🇭'},
    SI: {code: '386', flag: '🇸🇮'},
    SJ: {code: '4779', flag: '🇸🇯'},
    SK: {code: '421', flag: '🇸🇰'},
    SL: {code: '232', flag: '🇸🇱'},
    SM: {code: '378', flag: '🇸🇲'},
    SN: {code: '221', flag: '🇸🇳'},
    SO: {code: '252', flag: '🇸🇴'},
    SR: {code: '597', flag: '🇸🇷'},
    SS: {code: '211', flag: '🇸🇸'},
    ST: {code: '239', flag: '🇸🇹'},
    SV: {code: '503', flag: '🇸🇻'},
    SX: {code: '1721', flag: '🇸🇽'},
    SY: {code: '963', flag: '🇸🇾'},
    SZ: {code: '268', flag: '🇸🇿'},
    TA: {code: '290', flag: '🇹🇦'},
    TC: {code: '1649', flag: '🇹🇨'},
    TD: {code: '23516', flag: '🇹🇩'},
    TG: {code: '228', flag: '🇹🇬'},
    TH: {code: '66', flag: '🇹🇭'},
    TJ: {code: '992', flag: '🇹🇯'},
    TK: {code: '690', flag: '🇹🇰'},
    TL: {code: '670', flag: '🇹🇱'},
    TM: {code: '993', flag: '🇹🇲'},
    TN: {code: '216', flag: '🇹🇳'},
    TO: {code: '676', flag: '🇹🇴'},
    TR: {code: '90', flag: '🇹🇷'},
    TT: {code: '1868', flag: '🇹🇹'},
    TV: {code: '688', flag: '🇹🇻'},
    TW: {code: '886', flag: '🇹🇼'},
    TZ: {code: '255', flag: '🇹🇿'},
    UA: {code: '380', flag: '🇺🇦'},
    UG: {code: '256', flag: '🇺🇬'},
    US: {code: '1', flag: '🇺🇸'},
    UY: {code: '598', flag: '🇺🇾'},
    UZ: {code: '998', flag: '🇺🇿'},
    VA: {code: '3906698', flag: '🇻🇦'},
    VC: {code: '1784', flag: '🇻🇨'},
    VE: {code: '58', flag: '🇻🇪'},
    VG: {code: '1284', flag: '🇻🇬'},
    VI: {code: '1340', flag: '🇻🇮'},
    VN: {code: '84', flag: '🇻🇳'},
    VU: {code: '678', flag: '🇻🇺'},
    WF: {code: '681', flag: '🇼🇫'},
    WS: {code: '685', flag: '🇼🇸'},
    XK: {code: '383', flag: '🇽🇰'},
    YE: {code: '967', flag: '🇾🇪'},
    YT: {code: '262', flag: '🇾🇹'},
    ZA: {code: '27', flag: '🇿🇦'},
    ZM: {code: '260', flag: '🇿🇲'},
    ZW: {code: '263', flag: '🇿🇼'},
};
