import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Field from '../Field';
import {FormContextString, InputProps} from '../types';
import {getRegisterRules} from '../utils';

export type InputTextProps = InputProps & {
    hideMaxLength?: boolean;
};

const InputText: FC<InputTextProps> = ({
    className,
    classNameIcon,
    classNameInput,
    disabled,
    helpText,
    hideMaxLength,
    icon,
    id,
    label,
    maxLength,
    name,
    required,
    rules,
    ...rest
}) => {
    const {
        formState: {errors},
        register,
    } = useFormContext<FormContextString>();

    const registerRules = getRegisterRules({disabled, required, rules});

    return (
        <Field
            className={className}
            disabled={registerRules?.disabled}
            error={errors[name]}
            helpText={helpText}
            label={label}
            maxLength={hideMaxLength ? undefined : maxLength}
            name={name}
            required={!!registerRules?.required}
            type="input"
        >
            <div className={clsx(icon && 'relative')}>
                <input
                    aria-label={
                        rest['aria-label'] || label === null
                            ? undefined
                            : typeof label === 'string'
                            ? label
                            : name
                    }
                    className={clsx(
                        'w-full',
                        icon && 'pl-[2.3rem]',
                        errors[name] && 'input-invalid',
                        classNameInput
                    )}
                    id={id || name}
                    maxLength={maxLength}
                    {...register(name, registerRules)}
                    type="text"
                    {...rest}
                />
                {icon && (
                    <FontAwesomeIcon
                        className={clsx(
                            'absolute top-[0.825rem] left-3',
                            !classNameIcon?.includes('text-') &&
                                'text-disabled',
                            classNameIcon
                        )}
                        fixedWidth={true}
                        icon={icon}
                    />
                )}
            </div>
        </Field>
    );
};

export default InputText;
