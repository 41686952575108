import {FC, useRef} from 'react';
import {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import useComponentRect from 'hooks/useComponentRect';

export type SkeletonIconProps = {
    className?: string;
    icon: IconProp;
    size?: SizeProp;
    step?: boolean;
};

const SkeletonIcon: FC<SkeletonIconProps> = ({className, icon, size, step}) => {
    const ref = useRef(null);
    const rect = useComponentRect(ref);
    const style = rect.width
        ? {
              height: Math.max(rect.width, rect.height),
              width: Math.max(rect.width, rect.height),
          }
        : undefined;

    return (
        <div
            className={clsx(
                style && 'skeleton rounded-full',
                step && 'step',
                className
            )}
            style={style}
        >
            <FontAwesomeIcon
                ref={ref}
                className="text-transparent"
                icon={icon}
                size={size}
            />
        </div>
    );
};

export default SkeletonIcon;
