import {FC, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import RadioButtons from 'components/Form/RadioButtons';
import {ReserveVenueFragment, VisitFrequency} from 'gql/generated';
import useBreakpoint from 'hooks/useBreakpoint';
import {Option} from 'types';
import {ReserveDetailsFormData} from 'types/reserve';

export type VisitFrequencySelectProps = {
    venue: ReserveVenueFragment;
};

const VisitFrequencySelect: FC<VisitFrequencySelectProps> = ({venue}) => {
    const {t} = useTranslation();
    const isHorizontal = useBreakpoint('lg');

    const {watch} = useFormContext<ReserveDetailsFormData>();

    watch('visitFrequency');

    const options = useMemo(
        () =>
            Object.values(VisitFrequency).map((value) => ({
                label: t(`reserve.details.visitFrequency.${String(value)}`),
                value,
            })) as Option[],
        [t]
    );

    return (
        <RadioButtons
            aria-label={t('reserve.details.visitFrequency.label', venue)}
            data-dd-privacy="allow"
            isHorizontal={isHorizontal}
            label={t('reserve.details.visitFrequency.label', venue)}
            name="visitFrequency"
            options={options}
            required={true}
            size="sm"
        />
    );
};

export default VisitFrequencySelect;
