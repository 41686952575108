import clsx from 'clsx';
import styles from './static.module.css';

export const CompanyProfilePage = () => (
    <div
        className={clsx(
            'prose prose-sm max-w-none px-4 pt-6 dark:prose-dark md:prose md:max-w-none md:px-0',
            styles.page
        )}
    >
        <h1>会社概要</h1>
        <section>
            <h2>Pocket Conciergeがめざすもの</h2>
            <p>
                Pocket
                Conciergeは、厳選されたレストランの予約から決済までを可能にするオンライン予約サービスです。
            </p>
            <p>
                東京、京都・大阪などの日本全国の計1000以上のレストランを掲載しており、海外のお客様でも利用できるよう、英語でのご予約も可能となっております。
            </p>
            <p>
                私たちが目指すのは、言語の壁や決済の不便さなどを感じることなく、世界中の人々に日本が持つ最高の食文化を体験していただける、そんな世界を作り上げることです。
            </p>
            <p>
                ポケットコンシェルジュはアメリカン・エキスプレスのグループ会社です。
            </p>
        </section>
        <section>
            <h2>企業情報</h2>
            <table>
                <tbody>
                    <tr>
                        <th>会社名</th>
                        <td>株式会社ポケットコンシェルジュ</td>
                    </tr>
                    <tr>
                        <th>親会社</th>
                        <td>
                            アメリカン・エキスプレス・インターナショナル・インコーポレイテッド
                        </td>
                    </tr>
                    <tr>
                        <th>事業内容</th>
                        <td>
                            オンラインレストラン予約サービス「Pocket
                            Concierge」の開発及び運営
                        </td>
                    </tr>
                    <tr>
                        <th>代表</th>
                        <td>池田 博之</td>
                    </tr>
                    <tr>
                        <th>所在地</th>
                        <td>〒105-6920 東京都港区虎ノ門４－１－１</td>
                    </tr>
                    <tr>
                        <th>設立</th>
                        <td>2011年4月</td>
                    </tr>
                    <tr>
                        <th>資本金及び資本準備金</th>
                        <td>770,195,400円</td>
                    </tr>
                </tbody>
            </table>
        </section>
    </div>
);
