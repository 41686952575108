import {FC} from 'react';
import {VenueWithCoursesFragment} from 'gql/generated';
import VenueBasic from './VenueBasic';
import VenueFAQ from './VenueFAQ';
import VenueReservationTerms from './VenueReservationTerms';

export type VenueInfoProps = {
    className?: string;
    venue: VenueWithCoursesFragment;
};

const VenueInfo: FC<VenueInfoProps> = ({className, venue}) => (
    <div className={className}>
        <VenueBasic venue={venue} />
        <VenueReservationTerms venue={venue} />
        <VenueFAQ venue={venue} />
    </div>
);

export default VenueInfo;
