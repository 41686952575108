import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import Skeleton from 'components/Loaders/Skeleton';
import SkeletonIcon from 'components/Loaders/Skeleton/SkeletonIcon';
import SkeletonImage from 'components/Loaders/Skeleton/SkeletonImage';
import SkeletonText from 'components/Loaders/Skeleton/SkeletonText';
import useCurrentBreakpoint, {Breakpoint} from 'hooks/useCurrentBreakpoint';
import {useLocale} from 'state/locale';
import {toFullDate, toISO8601Date, toTime} from 'utils/date';

const IMAGE_SIZES: Record<Breakpoint, SizeProp> = {
    '': '3x',
    lg: '6x',
    md: '5x',
    sm: '4x',
    xl: '6x',
    xs: '3x',
    xxl: '6x',
};

export const ReservationListSkeleton: FC = () => {
    const {t} = useTranslation();
    const locale = useLocale();
    const breakpoint = useCurrentBreakpoint();
    const showFullDate = breakpoint !== 'md';
    const imageSize = IMAGE_SIZES[breakpoint] || '2x';

    const reservationDate = useMemo(
        () => (showFullDate ? toFullDate(new Date(), locale) : toISO8601Date()),
        [locale, showFullDate]
    );

    return (
        <Skeleton className="border-outline rounded-md border p-2.5 md:px-4 md:pb-4">
            <h3 className="border-outline hidden min-h-[2.8125rem] border-b pb-2.5 md:flex md:items-center md:justify-between md:gap-4">
                <SkeletonText className="font-semibold leading-snug line-clamp-2">
                    Restaurant name
                </SkeletonText>
            </h3>
            <div className="grid grid-cols-12 gap-4 md:mt-2.5 md:gap-6">
                <div className="col-span-4 lg:col-span-3">
                    <SkeletonImage size={imageSize} />
                </div>
                <SkeletonText className="col-span-8 font-semibold leading-snug line-clamp-3 md:hidden">
                    Restaurant name
                </SkeletonText>
                <hr className="col-span-full -mt-2 md:hidden" />
                <div className="col-span-full -mt-2 grid grid-cols-1 items-start gap-4 md:col-span-8 md:mt-0 md:grid-cols-12 md:gap-2 lg:col-span-9 lg:gap-4">
                    <div className="col-span-1 grid grid-cols-1 gap-3.5 md:col-span-4 md:pt-3 lg:col-span-5">
                        <div className="flex items-center text-sm">
                            <div className="w-5 text-center">
                                <SkeletonIcon icon={['far', 'clock']} />
                            </div>
                            <SkeletonText className="ml-2.5">
                                {reservationDate}
                            </SkeletonText>
                        </div>
                        <div className="flex items-center text-sm">
                            <div className="w-5 text-center">
                                <SkeletonIcon icon={['fas', 'clock']} />
                            </div>
                            <SkeletonText className="ml-2.5">
                                {toTime(new Date(), locale)}
                            </SkeletonText>
                        </div>
                        <div className="flex items-center text-sm">
                            <div className="w-5 text-center">
                                <SkeletonIcon icon={['fas', 'clock']} />
                            </div>
                            <SkeletonText className="ml-2.5">
                                {t('guestCount', {count: 2})}
                            </SkeletonText>
                        </div>
                    </div>
                    <div className="bg-step col-span-1 flex items-center rounded-md py-4 pr-4 md:col-span-8 lg:col-span-7">
                        <div className="flex w-14 flex-none justify-center text-2xl">
                            <SkeletonIcon
                                icon={['far', 'hourglass']}
                                size="sm"
                                step={true}
                            />
                        </div>
                        <div className="border-l-[3px] border-grey-200 pl-4 dark:border-grey-900">
                            <SkeletonText
                                className="h-3.5 overflow-hidden text-xs"
                                step={true}
                            >
                                {t('reservationStatus')}
                            </SkeletonText>
                            <SkeletonText
                                className="mt-1 h-[1.375rem] overflow-hidden font-semibold"
                                step={true}
                            >
                                {t('confirmingReservation')}
                            </SkeletonText>
                            <SkeletonText
                                className="mt-1 h-4 overflow-hidden text-sm"
                                step={true}
                            >
                                {t('confirmingReservation')}
                            </SkeletonText>
                        </div>
                    </div>
                    <div className="col-span-full place-self-center md:place-self-end">
                        <div className="flex items-center gap-1.5 text-sm">
                            <SkeletonText>
                                {t('showReservationDetails')}
                            </SkeletonText>
                            <SkeletonIcon icon={['fas', 'chevron-right']} />
                        </div>
                    </div>
                </div>
            </div>
        </Skeleton>
    );
};
