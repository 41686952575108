import {useEffect, useState} from 'react';
import clsx from 'clsx';

export type FadeInOptions = {
    delay?: string;
    duration?: string;
};

const useFadeIn = (options?: FadeInOptions): string => {
    const {delay, duration = 'duration-300'} = options || {};
    const classNames = clsx(`transition-opacity ${duration}`, delay);

    const [opacity, setOpacity] = useState(`${classNames} opacity-0`);

    useEffect(() => {
        window.setTimeout(setOpacity, 0, classNames);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return opacity;
};

export default useFadeIn;
