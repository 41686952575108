/* eslint-disable sort-keys,sort-keys-fix/sort-keys-fix */
export default {
    IS: 'アイスランド',
    IE: 'アイルランド',
    AZ: 'アゼルバイジャン',
    AF: 'アフガニスタン',
    AS: 'アメリカ領サモア',
    AE: 'アラブ首長国連邦',
    DZ: 'アルジェリア',
    AR: 'アルゼンチン',
    AW: 'アルバ',
    AL: 'アルバニア',
    AM: 'アルメニア',
    AI: 'アンギラ',
    AO: 'アンゴラ',
    AG: 'アンティグア・バーブーダ',
    AD: 'アンドラ',
    YE: 'イエメン',
    GB: 'イギリス',
    IO: 'イギリス領インド洋地域',
    IL: 'イスラエル',
    IT: 'イタリア',
    IQ: 'イラク',
    IR: 'イラン',
    IN: 'インド',
    ID: 'インドネシア',
    WF: 'ウォリス＆フツナ',
    UG: 'ウガンダ',
    UA: 'ウクライナ',
    UZ: 'ウズベキスタン',
    UY: 'ウルグアイ',
    EC: 'エクアドル',
    EG: 'エジプト',
    EE: 'エストニア',
    ET: 'エチオピア',
    ER: 'エリトリア',
    SV: 'エルサルバドル',
    OM: 'オマーン',
    NL: 'オランダ',
    AU: 'オーストラリア',
    AT: 'オーストリア',
    AX: 'オーランド諸島',
    KZ: 'カザフスタン',
    QA: 'カタール',
    CA: 'カナダ',
    CM: 'カメルーン',
    BQ: 'カリブ オランダ',
    KH: 'カンボジア',
    CV: 'カーボベルデ諸島',
    GY: 'ガイアナ',
    GA: 'ガボン',
    GM: 'ガンビア',
    GH: 'ガーナ',
    CY: 'キプロス',
    CW: 'キュラソー',
    CU: 'キューバ',
    KI: 'キリバス',
    KG: 'キルギスタン',
    GN: 'ギニア',
    GW: 'ギニア・ビサウ',
    GR: 'ギリシャ',
    KW: 'クウェート',
    CK: 'クック諸島',
    CX: 'クリスマス島',
    HR: 'クロアチア',
    GT: 'グアテマラ',
    GP: 'グアドループ',
    GU: 'グアム',
    GL: 'グリーンランド',
    GD: 'グレナダ',
    KY: 'ケイマン諸島',
    KE: 'ケニア',
    CC: 'ココス（キーリング）諸島',
    CR: 'コスタ・リカ',
    XK: 'コソボ',
    KM: 'コモロ',
    CO: 'コロンビア',
    CD: 'コンゴ-キンシャサ',
    CG: 'コンゴ-ブラザビル',
    CI: 'コートジボワール',
    SA: 'サウジアラビア',
    WS: 'サモア',
    ST: 'サントメ・プリンシペ',
    PM: 'サンピエール島＆ミクロン島',
    SM: 'サンマリノ',
    BL: 'サン・バルテルミー島',
    ZM: 'ザンビア',
    SL: 'シエラレオネ',
    SY: 'シリア',
    SG: 'シンガポール',
    SX: 'シント・マールテン',
    DJ: 'ジブチ',
    GI: 'ジブラルタル',
    JM: 'ジャマイカ',
    JE: 'ジャージー',
    GE: 'ジョージア',
    ZW: 'ジンバブエ',
    CH: 'スイス',
    SE: 'スウェーデン',
    ES: 'スペイン',
    SR: 'スリナム',
    LK: 'スリランカ',
    SK: 'スロバキア',
    SI: 'スロベニア',
    SZ: 'スワジランド',
    SJ: 'スヴァールバル諸島およびヤンマイエン島',
    SD: 'スーダン',
    SN: 'セネガル',
    RS: 'セルビア',
    KN: 'セントクリストファー・ネイビス',
    VC: 'セントビンセント・グレナディーン',
    SH: 'セントヘレナ',
    LC: 'セントルシア',
    MF: 'セント・マーチン島',
    SC: 'セーシェル',
    SO: 'ソマリア',
    SB: 'ソロモン諸島',
    TH: 'タイ',
    TJ: 'タジキスタン',
    TZ: 'タンザニア',
    TC: 'タークス・カイコス諸島',
    CZ: 'チェコ',
    TD: 'チャド',
    TN: 'チュニジア',
    CL: 'チリ',
    TV: 'ツバル',
    DK: 'デンマーク',
    TK: 'トケラウ',
    TA: 'トリスタンダクーニャ',
    TT: 'トリニダード・トバゴ',
    TM: 'トルクメニスタン',
    TR: 'トルコ',
    TO: 'トンガ',
    TG: 'トーゴ',
    DE: 'ドイツ',
    DO: 'ドミニカ共和国',
    DM: 'ドミニカ国',
    NG: 'ナイジェリア',
    NR: 'ナウル',
    NA: 'ナミビア',
    NU: 'ニウエ',
    NI: 'ニカラグア',
    NE: 'ニジェール',
    NC: 'ニューカレドニア',
    NZ: 'ニュージーランド',
    NP: 'ネパール',
    NO: 'ノルウェー',
    NF: 'ノーフォーク諸島',
    HT: 'ハイチ',
    HU: 'ハンガリー',
    VA: 'バチカン市国',
    VU: 'バヌアツ',
    BS: 'バハマ',
    BM: 'バミューダ',
    BB: 'バルバドス',
    BD: 'バングラデシュ',
    BH: 'バーレーン',
    PK: 'パキスタン',
    PA: 'パナマ',
    PG: 'パプアニューギニア',
    PW: 'パラオ',
    PY: 'パラグアイ',
    FJ: 'フィジー',
    PH: 'フィリピン',
    FI: 'フィンランド',
    FO: 'フェロー諸島',
    FK: 'フォークランド諸島',
    FR: 'フランス',
    GF: 'フランス領ギアナ',
    PF: 'フランス領ポリネシア',
    BR: 'ブラジル',
    BG: 'ブルガリア',
    BF: 'ブルキナファソ',
    BN: 'ブルネイ',
    BI: 'ブルンジ',
    BT: 'ブータン',
    PR: 'プエルトリコ',
    VN: 'ベトナム',
    BJ: 'ベナン',
    VE: 'ベネズエラ',
    BY: 'ベラルーシ',
    BZ: 'ベリーズ',
    BE: 'ベルギー',
    PE: 'ペルー',
    HK: 'ホンコン',
    HN: 'ホンジュラス',
    BA: 'ボスニア・ヘルツェゴビナ',
    BW: 'ボツワナ',
    BO: 'ボリビア',
    PT: 'ポルトガル',
    PL: 'ポーランド',
    MO: 'マカオ',
    MK: 'マケドニア',
    MG: 'マダガスカル',
    MW: 'マラウイ',
    ML: 'マリ',
    MT: 'マルタ',
    MQ: 'マルティニーク',
    MY: 'マレーシア',
    IM: 'マン島',
    MH: 'マーシャル諸島',
    FM: 'ミクロネシア',
    MM: 'ミャンマー',
    YT: 'メイヨット',
    MX: 'メキシコ',
    MZ: 'モザンビーク',
    MC: 'モナコ',
    MV: 'モルディブ',
    MD: 'モルドバ',
    MA: 'モロッコ',
    MN: 'モンゴル',
    ME: 'モンテネグロ',
    MS: 'モントセラト',
    MU: 'モーリシャス',
    MR: 'モーリタニア',
    JO: 'ヨルダン',
    LA: 'ラオス',
    LV: 'ラトビア',
    LT: 'リトアニア',
    LI: 'リヒテンシュタイン',
    LY: 'リビア',
    LR: 'リベリア',
    LU: 'ルクセンブルク',
    RW: 'ルワンダ',
    RO: 'ルーマニア',
    LS: 'レソト',
    LB: 'レバノン',
    RE: 'レユニオン',
    RU: 'ロシア',
    CN: '中国',
    CF: '中央アフリカ共和国',
    MP: '北マリアナ諸島',
    KP: '北朝鮮',
    ZA: '南アフリカ共和国',
    SS: '南スーダン',
    TW: '台湾',
    JP: '日本',
    TL: '東ティモール',
    US: '米国',
    VI: '米領バージン諸島',
    VG: '英領バージン諸島',
    EH: '西サハラ',
    GQ: '赤道ギニア',
    KR: '韓国',
};
