import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import FallbackImage from 'components/FallbackImage';
import Link from 'components/Link';
import {VenueSearchResultFragment} from 'gql/generated';
import {getS3ImageURL} from 'utils/environment';

type VenueImageCardProps = {
    className?: string;
    venue: VenueSearchResultFragment;
};

const VenueImageCard: FC<VenueImageCardProps> = ({className = '', venue}) => {
    const {t} = useTranslation();

    const [imageError, setImageError] = useState(false);
    const [featureImageError, setFeatureImageError] = useState(false);

    const {id, imageUrls, name, thumbnail} = venue;

    const image = thumbnail || imageUrls?.[0];

    const featureImage = getS3ImageURL(id);

    return (
        <Link aria-label={name} className={className} to={`/restaurants/${id}`}>
            <figure className="bg-step-dark-only link-item relative flex w-full select-none flex-col overflow-hidden rounded">
                {featureImage && !featureImageError ? (
                    <div className="w-full pb-[56.5%]">
                        <picture>
                            <img
                                alt={t('imageCarousel.imageName', {name})}
                                className="absolute left-0 top-0 h-full w-full overflow-hidden rounded-md object-cover object-center"
                                loading="lazy"
                                onError={() => setFeatureImageError(true)}
                                src={featureImage}
                            />
                        </picture>
                    </div>
                ) : image && !imageError ? (
                    <div className="w-full pb-[56.5%]">
                        <picture>
                            <img
                                alt={t('imageCarousel.imageName', {name})}
                                className="absolute left-0 top-0 h-full w-full overflow-hidden rounded-md object-cover object-center"
                                loading="lazy"
                                onError={() => setImageError(true)}
                                src={image}
                            />
                        </picture>
                    </div>
                ) : (
                    <div className="w-full pb-[56.5%]">
                        <picture>
                            <FallbackImage
                                alt={t('imageCarousel.imageName', {name})}
                                className="absolute left-0 top-0 h-full w-full overflow-hidden rounded-md object-cover object-center"
                            />
                        </picture>
                    </div>
                )}
            </figure>
            <div className="text-grey-200">{name}</div>
        </Link>
    );
};

export default VenueImageCard;
