import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import BreakpointWrapper from 'components/BreakpointWrapper';
import useBreakpoint from 'hooks/useBreakpoint';
import {checkIfWaitlist} from 'utils/params';
import ReserveStep from './ReserveStep';

type ReserveStepsProps = {
    isRealTimeBooking: boolean;
    onBack: () => void;
    step: number;
};

const ReserveSteps: FC<ReserveStepsProps> = ({
    isRealTimeBooking,
    onBack,
    step,
}) => {
    const {t} = useTranslation();
    const isDesktop = useBreakpoint('md');

    const [searchParams] = useSearchParams();
    const isWaitlist = checkIfWaitlist(searchParams);

    const detailsTitle = t(
        isWaitlist
            ? 'reserve.details.title.waitlist'
            : isRealTimeBooking
            ? 'reserve.details.title.realTimeBooking'
            : 'reserve.details.title.reservationRequest'
    );

    const summaryTitle = t(
        isWaitlist
            ? 'reserve.summary.waitlist.title'
            : isRealTimeBooking
            ? 'reserve.summary.realTimeBooking.title'
            : 'reserve.summary.reservationRequest.title'
    );

    return (
        <button
            className={clsx(
                'bg-step z-10 flex w-full select-none items-center justify-start px-3 pt-px shadow-md',
                'md:bg-body md:relative md:justify-between md:px-0 md:pt-0 md:shadow-none',
                (isDesktop || step === 1) && 'cursor-default'
            )}
            disabled={isDesktop || step === 1}
            onClick={onBack}
            type="button"
        >
            <FontAwesomeIcon
                className={clsx(
                    'flex-none md:invisible',
                    step === 1 && 'invisible'
                )}
                icon={['fas', 'chevron-left']}
                size="lg"
            />
            <BreakpointWrapper
                breakpoint="md"
                className={clsx(
                    'flex flex-1 items-center justify-center py-2.5',
                    step > 1 && '-ml-2.5'
                )}
            >
                <ReserveStep
                    isSelected={step === 1}
                    label={detailsTitle}
                    step={1}
                />
                <hr className="mx-3 hidden flex-1 md:block" />
                <ReserveStep
                    isSelected={step === 2}
                    label={t('reserve.payment.title')}
                    step={2}
                />
                <hr className="mx-3 hidden flex-1 md:block" />
                <ReserveStep
                    isSelected={step === 3}
                    label={summaryTitle}
                    step={3}
                />
            </BreakpointWrapper>
        </button>
    );
};

export default ReserveSteps;
