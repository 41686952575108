import {FC} from 'react';
import clsx from 'clsx';
import styles from './styles.module.css';

export type CloseButtonProps = {
    className?: string;
    onClick?: () => void;
};

const CloseButton: FC<CloseButtonProps> = ({className, onClick}) =>
    onClick ? (
        <button
            className={clsx(className, styles.button)}
            onClick={onClick}
            type="button"
        >
            <div />
            <div />
        </button>
    ) : (
        <div className={clsx(className, styles.button)}>
            <div />
            <div />
        </div>
    );

export default CloseButton;
