import {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {ApolloError, useLazyQuery, useQuery} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ServiceError from 'components/Errors/ServiceError';
import Spinner from 'components/Loaders/Spinner';
import {VenueCards} from 'components/MarketingContents/VenueCards';
import NoResults from 'components/NoResults';
import DateSelector, {
    DateSelectorChangeEvent,
} from 'components/Search/DateSelector';
import {addDays, isSameDay} from 'date-fns';
import {
    AvailabilitySearchDocument,
    AvailabilitySearchQuery,
    ReservableAvailability,
    SecretAvailabilitySearchDocument,
    SecretAvailabilitySearchQuery,
    ServiceType,
    VenueAvailabilityCalendarDocument,
    VenueAvailabilityCalendarQuery,
    VenueWithCoursesFragment,
    WaitlistableAvailability,
} from 'gql/generated';
import useBreakpoint from 'hooks/useBreakpoint';
import {useLocale} from 'state/locale';
import {
    getSearchDateRange,
    toFullDate,
    toISO8601Date,
    toJST,
    toTime24,
} from 'utils/date';
import {handleError} from 'utils/functions';
import {toCamelCase, withValues} from 'utils/object';
import {stringifySearchValues} from 'utils/params';
import CourseList from './CourseList';
import DateSelection from './DateSelection';
import GuestSizeSelection from './GuestSizeSelection';
import ReservationWidgetSkeleton from './ReservationWidgetSkeleton';
import SeatingSelection from './SeatingSelection';
import SecretSeatNotification from './SecretSeatNotification';
import ServiceSelection from './ServiceSelection';
import TimeSlotSelection from './TimeSlotSelection';
import useFilter from './useFilter';
import WaitlistArrival from './WaitlistArrival';
import WaitlistDeadline from './WaitlistDeadline';

type ReservationWidgetProps = {
    className?: string;
    isSecretSeat?: boolean;
    venue: VenueWithCoursesFragment;
};

const ReservationWidget: FC<ReservationWidgetProps> = ({
    className,
    isSecretSeat,
    venue,
}) => {
    const isDesktop = useBreakpoint('md');
    const {t} = useTranslation();
    const locale = useLocale();
    const {id} = useParams();

    const [secretSeatAvailabilities, setSecretSeatAvailabilities] = useState<{
        [x: string]: ReservableAvailability[];
    }>({});

    const [expirationDate, setExpirationDate] = useState<undefined | string>(
        undefined
    );

    const [availabilities, setAvailabilities] = useState<
        ReservableAvailability[] | WaitlistableAvailability[]
    >([]);

    const {id: venueId, realTimeBooking, transactionsAllowed} = venue;

    const {
        data: venueData,
        error: venueError,
        loading: venueLoading,
    } = useQuery<VenueAvailabilityCalendarQuery>(
        VenueAvailabilityCalendarDocument,
        {fetchPolicy: 'no-cache', variables: {id}}
    );

    const availabilityDates = useMemo(() => {
        if (venueData) {
            const {reservationDates, waitlistDates} =
                venueData.venue.availabilityCalendar;

            const reservation = Object.fromEntries(
                reservationDates.map((dateString) => [
                    dateString,
                    'reservation',
                ])
            );
            const waitlist = Object.fromEntries(
                waitlistDates.map((dateString) => [dateString, 'waitlist'])
            );

            return {...reservation, ...waitlist};
        }

        return undefined;
    }, [venueData]);

    const [
        loadAvailabilities,
        {
            data: availabilityData,
            error: availabilityError,
            loading: availabilityLoading,
        },
    ] = useLazyQuery<AvailabilitySearchQuery>(AvailabilitySearchDocument);

    const [
        loadSecretSeatAvailabilities,
        {
            data: secretSeatAvailabilityData,
            error: secretSeatAvailabilityError,
            loading: secretSeatAvailabilityLoading,
        },
    ] = useLazyQuery<SecretAvailabilitySearchQuery>(
        SecretAvailabilitySearchDocument
    );

    const isWaitlist =
        availabilities?.some(
            (availability) =>
                // eslint-disable-next-line no-underscore-dangle
                availability.__typename === 'WaitlistableAvailability'
        ) || false;

    const {
        allAvailableCourses,
        availableCourseIds,
        availableSeatingOptions,
        availableServiceTypes,
        date,
        guestRange,
        highlightsInterval,
        interval,
        isSubmittable,
        partySize,
        resetValues,
        seatingOptions,
        seatingType,
        selectableCourseIds,
        serviceType,
        setValue,
        time,
        timeSeries,
        waitlistArrivalEnd,
        waitlistArrivalStart,
        waitlistDeadline,
    } = useFilter({
        availabilities,
        isWaitlist,
    });

    const [dateRange] = useState(() => getSearchDateRange(date));

    const ymd = useMemo(() => toISO8601Date(date), [date]);

    // which type of availability for the selected date (reservation | waitlist | undefined)
    const availabilityType =
        availabilityDates === undefined ? undefined : availabilityDates[ymd];

    // If no date was selected for the search and we don't have an available seat for today, select tomorrow instead
    useEffect(() => {
        if (
            !isSecretSeat &&
            !isWaitlist &&
            availabilityDates &&
            !availabilityType &&
            isSameDay(date, toJST())
        ) {
            const tomorrow = addDays(toJST(), 1);

            setValue('date', tomorrow);
        }
    }, [
        availabilityDates,
        availabilityType,
        date,
        isSecretSeat,
        isWaitlist,
        setValue,
    ]);

    useEffect(() => {
        if (isSecretSeat) {
            loadSecretSeatAvailabilities({
                fetchPolicy: 'no-cache',
                variables: {venueId},
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            isSecretSeat &&
            secretSeatAvailabilityData?.secretAvailabilitySearch &&
            secretSeatAvailabilityData?.secretAvailabilitySearch.length > 0
        ) {
            const firstDate = toJST(
                new Date(
                    secretSeatAvailabilityData.secretAvailabilitySearch[0].startTime
                )
            );

            const temporarySecretSeatAvailabilities =
                secretSeatAvailabilityData.secretAvailabilitySearch.reduce(
                    (acc, current) => {
                        const dateString = toISO8601Date(
                            toJST(new Date(current.startTime))
                        );

                        if (acc[dateString] !== undefined) {
                            return {
                                ...acc,
                                [dateString]: [
                                    ...acc[dateString],
                                    current,
                                ].sort(
                                    (a, b) =>
                                        new Date(
                                            a.secretSeatExpiration
                                        ).getTime() -
                                        new Date(
                                            b.secretSeatExpiration
                                        ).getTime()
                                ),
                            };
                        }

                        return {
                            ...acc,
                            [dateString]: [current],
                        };
                    },
                    {} as {
                        [x: string]: ReservableAvailability[];
                    }
                );

            setSecretSeatAvailabilities(temporarySecretSeatAvailabilities);
            setAvailabilities(
                temporarySecretSeatAvailabilities[toISO8601Date(firstDate)]
            );
            setExpirationDate(
                temporarySecretSeatAvailabilities[toISO8601Date(firstDate)][0]
                    .secretSeatExpiration
            );
            setValue('date', firstDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSecretSeat, secretSeatAvailabilityData]);

    useEffect(() => {
        if (availabilityData?.availabilitySearch) {
            setAvailabilities(
                availabilityData.availabilitySearch as
                    | ReservableAvailability[]
                    | WaitlistableAvailability[]
            );
        }
    }, [availabilityData?.availabilitySearch]);

    const secretSeatDates = useMemo(
        () => Object.keys(secretSeatAvailabilities),
        [secretSeatAvailabilities]
    );

    // loadAvailabilities when the selected date changes
    useEffect(() => {
        // do not load availabilities if type is undefined
        if (availabilityType && !isSecretSeat) {
            loadAvailabilities({
                fetchPolicy: 'no-cache',
                variables: {
                    date: ymd,
                    venueId,
                },
            }).catch(handleError);
        }
    }, [availabilityType, isSecretSeat, loadAvailabilities, venueId, ymd]);

    const query = useMemo(() => {
        if (!isSubmittable) {
            return '';
        }

        const safeDate = toISO8601Date(date) || '';

        if (isWaitlist) {
            const safeWaitlistArrivalStart = waitlistArrivalStart
                ? toTime24(waitlistArrivalStart)
                : '';
            const safeWaitlistArrivalEnd = waitlistArrivalEnd
                ? toTime24(waitlistArrivalEnd)
                : '';
            const safeWaitlistDeadline = waitlistDeadline
                ? toISO8601Date(waitlistDeadline)
                : '';

            return stringifySearchValues(
                toCamelCase(
                    withValues({
                        date: safeDate,
                        partySize,
                        waitlistArrivalEnd: safeWaitlistArrivalEnd,
                        waitlistArrivalStart: safeWaitlistArrivalStart,
                        waitlistDeadline: safeWaitlistDeadline,
                    })
                )
            );
        }

        const safeTime = time ? toTime24(time) : '';

        return stringifySearchValues(
            toCamelCase(
                withValues({
                    date: safeDate,
                    isSecretSeat,
                    partySize,
                    seatingType,
                    time: safeTime,
                })
            )
        );
    }, [
        date,
        isSecretSeat,
        isSubmittable,
        isWaitlist,
        partySize,
        seatingType,
        time,
        waitlistArrivalEnd,
        waitlistArrivalStart,
        waitlistDeadline,
    ]);

    // Callback for the date component for reservations and waitlists
    const onDateChange = useCallback(
        (event: DateSelectorChangeEvent) => {
            if (event.value && !isSameDay(event.value, date)) {
                setValue('date', event.value);
                resetValues();
            }
        },
        [date, resetValues, setValue]
    );

    // This dropdown is only utilized in the secret seat
    const onDropdownDateChange = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            if (event.target.value) {
                setAvailabilities(secretSeatAvailabilities[event.target.value]);
                setExpirationDate(
                    secretSeatAvailabilities[event.target.value][0]
                        .secretSeatExpiration
                );

                setValue('date', toJST(new Date(event.target.value)));
            }
        },
        [secretSeatAvailabilities, setValue]
    );

    if (!transactionsAllowed) {
        return (
            <div
                className={`bg-step flex w-full flex-col items-center justify-center py-6 text-sm md:text-base ${className}`}
                role="alert"
            >
                <FontAwesomeIcon
                    className="text-invalid-step w-full"
                    icon={['fas', 'exclamation-circle']}
                    size="3x"
                />
                <div className="text-sub mt-3 text-center leading-loose">
                    {t('venue.noSeats')}
                </div>
            </div>
        );
    }

    if (venueLoading || secretSeatAvailabilityLoading) {
        return (
            <div className="flex flex h-full w-full items-center justify-center">
                <Spinner size="8x" title="Loading Availabilities" />
            </div>
        );
    }

    // In case of an error show an error component
    if (venueError || availabilityError || secretSeatAvailabilityError) {
        return (
            <ServiceError
                className={`w-full ${className}`}
                error={
                    (venueError ||
                        availabilityError ||
                        secretSeatAvailabilityError) as ApolloError
                }
            />
        );
    }

    // In case there are no secret seats, show an error and offer to go back to the normal venue show page
    if (
        isSecretSeat &&
        !secretSeatAvailabilityLoading &&
        secretSeatAvailabilityData?.secretAvailabilitySearch &&
        secretSeatAvailabilityData?.secretAvailabilitySearch.length === 0
    ) {
        return (
            <div
                className={`bg-step flex w-full flex-col items-center justify-center py-6 text-sm md:text-base ${className}`}
                role="alert"
            >
                <FontAwesomeIcon
                    className="text-invalid-step w-full"
                    icon={['fas', 'exclamation-circle']}
                    size="3x"
                />
                <div className="text-sub mt-3 text-center leading-loose">
                    <Trans
                        components={{
                            Link: (
                                <a
                                    href={
                                        locale === 'ja'
                                            ? process.env.CUSTOMER_HELP_JA
                                            : process.env.CUSTOMER_HELP_EN
                                    }
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {' '}
                                </a>
                            ),
                        }}
                        i18nKey="error.noSecretSeats"
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            <div className="border-venue-result rounded-md bg-grey-50 p-5 text-grey-800 dark:bg-grey-800 dark:text-grey-100">
                {isSecretSeat && expirationDate ? (
                    <div className="pb-2">
                        <SecretSeatNotification dateString={expirationDate} />
                        <DateSelection
                            availableDates={secretSeatDates}
                            date={date}
                            onChange={onDropdownDateChange}
                        />
                    </div>
                ) : (
                    <DateSelector
                        availabilityDates={availabilityDates}
                        className="flex-auto pb-2"
                        closeOnSelect={!isDesktop}
                        id="date-selector"
                        maxDate={dateRange.max}
                        minDate={dateRange.min}
                        onChange={onDateChange}
                        showFullDate={isDesktop}
                        value={date}
                    />
                )}
                {availabilityLoading ? (
                    <div className="skeleton mb-1 h-7 w-full text-left text-xl" />
                ) : (
                    <h2 className="pb-1 text-left text-xl">
                        {isWaitlist
                            ? t('waitlist')
                            : realTimeBooking
                            ? t('realTimeBooking')
                            : t('reservationRequest')}
                    </h2>
                )}
                {availabilityLoading ? (
                    <ReservationWidgetSkeleton
                        isWaitlist={availabilityType === 'waitlist'}
                    />
                ) : (
                    <>
                        {isWaitlist && (
                            <div className="pb-4 text-xs">
                                <div>{t('venue.waitlist.explanation')}</div>
                                <div>{t('venue.waitlist.footnote')}</div>
                            </div>
                        )}
                        {!availabilities || availabilities.length === 0 ? (
                            <NoResults
                                className="mx-auto w-[94%] md:py-8"
                                delay={250}
                                icon="dates"
                                label={t(
                                    'venue.tabs.courses.noCoursesAvailable',
                                    {
                                        date: toFullDate(date, locale),
                                    }
                                )}
                            />
                        ) : (
                            <>
                                <GuestSizeSelection
                                    onSelect={(value) => {
                                        setValue('partySize', value);
                                    }}
                                    partySize={partySize}
                                    range={guestRange}
                                />
                                <ServiceSelection
                                    availableServiceTypes={
                                        availableServiceTypes
                                    }
                                    isWaitlist={isWaitlist}
                                    onSelect={(value) => {
                                        setValue(
                                            'serviceType',
                                            value as ServiceType
                                        );
                                    }}
                                    serviceType={serviceType}
                                />
                                {isWaitlist ? (
                                    <>
                                        <WaitlistArrival
                                            interval={interval}
                                            onWaitlistArrivalEnd={(value) =>
                                                setValue(
                                                    'waitlistArrivalEnd',
                                                    value
                                                )
                                            }
                                            onWaitlistArrivalStart={(value) =>
                                                setValue(
                                                    'waitlistArrivalStart',
                                                    value
                                                )
                                            }
                                            waitlistArrivalEnd={
                                                waitlistArrivalEnd
                                            }
                                            waitlistArrivalStart={
                                                waitlistArrivalStart
                                            }
                                        />
                                        <WaitlistDeadline
                                            date={date}
                                            onSelect={(value) =>
                                                setValue(
                                                    'waitlistDeadline',
                                                    value
                                                )
                                            }
                                            waitlistDeadline={waitlistDeadline}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <TimeSlotSelection
                                            highlightsInterval={
                                                highlightsInterval
                                            }
                                            onSelect={(value: Date) => {
                                                setValue('time', value);
                                            }}
                                            time={time}
                                            times={timeSeries}
                                        />
                                        <SeatingSelection
                                            availableSeatingOptions={
                                                availableSeatingOptions
                                            }
                                            onSelect={(value) =>
                                                setValue('seatingType', value)
                                            }
                                            resetTimeSlots={() =>
                                                setValue('time', undefined)
                                            }
                                            seatingOptions={seatingOptions}
                                            seatingType={seatingType}
                                        />
                                    </>
                                )}
                                <CourseList
                                    activeServiceType={serviceType}
                                    availableCourseIds={availableCourseIds}
                                    courses={allAvailableCourses}
                                    isSubmittable={isSubmittable}
                                    query={query}
                                    selectableCourseIds={selectableCourseIds}
                                    venueId={id as string}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
            {locale === 'en' &&
                !isSecretSeat &&
                !availabilityLoading &&
                !availabilityData && <VenueCards />}
        </div>
    );
};

export default ReservationWidget;
